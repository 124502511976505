// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-title{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 42px;
}
.payment-btn{
    height: 61px;
}

.payment-btn1{
    background: linear-gradient(0deg, #ABEDD8, #ABEDD8), #ABEDD8;

}
.payment-btn1:hover{
    background: linear-gradient(0deg, #ABEDD8, #ABEDD8), #ABEDD8; 
}

.payment-btn2{
    background: #FFE81C;
}
.payment-btn2:hover{
    background: #FFE81C;
}


.payment-btn3{
    background: #F95E73;
}
.payment-btn3:hover{
    background: #F95E73;
}
.Paid{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height */
color: #000000;
}
.amount{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 48px;
/* identical to box height */


color: #FFFFFF;
}

.card-border1{
    border-radius: 30px;
    border: 2px solid black;
}`, "",{"version":3,"sources":["webpack://./src/component/panel/user/pages/Payments.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,4DAA4D;;AAEhE;AACA;IACI,4DAA4D;AAChE;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;;;AAGA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,sBAAsB;AAC1B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,4BAA4B;AAC5B,cAAc;AACd;AACA;IACI,sBAAsB;AAC1B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,4BAA4B;;;AAG5B,cAAc;AACd;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".payment-title{\r\n    font-family: 'Poppins';\r\nfont-style: normal;\r\nfont-weight: 600;\r\nfont-size: 28px;\r\nline-height: 42px;\r\n}\r\n.payment-btn{\r\n    height: 61px;\r\n}\r\n\r\n.payment-btn1{\r\n    background: linear-gradient(0deg, #ABEDD8, #ABEDD8), #ABEDD8;\r\n\r\n}\r\n.payment-btn1:hover{\r\n    background: linear-gradient(0deg, #ABEDD8, #ABEDD8), #ABEDD8; \r\n}\r\n\r\n.payment-btn2{\r\n    background: #FFE81C;\r\n}\r\n.payment-btn2:hover{\r\n    background: #FFE81C;\r\n}\r\n\r\n\r\n.payment-btn3{\r\n    background: #F95E73;\r\n}\r\n.payment-btn3:hover{\r\n    background: #F95E73;\r\n}\r\n.Paid{\r\n    font-family: 'Poppins';\r\nfont-style: normal;\r\nfont-weight: 400;\r\nfont-size: 16px;\r\nline-height: 24px;\r\n/* identical to box height */\r\ncolor: #000000;\r\n}\r\n.amount{\r\n    font-family: 'Poppins';\r\nfont-style: normal;\r\nfont-weight: 400;\r\nfont-size: 32px;\r\nline-height: 48px;\r\n/* identical to box height */\r\n\r\n\r\ncolor: #FFFFFF;\r\n}\r\n\r\n.card-border1{\r\n    border-radius: 30px;\r\n    border: 2px solid black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
