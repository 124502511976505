// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbot {
  position: absolute;
  z-index: 10;
  color: red;
  margin-top: 85vh;
  margin-left: 90%;
  width: 80px;
  cursor: pointer;
}
.campaign-main-scroller {
  /* max-height: calc(100vh - 80px); */
  /* overflow-y: hidden; */
}

/* Css  for size of sidebar width  Start */
/* .d-flex.flex-column.col {
  margin-left: -20px;
}

.main-content-main-page {
  z-index: 1;
  background-color: white;
} */
/* Css  for size of sidebar width  End */

.main-content-main-page {
  max-height: calc(100vh - 70px);
  overflow-y: auto;
}
@media (max-width: 767px) {
  .mainpage-show-sidebar {
    display: none;
  }
  .main-content-main-page {
    /* padding-top: 60px; */
  }
}

.custom-modal {
  display: flex;
  border: none;
  background: transparent;
  width: 800px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/component/account/Main Pages/MainPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;AACA;EACE,oCAAoC;EACpC,wBAAwB;AAC1B;;AAEA,0CAA0C;AAC1C;;;;;;;GAOG;AACH,wCAAwC;;AAExC;EACE,8BAA8B;EAC9B,gBAAgB;AAClB;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".chatbot {\r\n  position: absolute;\r\n  z-index: 10;\r\n  color: red;\r\n  margin-top: 85vh;\r\n  margin-left: 90%;\r\n  width: 80px;\r\n  cursor: pointer;\r\n}\r\n.campaign-main-scroller {\r\n  /* max-height: calc(100vh - 80px); */\r\n  /* overflow-y: hidden; */\r\n}\r\n\r\n/* Css  for size of sidebar width  Start */\r\n/* .d-flex.flex-column.col {\r\n  margin-left: -20px;\r\n}\r\n\r\n.main-content-main-page {\r\n  z-index: 1;\r\n  background-color: white;\r\n} */\r\n/* Css  for size of sidebar width  End */\r\n\r\n.main-content-main-page {\r\n  max-height: calc(100vh - 70px);\r\n  overflow-y: auto;\r\n}\r\n@media (max-width: 767px) {\r\n  .mainpage-show-sidebar {\r\n    display: none;\r\n  }\r\n  .main-content-main-page {\r\n    /* padding-top: 60px; */\r\n  }\r\n}\r\n\r\n.custom-modal {\r\n  display: flex;\r\n  border: none;\r\n  background: transparent;\r\n  width: 800px;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
