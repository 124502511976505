// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AiGenerate_brandBox__ogWW- {
  display: flex;
  padding: var(--Spacing-24, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-24, 5px);
  flex: 1 0;
  align-self: stretch;
}
`, "",{"version":3,"sources":["webpack://./src/component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/AiGenerate.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,SAAW;EACX,mBAAmB;AACrB","sourcesContent":[".brandBox {\r\n  display: flex;\r\n  padding: var(--Spacing-24, 16px);\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  gap: var(--Spacing-24, 5px);\r\n  flex: 1 0 0;\r\n  align-self: stretch;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandBox": `AiGenerate_brandBox__ogWW-`
};
export default ___CSS_LOADER_EXPORT___;
