// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminContent_tableHeading__61jGK th{
    color: black;
    font-size: 15px;
    font-weight: bold;
    align-items: center;
    /* display: flex; */
    /* font-weight: normal; */
    font-family: sans-serif;
}
.AdminContent_tableHeading__61jGK td{
    color: black;
    font-size: 15px;
    /* font-weight: bold; */
    align-items: center;
    /* display: flex; */
    /* font-weight: normal; */
    font-family: sans-serif;
}

.AdminContent_activePage__3TNPh{
    background-color:#47beb9;
    color: white;
    /* color:#47beb9; */
    border: 0px solid white;
}

  `, "",{"version":3,"sources":["webpack://./src/component/panel/admin/pages/AdminContent.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".tableHeading th{\r\n    color: black;\r\n    font-size: 15px;\r\n    font-weight: bold;\r\n    align-items: center;\r\n    /* display: flex; */\r\n    /* font-weight: normal; */\r\n    font-family: sans-serif;\r\n}\r\n.tableHeading td{\r\n    color: black;\r\n    font-size: 15px;\r\n    /* font-weight: bold; */\r\n    align-items: center;\r\n    /* display: flex; */\r\n    /* font-weight: normal; */\r\n    font-family: sans-serif;\r\n}\r\n\r\n.activePage{\r\n    background-color:#47beb9;\r\n    color: white;\r\n    /* color:#47beb9; */\r\n    border: 0px solid white;\r\n}\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeading": `AdminContent_tableHeading__61jGK`,
	"activePage": `AdminContent_activePage__3TNPh`
};
export default ___CSS_LOADER_EXPORT___;
