import React from 'react'

export default function BGStar() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-0.000854492" y="-0.000976562" width="28.0017" height="28.0017" rx="8" fill="#47BEB9" />
            <path d="M14 5.66577L16.5752 10.8828L22.334 11.7246L18.167 15.7832L19.1504 21.517L14 18.8084L8.84959 21.517L9.833 15.7832L5.66602 11.7246L11.4248 10.8828L14 5.66577Z" stroke="#EDF9F8" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
