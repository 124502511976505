import React, { useState } from 'react'
import "./GoogleAccountSelect.css";
import BottomArrow from "../../../../../assets/icons/select-bottom-arrow.svg";
import AddBudgetModal from '../AddBudgetModal';
import CloseIcon from "../../../../../assets/icons/close.png";
import { useNavigate } from 'react-router-dom';
export default function GoogleAccountSelect({ setChooseYourAccount }) {
    const [dropdownMenu, setdropdownMenu] = useState(false);
    const [addBudgetModal, setAddBudgetModal] = useState(false);
    const navigate = useNavigate();
    const handleChooseBtn = (url) => {
        navigate(url);
    };
    return (
        <>
            <div className='googleAccountSelectWrapper'>
                <div className="googleAccountSelectBOx">
                    <h4>Connect your own AD Account</h4>
                    <div className='selectFormAlignment'>
                        <div className="selectFormInput">
                            <label>Your Account</label>
                            <input type="text" placeholder='' />
                        </div>

                        <div className="selectFormInput">
                            <label>Ad Account</label>
                            <div className="selectionInput">
                                <input type="text" placeholder='' />
                                <div className="bottom-arrow" onClick={() => setdropdownMenu(!dropdownMenu)}>
                                    <img src={BottomArrow} alt="BottomArrow" />
                                </div>

                                <div className={dropdownMenu ? "dropdownBox open-box" : "dropdownBox hidden-box"}>
                                    <p>Page 1</p>
                                    <p>Page 2</p>
                                    <p>Page 3</p>
                                    <p>Page 4</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="next-button" onClick={() =>
                        handleChooseBtn("/main/campaign/googlecompaign/google-add-1/funding")
                    }>
                        <button>Next</button>
                    </div>
                    <div className="close-icon-alignment" onClick={(() => setChooseYourAccount(false))}>
                        <img src={CloseIcon} alt="CloseIcon" />
                    </div>
                </div>
            </div>

            {addBudgetModal && (
                <AddBudgetModal setAddBudgetModal={setAddBudgetModal} />
            )}

        </>
    )
}
