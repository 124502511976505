import React, { useEffect, useState } from 'react';
// import TestingPage from '../../../../Testing/TestingPage';
import dashboard from "../../Auth Pages/Images/dashboard.png";
// import LoadingTextAnimation, {
//     AILoaderSvg,
// } from '../../../../Testing/LoadingAnimation';
import "../../../../Testing/Testing.css";
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../axios/auth';
import Cookies from "js-cookie";
import { useSelector } from 'react-redux';
// Testing.css

const AdsLoading = () => {
const [adId,setAdId] = useState()
    const [adsDetails, setAdsDetails] = useState(null)
    const { id } = useParams();
    const userToken = Cookies.get("token");
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.loginuser);

    let currency=user?.plan[0]?.currency ;

    useEffect(() => {
        loadertext();
        // Parsing the URL
        let urlParams = new URLSearchParams(window.location.search);
        // Getting the value of the 'adType' parameter
        let adParams = urlParams.get("adType");
       

        if (adParams === "search") {
            sessionStorage.setItem("adType", "search"); // Corrected this line
            setAdsDetails(JSON.parse(sessionStorage.getItem("googleSearchAdsCompleteData")));
            setAdId(sessionStorage.getItem("adId"))

        } else if (adParams === "Display") {
            sessionStorage.setItem("adType", "Display");
            setAdsDetails(JSON.parse(sessionStorage.getItem("googleDisplayAdsCompleteData")));
            setAdId(sessionStorage.getItem("adId"))
        }
    }, []);



    useEffect(() => {
        if (adsDetails && id) {
            createAd()
        }
    }, [adsDetails, id])


    // /createads/
    const createAd = async () => {
        try {
            const res = await axios.post(`/createads/${id}`, { adsDetails ,adId,currency}, {
                headers: {
                    Authorization: `Bearer ${userToken}`, // Correct spelling
                },
            });

            if (res?.data?.status === true) {

                // navigate("/main/campaign/?status=true", { replace: true })
                navigate("/main/campignLoder?adType=Google")


            } else if (res?.data?.status !== true) {
                navigate("/main/campaign/?status=false", { replace: false })

            }

        } catch (e) {

        }
    }



    return (
        
        <div
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "999",
                backgroundImage: `url(${dashboard})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    position: "relative", // Added position property
                    backdropFilter: "blur(1px)", // Adjusted blur value
                    // background: "transparent",
                    zIndex: "100",
                    background: "rgba(0, 0, 0, 0.3)",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "40vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#edf9f8",
                        borderStartStartRadius: "50%",
                        borderStartEndRadius: "50%",
                    }}
                >
                    <div class="text-container">
                        <>
                            <div id="loadingText" class="loading-text">
                                Google Ad. Running
                            </div>
                        </>

                        <div className="AI-Loader-Text ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 16 16" fill="none">
                                <path d="M16 8.17772C16 7.51992 15.9456 7.03991 15.8277 6.54211H8.16309V9.51107H12.662C12.5714 10.2489 12.0816 11.3601 10.9931 12.1067L10.9778 12.2061L13.4012 14.0459L13.5691 14.0624C15.1111 12.6668 16 10.6134 16 8.17772Z" fill="#4285F4" />
                                <path d="M8.16189 16.0003C10.366 16.0003 12.2164 15.2891 13.5679 14.0625L10.9919 12.1067C10.3025 12.5779 9.3773 12.9068 8.16189 12.9068C6.00313 12.9068 4.17087 11.5112 3.51772 9.58228L3.42199 9.59021L0.902097 11.5014L0.869141 11.5912C2.21157 14.2046 4.96903 16.0003 8.16189 16.0003Z" fill="#34A853" />
                                <path d="M3.51935 9.58235C3.34701 9.08455 3.24727 8.55117 3.24727 8.00006C3.24727 7.44889 3.34701 6.91556 3.51028 6.41776L3.50571 6.31177L0.954236 4.36987L0.870752 4.40883C0.317475 5.49332 0 6.71114 0 8.00006C0 9.28898 0.317475 10.5068 0.870752 11.5913L3.51935 9.58235Z" fill="#FBBC05" />
                                <path d="M8.16194 3.09317C9.69484 3.09317 10.7288 3.74208 11.3185 4.28436L13.6224 2.07983C12.2074 0.790898 10.3661 -0.000244141 8.16194 -0.000244141C4.96903 -0.000244141 2.21157 1.79536 0.869141 4.40879L3.50867 6.41777C4.17089 4.48879 6.00312 3.09317 8.16194 3.09317Z" fill="#EB4335" />
                            </svg>
                            <p style={{ fontWeight: "bold" }}>Ad processing in progress...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const loadertext = () => {
    let loadingText = document.getElementById("loadingText");
    let characters;
    let currentIndex = 0;
    let timerId;
    function startAnimation() {
        characters = loadingText.innerText.split(" ");
        clearInterval(timerId); // Clear previous interval (if any)
        changeColor();
    }

    function changeColor() {
        timerId = setInterval(() => {
            if (currentIndex < characters.length) {
                characters[currentIndex] =
                    '<span style="color: #358F8B;">' +
                    characters[currentIndex] +
                    "</span>";
                loadingText.innerHTML = characters.join(" ");
                currentIndex++;
            } else {
                clearInterval(timerId); // Clear the interval after completing one cycle
                currentIndex = 0; // Reset index for the next cycle
                resetColor();
            }
        }, 500); // Change color every 500ms (adjust as needed)
    }

    function resetColor() {
        loadingText.innerHTML = "Google Ad. Running"; // Reset the text content
        loadingText.style.color = "black"; // Set text color to black
        setTimeout(startAnimation, 500); // Start the animation after 1 second delay
    }

    startAnimation(); // Start the animation initially
};




export default AdsLoading;
