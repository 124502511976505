import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./brandsetup.module.css";
import { Col, Container,  Row } from "react-bootstrap";
import { SketchPicker } from "react-color";
import {
  // BrandSelectAccountSvg,
  ColorSelect,
  CrownSvg,
  ImageSelectSvg,
  ImageSvg,
  // SaveNowSvg,
  // SaveSvg,
  Upload,
} from "../../.PagesSVG";

// import ColorBtn from "./BrandSetUpBtn";
import { Link, useNavigate } from "react-router-dom";
import { average, prominent } from "color.js";
import { DetailsSvg, ExtraColorSvg, data } from "./colorData";
import { useDispatch, useSelector } from "react-redux";
import { updateTempData } from "../../../../../Redux/templateSlice";
import {
  EditedBrand,
  addBrandsetupData,
  addCreativeEmpty,
  addCreativeNames,
  addCreativeUniqEmpty,
  addPreviewDescriptionColor,
  addPreviewHeadingColor,
  addPreviewImgkData,
  addPreviewManinColor,
  addPreviewPromoColor,
  addSingleEditDetails,
} from "../../../../../Redux/addCreateSlice";
// import { FreeStockSvg1 } from "../../Generate Page/SVg";
import { leftArrow } from "../../Campaigns/FacebookCampaign/SVG";
import { MailContext } from "../../../Auth Pages/mailContext";
import FontChanger from "./FontChange/FontChange";
// import axios, { Axios } from "axios";
import axios from "../../../../axios/auth";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
const BrandSetUp = () => {
  const [imageToggle, setImageToggle] = useState(true);
  const template = useSelector((state) => state.template.tempData);
  const editOne = useSelector((state) => state.addcreative.editOne);
  const { editAll, editBrand } = useSelector((state) => state.addcreative);
  const editOneDataArray = useSelector(
    (state) => state.addcreative.editOneData
  );
  // const user = useSelector((state) => state.loginuser.user);

  // const brandsetupData = useSelector(
  //   (state) => state.addcreative.brandseNotupData
  // );

  const [editData, setEditData] = useState({});
  const [currentfontFamily, setCurrentFontFamily] = useState();
  const [editTrue, seteditTrue] = useState(false);
  const [emainColor, setemainColor] = useState();
  const [brandLogo, setBrandLogo] = useState("");
  const [brandImage, setBrandImage] = useState("");
  const [eheadingColor, seteheadingColor] = useState();
  const [edescriptionColor, setedescriptionColor] = useState();
  const [epromotionTextColor, setePromotionTextColor] = useState();
  const [eCtaText, seteCtaText] = useState();
  const [selectedColor1, setSelectedColor1] = useState("#329da8"); // Set the initial color value
  const [flag, setFlag] = useState(false);
  const [selectedColor2, setSelectedColor2] = useState(
    editData?.HeadingColor || template?.HeadingColor || ""
  ); // Set the initial color value
  const [selectedColor3, setSelectedColor3] = useState("#d12c95"); // Set the initial color value
  const [toggle, setToggle] = useState(false);
  const [averagecolor, setAverageColor] = useState();
  const [colors, setColors] = useState(); //color array from here

  // const [bgtrue, setBgtrue] = useState(false);
  const [brandname, setBrandName] = useState();
  const [imageLogo, setImgLogo] = useState();
  const [accountImg, setAccountImg] = useState();
  const [ProTextColor, setProTextColor] = useState("black");
  const [ctaTextColor, setctaTextColor] = useState("black");
  // const [continueBtn, setcontinueBtn] = useState("");
  const fileRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = Cookies.get("token");
  const { HandleFonts, fonts } = useContext(MailContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const HandelBrandName = (e) => {
    const brandName = e.target.value.trim();
    setBrandName(brandName);
  };

  // const HandleimageToggle = () => {
  //   setImageToggle(!imageToggle);
  // };

  const HandleimageToggle = (data) => {
    if (data) {
      setImageToggle(true);
    } else {
      if (brandname) {
        setImageToggle(false);
      } else {
        // toast.error("Enter Brand Name ");
        toast.error("Enter Brand Name", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
        setImageToggle(true);
      }
    }
  };
  // editAll Functionality  Start
  useEffect(() => {
    if (editAll || editOne) {
      dispatch(addCreativeEmpty([]));
      dispatch(addCreativeUniqEmpty([]));
      dispatch(addCreativeNames(""));

      // setimgCreative(true);
    }
  }, []);

  // editAll Functionality  End

  // yaha kam karana hai
  // const template = useSelector((state)=>state.template.tempData)
  useEffect(() => {
    if (template?.image) {
      setAccountImg(template.image);
    }
    if (template?.logo) {
      setImgLogo(template?.logo);
    }
    if (template?.promotionTextColor) {
      setProTextColor(template?.promotionTextColor);
    }
    if (template?.mainColor) {
      setSelectedColor1(template && template?.mainColor);
    }
    if (template?.HeadingColor) {
      setSelectedColor2(template?.HeadingColor);
    }
    if (template?.descriptionColor) {
      setSelectedColor3(template?.descriptionColor);
    }
    if (template?.brandname) {
      setBrandName(template?.brandname);
    }
    if (template?.ctaColor) {
      setctaTextColor(template?.ctaColor);
    }
 
  }, []);



  const HandelLogo = (e) => {
    const files = e.target.files;
    const paths = [];
    const compressedPaths = [];
    const fileImage = e.target.files[0];
    setBrandLogo(fileImage);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 500; // Maximum width for the compressed image
          const MAX_HEIGHT = 500; // Maximum height for the compressed image
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.7); // Adjust quality (0.7 is 70% quality)
          paths.push(event.target.result); // Original image paths
          compressedPaths.push(compressedDataUrl); // Compressed image paths
          if (
            paths.length === files.length &&
            compressedPaths.length === files.length
          ) {
            setImgLogo(compressedPaths); // Set compressed image paths
            // Calculate colors for compressed images
            prominent(compressedDataUrl, {
              format: "hex",
              amount: 3,
            }).then((colors) => setColors(colors));
            average(compressedDataUrl).then((color) =>
              setAverageColor(`rgba(${color.join(",")}, .3)`)
            );
            // Calculate colors for original images
            paths.forEach((originalPath) => {
              prominent(originalPath, {
                format: "hex",
                amount: 3,
              }).then((colors) => console.log()); // Set original image colors or do something with them
              average(originalPath).then((color) =>
                console.log()
              ); // Set original image average color or do something with it
            });
          }
        };
      };
      reader.readAsDataURL(file);
    }
    setFlag(true);
  };

  const handleCloseModal = () => {
    // Close the modal by setting isModalOpen to false
    setModalOpen(false);
  };
  const [imgPic, setImgPic] = useState();

  const HandelAccountImage = (e) => {
    const fileSize = e.target.files[0].size;

    if (fileSize > 2 * 1024 * 1024) {
      // File size exceeds 2MB, handle accordingly
      // toast.error("Please upload a file below 2MB.");
      toast.error("Please upload a file below 2MB.", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      
      // Clear the file input value to allow selecting the same file again
      e.target.value = null;
    } else {
      setImgPic(e.target.files);
      setModalOpen(true);
      // Clear the file input value to allow selecting the same file again
    }
  };

  const updateBrandApi = async (formData) => {
    try {
       await axios.post("/update-brand", formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {}
  };

  const brandApi = async (formData) => {
    try {
      const res = await axios.post("/create-brand", formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(EditedBrand(res?.data?.data?.id));
    } catch (error) {}
  };

  const withBg = () => {
    setModalOpen(false);
    const files = imgPic;
    const formData = new FormData();
    formData.append("files", files[0]);
    bgRemove(files);
  };

  const withoutBg = () => {
    setModalOpen(false);
    const files = imgPic;
    const paths = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const MAX_WIDTH = 500; // Maximum width for the compressed image
          const MAX_HEIGHT = 500; // Maximum height for the compressed image
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.7); // Adjust quality (0.7 is 70% quality)
          paths.push(compressedDataUrl);

          if (paths.length === files.length) {
            localStorage.setItem("accountImg", JSON.stringify(paths)); // Store compressed images in localStorage
            setAccountImg(paths);
            dispatch(addPreviewImgkData(paths));
            // Update state with compressed image paths
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const bgRemove = async (formData) => {
    try {
      const res = await axios.post("/bg-remove", formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });


      if (res?.data && res?.data?.data) {
        setAccountImg(`data:image/jpeg;base64,${res?.data?.data}`);
        dispatch(addPreviewImgkData(`data:image/jpeg;base64,${res?.data?.data}`));
      } else {
      
      }
    } catch (error) {
      // console.error("Error uploading image:", error);

      if (error.response && error.response.status === 400) {
        // Handle specific HTTP error (e.g., validation error)
        // toast.error("Please upload a file below 2MB.");
        toast.error("Please upload a file below 2MB.", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
        setImgPic("");
      } else {
        // Handle other errors
        setImgPic("");
        // toast.error("An error occurred while uploading the file.");
        toast.error("An error occurred while uploading the file.", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
  
  
      }
    }
  };

  useEffect(() => {
    if (imageLogo) {
      dispatch(updateTempData({ ...template, logo: imageLogo }));
    }
  }, [imageLogo]);

  const HandleSelectLogoColor = (color1, color2, color3) => {
    setSelectedColor1(color1);
    setSelectedColor2(color2);
    setSelectedColor3(color3);
    setFlag(true);
  };

  const HandleChangeSelectColor1 = (color) => {
    setFlag(true);
    setSelectedColor1(color.hex);
  };

  const HandleChangeSelectColor2 = (color) => {
    setFlag(true);
    setSelectedColor2(color.hex);
  };

  const HandleChangeSelectColor3 = (color) => {
    setFlag(true);
    setSelectedColor3(color.hex);
  };

  const HandleNavigate = async () => {
    await saveDataContinue();
    navigate("/main/Generate/background");
  };

  useEffect(() => {
    if (colors) {
      HandleSelectLogoColor(colors[0], colors[1], colors[2]);
    }
  }, [colors]);

  const saveData = async () => {
    await sendTempdata();

    navigate("/main/Generate/inputpage");
  };

  const saveDataContinue = async () => {
    await sendTempdata();
  };

  useEffect(() => {
    dispatch(addPreviewManinColor(selectedColor1));
    dispatch(addPreviewHeadingColor(selectedColor2));
    dispatch(addPreviewDescriptionColor(selectedColor3));
    dispatch(addPreviewPromoColor(ProTextColor));
  }, [selectedColor1, selectedColor2, selectedColor3, ProTextColor]);

  const sendTempdata = () => {
    let obj = {
      promotionTextColor: ProTextColor,
      ctaColor: ctaTextColor,
      image: accountImg,
      brandname: brandname,
      logo: imageLogo,
      mainColor: selectedColor1,
      HeadingColor: selectedColor2,
      descriptionColor: selectedColor3,
      selectedFontFamily: fonts,
    };

    const formData = new FormData();
    formData.append("promotionTextColor", ProTextColor);
    formData.append("ctaColor", ctaTextColor);
    formData.append("image", brandImage);
    formData.append("brandname", brandname);
    formData.append("logo", brandLogo);
    formData.append("mainColor", selectedColor1);
    formData.append("HeadingColor", selectedColor2);
    formData.append("descriptionColor", selectedColor3);
    formData.append("selectedFontFamily", fonts);
    formData.append("ctaText", "testCtaText");
    formData.append("promotionText", "Promot");
    formData.append("heading", "");
    formData.append("description", "Descr");
    formData.append("ctaIcon", "CtaIcon");
    // BrandList Code Start
    if (editBrand) {
      formData.append("brandId", editBrand);
      updateBrandApi(formData);
    } else {
      brandApi(formData);
    }
    // BrandList Code End
    const updateData = { ...template, ...obj };
    if (editOne) {
      dispatch(addBrandsetupData({ ...obj }));
      dispatch(addSingleEditDetails({ ...updateData, editOne: editOne }));
    } else {
      dispatch(updateTempData(updateData));
    }
  };

  useEffect(() => {
    function getLastWatchObject(editOneDataArray) {
      for (let i = editOneDataArray.length - 1; i >= 0; i--) {
        if (editOneDataArray[i].category === editOne) {
          seteditTrue(true);
          return { lastObject: editOneDataArray[i] };
        }
      }
      seteditTrue(false);
      return { lastWatchObject: null }; // Return an object with null if no object with category "watch" is found
    }
    let result = getLastWatchObject(editOneDataArray);
    let editOneData = result.lastObject;
    setEditData(editOneData);
    if (editData) {
      setemainColor(editData.mainColor || "");
      seteheadingColor(editData.HeadingColor || "");
      setedescriptionColor(editData.descriptionColor || "");
      setePromotionTextColor(editData.promotionTextColor || "");
      seteCtaText(editData.ctaColor || "");
      // selectedColor(editData.HeadingColor || "")
      // setCtaIcon(editData.ctaIcon || []);
    }
    // setHeading(editData?.heading)
    seteditTrue(true);
  }, [editData]);
  const setctaTextColorfun = (e) => {
    setFlag(true);

    setctaTextColor(e.target.value);
    setFlag(true);
  };
  const setProTextColorfun = (e) => {
    setFlag(true);
    setProTextColor(e.target.value);
    setFlag(true);
  };

  useEffect(() => {
    if (editData) {
      if (flag) {
        const HandleChangeSelectColor1 = (color) => {
          setFlag(true);
          setSelectedColor1(color.hex);
        };
      } else {
        setSelectedColor1(editData?.mainColor || template?.mainColor || "");
        setSelectedColor2(
          editData?.HeadingColor || template?.HeadingColor || ""
        );
        setSelectedColor3(
          editData?.descriptionColor || template?.descriptionColor || ""
        );
      }
    }
    // setctaTextColorfun()
  }, [selectedColor1]);

  const HandleCurrentFonts = (e) => {
    setCurrentFontFamily(e);
    HandleFonts(e);
  };

  return (
    <>
      {imageToggle ? (
        <Container fluid className={style.brandMain}>
          <Row>
            <div className={style.brandHeading}>
              <h3>Brand Setup</h3>

              <p>
                To generate ad creatives, you must have a brand, so let's setup
                it up.
              </p>
            </div>
          </Row>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "32px" }}
          >
            <Row style={{}} className={style.brandSetupInput}>
              <div className={style.colorSelect} style={{ width: "auto" }}>
                {DetailsSvg}
                <label>Brand Details's</label>
              </div>
              <div className={`col-4 ${style.InputBrandWidth}`}>
                <InputBrand
                  svg={CrownSvg}
                  label={"Brand Name"}
                  // DefaultValue={user?.companyName}
                  DefaultValue={brandname}
                  placeholder={"Saas - Guru"}
                  HandelOnChange={HandelBrandName}
                />
              </div>
              <div className={`col-4 ${style.InputBrandWidth}`}>
                {/* <InputBrand
                svg={Upload}
                type={"File"}
                label={"Logo Upload"}
                fileRef={fileRef}
                HandelOnChange={HandelLogo}
              /> */}

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className={style.brandNameFile}>
                    {Upload}
                    <label>Logo Uploadqq</label>

                    <input
                      id="file"
                      type="file"
                      accept="image/*"
                      ref={fileRef && fileRef}
                      onChange={HandelLogo}
                    />
                    <label
                      style={{ color: "#f2f2f2" }}
                      for="file"
                      class="btn-2"
                    >
                      upload
                    </label>
                  </div>
                  {imageLogo && (
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "10px",
                      }}
                      src={imageLogo}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </Row>
            <Row>
              <Col className={style.colorSelectCol}>
                <div style={{ position: "absolute" }}>
                  {toggle && (
                    <ColorSelectionModal
                      colors={colors}
                      HandleSelectLogoColor={HandleSelectLogoColor}
                      selectedColor1={selectedColor1}
                      selectedColor2={selectedColor2}
                      selectedColor3={selectedColor3}
                      HandleChangeSelectColor1={HandleChangeSelectColor1}
                      HandleChangeSelectColor2={HandleChangeSelectColor2}
                      HandleChangeSelectColor3={HandleChangeSelectColor3}
                      HandleMainToggle={() => setToggle(false)}
                    />
                  )}
                </div>

                <div className={style.colorSelectFields}>
                  <div className={style.colorSelect}>
                    {ColorSelect}
                    <label>Color Select</label>
                  </div>
                  <ColorPicker
                    heading={"Main Color"}
                    // selectedColor={ true ? "1111" : selectedColor1}
                    selectedColor={
                      flag
                        ? selectedColor1
                        : editTrue
                        ? editData?.mainColor ||
                          template?.mainColor ||
                          selectedColor1 ||
                          ""
                        : emainColor
                        ? emainColor
                        : selectedColor1
                    }
                    setSelectedColor={setSelectedColor1}
                    subHeading={"Background, Button"}
                    id={"colorpicker1"}
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                  />
                  <ColorPicker
                    // selectedColor={selectedColor2}
                    selectedColor={
                      flag
                        ? selectedColor2
                        : editTrue
                        ? editData?.HeadingColor ||
                          template?.HeadingColor ||
                          selectedColor2 ||
                          ""
                        : eheadingColor
                        ? eheadingColor
                        : selectedColor2
                    }
                    setSelectedColor={setSelectedColor2}
                    heading={" Heading"}
                    subHeading={"Used for the title"}
                    id={"colorpicker2"}
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                  />

                  <ColorPicker
                    // selectedColor={selectedColor3}
                    selectedColor={
                      flag
                        ? selectedColor3
                        : editTrue
                        ? editData?.descriptionColor ||
                          template?.descriptionColor ||
                          selectedColor3 ||
                          ""
                        : edescriptionColor
                        ? edescriptionColor
                        : selectedColor3
                    }
                    setSelectedColor={setSelectedColor3}
                    heading={"Description"}
                    subHeading={"Used for the description"}
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                  />
                </div>

                <div
                  className={`d-flex ${style.ExtraColorsBandPage}`}
                  style={{}}
                >
                  <div
                    className={style.colorSelect}
                    style={{ padding: "0px 21px" }}
                  >
                    {ExtraColorSvg}
                    <label>Extra&nbsp;Color</label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      // flexDirection: "column",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={`${style.colorSelectMain} d-flex align-items-center mx-2 `}
                      style={{ padding: "14px 28px", gap: "23px" }}
                    >
                      <p
                      // style={{
                      //   color: "black",
                      //   fontSize: "14px",
                      //   alignItems: "center",
                      //   margin: "10px",
                      //   paddingBottom: "10px",
                      // }}
                      >
                        Promotion Text
                      </p>
                      <input
                        type="color"
                        className={`me-2 ${style.colorPickerBrands}`}
                        style={{ fontSize: "12px" }}
                        value={
                          flag
                            ? ProTextColor
                            : editTrue
                            ? editData?.promotionTextColor ||
                              template?.promotionTextColor ||
                              ProTextColor ||
                              ""
                            : epromotionTextColor
                            ? epromotionTextColor
                            : ProTextColor
                        }
                        // onChange={(e) => setProTextColor(e.target.value)}
                        onChange={(e) => setProTextColorfun(e)}
                      />
                    </div>
                    <div
                      className={`${style.colorSelectMain} d-flex align-items-center mx-2`}
                      style={{
                        border: "2px solid var(--Linear, #f58529)",
                        borderRadius: "var(--Spacing-24, 24px)",
                        gap: "23px",
                      }}
                    >
                      <p
                      // style={{
                      //   color: "black",
                      //   fontSize: "14px",
                      //   alignItems: "center",
                      //   margin: "10px",
                      //   paddingBottom: "10px",
                      // }}
                      >
                        
                        Call To Action Text
                      </p>
                      <input
                        className={`me-2 ${style.colorPickerBrands}`}
                        type="color"
                        style={{ fontSize: "12px" }}
                        value={
                          flag
                            ? ctaTextColor
                            : editTrue
                            ? editData?.ctaColor ||
                              template?.ctaColor ||
                              ctaTextColor ||
                              ""
                            : eCtaText
                            ? eCtaText
                            : ctaTextColor
                        }
                        // onChange={(e) => setctaTextColor(e.target.value)}
                        onChange={(e) => setctaTextColorfun(e)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  // marginTop: "40px",
                  justifyContent: "center",
                }}
              >
                <button
                  className={style.ContinueBtn}
                  style={{ borderRadius: "20px" }}
                  onClick={() => {
                    HandleimageToggle(false);
                  }}
                >
                  Continue
                </button>
              </div>
            </Row>
          </div>
        </Container>
      ) : (
        <Container className={style.container}>
          <Row>
            <div className="google-header-div" style={{ padding: "30px 10px" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Link onClick={() => HandleimageToggle(true)}>{leftArrow}</Link>
                <p className="google-header-text">Color Section</p>
              </div>
            </div>
          </Row>
          <Row style={{ padding: "0px 60px" }}>
            <Col>
              <div
                className={style.colorSelectFields}
                style={{
                  flexDirection: "column",
                  alignItems: "start",
                  width: "400px",
                }}
              >
                <Col lg={12} className={style.fontChange}>
                  <div className={style.colorSelect} style={{ width: "100%" }}>
                    <svg
                      fill="#546881"
                      height="50px"
                      width="50px"
                      version="1.1"
                      id="Capa_1"
                      viewBox="-30.87 -30.87 502.74 502.74"
                      stroke="#546881"
                      stroke-width="8.379000000000001"
                      transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        <path d="M435.5,155.408c3.037,0,5.5-2.462,5.5-5.5s-2.463-5.5-5.5-5.5h-84.329v-50.7H435.5c3.037,0,5.5-2.462,5.5-5.5 s-2.463-5.5-5.5-5.5h-84.329V12.76c0-3.038-2.463-5.5-5.5-5.5s-5.5,2.462-5.5,5.5v69.947H100.829V12.76c0-3.038-2.462-5.5-5.5-5.5 s-5.5,2.462-5.5,5.5v69.947H5.5c-3.038,0-5.5,2.462-5.5,5.5s2.462,5.5,5.5,5.5h84.329v50.7H5.5c-3.038,0-5.5,2.462-5.5,5.5 s2.462,5.5,5.5,5.5h84.329v169.037H5.5c-3.038,0-5.5,2.462-5.5,5.5s2.462,5.5,5.5,5.5h84.329v92.795c0,3.038,2.462,5.5,5.5,5.5 s5.5-2.462,5.5-5.5v-92.795h239.342v92.795c0,3.038,2.463,5.5,5.5,5.5s5.5-2.462,5.5-5.5v-92.795H435.5c3.037,0,5.5-2.462,5.5-5.5 s-2.463-5.5-5.5-5.5h-84.329V155.408H435.5z M340.171,93.707v50.7H169.725l18.727-50.7H340.171z M100.829,93.707h75.897 l-18.727,50.7h-57.169V93.707z M153.935,155.408l-53.106,143.773V155.408H153.935z M165.865,324.444l13.05-37.867h81.614 l13.047,37.867H165.865z M340.171,324.444h-54.96l-15.56-45.159c-0.765-2.219-2.854-3.708-5.2-3.708h-89.458 c-2.347,0-4.435,1.489-5.2,3.708l-15.563,45.159h-51.007l62.438-169.037h46.442l-27.327,79.562c-0.577,1.68-0.307,3.536,0.725,4.982 c1.033,1.446,2.7,2.304,4.477,2.304h59.486c1.776,0,3.444-0.858,4.477-2.304c1.032-1.446,1.303-3.302,0.726-4.982l-27.323-79.562 h112.827V324.444z M219.723,167.086l22.037,64.17h-44.077L219.723,167.086z" />{" "}
                      </g>
                    </svg>
                    <label>Font Selection</label>
                  </div>
                  <FontChanger
                    HandleCurrentFonts={HandleCurrentFonts}
                    fonts={fonts}
                  />
                </Col>

                <div className={style.colorSelect}>
                  {ImageSelectSvg}
                  <label>Image Selection</label>
                </div>
                <Col
                  className={style.colorSelectMain}
                  onClick={HandleNavigate}
                  style={{ width: "100%" }}
                >
                  <div>
                    <h6>{"Image"}</h6>
                  </div>
                  {ImageSvg}
                </Col>

                <Col
                  className={style.colorSelectMain}
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h6>{"Account Image Library"}</h6>

                    <input
                      id="file"
                      type="file"
                      accept="image/*"
                      className={style.UploadImageAlignment}
                      onChange={HandelAccountImage}
                    />
                    <label
                      for="file"
                      class="btn-2"
                      style={{ position: "relative" }}
                    >
                      upload
                    </label>
                  </div>
                </Col>

                <Col
                  className=""
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{
                      width: "200px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "10px",
                    }}
                    className="common-btn"
                    onClick={saveData}
                  >
                    Save & Next
                  </button>
                </Col>
              </div>
            </Col>
            {/* <Col style={{ padding: "0px" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "flex-end",
                  paddingTop: "3rem 12px",
                }}
              >
                <div
                  className="background-second-box "
                  style={{ width: "100%" }}
                >
                  <div className="background-input-div-header">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {FreeStockSvg1}
                      <div>
                        <p className="background-input-div-header-p">
                          Live Preview
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="background-second-box-sub-div card p-0 "
                    style={{ borderRadius: "30px 30px 0px 0px" }}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="previewLogo p-2 ps-3">
                        <img
                          src={template?.logo}
                          style={{ height: "60px", width: "60px" }}
                        />
                        <h4
                          className="previewHeading"
                          style={{
                            color: `${
                              editData?.HeadingColor
                                ? editData?.HeadingColor
                                : template?.HeadingColor
                            }`,
                            fontFamily: fonts,
                            fontWeight: "500",
                          }}
                        >
                          {user.companyName}
                        </h4>
                      </div>
                      <div
                        className="curvediv"
                        style={{
                          height: "90px",
                          width: "90px",
                          backgroundColor: `${
                            editData?.mainColor
                              ? editData?.mainColor
                              : template?.mainColor
                          }`,
                        }}
                      />
                    </div>

                    <div>
                      <span
                        className="previewHeading mb-2 "
                        style={{
                          borderRadius: "0px 20px 20px 0px",
                          color: `${
                            editData?.descriptionColor
                              ? editData?.descriptionColor
                              : template?.descriptionColor
                          }`,
                          backgroundColor: `${
                            editData?.mainColor
                              ? editData?.mainColor
                              : template?.mainColor
                          }`,
                        }}
                      >
                        <span
                          style={{ display: "inline", fontWeight: "bold" }}
                          className="p-2 px-4"
                        ></span>
                      </span>
                      <div>
                        <div
                          style={{
                            height: "80px",
                            width: "80px",
                            color: `${
                              editData?.descriptionColorr
                                ? editData?.descriptionColor
                                : template?.descriptionColor
                            }`,
                            position: "absolute",
                            right: "0",
                            top: "30%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "100%",
                            fontWeight: "bold",
                            backgroundColor: `${
                              editData?.mainColor
                                ? editData?.mainColor
                                : template?.mainColor
                            }`,
                          }}
                        ></div>

                        <div style={{ width: "300px", height: "300px" }}>
                          <img
                            className="border"
                            src={template?.image}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "0 300px 0 0",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      )}

      {/* modal start */}

      {/* Modal */}
      <div
        className={`modal fade ${isModalOpen ? "show" : ""} `}
        id="staticBackdrop"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className=" modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Background Images Selection
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              />
            </div>
            <div className="modal-body">
              Are You Sure Continue with Background Remove
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={withoutBg}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                style={{
                  background: "#358f8b",
                  border: "#358f8b",
                }}
                onClick={withBg}
              >
                yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* modal End */}
    </>
  );
};

const InputBrand = ({
  svg,
  label,
  placeholder,
  fileRef,
  type,
  HandelOnChange,
  DefaultValue,
}) => {
  return (
    <div className={style.brandName}>
      {svg}
      <label>{label}</label>
      <input
        className={style.customFileInput}
        Value={DefaultValue ? DefaultValue : ""}
        // type={type}
        type={type}
        accept="image/*"
        // onChange={changeHandle}
        ref={fileRef && fileRef}
        onChange={HandelOnChange}
      />
    </div>
  );
};

const ColorPicker = ({
  heading,
  subHeading,
  setSelectedColor,
  selectedColor,
  id,
  onClick,
}) => {
  return (
    <Col className={style.colorSelectMain} onClick={onClick}>
      <div>
        <h6>{heading}</h6>
        <p style={{ fontSize: "9px" }}>{subHeading}</p>
      </div>
      <div>
        <input
          className={style.ColorPickerBtn}
          type="button"
          id="button"
          value={selectedColor}
          style={{ background: selectedColor, color: "#fff" }}
        />
      </div>
    </Col>
  );
};

const ImageSelect = ({ heading, subHeading, svg }) => {
  return (
    <Col className={style.colorSelectMain} lg={3}>
      <div>
        <h6>{heading}</h6>
        <p>{subHeading}</p>
      </div>

      {svg}
    </Col>
  );
};

const Recommendations = ({ colors, HandleSelectLogoColor, CloseAllToggle }) => {
  const handleClick = () => {
    HandleSelectLogoColor(colors[0], colors[1], colors[2]);
    CloseAllToggle();
  };
  return (
    <div
      style={{
        padding: "6px 4px",
        display: "flex",
        gap: "10px",
        background: "#F6F8F9",
        borderRadius: "10px",
        margin: "8px 0px",
        border: "1px solid gray",
      }}
      onClick={handleClick}
    >
      {colors.map((color) => (
        <div
          style={{
            background: color,
            borderRadius: "100%",
            width: "18px",
            height: "18px",
          }}
        ></div>
      ))}
    </div>
  );
};

const ColorSelectionModal = ({
  colors,
  HandleSelectLogoColor,
  HandleChangeSelectColor1,
  HandleChangeSelectColor2,
  HandleChangeSelectColor3,
  HandleMainToggle,
  selectedColor1,
  selectedColor2,
  selectedColor3,
}) => {
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);

  const handleToggle1 = () => {
    setToggle1(!toggle1);
    setToggle2(false);
    setToggle3(false);
  };

  const handleToggle2 = () => {
    setToggle1(false);
    setToggle2(!toggle2);
    setToggle3(false);
  };
  const handleToggle3 = () => {
    setToggle1(false);
    setToggle2(false);
    setToggle3(!toggle3);
  };

  const CloseAllToggle = () => {
    setToggle1(false);
    setToggle2(false);
    setToggle3(false);
  };
  return (
    <Container
      className={style.ColorSelectionModal}
      fluid
      style={{ position: "relative" }}
    >
      <span
        style={{
          fontWeight: "500",
          position: "absolute",
          right: 4,
          cursor: "pointer",
        }}
        onClick={HandleMainToggle}
      >
        <svg
          width={30}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M16 8L8 16M12 12L16 16M8 8L10 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
          </g>
        </svg>
      </span>
      <Row style={{ marginTop: "10px" }}>
        <Col className={style.ColorSelectionModalHeading}>
          <h3>Recommend & Preview</h3>
          <p>Experiment with the color palette suggested by A.I.</p>
        </Col>
      </Row>
      <Row style={{ height: "auto" }}>
        <Col className={style.colorSideHeading} lg={1} md={1} sm={1} xs={1}>
          <label>Your&nbsp;Color</label>
        </Col>
        <Col
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            height: "20vh",
          }}
          className={style.FontSizeSetting}
        >
          <div>
            <h6 className="text-center" style={{ fontSize: "9px" }}>
              Main Color
            </h6>
            <div
              className={style.colorContainerBox}
              style={{
                background: selectedColor1,
                height: "30px",
                width: "80px",
              }}
              onClick={handleToggle1}
            >
              {/* {selectedColor1} */}
            </div>
            {toggle1 && (
              <div
                style={{
                  position: "absolute",
                  marginTop: "-180px",
                  marginLeft: "140px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // background: "transparent",
                  // border:"2px solid black"
                }}
              >
                <div
                  style={{
                    alignSelf: "end",
                    color: "Black",
                    fontSize: "38px",
                    cursor: "pointer",
                  }}
                  onClick={() => setToggle1(false)}
                >
                  &times;
                </div>
                <SketchPicker
                  color={selectedColor1}
                  onChange={HandleChangeSelectColor1}
                />
              </div>
            )}
          </div>

          <div>
            <h6 style={{ fontSize: "9px" }} className="text-center">
              Heading Color
            </h6>
            <div
              className={style.colorContainerBox}
              style={{
                background: selectedColor2,
                height: "30px",
                width: "80px",
              }}
              onClick={handleToggle2}
            >
              {/* {selectedColor2} */}
            </div>
            {toggle2 && (
              <div
                style={{
                  position: "absolute",
                  marginTop: "-190px",
                  marginLeft: "70px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  background: "transparent",
                }}
              >
                <div
                  style={{
                    alignSelf: "end",
                    color: "Black",
                    fontSize: "38px",
                    cursor: "pointer",
                  }}
                  onClick={() => setToggle2(false)}
                >
                  &times;
                </div>
                <SketchPicker
                  color={selectedColor2}
                  onChange={HandleChangeSelectColor2}
                />
              </div>
            )}
          </div>

          <div>
            <h6 className="text-center" style={{ fontSize: "9px" }}>
              Description Color
            </h6>
            <div
              className={style.colorContainerBox}
              style={{
                background: selectedColor3,
                height: "30px",
                width: "80px",
              }}
              onClick={handleToggle3}
            >
              {/* {selectedColor3} */}
            </div>
            {toggle3 && (
              <div
                style={{
                  position: "absolute",
                  marginTop: "-190px",
                  marginLeft: "70px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  background: "transparent",
                }}
              >
                <div
                  style={{
                    alignSelf: "end",
                    color: "Black",
                    fontSize: "38px",
                    cursor: "pointer",
                  }}
                  onClick={() => setToggle3(false)}
                >
                  &times;
                </div>
                <SketchPicker
                  color={selectedColor3}
                  onChange={HandleChangeSelectColor3}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className={style.colorSideHeading}
          style={{ height: "27vh" }}
          lg={1}
          md={1}
          sm={1}
          xs={1}
        >
          <label>Recommendations</label>
        </Col>

        <Col
          className={style.colorSelectPopUp}
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            maxHeight: "24vh",
            overflowY: "auto",
            margin: "10px",
          }}
        >
          {data.map((palette) => (
            <Recommendations
              colors={palette}
              HandleSelectLogoColor={HandleSelectLogoColor}
              CloseAllToggle={CloseAllToggle}
            />
          ))}
        </Col>
      </Row>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <button onClick={HandleMainToggle}>Save</button>
        {/* <button onClick={saveData}>Save & Next</button> */}
      </Row>
    </Container>
  );
};

export default BrandSetUp;
