import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
const CreativeList = () => {
  const [renderedTemplates, setRenderedTemplates] = useState([]);
  const [renderIndex, setRenderIndex] = useState(0);
  let intervalId;
  const userSize = useSelector((state) => state.addcreative.size);
  const { setcreateNewProject } = useSelector((state) => state.addcreative);
  const tempData = useSelector((state) => state.template.tempData);
  useEffect(() => {}, [tempData]);
  
  const size1080x1080 = [
   
    // Gc57_1080x1080,
    // Gc1_1080x1080,
    //  Gc2_1080x1080,
    // Gc3_1080x1080,
    // Gc4_1080x1080, Gc5_1080x1080,
    // Gc6_1080x1080,
    // Gc7_1080x1080, Gc8_1080x1080,
    // Gc9_1080x1080,
    //  Gc10_1080x1080,
    //  Gc11_1080x1080,
    // Gc12_1080x1080,
    // Gc13_1080x1080,
    //  Gc14_1080x1080, Gc15_1080x1080,
    // Gc16_1080x1080, Gc17_1080x1080,
    //  Gc18_1080x1080,
    // Gc19_1080x1080, Gc20_1080x1080,
    // Gc21_1080x1080,
    // Gc22_1080x1080,
    // Gc23_1080x1080, Gc24_1080x1080,

    // Gc26_1080x1080,
    // Gc271080x1080,
    // Gc28_1080x1080, Gc29_1080x1080,
    //  Gc30_1080x1080,

    // Gc31_1080x1080,

    // Gc34_1080x1080, Gc35_1080x1080,
    // Gc36_1080x1080,

    //  Gc38_1080x1080,

    // Gc40_1080x1080,

    //  Gc43_1080x1080,

    // Gc45_1080x1080,
    // Gc46_1080x1080,
    //  Gc47_1080x1080,

    // Gc49_1080x1080,

    // Gc51_1080x1080,
    // Gc52_1080x1080,
    //  Gc53_1080x1080,
    //  Gc54_1080x1080, Gc55_1080x1080,
    // Gc56_1080x1080,

    //  Gc58_1080x1080,
    //  Gc59_1080x1080, Gc60_1080x1080
  ];
  const size1200x628 = [
    
    // Gc29_1200x628,
    //  Gc30_1200x628,
    //  Gc31_1200x628,
    //  Gc35_1200x628,
    // Gc1_1200x628,
    // Gc2_1200x628,
    // Gc3_1200x628,
    // Gc4_1200x628,
    // Gc5_1200x628,
    // Gc6_1200x628,
    //  Gc7_1200x628,
    //  Gc8_1200x628,
    //  Gc9_1200x628,
    //  Gc10_1200x628,
    // Gc11_1200x628,
    // Gc12_1200x628,
    //  Gc13_1200x628,
    //  Gc14_1200x628,
    //  Gc15_1200x628,
    // Gc16_1200x628,
    // Gc17_1200x628,
    // Gc18_1200x628,
    // Gc19_1200x628,
    // Gc20_1200x628,
    // Gc21_1200x628,
    //  Gc22_1200x628,
    //  Gc23_1200x628,
    //  Gc24_1200x628,

    // Gc26_1200x628,
    // Gc27_1200x628,
    // Gc28_1200x628,

    //  Gc34_1200x628,

    // Gc36_1200x628,
    // Gc38_1200x628,
    // Gc40_1200x628,

    //  Gc43_1200x628,
    //  Gc45_1200x628,
    // Gc46_1200x628,
    // Gc47_1200x628,
    // Gc49_1200x628,
    // Gc51_1200x628,
    //  Gc52_1200x628,
    //  Gc53_1200x628,
    //  Gc54_1200x628,
    //  Gc55_1200x628,
    // Gc56_1200x628,
    // Gc57_1200x628,
    // Gc58_1200x628,
    //  Gc59_1200x628,
    //  Gc60_1200x628
  ]; //7

  const size1080x1920 = [
   
    //   Gc1_1080x1920,
    //   Gc2_1080x1920,
    //   Gc3_1080x1920,
    //   Gc4_1080x1920,
    //   Gc5_1080x1920,
    //   Gc6_1080x1920,
    //   Gc7_1080x1920,
    //   Gc8_1080x1920,
    //   Gc9_1080x1920,
    //   Gc10_1080x1920,
    //   Gc11_1080x1920,
    //  Gc12_1080x1920,
    //   Gc13_1080x1920,
    //   Gc14_1080x1920,
    //   Gc15_1080x1920,
    //   Gc16_1080x1920,
    //  Gc17_1080x1920,
    //  Gc18_1080x1920,
    //  Gc19_1080x1920,
    //   Gc20_1080x1920,
    //   Gc21_1080x1920,
    //  Gc22_1080x1920,
    //   Gc23_1080x1920,
    //   Gc24_1080x1920,
    //  Gc26_1080x1920,
    //  Gc27_1080x1920,
    //  Gc28_1080x1920,
    //   Gc29_1080x1920,
    //   Gc30_1080x1920,
    //   Gc31_1080x1920,
    //   Gc34_1080x1920,
    //   Gc35_1080x1920,
    //   Gc36_1080x1920,
    //   Gc38_1080x1920,
    //  Gc40_1080x1920,
    //   Gc43_1080x1920,
    //  Gc45_1080x1920,
    //   Gc46_1080x1920,
    //  Gc47_1080x1920,
    //   Gc49_1080x1920,

    //   Gc51_1080x1920,
    //   Gc52_1080x1920,
    //   Gc53_1080x1920,
    //  Gc54_1080x1920,
    //   Gc55_1080x1920,
    //   Gc56_1080x1920,
    //   Gc57_1080x1920,
    //  Gc58_1080x1920,
    //   Gc59_1080x1920,
    //   Gc60_1080x1920
  ]; //9

  const size1080x1350 = [
  

    //  Gc57_1080x1350,
    // Gc1_1080x1350,
    //  Gc2_1080x1350,
    //  Gc3_1080x1350,
    //  Gc4_1080x1350,
    //  Gc5_1080x1350,
    // Gc6_1080x1350,
    // Gc7_1080x1350,
    //  Gc8_1080x1350,
    //  Gc9_1080x1350,
    //  Gc10_1080x1350,
    // Gc11_1080x1350,
    //  Gc12_1080x1350,
    // Gc13_1080x1350,
    // Gc14_1080x1350,
    //  Gc15_1080x1350,
    // Gc16_1080x1350,
    // Gc17_1080x1350,
    // Gc18_1080x1350,
    //  Gc19_1080x1350,
    //  Gc20_1080x1350,
    // Gc21_1080x1350,
    // Gc22_1080x1350,
    // Gc23_1080x1350,
    // Gc24_1080x1350,

    // Gc26_1080x1350,
    //  Gc27_1080x1350,
    //  Gc28_1080x1350,
    //  Gc29_1080x1350,
    //  Gc30_1080x1350,
    // Gc31_1080x1350,

    // Gc34_1080x1350,
    //  Gc35_1080x1350,
    // Gc36_1080x1350,

    // Gc38_1080x1350,

    // Gc40_1080x1350,

    //  Gc43_1080x1350,

    //  Gc45_1080x1350,
    // Gc46_1080x1350,
    //  Gc47_1080x1350,

    // Gc49_1080x1350,

    // Gc51_1080x1350,
    //  Gc52_1080x1350,
    //  Gc53_1080x1350,
    //  Gc54_1080x1350,
    //  Gc55_1080x1350,
    // Gc56_1080x1350,

    // Gc58_1080x1350,
    // Gc59_1080x1350,
    // Gc60_1080x1350
  ]; //8

  const size1000x1500 = [
    
    //   Gc1_1000x1500,
    //   Gc2_1000x1500,
    //  Gc3_1000x1500,
    //  Gc4_1000x1500,
    //  Gc5_1000x1500,
    //   Gc6_1000x1500,
    //    Gc7_1000x1500,
    //    Gc8_1000x1500,
    //    Gc9_1000x1500,
    //    Gc10_1000x1500,
    //   Gc11_1000x1500,
    //   Gc12_1000x1500,
    //   Gc13_1000x1500,
    //   Gc14_1000x1500,
    //   Gc15_1000x1500,
    //   Gc16_1000x1500,
    //   Gc17_1000x1500,
    //   Gc18_1000x1500,
    //   Gc19_1000x1500,
    //   Gc20_1000x1500,
    //   Gc21_1000x1500,
    //   Gc22_1000x1500,
    //   Gc23_1000x1500,
    //   Gc24_1000x1500,
    //   Gc26_1000x1500,
    //   Gc27_1000x1500,
    //   Gc28_1000x1500,
    //   Gc29_1000x1500,
    //   Gc30_1000x1500,
    //   Gc31_1000x1500,
    //   Gc34_1000x1500,
    //   Gc35_1000x1500,
    //   Gc36_1000x1500,
    //   Gc38_1000x1500,
    //   Gc40_1000x1500,
    //   Gc43_1000x1500,
    //   Gc45_1000x1500,
    //   Gc46_1000x1500,
    //   Gc47_1000x1500,

    //   Gc49_1000x1500,
    //   Gc51_1000x1500,
    //   Gc52_1000x1500,
    //   Gc53_1000x1500,
    //   Gc54_1000x1500,
    //   Gc55_1000x1500,
    //   Gc56_1000x1500,
    //   Gc57_1000x1500,
    //   Gc58_1000x1500,
    //   Gc59_1000x1500,
    //   Gc60_1000x1500
  ]; //6
  const size160x600 = [
  ]; //2 done

  const size300x250 = [
    // Gc1_300x250,
    // Gc2_300x250,
    // Gc3_300x250,
    // Gc4_300x250,
    // Gc5_300x250,
    // Gc6_300x250,
    // Gc7_300x250,
    // Gc8_300x250,
    // Gc9_300x250,
    // Gc10_300x250,
    // Gc11_300x250,
    // Gc12_300x250,
    // Gc13_300x250,
    // Gc14_300x250,
    // Gc15_300x250,
    // Gc16_300x250,
    //  Gc17_300x250,
    //  Gc18_300x250,
    //  Gc19_300x250,
    //  Gc20_300x250,
    // Gc21_300x250,
    // Gc22_300x250,
    // Gc23_300x250,
    // Gc24_300x250,
    // Gc26_300x250,
    // Gc27_300x250,
    // Gc28_300x250,
    // Gc29_300x250,
    //  Gc30_300x250,
    // Gc31_300x250,
    //  Gc34_300x250,
    //  Gc35_300x250,
    // Gc36_300x250,
    // Gc38_300x250,
    // Gc40_300x250,
    //  Gc43_300x250,
    //  Gc45_300x250,
    // Gc46_300x250,
    // Gc47_300x250,
    // Gc49_300x250,

    // Gc51_300x250,
    //  Gc52_300x250,
    //  Gc53_300x250,
    //  Gc54_300x250,
    //  Gc55_300x250,
    // Gc56_300x250,
    // Gc57_300x250,
    // Gc58_300x250,
    // Gc59_300x250,
    // Gc60_300x250
  ]; //10 done

  const size300x600 = [
    // Gc1_300x600,
    //  Gc2_300x600,
    //  Gc3_300x600,
    //  Gc4_300x600,
    //  Gc5_300x600,
    // Gc6_300x600,
    // Gc7_300x600,
    // Gc8_300x600,
    // Gc9_300x600,
    // Gc10_300x600,
    // Gc11_300x600,
    //  Gc12_300x600,

    //  Gc14_300x600,
    //  Gc15_300x600,
    // Gc16_300x600,
    // Gc17_300x600,
    // Gc18_300x600,
    // Gc19_300x600,
    // Gc20_300x600,
    // Gc21_300x600,
    //  Gc22_300x600,
    //  Gc23_300x600,
    //  Gc24_300x600,
    // Gc26_300x600,
    // Gc27_300x600,
    // Gc28_300x600,
    // Gc29_300x600,
    // Gc30_300x600,
    // Gc31_300x600,
    // Gc13_300x600,
    //  Gc34_300x600,
    //  Gc35_300x600,
    // Gc36_300x600,
    // Gc38_300x600,
    // Gc40_300x600,

    //  Gc43_300x600,
    //  Gc45_300x600,
    // Gc46_300x600,
    // Gc47_300x600,
    // Gc49_300x600,

    // Gc51_300x600,
    // Gc52_300x600,
    // Gc53_300x600,
    // Gc54_300x600,
    // Gc55_300x600,
    // Gc56_300x600,
    // Gc57_300x600,
    //  Gc58_300x600,
    //  Gc59_300x600,
    //  Gc60_300x600
  ]; //3 done

  const size728x90 = [
   ]; //4
  const size970x90 = [
   ]; //5

  const displaySize = (sizeArray) => {
    let renderIndex = 0; // Initialize renderIndex
    let intervalId; // Declare intervalId variable within the scope
    const renderTemplates = () => {
      const nextIndex = renderIndex + 3;
      const templatesToRender = sizeArray.slice(renderIndex, nextIndex);
      setRenderedTemplates((prevTemplates) => [
        ...prevTemplates,
        ...templatesToRender,
      ]);
      if (setcreateNewProject || nextIndex >= sizeArray.length) {
        clearInterval(intervalId); // Stop rendering when all templates are rendered
      } else {
        renderIndex = nextIndex; // Update renderIndex
      }
    };
    intervalId = setInterval(renderTemplates, 1000); // Set interval
  };
  useEffect(() => {
    if (userSize === "1080x1080" && tempData) {
      displaySize(size1080x1080);
    } else if (userSize === "160x600") {
      displaySize(size160x600); //10 done
    } else if (userSize === "300x600") {
      displaySize(size300x600); //9
    } else if (userSize === "728x90") {
      displaySize(size728x90); //6 done
    } else if (userSize === "970x90") {
      displaySize(size970x90); //7 done
    } else if (userSize === "1000x1500") {
      displaySize(size1000x1500); //5 done
    } else if (userSize === "1200x628") {
      displaySize(size1200x628); //2 done
    } else if (userSize === "1080x1350") {
      displaySize(size1080x1350); //4 done
    } else if (userSize === "1080x1920") {
      displaySize(size1080x1920); //3 done
    } else if (userSize === "300x250") {
      displaySize(size300x250); //8 done
    }

    return () => {
      // Cleanup interval when component unmounts
      clearInterval(intervalId);
    };
  }, [userSize, tempData, renderIndex]);

  // function setState(tempalteAray) {
  //     selectTemp(tempalteAray)
  // }

  return (
    <>
      {renderedTemplates.map((Template, index) => (
        <Template
          key={index}
          image={tempData && tempData.image} //pass
          logo={tempData && tempData.logo} //pass
          heading={tempData && tempData.heading} //pass
          description={tempData && tempData.description} //pass
          promotionText={tempData && tempData.promotionText} //pass
          ctaIcon={tempData && tempData.ctaIcon} //pass
          ctaText={tempData && tempData.ctaText} //pass
          mainColor={tempData && tempData.mainColor}
          HeadingColor={tempData && tempData.HeadingColor}
          descriptionColor={tempData && tempData.descriptionColor}
          promotionTextColor={tempData && tempData.promotionTextColor}
          ctaColor={tempData && tempData.ctaColor}
          selectedFontFamily={tempData && tempData.selectedFontFamily}
          brandname={tempData && tempData.brandname}
        />
      ))}
    </>
  );
};

export default CreativeList;
