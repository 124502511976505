import React, { useEffect, useState, useRef } from "react";
import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import axios from "../../../axios/auth";
import Cookies from "js-cookie";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import StylesContaent from "./AdminContent.module.css";
import styles from "../sidebar/AdminSidebar.module.css";
import loader from "../images/ZZ5H.gif";

function ManageCustomer() {
  const token = Cookies.get("token");
  const [customer, setCustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnBlockModal, setShowUnBlockModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    const dropdownMenu = dropdownRef.current.querySelector(".dropdown-menu");
    if (dropdownMenu.classList.contains("show")) {
      dropdownMenu.classList.remove("show");
    } else {
      dropdownMenu.classList.add("show");
    }
  };
  async function fetchData() {
    try {
      setIsLoading(true);
      const res = await axios.get("/fetch-customer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      setCustomer(res?.data?.data);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [token]);

  // filter fetch inactive data
  async function fetchDataInactive() {
    try {
      setIsLoading(true);
      const res = await axios.get("/fetch-inactivec-ustomer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomer(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }

  // filter fetch deleted data
  async function fetchDataDeleted() {
    try {
      setIsLoading(true);
      const res = await axios.get("/fetch-deleted-ustomer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomer(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }

  // filter fetch deleted data
  async function fetchDataActive() {
    try {
      setIsLoading(true);
      const res = await axios.get("/fetch-active-ustomer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomer(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }

  // Filtered customer data based on searchQuery
  const filteredCustomers = customer.filter((data) =>
    data?.fullName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  // Calculate the total number of pages for filtered data
  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

  // Pagination logic
  const pageRange = 5;
  const maxPageButtons = Math.min(pageRange, totalPages);

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const pageNumbers = Array.from(
    { length: maxPageButtons },
    (_, index) => startPage + index
  );

  // Calculate the indexes of the customers to display on the current page
  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  let currentCustomers = filteredCustomers.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );
  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to open the delete confirmation modal
  const openDeleteModal = (customerId) => {
    setDeleteCustomerId(customerId);
    setShowDeleteModal(true);
  };

  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setDeleteCustomerId(null);
    setShowDeleteModal(false);
  };

  const openUnBlockModal = (customerId) => {
    setDeleteCustomerId(customerId);
    setShowUnBlockModal(true);
  };
  const closeUnBlockModal = () => {
    setDeleteCustomerId(null);
    setShowUnBlockModal(false);
  };

  // Function to handle delete customer action
  const handleDeleteCustomer = async () => {
    const res = await axios.post(
      `delete-customer`,
      { customerId: deleteCustomerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // toast.info("User Block succefully");
    toast.success("User Block successfully", {
      className: 'custom-toast-success',
      hideProgressBar: true
    });

    fetchData();
    closeDeleteModal();
  };

  const handleUnBlockCustomer = async () => {
    const res = await axios.post(
      `unblock-customer`,
      { customerId: deleteCustomerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // toast.success("User UnBlock succefully");
    toast.success("User UnBlock successfully", {
      className: 'custom-toast-success',
      hideProgressBar: true
    });
    fetchData();
   closeUnBlockModal()
  };

  const selectChange = async (e) => {
    if (e.target.value == "inactive") {
      await fetchDataInactive();
      setCurrentPage(1)
    }
    if (e.target.value == "deleted") {
      await fetchDataDeleted();
      setCurrentPage(1)

    }
    if (e.target.value == "active") {
      await fetchDataActive();
      setCurrentPage(1)

    }
    if (e.target.value == "all") {
      await fetchData();
      setCurrentPage(1)

    }
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-sm-2 p-0">
          <div className={styles.mobileSidebar}>
            <AdminSidebar className={styles.mobileSidebar} />
          </div>
        </div>
        <div className="col-sm-10 bg-white">
          <div className="container">
            <Header title="Add Creative" btnname="Create Campaigns" />
          </div>
          <div className="container">
            <div className="container">
              <div className="row">
                <h4 className="my-4 ps-0">
                  <b>Customer Listing</b>
                </h4>
                <hr />
                <div className="mt-2 p-0">
                  {isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "50vh",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={loader}
                        style={{ width: "100px", height: "100px" }}
                        alt=""
                      />
                    </div>
                  ) : (
                    <>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-group mb-3">
                              <input
                                style={{ borderRadius: 10 }}
                                type="text"
                                className="form-control"
                                placeholder="Search by Name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                              <button
                                className="btn btn-primary common-btn"
                                type="button"
                                style={{
                                  backgroundColor: "#47beb9",
                                  marginLeft: 10,
                                  borderRadius: 10,
                                }}
                                onClick={() => setSearchQuery("")}
                              >
                                Clear
                              </button>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input-group mb-3">
                              <select
                                className="form-control"
                                onChange={(e) => selectChange(e)}
                              >
                                <option>Select Users Type</option>
                                <option value="all">All Users</option>
                                <option value="active">Active Users</option>
                                <option value="inactive">Inactive Users</option>
                                <option value="deleted">Blocked Users</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={StylesContaent.tableHeading}>
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th className="border">FullName</th>
                              <th className="border">Email</th>
                              <th className="border">Credits</th>
                              <th className="border">Plan Name</th>
                              <th className="border">Plan Expiry Date</th>
                              <th className="border">Status</th>

                              <th className="border">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentCustomers.map((data) => (
                              <tr key={data.id}>
                                <td
                                  className="border"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {data?.fullName}
                                </td>
                                <td className="border">{data?.email}</td>
                                <td className="border">{data?.credits}</td>
                                <td className="border">
                                  {data?.result[0]?.name
                                    ? data?.result[0]?.name
                                    : "-"}
                                </td>
                                <td className="border">
                                  {data?.result[0]?.subscriptionExpiryDate.split(
                                    "T"
                                  )[0]
                                    ? data?.result[0]?.subscriptionExpiryDate.split(
                                        "T"
                                      )[0]
                                    : " -"}
                                </td>
                                <td className="border">
                                  {data?.isDeleted ? (
                                    <h5 className="text-info">Blocked</h5>
                                  ) : data?.credits <= 0 &&
                                    data?.isDeleted == false ? (
                                    <h5 className="text-danger">In-Active</h5>
                                  ) : (
                                    <h5 className="text-success">Active</h5>
                                  )}
                                </td>

                                {!data?.isDeleted ? (
                                  <td className="border">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-danger common-btn dropdown-toggle"
                                        type="button"
                                        style={{ fontSize: "18px" }} // Adjust the font size as needed
                                        onClick={() =>
                                          openDeleteModal(data?._id)
                                        }
                                      >
                                        Block User
                                      </button>

                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby={`dropdownMenuButton_${data?.id}`}
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() =>
                                              openDeleteModal(data?._id)
                                            }
                                          >
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                ) : (
                                  <td className="border">
                                    <div className="dropdown">
                                      <button
                                        className="btn  btn-success  common-btn dropdown-toggle"
                                        type="button"
                                        onClick={() =>
                                          openUnBlockModal(data?._id)
                                        }
                                      >
                                        Unblock User
                                      </button>

                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby={`dropdownMenuButton_${data?.id}`}
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() =>
                                              openDeleteModal(data?._id)
                                            }
                                          >
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                )}

                                {/* <td className="border">
      <div className="dropdown" ref={dropdownRef}>
        <button
          className="btn common-btn dropdown-toggle"
          type="button"
          onClick={toggleDropdown}
        >
          Action
        </button>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="#" onClick={() => openDeleteModal(data?._id)}>
              Delete
            </a>
          </li>
        </ul>
      </div>
    </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Pagination */}
                      <div className="d-flex justify-content-center mt-3">
                        <ul className="pagination">
                          {currentPage > pageRange && (
                            <li className="page-item">
                              <button
                                className="btn btn-primary"
                                style={{ backgroundColor: "#47beb9" }}
                                onClick={() => paginate(1)}
                              >
                                1
                              </button>
                            </li>
                          )}
                          {currentPage > pageRange && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {pageNumbers.map((pageNumber) => (
                            <li
                              key={pageNumber}
                              className={
                                `${currentPage === pageNumber}`
                                  ? `${StylesContaent.activePage}`
                                  : ""
                              }
                            >
                              <button
                                className={`${StylesContaent.activePage} page-link`}
                                onClick={() => paginate(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          ))}
                          {currentPage + Math.floor(pageRange / 2) <
                            totalPages && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {currentPage + Math.floor(pageRange / 2) <
                            totalPages && (
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={() => paginate(totalPages)}
                              >
                                {totalPages}
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Action</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to block this user? This action will prevent them from accessing their account.
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={closeDeleteModal}>
      Cancel
    </Button>
    <Button variant="danger" onClick={handleDeleteCustomer}>
      Block User
    </Button>
  </Modal.Footer>
</Modal>



         {/* UnBlock Confirmation Modal */}
         <Modal show={showUnBlockModal} onHide={closeUnBlockModal}>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Action</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    Are you sure you want to unblock this user? They will regain access to their account.
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={closeUnBlockModal}>
      Cancel
    </Button>
    <Button variant="success" onClick={handleUnBlockCustomer}>
      Unblock User
    </Button>
  </Modal.Footer>
</Modal>

      </div>
    </div>
  );
}

export default ManageCustomer;
