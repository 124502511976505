import React, { useContext, useEffect, useState } from "react";
import "./TextInput.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { CommonBtn, NavItems } from "../../../UI-Components/ReusableComponents";
import Cookies from "js-cookie";
import {
  BackgroundSvg,
  CreativeSizeSvg,
  EmojiPickerSvg,
  FreeStockSvg,
  FreeStockSvg1,
  PreviewShow,
  RightArrow,
  TextAndBtnSvg,
} from "./SVg";
import { set, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateTempData } from "../../../../Redux/templateSlice";
import { useNavigate } from "react-router-dom";
import { addSingleEditDetails } from "../../../../Redux/addCreateSlice";
import {
  SearchSvg,
  wondSvg,
} from "../Campaigns/FacebookCampaign/SideNavContainer/SVG";
import EmojiPicker from "emoji-picker-react";
import { MailContext } from "../../Auth Pages/mailContext";
import axios from "../../../axios/auth";
import { leftArrow } from "../Campaigns/FacebookCampaign/SVG";
const TextAndBtnPage = () => {
  const userToken = Cookies.get("token");

  const [headingText, setheadingText] = useState("Heading Text");
  const [AiHeading, setAiHeading] = useState();
  // const[AiHeading,setAiHeading]=useState()
  const [descriptionText, setdescriptionText] = useState();
  const [promotionText, setpromotionText] = useState();
  const [ctaText, setctaText] = useState();
  const [ctaIcons, setctaIcon] = useState();
  const { register, handleSubmit, reset } = useForm();
  const [CtaIconPicked, setCtaIconPicked] = useState();
  const template = useSelector((state) => state.template.tempData);
  const editOne = useSelector((state) => state.addcreative.editOne);
  const { editAll, updateBrandNumber, editBrand } = useSelector(
    (state) => state.addcreative
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fonts } = useContext(MailContext);
  const editOneDataArray = useSelector(
    (state) => state.addcreative.editOneData
  );
  const brandsetupData = useSelector(
    (state) => state.addcreative.brandsetupData
  );
  const [editOneDataimg, setEditOneData] = useState();
  const [heading, setHeading] = useState();
  const [CTAToggle, setCTAToggle] = useState(false);
  const [description, setDescription] = useState();
  const [promotionText1, setPromotionText] = useState();
  const [ctaText1, setCtaText] = useState();
  const [CtaIcon1, setCtaIcon] = useState();
  const [flag, setFlag] = useState(false);
  // const editOne = useSelector((state) => state.addcreative.editOne)
  const [editData, setEditData] = useState({});
  const [editTrue, seteditTrue] = useState(false);
  const {
    previewImage,
    preMainColor,
    preHeadingColor,
    PreDescription,
    PrePromoColor,
  } = useSelector((state) => state.addcreative);

  // for AI Suggestion.
  const [aiApiDescription, setAiApiDescription] = useState();
  const [aiApiHeading, setAiApiHeading] = useState("");
  const [titleDetail, setTitleDetails] = useState("");

  // ai API for Getting title details and then another call for headline description start

  const updateBrandApi = async (formData) => {
    try {
      const res = await axios.post("/update-brand", formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {}
  };

  const FetchAiTitle = async (UrlOfWebsite) => {
    try {
      const url = "/fetch-ai/website";

      await axios
        .post(
          url,
          { websiteUrl: template?.brandname },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          setTitleDetails(response.data.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    FetchAiTitle();
  }, []);

  const FetchAiHeadlineDescription = async (titleDetail) => {
    try {
      const url = "/fetch-ai/tempalte-data";

      await axios
        .post(
          url,
          { websiteDetail: titleDetail },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          setAiApiHeading(response?.data?.Headline);
          setAiApiDescription(response?.data?.Description);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (titleDetail.length > 0) {
      FetchAiHeadlineDescription(titleDetail);
    }
  }, [titleDetail]);
  // ai API for Getting title details and then another call for headline description end

  const [aimodalToggle, setAiModalToggle] = useState(false);
  const [aimodalToggle2, setAiModalToggle2] = useState(false);

  useEffect(() => {
    function getLastWatchObject(editOneDataArray) {
      for (let i = editOneDataArray.length - 1; i >= 0; i--) {
        if (editOneDataArray[i].category === editOne) {
          seteditTrue(true);

          return { lastObject: editOneDataArray[i] };
        }
      }

      seteditTrue(false);
      return { lastWatchObject: null }; // Return an object with null if no object with category "watch" is found
    }
    let result = getLastWatchObject(editOneDataArray);
    let editOneData = result.lastObject;
    setEditData(editOneData);
    if (editData) {
      setEditOneData(editData.image || "");
      setHeading(editData.heading || "");
      setDescription(editData.description || "");
      setPromotionText(editData.promotionText || "");
      setCtaText(editData.ctaText || "");
      setCtaIcon(editData.ctaIcon || []);
    }
    // setHeading(editData?.heading)
    seteditTrue(true);
  }, [editData]);

  const HandleCtaIcon = (e) => {
    setCtaIconPicked("");
    const files = e.target.files;
    const paths = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        paths.push(reader.result);
        if (paths.length === files.length) {
          // All files have been read, update the state with the paths
          // setImagePaths(paths);
          // Do something with imagePaths, for example, save them in localStorage
          // localStorage.setItem("accountImg", [...paths]);
          setctaIcon([...paths]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const HandelAiHeadline = (item) => {
    setHeading(item);
    setAiHeading(item);

    setFlag(true);
    setheadingText(item);
    setheadingText(item);
    setAiModalToggle2(false);
  };
  const HandelAiDescription = (item) => {
    setDesFlag(true);
    setDescription(item);
    // setdescriptionText(item);
    setdescriptionText(item);
    setAiModalToggle(false);
  };

  const submit = (data) => {
    let withAIData = {
      ...data,
      heading: heading ? heading : data?.heading,
      description: data?.description ? data?.description : description,
    };
    // let withAIData={...data,heading:data?.heading?data?.heading:heading}
    // setHeading(data.heading);
    const updateData = {
      ...withAIData,
      ctaIcon: CtaIconPicked
        ? CtaIconPicked
        : ctaIcons
        ? ctaIcons
        : editData?.ctaIcon
        ? editData?.ctaIcon
        : template?.ctaIcon,
    };
    const formData = new FormData();
    formData.append("brandId", editBrand);
    formData.append("ctaText", data.ctaText);
    formData.append("promotionText", data.promotionText);
    formData.append("heading", data.heading);
    formData.append("description", data.description);
    formData.append("ctaIcon", updateData.ctaIcon);
    updateBrandApi(formData);
    let updatetemp = { ...template, ...updateData };
    let editupdatetemp = { ...brandsetupData, ...updateData };
    if (editOne) {
      dispatch(addSingleEditDetails({ ...editupdatetemp, category: editOne }));
      // navigate("/brand")
      // cooment
      navigate("/main/brand/templates");
    } else {
      dispatch(updateTempData(updatetemp));
      // navigate("/brand")
      // comment
      navigate("/main/brand/templates");
    }
    // const editOne = useSelector((state) => state.addcreative.editOne)

    // dispatch(updateTempData(updatetemp))

    // navigate("/brand")
    SaveFont();
    setHeading("");
    reset();
    setFlag(false);
  };

  const SaveFont = async () => {
    try {
      const res = await axios.post(
        "/save-font",
        { fontFamily: fonts },
        {
          headers: {
            Authorization: `Bearer ${userToken}`, // Correct spelling
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const [desFlag, setDesFlag] = useState(false);
  const [promoFlag, setPromolag] = useState(false);
  const [ctaFlag, setCtaFlag] = useState(false);

  const setheadingTexthandle = (e) => {
    setFlag(true);
    setheadingText(e.target.value);
  };
  const setdescriptionTexthandle = (e) => {
    // setFlag(true);
    setDesFlag(true);
    setdescriptionText(e.target.value);
  };
  const setpromotionTextHandle = (e) => {
    // setFlag(true);
    setPromolag(true);
    setpromotionText(e.target.value);
  };
  const setctaTexthandle = (e) => {
    // setFlag(true);
    setCtaFlag(true);
    setctaText(e.target.value);
  };

  const ToggleEmojiPicker = (icon) => {
    setCTAToggle((prev) => !prev);
  };

  useEffect(() => {}, [heading]);

  // Handle Picked Emoji fuction start
  const HandleCtaPicker = async (icon) => {
    setCtaIcon("");

    // Fetch the image data
    const response = await fetch(icon?.imageUrl);
    const blob = await response.blob();

    // Convert the image data to base64
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      const base64Encoded = reader.result.split(",")[1];

      // Now you can use the base64Encoded string as needed
      setCtaIconPicked(`data:image/jpeg;base64,${base64Encoded}`);
    };

    setCTAToggle(false);
  };
  

  useEffect(() => {}, [descriptionText, headingText]);
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container
      fluid
      className="text-btn-container textbtn-scroller-class "
      onClick={() => {
        setAiModalToggle(false);
        setAiModalToggle2(false);
      }}
    >
      <Row style={{ margin: "10px 20px" }}>
        <div className="left-navbar" style={{ border: "none" }}>
          <span onClick={handleBack}>
            <NavItems
              logo={leftArrow}
              active={false}
              text={"logo1"}
              label={"back"}
            />
          </span>
          <NavItems
            logo={CreativeSizeSvg}
            active={false}
            text={"logo1"}
            label={"Select Creative Size"}
          />
          <NavItems logo={BackgroundSvg} active={false} text={"logo2"} />
          <NavItems
            logo={TextAndBtnSvg}
            active={true}
            text={"logo3"}
            label={"Text and Button"}
          />
        </div>
        <Row className=" text-btn-row-gap">
          <Col className="text-btn-col-gap" lg={5} md={12} sm={12}>
            <div className="background-title">
              <h3 style={{ fontSize: "32px", marginBottom: "10px" }}>
                Select Text On Image
              </h3>
            </div>
            <div
              className="background-input-div"
              style={{ width: "90%", position: "relative" }}
            >
              <div className="background-input-div-header">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {FreeStockSvg1}
                  <div>
                    <p className="background-input-div-header-p">Post</p>
                    <p className="background-input-div-header-p">1080 x 1080</p>
                  </div>
                </div>
                {/* <CommonBtn
                  borderRadius={"20px"}
                  Left={true}
                  icon={RightArrow}
                  handleSubmit={handleSubmit(submit)}
                >
                  Text AI
                </CommonBtn> */}

                <button
                  className="common-btn"
                  style={{ borderRadius: "20px", gap: "7px" }}
                >
                  Text AI
                  <span>
                    {titleDetail.length > 0 ? (
                      RightArrow
                    ) : (
                      <Spinner size="sm" />
                    )}
                  </span>
                </button>
              </div>
              <InputField
                label={"Enter Your Main Headline"}
                // placeholder={"Max 40  Word"}
                placeholder={"Max 40 Characters"}
                maxlength={40}
                registerName={"heading"}
                register={register}
                valueName={
                  heading
                    ? heading
                    : editAll
                    ? editData?.heading || template?.heading
                    : editTrue
                    ? editData?.heading || template?.heading || ""
                    : heading
                }
                // previewText={((e)=>setheadingText(e.target.value))}
                previewText={(e) => setheadingTexthandle(e)}
                HandleAiText={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAiModalToggle2(true);
                }}
                AiText={titleDetail.length > 0 && "Pick Headline"}
              />

              <InputField
                label={"Enter Your Description"}
                placeholder={"Max 40 Characters"}
                registerName={"description"}
                maxlength={40}
                // valueName={template?.description}
                valueName={
                  description
                    ? description
                    : editAll
                    ? editData?.description || template?.description
                    : editTrue
                    ? editData?.description || template?.description || ""
                    : description
                }
                register={register}
                // previewText={((e)=>setdescriptionText(e.target.value))}
                previewText={(e) => setdescriptionTexthandle(e)}
                AiText={titleDetail.length > 0 && "Pick Description"}
                HandleAiText={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAiModalToggle(true);
                }}
              />
              <InputField
                label={"Enter Your Promotion Text"}
                placeholder={"Max 15 Characters"}
                registerName={"promotionText"}
                maxlength={15}
                // valueName={template?.promotionText}
                valueName={
                  editAll
                    ? editData?.promotionText || template?.promotionText
                    : editTrue
                    ? editData?.promotionText || template?.promotionText || ""
                    : promotionText
                }
                register={register}
                previewText={(e) => setpromotionTextHandle(e)}
              />
              {/* previewText={(e) => setpromotionText(e.target.value)} */}

              <InputField
                label={"Enter your Call To Action"}
                placeholder={"Max 24 Characters"}
                maxlength={24}
                registerName={"ctaText"}
                // valueName={template?.ctaText}
                valueName={
                  editAll
                    ? editData?.ctaText || template?.ctaText
                    : editTrue
                    ? editData?.ctaText || template?.ctaText || ""
                    : ctaText
                }
                previewText={(e) => setctaTexthandle(e)}
                register={register}
              />

              {/*Ai Text Headline*/}
              {aimodalToggle2 && (
                <div
                  style={{ zIndex: 100, top: "-15px", right: "-230px" }}
                  className="google-content-modal  mobile-message-suggestionbing AiHeadlineTextBtn"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="google-content-modal-div1">
                    <h1>AI Suggestions</h1>
                    <p>{`Google Ads > Headlines`}</p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      background: "#B2BBC6 ",
                    }}
                  ></div>
                  <div className="google-content-modal-div2">
                    {aiApiHeading ? ( //aiApiDescription
                      aiApiHeading.map((item, i) => (
                        <ul>
                          <li key={i} onClick={() => HandelAiHeadline(item)}>
                            {item}
                          </li>
                        </ul>
                      ))
                    ) : (
                      <Spinner style={{ alignSelf: "center" }} />
                    )}
                  </div>
                  {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
                handleSubmit={() => setLgShow(true)}
              >
                Generate More
              </CommonBtn>
              <AiGenerationModal
                lgShow={lgShow}
                hide={() => setLgShow(false)}
              />
            </div> */}
                </div>
              )}

              {/*  */}

              {/*Ai Text Description*/}
              {aimodalToggle && (
                <div
                  style={{ zIndex: 100, top: "-15px", right: "-230px" }}
                  className="google-content-modal  mobile-message-suggestionbing AiHeadlineTextBtn"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="google-content-modal-div1">
                    <h1>AI Suggestions</h1>
                    <p>{`Google Ads > Description`}</p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      background: "#B2BBC6 ",
                    }}
                  ></div>
                  <div className="google-content-modal-div2">
                    {aiApiDescription ? ( //aiApiDescription
                      aiApiDescription.map((item, i) => (
                        <ul>
                          <li key={i} onClick={() => HandelAiDescription(item)}>
                            {item}
                          </li>
                        </ul>
                      ))
                    ) : (
                      <Spinner style={{ alignSelf: "center" }} />
                    )}
                  </div>
                  {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
                handleSubmit={() => setLgShow(true)}
              >
                Generate More
              </CommonBtn>
              <AiGenerationModal
                lgShow={lgShow}
                hide={() => setLgShow(false)}
              />
            </div> */}
                </div>
              )}

              {/*  */}

              {CTAToggle && (
                <div
                  style={{ position: "absolute", top: "50px", left: "40px" }}
                >
                  <EmojiPicker
                    // onEmojiClick={(e) => setValue(e)}
                    height={300}
                    width={300}
                    onEmojiClick={(e) => HandleCtaPicker(e)}
                  />
                </div>
              )}

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="backround-maininput-div">
                  <p
                    className="backround-maininput-div-p1"
                    style={{ fontSize: "14px" }}
                  >
                    Call to Action Icon
                  </p>
                  <div
                    className="background-maininput-div-input"
                    style={{
                      background: "transparent",
                      color: "#000",
                    }}
                  >
                    <input
                      id="file"
                      {...register("ctaIcon")}
                      className=""
                      type="file"
                      placeholder="Preview shown here"
                      onChange={HandleCtaIcon}
                      // defaultValue={valueName}
                    />
                    <label for="file" class="btn-2">
                      Icon Upload
                    </label>

                    <span
                      style={{ cursor: "pointer" }}
                      onClick={ToggleEmojiPicker}
                    >
                      {EmojiPickerSvg}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <CommonBtn
                    borderRadius={"20px"}
                    Left={true}
                    icon={RightArrow}
                    handleSubmit={handleSubmit(submit)}
                  >
                    Generate Creatives
                  </CommonBtn>
                </div>
              </div>
            </div>
          </Col>
          <Col style={{ padding: "0px" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                // alignItems: "flex-end",
                paddingTop: "3rem 12px",
              }}
            >
              <div className="background-second-box " style={{ width: "100%" }}>
                <div className="background-input-div-header">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {FreeStockSvg1}
                    <div>
                      <p className="background-input-div-header-p">
                        Live Preview 
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="background-second-box-sub-div card p-0 "
                  style={{ borderRadius: "30px" }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="previewLogo p-2 ps-3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          src={template?.logo} alt=""
                          style={{ maxHeight: "60px", maxWidth: "100px" }}
                        />
                      </div>
                      <h6
                        className="previewHeading"
                        style={{
                          fontFamily: `${
                            fonts
                              ? fonts
                              : editData?.selectedFontFamily
                              ? editData?.selectedFontFamily
                              : template?.selectedFontFamily
                          }`,
                          color: `${
                            preHeadingColor
                              ? preHeadingColor
                              : editData?.HeadingColor
                              ? editData?.HeadingColor
                              : template?.HeadingColor
                          }`,
                        }}
                      >
                        {flag
                          ? headingText
                          : editTrue
                          ? editData?.heading ||
                            template?.heading ||
                            "This Is a Heading"
                          : heading}
                      </h6>
                    </div>

                    <div
                      className="curvediv"
                      style={{
                        height: "90px",
                        width: "90px",
                        backgroundColor: `${
                          editData?.mainColor
                            ? editData?.mainColor
                            : template?.mainColor
                        }`,
                      }}
                    />
                  </div>

                  <div style={{ marginTop: "-5px" }}>
                    {/*  */}
                    <span
                      className="previewHeading mb-2 "
                      style={{
                        fontSize: "12px",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        borderRadius: "0px 20px 20px 0px",
                        fontFamily: `${
                          fonts
                            ? fonts
                            : editData?.selectedFontFamily
                            ? editData?.selectedFontFamily
                            : template?.selectedFontFamily
                        }`,
                        color: `${
                          editData?.descriptionColor
                            ? editData?.descriptionColor
                            : template?.descriptionColor
                        }`,
                        backgroundColor: `${
                          editData?.mainColor
                            ? editData?.mainColor
                            : template?.mainColor
                        }`,
                      }}
                    >
                      <span
                        style={{ display: "inline", fontWeight: "bold" }}
                        className="p-2 px-4"
                      >
                        {desFlag
                          ? descriptionText
                          : editTrue
                          ? editData?.description ||
                            template?.description ||
                            "This is description"
                          : description}
                      </span>
                    </span>

                    <br></br>
                    <span
                      className="previewHeading mb-2 "
                      style={{
                        fontSize: "12px",
                        borderRadius: "0px 20px 20px 0px",
                        fontFamily: `${
                          fonts
                            ? fonts
                            : editData?.selectedFontFamily
                            ? editData?.selectedFontFamily
                            : template?.selectedFontFamily
                        }`,
                        color: `${
                          editData?.descriptionColor
                            ? editData?.descriptionColor
                            : template?.descriptionColor
                        }`,
                        backgroundColor: `${
                          editData?.mainColor
                            ? editData?.mainColor
                            : template?.mainColor
                        }`,
                      }}
                    >
                      <span
                        style={{ display: "inline", fontWeight: "bold" }}
                        className="p-2 px-4"
                      >
                        {" "}
                        <img
                          src={
                            CtaIconPicked
                              ? CtaIconPicked
                              : ctaIcons
                              ? ctaIcons
                              : editData?.ctaIcon
                              ? editData?.ctaIcon
                              : template?.ctaIcon
                              ? template?.ctaIcon
                              : CtaIconPicked
                              ? CtaIconPicked
                              : ctaIcons
                          }
                          style={{ maxHeight: "15px", maxWidth: "15px" }} alt=""
                        />
                        <span
                          style={{
                            marginLeft: "5px",
                            fontFamily: `${
                              fonts
                                ? fonts
                                : editData?.selectedFontFamily
                                ? editData?.selectedFontFamily
                                : template?.selectedFontFamily
                            }`,
                          }}
                        >
                          {ctaFlag
                            ? ctaText
                            : editTrue
                            ? editData?.ctaText || template?.ctaText || ""
                            : ctaText}
                        </span>
                      </span>
                    </span>
                    <div>
                      <div
                        style={{
                          fontFamily: `${
                            fonts
                              ? fonts
                              : editData?.selectedFontFamily
                              ? editData?.selectedFontFamily
                              : template?.selectedFontFamily
                          }`,
                          width: "100px",
                          // color: `${editData?.promotionTextColor ? editData?.promotionTextColor : template?.promotionTextColor}`,
                          color: `${
                            editData?.descriptionColorr
                              ? editData?.descriptionColor
                              : template?.descriptionColor
                          }`,
                          position: "absolute",
                          right: "0",
                          top: "35%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "40px",
                          fontWeight: "bold",
                          fontSize: "12px",
                          padding: "5px",
                          backgroundColor: `${
                            editData?.mainColor
                              ? editData?.mainColor
                              : template?.mainColor
                          }`,
                        }}
                      >
                        {promoFlag
                          ? promotionText
                          : editTrue
                          ? editData?.promotionText ||
                            template?.promotionText ||
                            "PromoText"
                          : promotionText}
                      </div>

                      {/* <h2 className="previewHeading" style={{ color: "black" }}>
                    {flag?ctaText:editTrue ? (editData?.ctaText || template?.ctaText || '') : ctaText}
           
                    </h2> */}
                      <div
                        style={{
                          maxWidth: "300px",
                          maxHeight: "300px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          className="border"
                          src={
                            previewImage
                              ? previewImage
                              : editOneDataimg
                              ? editOneDataimg
                              : template?.image
                          }
                          style={{
                            height: "auto",
                            width: "auto",
                          }} alt=""
                        />
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export const InputField = ({
  label,
  placeholder,
  text,
  registerName,
  register,
  valueName,
  previewText,
  handleClick,
  value,
  maxlength,
  Icon,
  handleIcon,
  AiText,
  HandleAiText,
}) => {
  useEffect(() => {
  }, [valueName]);
  return (
    <div style={{ display: "flex", position: "relative", width: "100%" }}>
      <div className="backround-maininput-div">
        <p className="backround-maininput-div-p1">{label}</p>

        {handleClick ? (
          <div
            className="background-maininput-div-input"
            style={{ background: "transparent" }}
          >
            <input
              id="file"
              {...register(registerName)}
              className=""
              type={text ? text : "text"}
              placeholder={placeholder}
              maxLength={maxlength}
              onChange={handleClick}
              defaultValue={valueName}
            />
            <label for="file" class="btn-2">
              Upload
            </label>
            {/* <input
            {...register(registerName)}
            className=""
            type={text ? text : "text"}
            placeholder={placeholder}
            maxLength={maxlength}
            onChange={handleClick}
            defaultValue={valueName}
            //  onChange={previewText}
          /> */}
            <span style={{ cursor: "pointer" }} onClick={handleIcon}>
              {Icon}
            </span>
          </div>
        ) : (
          <input
            {...register(registerName)}
            className="background-maininput-div-input"
            type={text ? text : "text"}
            placeholder={placeholder}
            defaultValue={valueName}
            onChange={previewText}
            maxLength={maxlength}
            // value={valueName}
          />
        )}
      </div>
      {AiText && (
        <div
          className="google-content-greendot"
          style={{
            width: "12px",
            height: "12px",
            position: "absolute",
            right: "0px",
            top: "30px",
          }}
          onClick={HandleAiText}
        >
          <div
            className="pointed-button "
            style={{
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
              display: "flex",
              width: "120px",

              alignItems: "center",
            }}
          >
            {wondSvg}
            {AiText}
          </div>
        </div>
      )}
    </div>
  );
};

export default TextAndBtnPage;
