import React from 'react'

export default function ConvertIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_247_227)">
                <path d="M19.1635 3.33252V8.33353H14.1625" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M17.0712 12.5001C16.5294 14.0335 15.504 15.3493 14.1494 16.2492C12.7948 17.1491 11.1844 17.5843 9.56095 17.4893C7.93746 17.3943 6.38882 16.7743 5.14839 15.7226C3.90796 14.6709 3.04295 13.2445 2.68371 11.6584C2.32447 10.0723 2.49045 8.41247 3.15666 6.92892C3.82287 5.44538 4.95319 4.21854 6.37731 3.4333C7.80144 2.64805 9.4422 2.34693 11.0523 2.57532C12.6625 2.80371 14.1548 3.54922 15.3044 4.69953L19.163 8.33314" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_247_227">
                    <rect width="20.0017" height="20.0017" fill="white" transform="translate(-0.00512695 -0.000976562)" />
                </clipPath>
            </defs>
        </svg>

    )
}
