import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import {
  BrowserRouter,
  Routes,
  Route,
  // useNavigate,
  Navigate,
} from "react-router-dom";
import WrapperRoute from "./component/route/WrapperRoute";
import Login from "./component/account/Auth Pages/Login";
import MainPage from "./component/account/Main Pages/MainPage";
import Campaigns from "./component/account/Pages/Campaigns/Campaigns";
import Integrations from "./component/account/Pages/IntegrationPage/Integrations";
import Payments from "./component/account/Pages/PaymentsPage/Payments";
import Generate from "./component/account/Pages/Generate Page/Generate";
// import Settings from "./component/account/Pages/Settings Page/Settings";
import Add from "./component/account/Pages/Ad Page/Add";
// import Login1 from "./component/account/Auth Pages/Login";
import SignUp from "./component/account/Auth Pages/Signup";

import Plan from "./component/account/Pages/Plans/Plans";
import NewCampaigns from "./component/account/Pages/Campaigns/NewCampaigns/NewCampaigns";
// import HomeCampaigns from "./component/account/Pages/Campaigns/Home Campaigns/HomeCampaigns";
import BrandSummery from "./component/account/Pages/Campaigns/BrandSummery/BrandSummery";
import PlatformSelection from "./component/account/Pages/Campaigns/Platfrom  Section/PlatformSelection";

import GoogleCampaign from "./component/account/Pages/Campaigns/GoogleCampaign/GoogleCampaignMain";
import GoogleContent from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/GoogleContent";
import GoogleTargeting from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/GoogleTargeting";
import GoogleFunding from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/GoogleFunding";
import GoogleAdd1 from "./component/account/Pages/Campaigns/GoogleCampaign/Google-Add-1/GoogleAdd-1";
import GoogleAdd2 from "./component/account/Pages/Campaigns/GoogleCampaign/Google-Add-2/GoogleAdd2";
import GoogleAdd3 from "./component/account/Pages/Campaigns/GoogleCampaign/Google-Add-3/GoogleAdd3";
import PerformanceCampaigns from "./component/account/Pages/Campaigns/Performance Campaigns/PerformanceCampaigns";
import AdTrafficeOnly from "./component/account/Pages/Campaigns/Performance Campaigns/Ad Traffice Only/AdTrafficeOnly";
import Brands from "./component/account/Pages/Brands Page/Brands";
import BackGroundImages from "./component/account/Pages/Generate Page/BackGroundImages";
import TextAndBtnPage from "./component/account/Pages/Generate Page/TextAndBtnPage";
import Brand from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/Brand";
import AiGenerateCampaign from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/AiGenerateCampaign";
import AiGenerateGoogleAd from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/AiGenerateGoogleAd";
import AiHeadline from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/AiHeadline";
import AiDescription from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/AiDescription";
import AiKeyword from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/AiKeyword";
import InputMain from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/InputMain";
import AiSuggestionMain from "./component/account/Pages/Campaigns/GoogleCampaign/SideNavContainer/AiGenerationModal/AiSuggestionMain";
import ForgetPassword from "./component/account/Auth Pages/ForgetPassword";
import OtpScreen from "./component/account/Auth Pages/OtpScreen";
import ConfirmPassword from "./component/account/Auth Pages/ConfirmPassword";
import { MailProvider } from "./component/account/Auth Pages/mailContext";
import BrandSetUp from "./component/account/Pages/Brands Page/BrandsEntry/BrandSetUp";
import AiCampaignSuccess from "./component/account/Pages/Plans/SuccessfullPurchase/AiCampaignSuccess";
import ManageAccess from "./component/panel/admin/pages/ManageAccess";
import Dashboard from "./component/panel/admin/pages/Dashboard.js";
import ManageCampaign from "./component/panel/admin/pages/ManageCampaign";
import PlanManagement from "./component/panel/admin/pages/ManagePlan";
import ManageDiscount from "./component/panel/admin/pages/ManageDiscount";
import ManageTransaction from "./component/panel/admin/pages/ManageTransaction";
import AdminMain from "./component/panel/admin/pages/AdminMain";
import ManageCustomer from "./component/panel/admin/pages/ManageCustomer";
import ImageRepository from "./component/panel/admin/pages/ManageImageRepo";
// import Home from "./LandingPage/home";
import AMain from "./component/admin/AMain";
import MainContainer from "./LandingPage/MainUI/Main";
import Home from "./LandingPage/Pages/Home/home";
import ABoutUs from "./LandingPage/Pages/AboutUs/ABoutUs";
import AiAd from "./LandingPage/Pages/AIAD/AiAd";
import AiCreative from "./LandingPage/Pages/AlCreative/AiCreative";
import Blog from "./LandingPage/Pages/Blog/Blog";
import Features from "./LandingPage/Pages/Features/Features";
import GraphicDesigner from "./LandingPage/Pages/AlCreative/GraphicDesigner";
import Freelancer from "./LandingPage/Pages/AlCreative/Freelancer";
import MainBlog from "./LandingPage/Pages/Blog/MainBlogPage/MainBlog";
import FacebookCampaign from "./component/account/Pages/Campaigns/FacebookCampaign/FacebookCampaignMain";
import FacebookAdd1 from "./component/account/Pages/Campaigns/FacebookCampaign/Facebook-Add-1/FaceBookAdd-1";
import FacebookContent from "./component/account/Pages/Campaigns/FacebookCampaign/SideNavContainer/FacebookContent";
import FacebookTargeting from "./component/account/Pages/Campaigns/FacebookCampaign/SideNavContainer/FacebookTargeting";
import FacebookFunding from "./component/account/Pages/Campaigns/FacebookCampaign/SideNavContainer/FacebookFunding";
import FacebookAdd2 from "./component/account/Pages/Campaigns/FacebookCampaign/Facebook-Add-2/FacebookAdd2";
import FacebookAdd3 from "./component/account/Pages/Campaigns/FacebookCampaign/Facebook-Add-3/FacebookAdd3";
 
// google-2
import GoogleCampaign2 from "./component/account/Pages/Campaigns/GoogleCampaign2/Google2CampaignMain.jsx";
import Google2Add1 from "./component/account/Pages/Campaigns/GoogleCampaign2/Google2-Add-1/Google2Add-1.jsx";
import Google2Content from "./component/account/Pages/Campaigns/GoogleCampaign2/SideNavContainer/Google2Content.jsx";
import Google2Targeting from "./component/account/Pages/Campaigns/GoogleCampaign2/SideNavContainer/Google2Targeting.jsx";
import Google2Funding from "./component/account/Pages/Campaigns/GoogleCampaign2/SideNavContainer/Google2Funding.jsx";
import Google2Add2 from "./component/account/Pages/Campaigns/GoogleCampaign2/Google2-Add-2/Google2Add2.jsx";
import Google2Add3 from "./component/account/Pages/Campaigns/GoogleCampaign2/Google2-Add-3/Google2Add3.jsx";

// ig
import InstagramCampaign from "./component/account/Pages/Campaigns/InstagramCampaign/InstagramCampaignMain";
import InstagramAdd1 from "./component/account/Pages/Campaigns/InstagramCampaign/InstagramAdd-1/InstagramAdd1";
import InstagramContent from "./component/account/Pages/Campaigns/InstagramCampaign/SideNavContainer/InstagramContent";
import InstagramTargeting from "./component/account/Pages/Campaigns/InstagramCampaign/SideNavContainer/InstagramTargeting";
import InstagramFunding from "./component/account/Pages/Campaigns/InstagramCampaign/SideNavContainer/InstagramFunding";
import InstagramAdd2 from "./component/account/Pages/Campaigns/InstagramCampaign/InstagramAdd-2/InstagramAdd2";
import InstagramAdd3 from "./component/account/Pages/Campaigns/InstagramCampaign/InstagramAdd-3/InstagramAdd3";

// Bing
import BingCampaign from "./component/account/Pages/Campaigns/BingCampaign/BingCampaignMain";
import BingAdd1 from "./component/account/Pages/Campaigns/BingCampaign/Bing-Add-1/BingAdd-1";
import BingContent from "./component/account/Pages/Campaigns/BingCampaign/SideNavContainer/BingContent";

import BingTargeting from "./component/account/Pages/Campaigns/BingCampaign/SideNavContainer/BingTargeting";
import BingFunding from "./component/account/Pages/Campaigns/BingCampaign/SideNavContainer/BingFunding";
import BingAdd2 from "./component/account/Pages/Campaigns/BingCampaign/Bing-Add-2/BingAdd2";
import BingAdd3 from "./component/account/Pages/Campaigns/BingCampaign/Bing-Add-3/BingAdd3";
import FreeTrial from "./component/account/Pages/Plans/Free-trial/FreeTrial";
// import BrandList from "./component/account/Pages/Brands Page/BrandList";
import TestingPage from "./Testing/TestingPage.jsx";
import YourWebsite from "./component/account/Pages/Campaigns/Performance Campaigns/YourWebsite/YourWebsite.jsx";
import ContactUS from "./LandingPage/Pages/SupportPages/ContactUS.jsx";
import TermsAndCondition from "./LandingPage/Pages/SupportPages/TermsAndCondition.jsx";
import PrivacyPolicy from "./LandingPage/Pages/SupportPages/PrivacyPolicy.jsx";
import BusinessMan from "./LandingPage/Pages/AlCreative/BusinessMan.jsx";
import AdsLoading from "./component/account/Pages/AdsLoader/AdsLoading.jsx";
import DashboardPage from "./component/account/Pages/Campaigns/Home Campaigns/DashboardPage.jsx";

import TermsOfPromotion from "./component/panel/admin/pages/TermsAnd conditions.js";
import Privacy from "./component/panel/admin/pages/PrivacyPolicies.js";
import NewBrand from "./component/account/Pages/Brands Page/NewBrand/NewBrand.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewBrandSetup from "./component/account/Pages/Brands Page/NewBrandSetup/index.jsx";
import NewSettingPage from "./component/account/Pages/Settings Page/NewSettingPage/index.jsx";

import NewGenerate from "./component/account/Pages/Generate Page/NewGenerate/index.jsx";
import GeneratedSelection from "./component/account/Pages/Generate Page/NewGenerate/generatedSelection/index.jsx";
import CampaignsLoader from "./component/account/Pages/Dashboard/CampaignsLoader.jsx";
import CreditManagment from "./component/panel/admin/pages/MangeCredits.js";
import ManageEnquiries from "./component/panel/admin/pages/ManageEnquiries.js";
import ImageSelection from "./component/account/Pages/Generate Page/NewGenerate/generatationStep3/imageSelection/index.jsx";
import SizeSelection from "./component/account/Pages/Generate Page/NewGenerate/onlyCreative/sizeSelection/index.jsx";
import CreativesGeneration from "./component/account/Pages/Generate Page/NewGenerate/creativesGeneration/index.jsx";
import BrandScreen from "./component/account/Pages/Brands Page/brandScreen/index.jsx";
import ProtectedRoute from "./Redux/ProtectedRoute.js";
import AdsLoadFacebook from "./component/account/Pages/AdsLoader/AdsLoadFacebook.jsx";
import AdsLoadInstagram from "./component/account/Pages/AdsLoader/AdsLoaderInstagram.jsx";
import ManageJobs from "./component/panel/admin/pages/MangeJobs.js";
import ManageAskExpert from "./component/panel/admin/pages/ManageAskExpert.js";
import ManageFacebook from "./component/panel/admin/pages/MangeFacebookAds.js";
import ManageInstagarm from "./component/panel/admin/pages/ManageInastagramAds.js";
import ManageAdsGoogle from "./component/panel/admin/pages/ManageGoogleAds.js";
import NoPageFound from "./component/account/NoData.jsx";
function App() {


  return (
    <>
      <BrowserRouter>
        <MailProvider>
          <Routes>
          <Route path="*" element={<NoPageFound />} />
            <Route path="success/:id" element={<AdsLoading />} />
            <Route path="success/facebbok/:id" element={<AdsLoadFacebook />} />
            <Route path="success/instagram/:id" element={<AdsLoadInstagram />} />
            <Route path="testingpage" element={<TestingPage />} />
            <Route path="modal" element={<AiCampaignSuccess />} />
            <Route path="testadmin" element={<AMain />}></Route>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="forgetpassword" element={<ForgetPassword />} />
            <Route path="OtpCheck" element={<OtpScreen />} />
            <Route path="confirmpassword" element={<ConfirmPassword />} />
            <Route path="plans" element={<Plan />} />
            <Route path="main/setting/plans" element={<Plan />} />



            
            <Route path="plans/success" element={<AiCampaignSuccess />} />
            <Route path="plans/freetrial" element={<FreeTrial />} />
            {/* landing page routes start */}
            <Route path="/">
              <Route
                index
                element={<Navigate replace to="landingpage/home" />}
              />
              <Route path="landingpage" element={<MainContainer />}>
                {/* <Route
                  index
                  element={<Navigate replace to="landingpage/home" />}
                /> */}
                <Route path="home" element={<Home />} />
                <Route path="aboutus" element={<ABoutUs />} />
                <Route path="aiad" element={<AiAd />}>
                  <Route
                    index
                    element={<Navigate replace to="graphicdesigner" />}
                  />
                  <Route path="graphicdesigner" element={<GraphicDesigner />} />
                  <Route path="freelancer" element={<Freelancer />} />
                  <Route path="businessman" element={<BusinessMan />} />
                </Route>
                <Route path="aicreative" element={<AiCreative />}>
                  <Route
                    index
                    element={<Navigate replace to="graphicdesigner" />}
                  />
                  <Route path="graphicdesigner" element={<GraphicDesigner />} />
                  <Route path="freelancer" element={<Freelancer />} />
                  <Route path="businessman" element={<BusinessMan />} />
                </Route>
                <Route path="blog" element={<MainBlog />} />
                <Route path="blog-detail" element={<Blog />} />

                <Route path="features" element={<Features />} />
                
                <Route path="contactus" element={<ContactUS />} />
                <Route path="terms&condition" element={<TermsAndCondition />} />
                <Route path="privacy&Policy" element={<PrivacyPolicy />} />
              </Route>
            </Route>
            {/* landing page routes end */}
            <Route path="privacy&Policy" element={<PrivacyPolicy />} />
            <Route element={<ProtectedRoute />}>
            <Route path="/main" element={<MainPage />}>
            <Route path="add" element={<Add />} />
            
              {/* {subscriptionMode === "aiCreative" && (
                <>
                  <Route index element={<Navigate replace to="brand" />} />
                  <Route path="brand" element={<BrandList />} />
                  <Route path="brand/templates" element={<Brands />} />
                  <Route path="brand/brandsetup" element={<BrandSetUp />} />
                  <Route path="Generate" element={<Generate />} />
                  <Route
                    path="Generate/background"
                    element={<BackGroundImages />}
                  />
                  <Route
                    path="Generate/inputpage"
                    element={<TextAndBtnPage />}
                  />
                  <Route path="setting" element={<Settings />} />
                </>
              )} */}
              {/* {subscriptionMode === "aiCampaign" && ( */}
              
              <>
             
                <Route index element={<Navigate replace to="dashboard" />} />

                <Route path="campaign" element={<Campaigns />}>
                  {/* <Route path="/main/campaign" element={<HomeCampaigns />} /> */}

                  {/* below routes are not in use now ---->start */}
                  <Route
                    path="performancecampaign"
                    element={<PerformanceCampaigns />}
                  >
                    <Route index element={<Navigate replace to="ad" />} />
                    <Route path="ad" element={<AdTrafficeOnly />} />
                  

                    <Route path="website" element={<YourWebsite />} />
                  </Route>
                  {/* below routes are not in use now ---->End */}
                  <Route
                    index
                    element={<Navigate replace to="newcampaign" />}
                  />
                  <Route path="newcampaign" element={<NewCampaigns />} />
                  <Route path="brandsummery" element={<BrandSummery />} />
                  <Route
                    path="platfromselection"
                    element={<PlatformSelection />}
                  />
                  {/* Google-1 campaign */}

                  <Route path="googlecompaign" element={<GoogleCampaign />}>
                    <Route
                      index
                      element={<Navigate replace to="google-add-1" />}
                    />
                    <Route path="google-add-1" element={<GoogleAdd1 />}>
                      <Route
                        index
                        element={<Navigate replace to="content" />}
                      />
                      <Route path="content" element={<GoogleContent />}>
                        <Route path="inputbv   " element={<InputMain />}>
                          <Route path="brand" element={<Brand />} />
                          <Route
                            path="campaign"
                            element={<AiGenerateCampaign />}
                          />
                          <Route
                            path="googlead"
                            element={<AiGenerateGoogleAd />}
                          />
                        </Route>
                        <Route
                          path="aisuggestion"
                          element={<AiSuggestionMain />}
                        >
                          <Route path="headline" element={<AiHeadline />} />
                          <Route
                            path="description"
                            element={<AiDescription />}
                          />
                          <Route path="keyword" element={<AiKeyword />} />
                        </Route>
                      </Route>
                      <Route path="targeting" element={<GoogleTargeting />} />
                      <Route path="funding" element={<GoogleFunding />} />
                    </Route>
                    <Route path="google-add-2" element={<GoogleAdd2 />} />
                    <Route path="google-add-3" element={<GoogleAdd3 />} />
                  </Route>
                  {/* Google-2 campaign */}
                  <Route path="googlecompaign2" element={<GoogleCampaign2 />}>
                    <Route
                      index
                      element={<Navigate replace to="google-add-1" />}
                    />
                    <Route path="google-add-1" element={<Google2Add1 />}>
                      <Route
                        index
                        element={<Navigate replace to="content" />}
                      />
                      <Route
                        path="content"
                        element={<Google2Content />}
                      ></Route>
                      <Route
                        path="targeting"
                        element={<Google2Targeting />}
                      />
                      <Route path="funding" element={<Google2Funding />} />
                    </Route>
                    <Route path="google-add-2" element={<Google2Add2 />} />
                    <Route path="google-add-3" element={<Google2Add3 />} />
                  </Route>
                  {/* facebook campaign */}
                  <Route
                    path="facebookcampaign"
                    element={<FacebookCampaign />}
                  >
                    <Route
                      index
                      element={<Navigate replace to="facebook-add-1" />}
                    />
                    <Route path="facebook-add-1" element={<FacebookAdd1 />}>
                      <Route
                        index
                        element={<Navigate replace to="content" />}
                      />
                      <Route
                        path="content"
                        element={<FacebookContent />}
                      ></Route>
                      <Route
                        path="targeting"
                        element={<FacebookTargeting />}
                      />
                      <Route path="funding" element={<FacebookFunding />} />
                    </Route>
                    <Route path="facebook-add-2" element={<FacebookAdd2 />} />
                    <Route path="facebook-add-3" element={<FacebookAdd3 />} />
                  </Route>

                  {/* Ig campaign */}
                  <Route
                    path="instagramcampaign"
                    element={<InstagramCampaign />}
                  >
                    <Route
                      index
                      element={<Navigate replace to="instagram-add-1" />}
                    />
                    <Route path="instagram-add-1" element={<InstagramAdd1 />}>
                      <Route
                        index
                        element={<Navigate replace to="content" />}
                      />
                      <Route
                        path="content"
                        element={<InstagramContent />}
                      ></Route>
                      <Route
                        path="targeting"
                        element={<InstagramTargeting />}
                      />
                      <Route path="funding" element={<InstagramFunding />} />
                    </Route>
                    <Route
                      path="instagram-add-2"
                      element={<InstagramAdd2 />}
                    />
                    <Route
                      path="instagram-add-3"
                      element={<InstagramAdd3 />}
                    />
                  </Route>
                  {/* bing campaign */}
                  <Route path="bingcampaign" element={<BingCampaign />}>
                    <Route
                      index
                      element={<Navigate replace to="bing-add-1" />}
                    />
                    <Route path="bing-add-1" element={<BingAdd1 />}>
                      <Route
                        index
                        element={<Navigate replace to="content" />}
                      />
                      <Route path="content" element={<BingContent />}></Route>
                      <Route path="targeting" element={<BingTargeting />} />
                      <Route path="funding" element={<BingFunding />} />
                    </Route>
                    <Route path="bing-add-2" element={<BingAdd2 />} />
                    <Route path="bing-add-3" element={<BingAdd3 />} />
                  </Route>
                </Route>
                <Route path="Integrations" element={<Integrations />} />
              {/* <ProtectedRoute path="dashboard" element={<DashboardPage />} /> */}

                <Route path="dashboard" element={<DashboardPage />} />

                <Route path="Payments" element={<Payments />} />
                <Route path="Generate" element={<Generate />} />
                <Route path="onlyCreative" element={<SizeSelection />} />
                <Route
                  path="Generate/background"
                  element={<BackGroundImages />}
                />
                <Route
                  path="Generate/inputpage"
                  element={<TextAndBtnPage />}
                />
                  <Route path="brand" element={<BrandScreen />} />
                <Route path="brand-list" element={<NewBrand />} />
                <Route path="brand-setup" element={<NewBrandSetup />} />
                <Route path="new-generate" element={<NewGenerate />} />
                <Route path="new-creativesgeneration" element={<CreativesGeneration />} />
                <Route path="new-imageselection" element={<ImageSelection />} />
                <Route path="generated-selection" element=
                  {<GeneratedSelection />} />

                  
       

                <Route path="brand/templates" element={<Brands />} />
                <Route path="brand/brandsetup" element={<BrandSetUp />} />
                {/* <Route path="setting" element={<Settings />} /> */}
                <Route path="setting" element={<NewSettingPage/>} />
            <Route path="campignLoder" element={<CampaignsLoader/>} />

              </>

            </Route>
            </Route>
            <Route path="/admin" element={<AdminMain />}>
              <Route
                index
                element={<Navigate replace to="dashboard" />}
              ></Route>
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="imagerepository" element={<ImageRepository />} />
              <Route path="manageaccess" element={<ManageAccess />} />
              <Route path="managecampaign" element={<ManageCampaign />} />
              <Route path="managecustomer" element={<ManageCustomer />} />
              <Route path="planmanagement" element={<PlanManagement />} />
              <Route path="managediscount" element={<ManageDiscount />} />
              {/* <Route path="managetransaction" element={<ManageTransaction />} /> */}
              <Route path="managetransaction" element={<ManageTransaction />} />

              <Route path="manageCredits" element={<CreditManagment />} />
              <Route path="manageFacebook" element={<ManageFacebook />} />
              <Route path="manageInstagram" element={<ManageInstagarm />} />
              <Route path="manageGoogle" element={<ManageAdsGoogle />} />
              <Route path="manageEnquiry" element={< ManageEnquiries />} />
              <Route
                path="manageTermsAndConditions"
                element={<TermsOfPromotion />}
              />
                      <Route path="manageJobs" element={< ManageJobs />} />
                      <Route path="manageExpert" element={< ManageAskExpert />} />
              <Route path="managePrivacyPolicies" element={<Privacy />} />
            
            </Route>
          </Routes>
        </MailProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
