// import generate from "../../images/selectImage/generate.png"
import generate from "../../../assets/images/selectImage/generate.png"
import accVery from "../../../assets/images/selectImage/AccVery.png"
import downArrow from "../../../assets/images/selectImage/downArrow.png"
// import upload from "../../images/selectImage/upload.png"
import playBtn from "../../../assets/images/selectImage/playbtn.svg"
import "./SelectImage.css";

import { Link } from "react-router-dom"
import Header from "../header/Header"
import Sidebar from "../sidebar/Sidebar"

function SelectImage() {
  return (
    <>
      <div className="row  h-100 m-0 p-0">
        <div className="col-sm-2 sidebar-bg-color px-0 mx-0 sidebar-border">
          <div className="">
            < Sidebar className="" />
          </div>
        </div>
        <div className="col-sm-10 bg-white">
          {/* <div className="container-fluid"> */}
          {/* <div className="container-fluid"> */}
          <div className="container">


            <div className="container">
              <Header title="CreatedList" btnname="Upgrade Plan" />
            </div>
            <div className="row">
              <h6 className="title text-dark text-center mt-2">
                Selet your creative size below depending on the
                platform you Upload any image for your product/service or use our free & paid image Search Engine for Premium stock images. to advertise on.
              </h6>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card card-border border-0" >
                  <div className="card-header bg-transparent border-success">
                    <div className="d-flex  justify-content-between ">
                      <div className="d-flex">
                        <img src={generate} style={{ height: "20px" }} alt="" />
                        <div className=" ms-3">
                          <h6 className='card-heading fw-bold'>Generate using signle background image</h6>
                          <p className='card-paragraph'>for those who needs to generate creatives using one background</p>
                        </div>
                      </div>
                      <img src={downArrow} style={{ height: "10px" }} className="mt-1" alt="" />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="upload-img-box card-title">

                      <div className="d-flex justify-content-center">
                        {/* <img src={upload} className="" style={{ height: "30px", width: "30px" }} /> */}
                        <input type="file" />
                        <div className="ms-2">
                          <h6 className="upload-text-heading">  Upload a background image</h6>
                          <p className="upload-text-para">
                            or drag & drop a background image here.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="d-flex  btn-a-primary justify-content-between align-items-center">
                          <h6 className="text-white">
                            Account Image Library
                          </h6>
                          <img src={accVery} style={{ height: "20px" }} alt="" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex  btn-z-primary  justify-content-between align-items-center">
                          <h6 className="text-white">
                            Account Image Library
                          </h6>
                          <img src={accVery} style={{ height: "20px" }} alt="" />
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="upload-img-box2 ">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Vertical Rol */}
              <div className="text-center col-md-1 py-5">
                <div className="vr" style={{ height: "50%" }}></div>
                <div className="rotate-text">OR</div>
                <div className="vr" style={{ height: "50%" }}></div>
              </div>
              <div className="col-md-5">
                <div className="card p-0 m-0 card-border border-0" >

                  <div className=" bg-transparent p-0 m-0 ">
                    <div></div>
                    <div className="d-flex justify-content-end">
                      <div className="d-flex  btn-c-primary justify-content-between align-items-center">
                        <h6 className="text-white me-2">
                          How does it work?
                        </h6>
                        <img src={playBtn} style={{ height: "20px" }} alt="" />
                      </div>
                    </div>
                    <div className="d-flex  justify-content-between px-2">
                      <div className="d-flex">
                        <img src={generate} style={{ height: "20px" }} alt="" />
                        <div className=" ms-3">
                          <h6 className='card-heading fw-bold'>Generate using signle background image</h6>
                          <p className='card-paragraph'>for those who needs to generate creatives using one background</p>
                        </div>
                      </div>
                      <img src={downArrow} style={{ height: "10px" }} className="mt-1" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className='d-flex justify-content-end mt-3'>
                <Link to='/user/selectSize'>   <button className="btn">  &#x2190;  Go Back</button></Link>
                <Link to='/user/textScreen'>
                  <button className='btn btn-prime'>Next Step </button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}
export default SelectImage