import { Link } from "react-router-dom";
import img1 from "../../../assets/images/selectPage/1.png";
import img2 from "../../../assets/images/selectPage/2.png";
import img3 from "../../../assets/images/selectPage/3.webp";
import img4 from "../../../assets/images/selectPage/4.webp";
import img5 from "../../../assets/images/selectPage/5.webp";
import img6 from "../../../assets/images/selectPage/6.webp";
import img7 from "../../../assets/images/selectPage/7.webp";
import img8 from "../../../assets/images/selectPage/8.webp";
import img9 from "../../../assets/images/selectPage/9.webp";
import img10 from "../../../assets/images/selectPage/10.webp";

import "./SelectSize.css";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
function SelectSize() {
  return (
    <>
      <div className="row  h-100 m-0 p-0">
        <div className="col-sm-2 sidebar-bg-color px-0 mx-0 sidebar-border">
          <div className="">
            <Sidebar className="" />
          </div>
        </div>
        <div className="col-sm-10 bg-white">
          {/* <div className="container-fluid"> */}
          {/* <div className="container-fluid"> */}
          <div className="container">
            <div className="container">
              <Header title="CreatedList" btnname="Upgrade Plan" />
            </div>
            <div className="row">
              <div className="row">
                <h6 className="title text-dark text-center mt-2">
                  Select your creative size below depending on the platform you
                  want to advertise on.
                </h6>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-3">
                  <h4 className="pageheadline">
                    Social Media Sizes Create Post
                  </h4>
                  <h6 className="paragraph fw-bold">
                    Most Common sizes for social media advertising
                  </h6>
                </div>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card-group">
                        <div className="card  card-border bg-gradiant ms-2 me-2">
                          <div className=" card-body ">
                            <div className="flex-column justify-content-between">
                              <div className="d-flex  justify-content-center mt-3">
                                <img src={img1} className="" alt="" />
                              </div>
                              <div className="mt-2">
                                <h5 className="card-title img-title text-center">
                                  Post Size
                                </h5>
                                <p className="card-text text-center">
                                  1080x1080
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img2} className="" alt=""/>
                            </div>
                            <div className="mt-2">
                              <h5 className="card-title img-title text-center">
                                Landscape Size
                              </h5>
                              <p className="card-text text-center">1200x628</p>
                            </div>
                          </div>
                        </div>
                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img3} className="" alt="" />
                            </div>
                            <div className="d-flex justify-content-center">
                              <div className="mt-2">
                                <h5 className="card-title img-title text-center">
                                  Story Size
                                </h5>
                                <p className="card-text text-center">
                                  1080x1920
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img4} className="" alt="" />
                            </div>
                            <div className="d-flex justify-content-center">
                              <div className="mt-2">
                                <h5 className="card-title img-title text-center">
                                  Vertical Size
                                </h5>
                                <p className="card-text text-center">
                                  1080x1350
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img5} className=""  alt="" />
                            </div>
                            <div className="mt-2">
                              <h5 className="card-title img-title text-center">
                                Pin Size
                              </h5>
                              <p className="card-text text-center">1000x1500</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Row */}
              <div className="row align-items-center mt-5">
                <div className="col-sm-3">
                  <h4 className="pageheadline">Display Sizes</h4>
                  <h6 className="paragraph fw-bold text-start">
                    Most Common sizes for Display advertising
                  </h6>
                </div>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card-group">
                        <div className="card  card-border bg-gradiant ms-2 me-2">
                          <div className=" card-body">
                            <div className="d-flex  justify-content-center mt-4">
                              <img src={img6} className="" alt=""/>
                            </div>
                          </div>
                          <div className=" card-footer">
                            <h5 className="card-title img-title text-center">
                              Post Size
                            </h5>
                            <p className="card-text text-center">1080x1080</p>
                          </div>
                        </div>

                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body">
                            <div className="d-flex justify-content-center mt-4">
                              <img src={img7} className="" alt="" />
                            </div>
                          </div>
                          <div className=" card-footer">
                            <div>
                              <h5 className="card-title img-title text-center">
                                Landscape Size
                              </h5>
                              <p className="card-text text-center">1200x628</p>
                            </div>
                          </div>
                        </div>
                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img8} className="" alt="" />
                            </div>
                          </div>
                          <div className="card-footer">
                            <h5 className="card-title img-title text-center">
                              Story Size
                            </h5>
                            <p className="card-text text-center">1080x1920</p>
                          </div>
                        </div>
                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center ">
                              <img src={img9} className="" alt="" />
                            </div>
                          </div>
                          <div className="card-footer">
                            <h5 className="card-title img-title text-center">
                              Vertical Size
                            </h5>
                            <p className="card-text text-center">1080x1350</p>
                          </div>
                        </div>

                        <div className="card card-border  ms-2 ">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center ">
                              <img src={img10} className="" alt="" />
                            </div>
                          </div>
                          <div className="card-footer">
                            <h5 className="card-title img-title text-center">
                              Pin Size
                            </h5>
                            <p className="card-text text-center">1000x1500</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3 ">
                <Link to="/user/selectImage">
                  <button className="btn btn-prime">Next Step </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SelectSize;
