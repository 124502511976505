import React from 'react'

export default function UserIcon() {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6134 1.67262C13.6144 0.593992 12.219 0 10.6788 0C9.13042 0 7.7304 0.590398 6.73597 1.66236C5.73075 2.74612 5.24098 4.21904 5.35598 5.80952C5.58392 8.94736 7.9717 11.4999 10.6788 11.4999C13.3859 11.4999 15.7696 8.94787 16.0011 5.81055C16.1177 4.23444 15.6248 2.7646 14.6134 1.67262ZM19.7145 22.9999H1.64314C1.4066 23.0029 1.17236 22.9533 0.957439 22.8544C0.742523 22.7556 0.552343 22.6101 0.400738 22.4285C0.0670343 22.0296 -0.0674738 21.4849 0.0321238 20.934C0.465425 18.5303 1.81769 16.5111 3.94313 15.0937C5.83138 13.8353 8.22326 13.1428 10.6788 13.1428C13.1343 13.1428 15.5262 13.8359 17.4145 15.0937C19.5399 16.5106 20.8922 18.5298 21.3255 20.9335C21.4251 21.4843 21.2906 22.029 20.9569 22.4279C20.8053 22.6096 20.6152 22.7552 20.4002 22.8542C20.1853 22.9531 19.951 23.0029 19.7145 22.9999Z" fill="#358F8B" />
        </svg>

    )
}
