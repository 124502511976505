import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Cookies from 'js-cookie';
// import axios from "../../../axios/auth"
export default function AdminMain() {
  // Retrieve the token from cookies
  const userToken = Cookies.get('token');

  // Check if userToken is present
  if (userToken) {
    return (
      <>
        <Outlet />
      </>
    )
  }

  else {
    return <Navigate to="/login" />;
  }

}

