import "./Login.css";
// import LoginImg from "./Images/Login.png";
import Exoad from "./Images/EXOAD.png";

import { Col, Container, Modal, Row } from "react-bootstrap";
// import OtpInput from "react-otp-input";
import { useContext, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../axios/auth";
import { 
  // ToastContainer,
   toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MailContext } from "./mailContext";
// import { submitemail } from "./ForgetPassword";

import MailSuccess from "./Images/MailSuccess.jpg";
const OtpScreen = () => {
  // do not navigate until you get user Data.

  const [otp, setOtp] = useState("");
  const {
    handleSubmit,
    // formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const { mail, handleMail } = useContext(MailContext);
  const [modal, setModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userEmail = sessionStorage.getItem("email");
  const onSubmit = async (data) => {
    setIsSubmitting(true)
    try {
      // await sendOpt(userEmail, otp);
      let para = {
        email: userEmail,
        otp: otp,
      };
      

      const res = await axios.post(
        "/resetpassword",
        para // Assuming 'data' is a variable with the OTP value
      );
      if (res?.data?.status === true) {
        setIsSubmitting(false)
        navigate("/confirmpassword");
      } else {
        setIsSubmitting(false)

        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
      }
    } catch (error) {
      setIsSubmitting(false)
      // toast.error("Invalid OTP");
      toast.error("Invalid OTP.Please check and try again.", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });

      // Handle the error appropriately (e.g., show an error message to the user)
    }
  };

  const OtpResend = async (email, navigate) => {
    try {

      try {
        await axios.post("/forgetpassword", { email: email });

        setOtp("")
        toast.success("OTP resend successfully!", {
          className: 'custom-toast-success',
          hideProgressBar: true
        });

      } catch (e) {
        toast.error(e.response?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
      }

      // Store the email in sessionStorage
      sessionStorage.setItem("email", email);

    } catch (e) {
      // Display an error toast

      // toast.error("An error occurred while submitting the email.");
      toast.error("An error occurred while submitting the email.", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }
  };

  const HandleEmailResend = () => {
    OtpResend(userEmail);
  };

  return (
    <Container fluid className="layout">
      <Row className="align-items-center">
        <Col
          className=" liginImg  d-flex justify-content-end align-items-center "
          lg={6}
          md={12}
          sm={12}
        >
          {/* <img
            src={LoginImg}
            style={{
              width: "70%",
            }}
            alt="login-img"
          /> */}
        </Col>
        <Col style={{ textAlign: "center" }} lg={6} md={12} sm={12}>
          <div
            className="right d-flex justify-content-center align-items-center otpscreenpadding  "
            style={{
              padding: "3rem 2rem 1rem 2rem",
              height: "445px",
              // maxHeight:"500px",
              gap: "20px",
              border:"3px solid white"
            }}
          >
            <div className=" ">
              <img src={Exoad} alt="Exoad" />

              <div style={{ width: "100%" }}>
                <h1 className="welcome">Forgot Password</h1>
                <p className="p" style={{ width: "100%", margin: " 12px 0px" }}>
                  We have send a code to your email:&nbsp;
                  <strong>{sessionStorage.getItem("email")}</strong>
                </p>
              </div>

              <form
                className="otp-screen-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                {/* <OtpInput
                  containerStyle="otp-input"
                  inputStyle="otp-style  signup-otp-style"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  // inputType=""zzzz
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                /> */}
                 <CustomOTPInput
      value={otp}
      required
      onChange={setOtp}
    />


                <p>
                  Didn’t receive code?
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={HandleEmailResend}
                  >
                    Resend
                  </span>
                </p>
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "20px",
                    width: "80%",
                    justifyContent: "center",
                  }}
                  type="submit"
                  disabled={isSubmitting}
                  className="login-button button-container"
                >
                  {isSubmitting ? "Submitting..." : "Confrim"}
                </button>

                {/* <button
        type="submit"
        className="button-container login-button"
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Send"}
      </button> */}



              </form>
            </div>
          </div>
        </Col>
      </Row>
      {/* <ToastContainer /> */}
      <CustomModal show={modal} mail={mail} />
    </Container>
  );
};

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="confirmpass-body-modal">
        <img src={MailSuccess} alt="" />
        <p className="">
          We have Re-send a code to your email:&nbsp;
          {props.mail}
        </p>
      </div>
    </Modal>
  );
};


const CustomOTPInput = ({ numInputs = 6, value="", onChange }) => {
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const newOtp = [...value];
    newOtp[index] = e.target.value;
    onChange(newOtp.join(''));

    // Move focus to the next input if available and a digit is entered
    if (e.target.value && index < numInputs - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      // Move focus to the previous input on backspace
      inputsRef.current[index - 1].focus();
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="otp-input-container">
      {Array.from({ length: numInputs }, (_, index) => (
        <input
          key={index}
          className="otp-input"
          type="text"
          maxLength="1"
          required
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onKeyPress={handleKeyPress}
          ref={(el) => (inputsRef.current[index] = el)}
        />
      ))}
    </div>
  );
};


export default OtpScreen;
