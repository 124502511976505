import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Home from "../Pages/Home/home";
import Header from "../Pages/Header/Header";
// import footer from "../Images/footer.png";
import "./main.css";
import {
  // DividerSvg,
  FooterFacebook,
  // FooterarrowRight,
  Footerlinkedin,
  Footertwitter,
  footerYoutube,
  footerig,
  // chatbot,
  GoogleAdsSvg,
  bingAdsSvg,
  FacebookAdsSvg,
  InstagramAdsSvg,
} from "../Images/landingSvg";
// import UpwordsArrow from "../Images/UpwordsArrow.png";
import arrowUp2 from "../Images/arrowUp2.png";
// import chatImg from "../Images/chatbot.png";
// import MoveToTop from "../Images/MoveToTop.png";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
// import { NavigateToLogin, ScrollToHome } from "../Reusable function/Reusable";
import { Link } from "react-router-dom";

const MainContainer = () => {
  const [expanded, setExpanded] = useState(false);

  const [showScroll, setShowScroll] = useState(false);
  const navigate = useNavigate();
  // const { id } = useParams();
  // Update the useEffect hook to consider header height
  // const pricingRef = useRef(null);

  const scrollWithOffset = (el) => {
    const yOffset = 550; // Adjust this value to your desired offset
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: y,
      behavior: 'smooth' // Change to "auto" for immediate scrolling
    });
  };

  const handleCollapse = () => {
    setExpanded(!expanded);
  };
  const handleNavigation = () => {
    navigate('/privacy&Policy'); // Use the correct path for your privacy policy
    // If you want to scroll after navigating, consider using a ref or another method in the new component
  };

  const handleNavigations = () => {
    navigate('/terms&condition'); // Use the correct path for your privacy policy
    // If you want to scroll after navigating, consider using a ref or another method in the new component
  };
  const handleLoginClick = () => {
    navigate('/login');
  };
  useEffect(() => {
    const headerHeight = document.querySelector("header").offsetHeight;
    const handleScroll = () => {
      if (window.scrollY > headerHeight) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const HandleFooterNAvigation = () => {
    scrollToTop();
  };

  // const Bot = () => {
  //   var Tawk_API = Tawk_API || {},
  //     Tawk_LoadStart = new Date();
  //   (function() {
  //     var s1 = document.createElement("script"),
  //       s0 = document.getElementsByTagName("script")[0];
  //     s1.async = true;
  //     s1.src = "https://embed.tawk.to/650c2d110f2b18434fd9c60f/1harp03km";
  //     s1.charset = "UTF-8";
  //     s1.setAttribute("crossorigin", "*");
  //     s0.parentNode.insertBefore(s1, s0);
  //   })();
  // };


  if (true) {
    {
      // Bot();
    }

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        {showScroll && (
          <img
            src={arrowUp2}
            alt=""
            onClick={scrollToTop}
            className="vert-move"
            style={{
              position: "fixed",
              bottom: "100px",
              right: "20px",
              zIndex: "100",
              cursor: "pointer",
              width: "70px",
            }}
          />
        )}
        {/* <img
          src={chatImg}
          alt=""
          style={{
            position: "fixed",
            bottom: "0px",
            right: "20px",
            zIndex: "100",
            cursor: "pointer",
            width: "50px",
          }}
        /> */}
        <Container
          fluid
          className="landinpage-main-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "0px",
          }}
        >
          <Row style={{ backgroundColor: "#F2F4FF" }}>
            <Col xs={12} style={{ padding: "0px 20px" }}>
              <header>
                <Header />
              </header>
            </Col>
          </Row>
          <Row
            style={{
              // flex: 1,
              maxWidth: "1800px",
              width: "100%",
            }}
          >
            <Col xs={12} style={{ padding: "0px" }}>
              {/* Main Content */}
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#FFF",
                }}
              >
                <Outlet />
              </div>
            </Col>
          </Row>
          <Row style={{ width: "100%", maxWidth: "1800px" }}>
            <Col
              xs={12}
              style={{
                padding: "0px",
              }}
            >
              {/* <div style={{ marginTop: "0px" }}>&nbsp;</div> */}
              <footer
                className="landing-footer footer"
                style={{
                  minHeight: "700px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  // alignItems: "end",
                  gap: "50px",
                }}
              >
                <div className="landingpage-footer-textAndImage">
                  <Col
                    lg={6}
                    style={{ padding: "10px" }}
                    className="landing-footer-text"
                  >
                    <h3>
                      Get Our Free <br />{" "}
                      <span>
                        Trial <button  onClick={handleLoginClick}>Try for Free Now</button>{" "}
                      </span>
                    </h3>
                    <p>
                      Generate promotional materials that stand out from the
                      competition.
                    </p>

                    <div
                      className="landing-page-footer-input-box"
                      style={{}}
                    ></div>
                  </Col>
                  <Col
                    style={{
                      padding: "0px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    lg={5}
                  ></Col>
                </div>
                <Row className="landing-footer-main-box">
                  <Col className="landing-footer-main-box-col2">
                    <h4>Ad Platforms</h4>
                    <div className="landing-footer-main-box-col2-link">
                      <Link
                        // onClick={NavigateToLogin}
                        className="footerNavlinksMain"
                      >
                        {/* {FooterarrowRight} */}

                        <label
                          className="footerNavlinks"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {GoogleAdsSvg}
                          Google Ads
                        </label>
                      </Link>

                      <Link
                        // onClick={HandleFooterNAvigation}
                        className="footerNavlinksMain"
                      >
                        <label
                          className="footerNavlinks"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {FacebookAdsSvg}
                          Facebook Ads
                        </label>
                        {/* {FooterarrowRight} */}
                      </Link>

                      <Link
                        className="footerNavlinksMain"
                        // onClick={HandleFooterNAvigation}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {InstagramAdsSvg}
                          Instagram Ads
                        </label>
                      </Link>
                      <Link
                        className="footerNavlinksMain"
                        // onClick={HandleFooterNAvigation}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {bingAdsSvg}
                          Bing Ads
                        </label>
                      </Link>
                    </div>
                  </Col>

                  <Col className="landing-footer-main-box-col2">
                    <h4>Quick Links</h4>
                    <div className="landing-footer-main-box-col2-link">
                      <NavLink
                        className="footerNavlinksMain"
                        to={"/landingpage/aicreative/graphicdesigner"}
                        onClick={HandleFooterNAvigation}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          AI Creative
                        </label>
                      </NavLink>

                      <NavLink
                        className="footerNavlinksMain"
                        to={"/landingpage/aiad"}
                        onClick={HandleFooterNAvigation}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Smart Ads
                        </label>
                      </NavLink>

                      <NavLink
                        className="footerNavlinksMain"
                        to={"/landingpage/aboutus"}
                        onClick={HandleFooterNAvigation}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          About Us
                        </label>
                      </NavLink>
                      <NavLink
                        className="footerNavlinksMain"
                        to={"/landingpage/blog"}
                        onClick={HandleFooterNAvigation}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Blog
                        </label>
                      </NavLink>
                    </div>
                  </Col>

                  <Col
                    className="landing-footer-main-box-col2"
                    style={{ alignItems: "center" }}
                  >
                    <h4>AI Creatives</h4>
                    <div className="landing-footer-main-box-col2-link ">
                      <HashLink
                        // scroll={(el) =>
                        //   el.scrollIntoView({
                        //     behavior: "auto",
                        //     block: "start",
                        //     inline: "nearest",
                        //   })
                        // }
                        onClick={() => {
                          handleCollapse();
                        }}
                        className="footerNavlinksMain"
                        to={"/landingpage/aicreative/graphicdesigner#Pricing"}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Pricing
                        </label>
                      </HashLink>

                      <HashLink
                        className="footerNavlinksMain"
                        to={"/landingpage/aicreative/graphicdesigner#features"}
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "auto",
                            block: "start",
                            inline: "nearest",
                          })
                        }
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks "
                          style={{ display: "inline" }}
                        >
                          Features
                        </label>
                      </HashLink>
                    </div>
                  </Col>

                  <Col
                    className="landing-footer-main-box-col2"
                    style={{ alignItems: "center" }}
                  >
                    <h4>Smart Ads</h4>
                    <div className="landing-footer-main-box-col2-link ">
                      <HashLink
                        className="footerNavlinksMain"
                        to={"/landingpage/aiad#Pricing"}

                        scroll={scrollWithOffset}

                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Pricing
                        </label>
                      </HashLink>

                      <HashLink
                        className="footerNavlinksMain"
                        to={"/landingpage/aiad#features"}
                        scroll={scrollWithOffset}
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Features
                        </label>
                      </HashLink>
                    </div>
                  </Col>

                  <Col
                    className="landing-footer-main-box-col2"
                    style={{ alignItems: "center" }}
                  >
                    <h4 style={{ paddingRight: "44px" }}>Support</h4>
                    <div className="landing-footer-main-box-col2-link ">
                      <HashLink
                        className="footerNavlinksMain"
                        to={"/landingpage/contactus"}
                        onClick={() => HandleFooterNAvigation()}
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "auto",
                            block: "start",
                            inline: "nearest",
                          })
                        }
                      >
                        {/* {FooterarrowRight} */}
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Contact Us
                        </label>
                      </HashLink>

                      {/* <HashLink
                        className="footerNavlinksMain"
                        to={"/landingpage/privacy&Policy"}
                        onClick={() => HandleFooterNAvigation()}
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "auto",
                            block: "start",
                            inline: "nearest",
                          })
                        }
                      >
                      
                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Privacy policy
                        </label>
                      </HashLink> */}
                           <div
      className="footerNavlinksMain"
      onClick={handleNavigation}
      style={{ cursor: 'pointer' }}
    >
      <label className="footerNavlinks" style={{ display: 'inline' }}>
        Privacy Policy
      </label>
    </div>


                      

                      {/* <HashLink
                        className="footerNavlinksMain"
                        // style={{ justifyContent: "center" }}
                        to={"/landingpage/terms&condition"}
                        onClick={() => HandleFooterNAvigation()}
                        scroll={(el) =>
                          el.scrollIntoView({
                            behavior: "auto",
                            block: "start",
                            inline: "nearest",
                          })
                        }
                      >


                        

                        <label
                          className="footerNavlinks"
                          style={{ display: "inline" }}
                        >
                          Terms & Condition
                        </label>
                      </HashLink> */}


                      <div
      className="footerNavlinksMain"
      onClick={handleNavigations}
      style={{ cursor: 'pointer' }}
    >
      <label className="footerNavlinks" style={{ display: 'inline' }}>
      Terms & Condition
      </label>
    </div>
                      {/* <NavLink className="footerNavlinks" to={"/"}>
                      {FooterarrowRight}
                      F-Menu
                    </NavLink> */}
                    </div>
                  </Col>
                </Row>
              </footer>
            </Col>
            <Col style={{ paddingBottom: "50px" }} className="Bottom-footer">
              <Col
                className="landing-footer-main-box-col1"
                style={{
                  displayL: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Link to={"/"}>
                  <h4>EXOADS</h4>
                </Link>
                <p>
                  Comfortable workplace according to your needs. Upgrade your
                  workspace, reduce your overhead.
                </p>
                <div style={{ display: "flex", gap: "8px" }}>
                  <Link>{Footertwitter}</Link>
                  <Link>{Footerlinkedin}</Link>
                  <Link>{FooterFacebook}</Link>
                  <Link>{footerYoutube}</Link>
                  <Link>{footerig}</Link>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }


};

export default MainContainer;
