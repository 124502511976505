import React from 'react'

export default function EditIcon2() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.9989 16.6667H17.4995" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.7493 2.91576C14.0808 2.58421 14.5305 2.39795 14.9994 2.39795C15.2315 2.39795 15.4614 2.44368 15.6759 2.53252C15.8904 2.62137 16.0853 2.75159 16.2495 2.91576C16.4136 3.07993 16.5439 3.27482 16.6327 3.48931C16.7216 3.70381 16.7673 3.9337 16.7673 4.16587C16.7673 4.39803 16.7216 4.62793 16.6327 4.84242C16.5439 5.05691 16.4136 5.25181 16.2495 5.41597L5.83191 15.8335L2.49829 16.6669L3.3317 13.3333L13.7493 2.91576Z" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
