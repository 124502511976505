import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import axios from "../../../axios/auth";
import Cookies from "js-cookie";
import { Button, Modal, Form, } from "react-bootstrap";
import {  toast } from "react-toastify";
import StylesContaent from "./AdminContent.module.css"
import styles from '../sidebar/AdminSidebar.module.css'
import loader from "../images/ZZ5H.gif" 

function ManageTransaction() {
  const token = Cookies.get("token");
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [refundId, setRefundId] = useState(null);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [amount, setAmount] = useState('');
  const [id, setId] = useState('');
  const [isLoading,setIsLoading] = useState(false)

  const [refundReason, setRefundReason] = useState('');

  const getTransactions = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get("/getAlltransaction", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTransactions(res?.data?.allPlan);
      setCurrentPage(1);
      setIsLoading(false)
    } catch (error) {
      // console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    getTransactions();
  }, [token]);

  const getStatusStyle = (status) => {
    switch (status) {
      case 'success':
        return { color: 'green',fontWeight:700 };
      case 'refund':
        return { color: 'red',fontWeight:700 };
      default:
        return { color: 'black' };
    }
  };

  // Filtered transaction data based on searchQuery
  const filteredTransactions = transactions.filter((data) =>
    data?.userId?.fullName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  // Calculate the total number of pages for filtered data
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);

  // Pagination logic
  const pageRange = 5;
  const maxPageButtons = Math.min(pageRange, totalPages);

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const pageNumbers = Array.from(
    { length: maxPageButtons },
    (_, index) => startPage + index
  );

  // Calculate the indexes of the transactions to display on the current page
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const openDeleteModal = (customerId, id) => {
    setId(id)
    setRefundId(customerId);
    setShowRefundModal(true);
  };

  // Function to close the delete confirmation modal
  const closeRefundeModal = () => {
    setRefundId(null);
    setShowRefundModal(false);
  };

  const isFormValid = amount.trim() !== '' && refundReason.trim() !== '';
  const handleSubmit = async (e) => {
    // Here, you can access the 'amount' and 'refundReason' state values
    e.preventDefault();

    try {
      const refundData = {
        id,
        amount,
        refundReason
      }

      const res = await axios.post(`/refund-payment/${refundId}`, refundData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (res?.data?.status === true) {
        // toast.success(res?.data?.message)
        toast.success(res?.data?.message, {
          className: 'custom-toast-success',
          hideProgressBar: true
        });
        getTransactions();


      }
      else {
        // toast.error(res?.data?.message)
        toast.error(res?.data?.message, {
          className: 'custom-toast-error',
           hideProgressBar: true
         }); 

      }

    } catch (error) {
      // console.log(error);
      // toast.error(error.response?.data?.error)
      toast.error(error.response?.data?.error, {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 

    }
    // Perform any further actions, such as making an API request
    // Reset the form fields and close the modal if needed
    setAmount('');
    setRefundReason('');
    closeRefundeModal();
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 p-0"  >
            <div className={styles.mobileSidebar}>
              <AdminSidebar className={styles.mobileSidebar} />
            </div>
          </div>
          <div className="col-md-10 bg-white">
            <div className="container">
              <Header title="Add Creative" btnname="Create Campaigns" />
            </div>
            <div className="container">
              <div className="container">
                <div className="row">
                  <h4 className="my-4 ps-0">
                    <b> Manage Transaction </b>
                  </h4>
                  <hr />
           
            
                  {currentPage === 1 && ( // Only show search bar on the first page
                    <div className="mt-2 p-0">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search by Customer Name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() => setSearchQuery("")}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                         {isLoading?<div style={{width:"100%",height:"50vh", position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
                         <img src={loader} style={{width:"100px",height:"100px"}} alt="" /></div>: 
                  <div className={StylesContaent.tableHeading}>
                    <table className="table table-striped table-border text-center">
                      <thead>
                        <tr>
                          <th className="border">Customer Name</th>
                          <th className="border">Amount</th>
                          <th className="border">Transaction Id</th>
                          <th className="border">Payment Status</th>
                          <th className="border">Payment Date</th>
                          <th className="border">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTransactions.map((data) => (
                          <tr key={data.paymentId}>
                            <td className="border"  style={{ textTransform: "capitalize" }}>{data?.userId?.fullName}</td>
                            <td className="border">{data.amount}</td>
                            <td className="border">{data.paymentId}</td>

                            <td
  className="border"
  style={getStatusStyle(data.paymentStatus)}
>

  {data?.paymentStatus
    ? `${data.paymentStatus.charAt(0).toUpperCase()}${data.paymentStatus.slice(1)}`
    : ''}

</td>

                            <td className="border">
                              {data?.paymentDate?.slice(0, 10)}
                            </td>

                            {/* <td className="border">
                              <Button variant="primary" className="common-btn" onClick={() => openDeleteModal(data.paymentId, data._id)}>Refund</Button>
                            </td> */}
                            <td className="border">
  {data?.paymentStatus === 'refund' ? (
 <Button
 variant="success" // Corrected variant name
 className="common-btn"
 style={{
   color: 'white',
   backgroundColor: 'green',
   borderColor: 'green',
   cursor: 'default', // Set cursor to default
   textAlign: 'center', 
   fontSize:"13px"// Center align text for better appearance
     // Ensure the button behaves as a block-level element
 }}
>
 Refund Done
</Button>


  ) : (
    <Button
      variant="primary"
      className="common-btn"
      onClick={() => openDeleteModal(data.paymentId, data._id)}
      style={{
        fontSize:"15px"// Center align text for better appearance
          // Ensure the button behaves as a block-level element
      }}
    >
      Refund
    </Button>
  )}
</td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pagination */}
        <div className="d-flex justify-content-center my-3">
          <ul className="pagination">
            {currentPage > pageRange && (
              <li className="page-item">
                <button className="page-link" onClick={() => paginate(1)}>
                  1
                </button>
              </li>
            )}
            {currentPage > pageRange && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
            {pageNumbers.map((pageNumber) => (
              <li
                key={pageNumber}
                className={`${currentPage === pageNumber}` ? `${StylesContaent.activePage}` : ""}
              >
                <button 
                  className= {`${StylesContaent.activePage} page-link` } 
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            {currentPage + Math.floor(pageRange / 2) < totalPages && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
            {currentPage + Math.floor(pageRange / 2) < totalPages && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </button>
              </li>
            )}
          </ul>

          {/*  Confirmation Modal */}

          <Modal show={showRefundModal} onHide={closeRefundeModal}>
            <Modal.Header closeButton    style={{
              background: 'linear-gradient(135deg, #47beb9 0%, #b7b2c6 100%)',
              color: 'white',
              padding: '10px',
              borderRadius: '5px',
              textAlign: 'center',
              width: '100%',
              boxSizing: 'border-box'
            }}>
            <Modal.Title
               style={{
                background: 'linear-gradient(135deg, #47beb9 0%, #b7b2c6 100%)',
                color: 'white',
                textAlign: 'center',
                width: '100%',
                boxSizing: 'border-box'
              }}>
         
          
            Confirm Refund
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="amount">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="refundReason">
                  <Form.Label>Refund Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter refund reason"
                    value={refundReason}
                    onChange={(e) => setRefundReason(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="primary" className="common-btn" onClick={handleSubmit}>
                Submit
              </Button> */}
              <Button
          variant="primary"
          className="common-btn"
          onClick={handleSubmit}
          disabled={!isFormValid} // Disable button if form is not valid
        >
          Submit
        </Button>

              {/* Add other buttons or actions if needed */}
            </Modal.Footer>
          </Modal>


        </div>
        {/* <ToastContainer /> */}
      </div>
    </>
  );
}

export default ManageTransaction;
