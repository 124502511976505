import React from 'react';

const AHeader = () => {
    return (
        <>
            <button className="btn btn-warning m-2" style={{height:"500px"}}> Home</button>
            <button className="btn btn-warning m-2" style={{height:"500px"}}> About</button>
            <button className="btn btn-warning m-2"> Home</button>
            <button className="btn btn-warning m-2"> Home</button>
        </>
    );
}

export default AHeader;
