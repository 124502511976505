import { Link, useNavigate } from "react-router-dom";
import "./TextScreen.css";

import post from "../../../assets/images/textScreen/post.svg";
import { useState, useEffect } from "react";
import { toPng } from "html-to-image"; // Import specific function
// import livePreview from "../../images/textScreen/livePreview.svg"
import livePreview from "../../../assets/images/textScreen/livePreview.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import Header from "../header/Header";

import React, { createContext } from "react";

import Sidebar from "../sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { addCreatives } from "../../../../Redux/addCreateSlice";
import { useForm } from "react-hook-form";
// import Template from "../AddTemplate/Template1/Template";

// Create a new context
const HTMLContent = ({ imageUrl, title, description, color }) => {
  return <div id="html-content"></div>;
};

const FormContent = ({ onSubmit }) => {
  // const [active  ,setActive]=useState(false);
  //   const [data, setData] = useState();
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { addCreative } = useSelector((state) => state.addcreative);

  const submit = (e) => {
    dispatch(addCreatives(e));
    // setData(e);
    // setActive(true)

    navigate("/user/createdListing");


    reset();
  };
  return (
    <>
      <div className="row  h-100 m-0 p-0">
        <div className="col-sm-2 sidebar-bg-color px-0 mx-0 sidebar-border">
          <div className="">
            <Sidebar className="" />
          </div>
        </div>
        <div className="col-sm-10 bg-white">
          <div className="container">
            <div className="container">
              <Header title="CreatedList" btnname="Upgrade Plan" />
            </div>
            <div className="row">
              <h6 className="title text-dark text-center mt-2">
                Select your creative size below depending on the platform you
                Upload any image for your product/service or use our free & paid
                image Search Engine for Premium stock images. to advertise on.
              </h6>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="card card-border border-0 px-2">
                  <div className="card-header bg-transparent border-success">
                    <div className="d-flex  justify-content-between ">
                      <div className="d-flex">
                        <img src={post} style={{ height: "20px" }} alt="" />
                        <div className=" ms-3">
                          <h6 className="card-heading fw-bold">Post : Name</h6>
                          <p className="card-paragraph">(1080x1080)</p>
                        </div>
                      </div>
                      <div className="d-flex  btn-b-primary justify-content-center align-items-center">
                        <h6 className="text-white text-center align-items-center mt-1">
                          Text AI <FontAwesomeIcon icon={faForward} />
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <form onSubmit={handleSubmit(submit)}>
                        <div className="col-sm-12  my-2">
                          <input
                            type="text"
                            className="form-control custom-input p-3"
                            placeholder="Image URL"
                            {...register("imageUrl")}
                          />
                        </div>
                        <div className="col-sm-12 ">
                          <input
                            type="text"
                            className="form-control custom-input p-3"
                            placeholder="Logo Image  URL"
                            // value={logoUrl}
                            // onChange={(e) => setLogoUrl(e.target.value)}
                            {...register("logoUrl")}
                          />
                        </div>
                        <div className="col-sm-12 mt-3">
                          <input
                            type="text"
                            placeholder="Headline"
                            {...register("title")}
                            className="form-control custom-input"
                          />
                        </div>
                        <div className="col-sm-12 mt-3">
                          <input
                            type="text"
                            placeholder="Description"
                            {...register("description")}
                            className="form-control custom-input"
                          />
                        </div>
                        <div className="col-sm-12 mt-3">
                          <input
                            type="color"
                            {...register("color")}
                            className="form-control custom-input"
                          />
                        </div>

                        <div className="col-sm-12 mt-3 ">
                          <div className="form-control custom-input d-flex justify-content-between ">
                            <input
                              type="text"
                              {...register("callToAction")}
                              className="form-control custom-input2"
                            />
                            <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic mixed styles example"
                            >
                              <button type="button" class="btn btn1">
                                {" "}
                                <i className="fa-solid fa-chevron-right text-white"></i>
                              </button>
                              <button type="button" class="btn btn2">
                                <i class="fa-solid fa-chevron-down text-white"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* <Link to='/pages/createdListing'> */}

                        <button type="Submit" className="btn btn-prime mt-2">
                          Save & Continue{" "}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card card-border border-0">
                  <div className="card-header bg-transparent ">
                    <div className="d-flex ">
                      <img src={livePreview} style={{ height: "20px" }} alt="" />
                      <div className=" ms-3">
                        <h6 className="card-heading fw-bold">Live Preview</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 ">
                        {/* <div className="upload-img-box2">
                                    </div> */}
                        <HTMLContent {...HTMLContent} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-end mt-3 ">
                <Link to="/user/selectImage">
                  <button className="btn"> &#x2190; Go Back</button>
                </Link>
                <Link to="/user/createdListing">
                  {/* <button onClick={handleSaveImage}>Save as Image</button> */}
                  {/* <button  className='btn btn-prime' >Save & Continue </button> */}
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function TextScreen({ data, myData }) {
  const [htmlContent, setHtmlContent] = useState({
    imageUrl: "default-image-url.jpg",
    title: "",
    description: "",
    color: "",
    logoUrl: "",
    calltoA: "",
  });

  const handleFormSubmit = (formData) => {
    setHtmlContent(formData);
    localStorage.setItem("imgurl", formData.imageUrl);
    localStorage.setItem("title", formData.title);
    localStorage.setItem("description", formData.description);
    localStorage.setItem("calltoA", formData.calltoA);
    localStorage.setItem("logoUrl", formData.logoUrl);
    localStorage.setItem("color", formData.color);
  };

  const handleSaveImage = () => {
    const element = document.getElementById("html-content");
    toPng(element)
      .then(function (dataUrl) {
        // Save or display the resulting image
        const link = document.createElement("a");
        link.href = dataUrl;
        localStorage.setItem("url", dataUrl);
        // link.download = 'content-image.png';
        link.click();
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div>
        <h3>{myData}</h3>
        <HTMLContent {...htmlContent} />
        <FormContent onSubmit={handleFormSubmit} />
        {/* <button onClick={handleSaveImage}>Save as Image</button> */}
      </div>
    </>
  );
}
export default TextScreen;
