import React, { useEffect, useState } from "react";
import "./PerformanceCampaigns.css";
import { GoogleNavigation, Header } from "../GoogleCampaign/GoogleCampaignMain";
import { Outlet, useNavigate } from "react-router-dom";
import { Col, Container, Modal, Row } from "react-bootstrap";
import {
  BingModal,
  FbLogoForModal,
  GoogleForModal,
  IGModal,
} from "./Ad Traffice Only/SVg";
import { Btn } from "../GoogleCampaign/GoogleCampaignMain";
import RectanglePic from "./Ad Traffice Only/Image/RectanglePic.png";
// import Bing from "../../IntegrationPage/Images/Bing.png";
// import { MailContext } from "../../../Auth Pages/mailContext";
import { useSelector } from "react-redux";

const PerformanceCampaigns = ({ data }) => {
  const [activeModal, setActiveModal] = useState(false);
  const [AdsDetail, setAdsDetail] = useState(null);

  const { SingleGoogleAdDetail } = useSelector(state => state.AdsAnalyticsDetail)

  const navigate = useNavigate()
  useEffect(() => {
    if (SingleGoogleAdDetail) {
      setAdsDetail(SingleGoogleAdDetail)
    } else {
      navigate("/main/campaign")
    }
  }, [SingleGoogleAdDetail])


  const HandleNewAdBtn = () => {
    setActiveModal(true);
  };

  return (
    <Container
      fluid
      style={{
        padding: "0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          margin: "10px 0px",
          position: "sticky",
        }}
      >
        <div>
          <Header
            navigate={"/main"}
            display={2}
            title={"Campaign Name"}
            HandleNewAdBtn={HandleNewAdBtn}
          />
          <CustomModal
            show={activeModal}
            onHide={() => setActiveModal(false)}
          />
        </div>
        <div style={{ margin: "0px 5px" }}>
          <GoogleNavigation
            title1={"Ad Traffic Only"}
            title2={"All Traffic"}
            title3={"Your Website"}
            NavTo1={"ad"}
            NavTo2={"ad"}
            NavTo3={"/main/campaign/performancecampaign/website"}
          />
        </div>
      </Row>
      <Row
        style={{ height: "100%", overflowY: "auto" }}
        className="Performance-scroller-class"
      >
        <Outlet />
      </Row>
    </Container>
  );
};

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container fluid style={{ width: "100%" }}>
        <Row style={{ margin: "10px 5px" }}>
          <ModalItem
            logo={FbLogoForModal}
            heading={"Facebook Ads"}
            rectangleColor={false}
            borderColor={"#036DC3"}
            body={`With Facebook Ads you can reach an audience based on their interests. You will attract visitors because your ad will make them curious.`}
          />

          <ModalItem
            logo={GoogleForModal}
            heading={"Google Ads"}
            rectangleColor={false}
            borderColor={"#036DC3"}
            body={`With Google Search Ads you can reach a large audience that is specifically searching for a certain topic and might be in a buying mood already.`}
          />

          <ModalItem
            logo={IGModal}
            heading={"Instagram Ads"}
            margin={"10px 0px 3px 0px"}
            rectangleColor={RectanglePic}
            borderColor={"#EF3576"}
            body={`With Google Search Ads you can reach a large audience that is specifically searching for a certain topic and might be in a buying mood already.`}
          />

          <ModalItem
            logo={BingModal}
            heading={"Bing Ads"}
            margin={"10px 0px 3px 0px"}
            // rectangleColor={RectanglePic}
            borderColor={"#036DC3"}
            body={`With Google Search Ads you can reach a large audience that is specifically searching for a certain topic and might be in a buying mood already.`}
          />
        </Row>
      </Container>
    </Modal>
  );
};
export const ModalItem = ({
  logo,
  logoImg,
  heading,
  rectangleColor,
  borderColor,
  body,
  margin,
  height,
  lg,
  sm,
  md,
  mainHeight,
  ButtonLabel,
}) => {
  return (
    <Col
      lg={lg ? lg : 3}
      md={md ? md : 12}
      sm={sm ? sm : 12}
      style={{ padding: "0px" }}
    >
      <div
        className="Performance-modal-item-body"
        style={{ border: `1px solid ${borderColor}` }}
      >
        <div
          className="Performance-rectangle"
          style={height && { height: height }}
        >
          {rectangleColor ? (
            <img
              src={rectangleColor}
              alt="rectangle"
              style={{ width: "100%", height: "100%" }}
            />
          ) : (
            <div
              style={{
                background: "#4285f4",
                width: "100%",
                height: "100%",
                fontSize: "35px",
                color: "#4285f4",
              }}
            >
              a
            </div>
          )}
        </div>

        <div className="Performance-modal-item-box">
          <div
            className="Performance-modal-item-box-Header"
            style={{ margin: margin }}
          >
            {logo ? (
              <span>{logo}</span>
            ) : (
              <img src={rectangleColor} alt="rectangle" />
            )}

            <h5>{heading}</h5>
          </div>
          <p
            className="Performance-modal-item-box-main"
            style={mainHeight && { height: mainHeight }}
          >
            {body}
          </p>
        </div>
        <div style={{ margin: "10px" }}>
          <Btn title={ButtonLabel ? ButtonLabel : "Select"} />
        </div>
      </div>
    </Col>
  );
};

export default PerformanceCampaigns;
