export const PlayIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M10.9985 18.334C15.6012 18.334 19.3325 14.6027 19.3325 9.99998C19.3325 5.39726 15.6012 1.66602 10.9985 1.66602C6.3958 1.66602 2.66455 5.39726 2.66455 9.99998C2.66455 14.6027 6.3958 18.334 10.9985 18.334Z"
      stroke="#03BB63"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33203 6.6665L14.333 10.0005L9.33203 13.3345V6.6665Z"
      stroke="#03BB63"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const PauseIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M10.999 18.3335C15.6017 18.3335 19.333 14.6022 19.333 9.9995C19.333 5.39677 15.6017 1.66553 10.999 1.66553C6.39628 1.66553 2.66504 5.39677 2.66504 9.9995C2.66504 14.6022 6.39628 18.3335 10.999 18.3335Z"
      stroke="#D30303"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6655 12.5V7.49902"
      stroke="#D30303"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33203 12.5V7.49902"
      stroke="#D30303"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const RedDotIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <circle cx="6" cy="6" r="6" fill="#D30303" />
  </svg>
);

export const GreenDotIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <circle cx="6" cy="6" r="6" fill="#03BB63" />
  </svg>
);

export const GoogleForModal = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
  >
    <g filter="url(#filter0_d_1123_27196)">
      <circle cx="52" cy="52" r="40" fill="white" />
      <path
        d="M78.8475 51.4715C78.8475 49.1691 78.6569 47.489 78.2444 45.7466H51.417V56.1384H67.164C66.8466 58.7208 65.1322 62.6101 61.3223 65.2235L61.2689 65.5714L69.7513 72.011L70.3389 72.0686C75.7362 67.1838 78.8475 59.9965 78.8475 51.4715Z"
        fill="#4285F4"
      />
      <path
        d="M51.4174 78.8505C59.1319 78.8505 65.6083 76.3614 70.3387 72.068L61.3225 65.2229C58.9098 66.872 55.6714 68.0232 51.4174 68.0232C43.8617 68.0232 37.4487 63.1385 35.1627 56.3872L34.8276 56.415L26.0079 63.1042L25.8926 63.4185C30.5911 72.5656 40.2423 78.8505 51.4174 78.8505Z"
        fill="#34A853"
      />
      <path
        d="M35.1659 56.3875C34.5627 54.6452 34.2136 52.7782 34.2136 50.8493C34.2136 48.9201 34.5627 47.0534 35.1342 45.311L35.1182 44.94L26.1876 38.1431L25.8954 38.2794C23.9589 42.0753 22.8477 46.3378 22.8477 50.8493C22.8477 55.3607 23.9589 59.6232 25.8954 63.4191L35.1659 56.3875Z"
        fill="#FBBC05"
      />
      <path
        d="M51.4176 33.6747C56.7828 33.6747 60.4018 35.9459 62.4656 37.8439L70.5293 30.128C65.577 25.6167 59.1321 22.8477 51.4176 22.8477C40.2423 22.8477 30.5911 29.1323 25.8926 38.2794L35.131 45.3109C37.4488 38.5594 43.8617 33.6747 51.4176 33.6747Z"
        fill="#EB4335"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1123_27196"
        x="0"
        y="0"
        width="104"
        height="104"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1123_27196"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1123_27196"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const FbLogoForModal = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
  >
    <g filter="url(#filter0_d_1123_27212)">
      <circle cx="52" cy="52" r="40" fill="white" />
      <path
        d="M52 80C67.464 80 80 67.464 80 52C80 36.536 67.464 24 52 24C36.536 24 24 36.536 24 52C24 67.464 36.536 80 52 80Z"
        fill="url(#paint0_linear_1123_27212)"
      />
      <path
        d="M63.0525 61.5274L64.2961 53.97H56.5154V49.0679C56.5154 46.9998 57.6003 44.9827 61.0854 44.9827H64.625V38.5487C64.625 38.5487 61.4141 38.0381 58.3456 38.0381C51.9348 38.0381 47.7484 41.6585 47.7484 48.21V53.97H40.625V61.5274H47.7484V79.6444C49.1784 79.8539 50.6415 79.9611 52.132 79.9611C53.6224 79.9611 55.0853 79.8539 56.5154 79.6444V61.5274H63.0525Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1123_27212"
        x="0"
        y="0"
        width="104"
        height="104"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1123_27212"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1123_27212"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1123_27212"
        x1="52"
        y1="24"
        x2="52"
        y2="79.834"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#18ACFE" />
        <stop offset="1" stop-color="#0163E0" />
      </linearGradient>
    </defs>
  </svg>
);

export const IGModal = (
  <div
    style={{
      backgroundColor: "white",
      width: "82px",
      height: "82px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "100%",
      marginBottom: "10px",
    }}
    className="shadow"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      style={{ position: "", left: "12px", top: "12px" }}
      height="60"
      viewBox="0 0 57 56"
      fill="none"
    >
      <g clip-path="url(#clip0_1123_27228)">
        <path
          d="M56.5 28C56.5 12.536 43.964 0 28.5 0C13.036 0 0.5 12.536 0.5 28C0.5 43.464 13.036 56 28.5 56C43.964 56 56.5 43.464 56.5 28Z"
          fill="url(#paint0_linear_1123_27228)"
        />
        <path
          opacity="0.1"
          d="M49.5739 9.57918C45.4918 17.0729 39.7186 24.8023 32.5049 32.016C25.2912 39.2297 17.5618 44.9918 10.0791 49.0739C9.61 48.6662 9.15439 48.2413 8.71228 47.7992C6.03996 45.2161 3.90865 42.1268 2.44262 38.7115C0.976597 35.2961 0.205191 31.6231 0.173388 27.9066C0.141586 24.19 0.850022 20.5044 2.25739 17.0644C3.66476 13.6245 5.74289 10.4992 8.37061 7.87077C10.9983 5.24231 14.1231 3.16331 17.5626 1.75498C21.0021 0.346652 24.6876 -0.362814 28.4041 -0.332048C32.1207 -0.301283 35.7939 0.469097 39.2097 1.93417C42.6254 3.39925 45.7153 5.52969 48.2991 8.20129C48.7412 8.6434 49.1661 9.10269 49.5739 9.57918Z"
          fill="white"
        />
        <path
          d="M35.1316 13.2632H21.8684C19.7188 13.2632 17.6572 14.1171 16.1372 15.6372C14.6171 17.1572 13.7632 19.2188 13.7632 21.3684V34.6316C13.7632 36.7813 14.6171 38.8429 16.1372 40.3629C17.6572 41.8829 19.7188 42.7369 21.8684 42.7369H35.1316C37.2813 42.7369 39.3429 41.8829 40.8629 40.3629C42.3829 38.8429 43.2369 36.7813 43.2369 34.6316V21.3684C43.2369 19.2188 42.3829 17.1572 40.8629 15.6372C39.3429 14.1171 37.2813 13.2632 35.1316 13.2632ZM40.6579 33.4674C40.6579 35.2418 39.953 36.9436 38.6983 38.1983C37.4436 39.453 35.7418 40.1579 33.9674 40.1579H23.0327C21.2582 40.1579 19.5565 39.453 18.3017 38.1983C17.047 36.9436 16.3421 35.2418 16.3421 33.4674V22.5327C16.3421 20.7582 17.047 19.0565 18.3017 17.8017C19.5565 16.547 21.2582 15.8421 23.0327 15.8421H33.9674C35.7418 15.8421 37.4436 16.547 38.6983 17.8017C39.953 19.0565 40.6579 20.7582 40.6579 22.5327V33.4674Z"
          fill="white"
        />
        <path
          d="M33.8751 22.68L33.8051 22.61L33.7462 22.5511C32.3531 21.1629 30.4664 20.3838 28.4998 20.3848C27.5067 20.3915 26.5247 20.5939 25.6098 20.9803C24.6949 21.3667 23.8651 21.9296 23.1679 22.6368C22.4706 23.344 21.9195 24.1816 21.546 25.1019C21.1726 26.0221 20.9841 27.0069 20.9914 28C20.9899 30.0155 21.7844 31.95 23.2019 33.3827C23.8963 34.0855 24.7237 34.6429 25.6359 35.0224C26.548 35.402 27.5266 35.596 28.5146 35.5932C29.9965 35.5621 31.4374 35.1005 32.6614 34.2646C33.8855 33.4287 34.8399 32.2546 35.4082 30.8857C35.9765 29.5167 36.1341 28.0119 35.8619 26.5549C35.5897 25.0978 34.8993 23.7515 33.8751 22.68ZM28.4998 32.9958C27.5089 33.0097 26.5362 32.7286 25.7055 32.1881C24.8748 31.6477 24.2236 30.8724 23.8347 29.9608C23.4458 29.0493 23.3369 28.0427 23.5217 27.069C23.7064 26.0954 24.1766 25.1987 24.8724 24.493C25.5682 23.7873 26.4582 23.3045 27.4292 23.106C28.4001 22.9075 29.4082 23.0022 30.3251 23.3782C31.2421 23.7542 32.0265 24.3944 32.5786 25.2174C33.1307 26.0404 33.4255 27.009 33.4256 28C33.4305 28.6516 33.3069 29.2977 33.0619 29.9014C32.817 30.5052 32.4554 31.0547 31.998 31.5187C31.5405 31.9826 30.9961 32.3519 30.3959 32.6053C29.7957 32.8588 29.1514 32.9915 28.4998 32.9958Z"
          fill="white"
        />
        <path
          d="M38.2375 20.0348C38.2395 20.2712 38.1948 20.5056 38.106 20.7248C38.0172 20.9439 37.886 21.1433 37.7201 21.3117C37.5541 21.4801 37.3565 21.614 37.1387 21.706C36.9209 21.7979 36.6871 21.8459 36.4507 21.8474C36.2162 21.8473 35.9842 21.8007 35.7679 21.7102C35.5516 21.6198 35.3554 21.4873 35.1907 21.3205C34.9411 21.0673 34.7713 20.7464 34.7024 20.3976C34.6335 20.0487 34.6685 19.6874 34.803 19.3582C34.9376 19.0291 35.1658 18.7467 35.4593 18.5461C35.7529 18.3454 36.0988 18.2354 36.4544 18.2295C36.8705 18.2294 37.2734 18.3755 37.5928 18.6421L37.6296 18.679C37.689 18.7273 37.7434 18.7817 37.7917 18.8411L37.8323 18.8853C38.096 19.2102 38.2392 19.6163 38.2375 20.0348Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1123_27228"
          x1="8.70105"
          y1="8.20105"
          x2="48.2989"
          y2="47.7989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAE100" />
          <stop offset="0.15" stop-color="#FCB720" />
          <stop offset="0.3" stop-color="#FF7950" />
          <stop offset="0.5" stop-color="#FF1C74" />
          <stop offset="1" stop-color="#6C1CD1" />
        </linearGradient>
        <clipPath id="clip0_1123_27228">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export const BingModal = (
  <div
    style={{
      backgroundColor: "white",
      width: "82px",
      height: "82px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "100%",
      marginBottom: "10px",
    }}
    className="shadow"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="49"
      viewBox="0 0 37 49"
      fill="none"
    >
      <g clip-path="url(#clip0_2903_28788)">
        <path
          d="M17.9023 16.8671C17.895 16.9254 17.895 16.9917 17.895 17.0568C17.895 17.3097 17.9455 17.553 18.0367 17.7768L18.1182 17.9446L18.4405 18.7692L20.1145 23.0398L21.5746 26.7692C21.9911 27.522 22.6582 28.1131 23.4627 28.4281L23.7126 28.5199C23.7223 28.5229 23.7388 28.5229 23.7521 28.5266L27.754 29.9027V29.9057L29.2839 30.4311L29.3885 30.4664C29.3922 30.4664 29.3982 30.47 29.4019 30.47C29.7035 30.546 29.999 30.6482 30.2812 30.7728C30.9337 31.0544 31.5253 31.4557 32.0307 31.9452C32.2344 32.1398 32.4217 32.3496 32.595 32.5734C32.7425 32.7613 32.8774 32.9587 32.9987 33.1645C33.5326 34.0511 33.8391 35.0933 33.8391 36.2031C33.8391 36.4013 33.8294 36.5929 33.8093 36.7875C33.8026 36.8732 33.7923 36.9559 33.7795 37.038V37.0447C33.7661 37.1316 33.7497 37.2216 33.7333 37.3098C33.7163 37.3955 33.7005 37.4807 33.681 37.5658C33.678 37.5755 33.6743 37.5859 33.6707 37.5956C33.6524 37.682 33.6293 37.7671 33.6056 37.8528C33.5843 37.9337 33.5576 38.017 33.5278 38.0955C33.5015 38.1813 33.4709 38.2657 33.436 38.3484C33.4062 38.4336 33.3709 38.5199 33.3314 38.6014C33.2231 38.8513 33.0942 39.0915 32.9495 39.3208C32.7629 39.6219 32.5499 39.9058 32.3128 40.169C34.1906 38.1502 35.439 35.5433 35.7497 32.6585C35.802 32.1854 35.8288 31.7057 35.8288 31.2228C35.8288 30.9145 35.8154 30.6093 35.7953 30.3028C35.5588 26.8434 33.9838 23.7494 31.5801 21.5414C30.9173 20.9279 30.193 20.3818 29.4141 19.9185L27.9425 19.1662L20.4855 15.3395C20.2563 15.2413 20.0094 15.1908 19.76 15.1912C18.7944 15.1912 17.9972 15.9276 17.9023 16.8671Z"
          fill="#7F7F7F"
        />
        <path
          d="M17.9023 16.8671C17.895 16.9254 17.895 16.9917 17.895 17.0568C17.895 17.3097 17.9455 17.553 18.0367 17.7768L18.1182 17.9446L18.4405 18.7692L20.1145 23.0398L21.5746 26.7692C21.9911 27.522 22.6582 28.1131 23.4627 28.4281L23.7126 28.5199C23.7223 28.5229 23.7388 28.5229 23.7521 28.5266L27.754 29.9027V29.9057L29.2839 30.4311L29.3885 30.4664C29.3922 30.4664 29.3982 30.47 29.4019 30.47C29.7035 30.546 29.999 30.6482 30.2812 30.7728C30.9337 31.0544 31.5253 31.4557 32.0307 31.9452C32.2344 32.1398 32.4217 32.3496 32.595 32.5734C32.7425 32.7613 32.8774 32.9587 32.9987 33.1645C33.5326 34.0511 33.8391 35.0933 33.8391 36.2031C33.8391 36.4013 33.8294 36.5929 33.8093 36.7875C33.8026 36.8732 33.7923 36.9559 33.7795 37.038V37.0447C33.7661 37.1316 33.7497 37.2216 33.7333 37.3098C33.7163 37.3955 33.7005 37.4807 33.681 37.5658C33.678 37.5755 33.6743 37.5859 33.6707 37.5956C33.6524 37.682 33.6293 37.7671 33.6056 37.8528C33.5843 37.9337 33.5576 38.017 33.5278 38.0955C33.5015 38.1813 33.4709 38.2657 33.436 38.3484C33.4062 38.4336 33.3709 38.5199 33.3314 38.6014C33.2231 38.8513 33.0942 39.0915 32.9495 39.3208C32.7629 39.6219 32.5499 39.9058 32.3128 40.169C34.1906 38.1502 35.439 35.5433 35.7497 32.6585C35.802 32.1854 35.8288 31.7057 35.8288 31.2228C35.8288 30.9145 35.8154 30.6093 35.7953 30.3028C35.5588 26.8434 33.9838 23.7494 31.5801 21.5414C30.9173 20.9279 30.193 20.3818 29.4141 19.9185L27.9425 19.1662L20.4855 15.3395C20.2563 15.2413 20.0094 15.1908 19.76 15.1912C18.7944 15.1912 17.9972 15.9276 17.9023 16.8671Z"
          fill="url(#paint0_linear_2903_28788)"
        />
        <path
          d="M4.62447 0.00012207C3.56336 0.0189728 2.71143 0.887325 2.71143 1.94905V35.9449C2.71568 36.1821 2.72906 36.4162 2.74609 36.6539C2.76129 36.7792 2.77771 36.9093 2.80081 37.0358C3.28546 39.7643 5.65944 41.8349 8.52598 41.8349C9.53115 41.8349 10.4737 41.5788 11.2983 41.1337C11.3031 41.1301 11.3116 41.1252 11.3159 41.1252L11.6126 40.9458L12.8154 40.238L14.3454 39.3301L14.349 9.1245C14.349 7.1172 13.3445 5.34766 11.8066 4.29627C11.7701 4.27438 11.7349 4.25128 11.7026 4.22513L5.74944 0.332139C5.44783 0.132686 5.08419 0.00741914 4.69441 0.00012207H4.62447Z"
          fill="#7F7F7F"
        />
        <path
          d="M4.62447 0.00012207C3.56336 0.0189728 2.71143 0.887325 2.71143 1.94905V35.9449C2.71568 36.1821 2.72906 36.4162 2.74609 36.6539C2.76129 36.7792 2.77771 36.9093 2.80081 37.0358C3.28546 39.7643 5.65944 41.8349 8.52598 41.8349C9.53115 41.8349 10.4737 41.5788 11.2983 41.1337C11.3031 41.1301 11.3116 41.1252 11.3159 41.1252L11.6126 40.9458L12.8154 40.238L14.3454 39.3301L14.349 9.1245C14.349 7.1172 13.3445 5.34766 11.8066 4.29627C11.7701 4.27438 11.7349 4.25128 11.7026 4.22513L5.74944 0.332139C5.44783 0.132686 5.08419 0.00741914 4.69441 0.00012207H4.62447Z"
          fill="url(#paint1_linear_2903_28788)"
        />
        <path
          d="M28.2301 31.0579L14.5426 39.171L14.3456 39.2896V39.3327L12.8157 40.2388L11.6135 40.9484L11.3185 41.1266L11.2985 41.1363C10.4757 41.5833 9.53258 41.8362 8.52801 41.8362C5.66209 41.8362 3.28507 39.7669 2.80225 37.0372C3.0315 39.0342 3.71195 40.8931 4.7384 42.5124C6.85212 45.849 10.4265 48.1701 14.5615 48.5733H17.0899C19.3265 48.3398 21.1939 47.444 23.1568 46.2345L26.1754 44.3884C27.5351 43.517 31.2256 41.3735 32.3128 40.1707C32.5494 39.9074 32.7628 39.6252 32.9495 39.323C33.0942 39.0931 33.2231 38.853 33.3314 38.603C33.3679 38.5179 33.4025 38.4352 33.436 38.3507C33.4676 38.2668 33.4974 38.1822 33.5278 38.0965C33.5843 37.9323 33.6293 37.7675 33.6725 37.5979C33.6944 37.5012 33.7145 37.4057 33.7345 37.3108C33.8002 36.9527 33.8361 36.5848 33.8361 36.2035C33.8361 35.0937 33.5296 34.0515 32.9987 33.1661C32.8771 32.9594 32.7433 32.7617 32.595 32.575C32.4217 32.35 32.2344 32.1415 32.0307 31.9469C31.5253 31.4574 30.9355 31.056 30.2812 30.7739C29.999 30.6492 29.7047 30.5465 29.4019 30.4717C29.3982 30.4717 29.3922 30.468 29.3885 30.468L29.2839 30.4327L28.2301 31.0579Z"
          fill="#7F7F7F"
        />
        <path
          d="M28.2301 31.0579L14.5426 39.171L14.3456 39.2896V39.3327L12.8157 40.2388L11.6135 40.9484L11.3185 41.1266L11.2985 41.1363C10.4757 41.5833 9.53258 41.8362 8.52801 41.8362C5.66209 41.8362 3.28507 39.7669 2.80225 37.0372C3.0315 39.0342 3.71195 40.8931 4.7384 42.5124C6.85212 45.849 10.4265 48.1701 14.5615 48.5733H17.0899C19.3265 48.3398 21.1939 47.444 23.1568 46.2345L26.1754 44.3884C27.5351 43.517 31.2256 41.3735 32.3128 40.1707C32.5494 39.9074 32.7628 39.6252 32.9495 39.323C33.0942 39.0931 33.2231 38.853 33.3314 38.603C33.3679 38.5179 33.4025 38.4352 33.436 38.3507C33.4676 38.2668 33.4974 38.1822 33.5278 38.0965C33.5843 37.9323 33.6293 37.7675 33.6725 37.5979C33.6944 37.5012 33.7145 37.4057 33.7345 37.3108C33.8002 36.9527 33.8361 36.5848 33.8361 36.2035C33.8361 35.0937 33.5296 34.0515 32.9987 33.1661C32.8771 32.9594 32.7433 32.7617 32.595 32.575C32.4217 32.35 32.2344 32.1415 32.0307 31.9469C31.5253 31.4574 30.9355 31.056 30.2812 30.7739C29.999 30.6492 29.7047 30.5465 29.4019 30.4717C29.3982 30.4717 29.3922 30.468 29.3885 30.468L29.2839 30.4327L28.2301 31.0579Z"
          fill="url(#paint2_linear_2903_28788)"
        />
        <path
          opacity="0.15"
          d="M33.8361 36.2035C33.8361 36.5848 33.8002 36.9533 33.7339 37.3114C33.7145 37.4063 33.6944 37.5012 33.6719 37.5972C33.6293 37.7675 33.5843 37.9323 33.5278 38.0971C33.498 38.1822 33.4676 38.2674 33.436 38.3501C33.4031 38.4352 33.3679 38.5179 33.3308 38.603C33.2223 38.8521 33.0951 39.0926 32.9501 39.3224C32.7637 39.6236 32.5504 39.9073 32.3128 40.1701C31.2256 41.3735 27.5351 43.517 26.1754 44.3884L23.1568 46.2345C20.944 47.5979 18.8534 48.5629 16.2167 48.6292C16.092 48.6329 15.9698 48.6359 15.8488 48.6359C15.6785 48.6359 15.5107 48.6329 15.3423 48.6262C10.8777 48.4559 6.98408 46.057 4.7384 42.5124C3.68761 40.8571 3.02564 38.9851 2.80225 37.0372C3.28507 39.7669 5.66209 41.8362 8.52801 41.8362C9.53258 41.8362 10.4757 41.5827 11.2985 41.1363L11.3185 41.126L11.6135 40.9484L12.8157 40.2388L14.3456 39.3327V39.2896L14.5426 39.171L28.2301 31.0572L29.2839 30.4327L29.3885 30.468C29.3916 30.468 29.3982 30.4717 29.4019 30.4717C29.7047 30.5465 29.9996 30.6492 30.2812 30.7739C30.9355 31.056 31.5247 31.4574 32.0307 31.9469C32.2343 32.1418 32.4229 32.3518 32.595 32.575C32.7433 32.7617 32.8771 32.9594 32.9987 33.1661C33.5296 34.0515 33.8361 35.0937 33.8361 36.2035Z"
          fill="#7F7F7F"
        />
        <path
          opacity="0.15"
          d="M33.8361 36.2035C33.8361 36.5848 33.8002 36.9533 33.7339 37.3114C33.7145 37.4063 33.6944 37.5012 33.6719 37.5972C33.6293 37.7675 33.5843 37.9323 33.5278 38.0971C33.498 38.1822 33.4676 38.2674 33.436 38.3501C33.4031 38.4352 33.3679 38.5179 33.3308 38.603C33.2223 38.8521 33.0951 39.0926 32.9501 39.3224C32.7637 39.6236 32.5504 39.9073 32.3128 40.1701C31.2256 41.3735 27.5351 43.517 26.1754 44.3884L23.1568 46.2345C20.944 47.5979 18.8534 48.5629 16.2167 48.6292C16.092 48.6329 15.9698 48.6359 15.8488 48.6359C15.6785 48.6359 15.5107 48.6329 15.3423 48.6262C10.8777 48.4559 6.98408 46.057 4.7384 42.5124C3.68761 40.8571 3.02564 38.9851 2.80225 37.0372C3.28507 39.7669 5.66209 41.8362 8.52801 41.8362C9.53258 41.8362 10.4757 41.5827 11.2985 41.1363L11.3185 41.126L11.6135 40.9484L12.8157 40.2388L14.3456 39.3327V39.2896L14.5426 39.171L28.2301 31.0572L29.2839 30.4327L29.3885 30.468C29.3916 30.468 29.3982 30.4717 29.4019 30.4717C29.7047 30.5465 29.9996 30.6492 30.2812 30.7739C30.9355 31.056 31.5247 31.4574 32.0307 31.9469C32.2343 32.1418 32.4229 32.3518 32.595 32.575C32.7433 32.7617 32.8771 32.9594 32.9987 33.1661C33.5296 34.0515 33.8361 35.0937 33.8361 36.2035Z"
          fill="url(#paint3_linear_2903_28788)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2903_28788"
          x1="20.1166"
          y1="17.2738"
          x2="35.6638"
          y2="34.6009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#37BDFF" />
          <stop offset="0.25" stop-color="#26C6F4" />
          <stop offset="0.5" stop-color="#15D0E9" />
          <stop offset="0.75" stop-color="#3BD6DF" />
          <stop offset="1" stop-color="#62DCD4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2903_28788"
          x1="9.32859"
          y1="41.0997"
          x2="8.82856"
          y2="2.64951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1B48EF" />
          <stop offset="0.5" stop-color="#2080F1" />
          <stop offset="1" stop-color="#26B8F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2903_28788"
          x1="18.319"
          y1="39.5029"
          x2="55.9482"
          y2="39.5029"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#39D2FF" />
          <stop offset="0.5" stop-color="#248FFA" />
          <stop offset="1" stop-color="#104CF5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2903_28788"
          x1="18.319"
          y1="39.5029"
          x2="55.9482"
          y2="39.5029"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" />
        </linearGradient>
        <clipPath id="clip0_2903_28788">
          <rect width="36.7296" height="48.6356" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export const BingSvg = (
  <svg
    fill="#25d4c0"
    height="82px"
    width="82px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 512 512"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M437.019,74.982C388.667,26.628,324.379,0,256,0S123.333,26.628,74.981,74.982C26.628,123.333,0,187.621,0,256 s26.628,132.667,74.981,181.019C123.333,485.372,187.621,512,256,512s132.667-26.628,181.019-74.982 C485.372,388.667,512,324.379,512,256S485.372,123.333,437.019,74.982z M256,495.832C123.756,495.832,16.168,388.244,16.168,256 S123.756,16.168,256,16.168S495.832,123.756,495.832,256S388.244,495.832,256,495.832z"></path>{" "}
        </g>{" "}
      </g>{" "}
      <g>
        {" "}
        <g>
          {" "}
          <path d="M377.678,248.538l-129.347-53.895c-3.445-1.432-7.508-0.348-9.756,2.853c-1.429,2.035-1.748,4.654-1.065,7.046 l21.496,75.236c0.527,1.844,1.693,3.442,3.289,4.506l21.918,14.613l-63.244,39.527v-31.9c0-4.465-3.62-8.084-8.084-8.084 s-8.084,3.62-8.084,8.084v46.247c0,2.344,0.9,4.649,2.641,6.219c2.753,2.485,6.713,2.758,9.728,0.876l86.232-53.895 c2.328-1.456,3.759-3.993,3.798-6.737c0.041-2.745-1.315-5.323-3.599-6.846l-29.864-19.91l-16.131-56.457l108.879,45.366v76.138 l-143.876,81.765l-77.092-38.92v-275.34l59.284,16.938v145.174c0,4.465,3.62,8.084,8.084,8.084s8.084-3.62,8.084-8.084V115.874 c0-3.61-2.393-6.781-5.863-7.774l-75.453-21.558c-3.223-0.921-6.766,0.226-8.827,3.106c-1.012,1.414-1.478,3.15-1.478,4.888 v290.811c0,3.05,1.717,5.842,4.441,7.217l85.403,43.116c1.147,0.579,2.396,0.868,3.643,0.868c1.38,0,2.758-0.352,3.995-1.055 l151.734-86.232c2.529-1.437,4.09-4.121,4.09-7.029V256C382.653,252.737,380.691,249.792,377.678,248.538z"></path>{" "}
        </g>{" "}
      </g>{" "}
    </g>
  </svg>
);
