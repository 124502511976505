import React, { useState } from 'react'
import "./ChooseAccountPopup.css";
import GoogleAccountSelect from '../../../GoogleAccountSelect/GoogleAccountSelect';
import CloseIcon from "../../../../../../../assets/icons/close.png"
import {  useNavigate } from "react-router-dom";

export default function ChooseAccountPopup({ setAccountModal }) {
    const [chooseYourAccount, setChooseYourAccount] = useState(false);
    const navigate = useNavigate();
    const handleChooseBtn = (url) => {
        navigate(url);
    };
    return (
        <>
            <div className="choose-account-popup-wrapper">
                <div className='choose-account-popup-modal'>
                    <div>

                        <h1>Choose an Account For Your Google Ads</h1>
                        <p>You Can Choose one ad account per brand to run google ads</p>
                    </div>
                    <div className="choose-account-popup-body-alignment">
                        <div className="choose-box-alignment"
                            onClick={() =>
                                handleChooseBtn("/main/campaign/googlecompaign2/google-add-1/funding")
                            }>
                            <div className="choose-left-side">
                                <h4>Exaods Ad Account</h4>
                                <ul>
                                    <li>Exaods manages an account for you</li>
                                    <li>No connection setup</li>
                                    <li>10% Commission  </li>
                                </ul>
                            </div>
                            <div className='choose-right-side'>
                                <div className="right-side-alignment">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_351_1327)">
                                            <path d="M14.0168 4C14.9363 4 15.8232 4.11738 16.6775 4.35215C17.5318 4.58692 18.3274 4.92603 19.0643 5.36948C19.8012 5.81293 20.4762 6.33464 21.0892 6.9346C21.7022 7.53456 22.2271 8.20952 22.6641 8.95948C23.101 9.70943 23.4368 10.5083 23.6716 11.3561C23.9064 12.2038 24.027 13.0907 24.0335 14.0168C24.0335 14.9363 23.9162 15.8232 23.6814 16.6775C23.4466 17.5318 23.1075 18.3274 22.6641 19.0643C22.2206 19.8012 21.6989 20.4762 21.0989 21.0892C20.499 21.7022 19.824 22.2271 19.0741 22.6641C18.3241 23.101 17.5253 23.4368 16.6775 23.6716C15.8297 23.9064 14.9428 24.027 14.0168 24.0335C13.0973 24.0335 12.2104 23.9162 11.3561 23.6814C10.5018 23.4466 9.70617 23.1075 8.96926 22.6641C8.23235 22.2206 7.55739 21.6989 6.94438 21.0989C6.33138 20.499 5.80641 19.824 5.36948 19.0741C4.93255 18.3241 4.5967 17.5253 4.36193 16.6775C4.12717 15.8297 4.00652 14.9428 4 14.0168C4 13.0973 4.11738 12.2104 4.35215 11.3561C4.58692 10.5018 4.92603 9.70617 5.36948 8.96926C5.81293 8.23235 6.33464 7.55739 6.9346 6.94438C7.53456 6.33138 8.20952 5.80641 8.95948 5.36948C9.70943 4.93255 10.5083 4.5967 11.3561 4.36193C12.2038 4.12717 13.0907 4.00652 14.0168 4ZM19.9153 10.7007L18.585 9.37032L12.1386 15.8167L9.44858 13.1266L8.11822 14.457L12.1386 18.4774L19.9153 10.7007Z" fill="#47BEB9" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_351_1327" x="0.524793" y="0.933641" width="31.0721" height="31.0725" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dx="2.04424" dy="2.45309" />
                                                <feGaussianBlur stdDeviation="2.75972" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.937255 0 0 0 0.21 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_351_1327" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_351_1327" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>

                                </div>
                            </div>
                        </div>
                        <div className="choose-box-alignment " onClick={() => setChooseYourAccount(true)}>
                            <div className="choose-left-side">
                                <h4>Your Ad Account</h4>
                                <ul>
                                    <li>Full Access To Your Account</li>
                                    <li>Pay in your local currency</li>
                                    <li>Zero Commission</li>
                                </ul>
                            </div>

                            <div className='choose-right-side'>
                                <div className="right-side-alignment">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_351_1327)">
                                            <path d="M14.0168 4C14.9363 4 15.8232 4.11738 16.6775 4.35215C17.5318 4.58692 18.3274 4.92603 19.0643 5.36948C19.8012 5.81293 20.4762 6.33464 21.0892 6.9346C21.7022 7.53456 22.2271 8.20952 22.6641 8.95948C23.101 9.70943 23.4368 10.5083 23.6716 11.3561C23.9064 12.2038 24.027 13.0907 24.0335 14.0168C24.0335 14.9363 23.9162 15.8232 23.6814 16.6775C23.4466 17.5318 23.1075 18.3274 22.6641 19.0643C22.2206 19.8012 21.6989 20.4762 21.0989 21.0892C20.499 21.7022 19.824 22.2271 19.0741 22.6641C18.3241 23.101 17.5253 23.4368 16.6775 23.6716C15.8297 23.9064 14.9428 24.027 14.0168 24.0335C13.0973 24.0335 12.2104 23.9162 11.3561 23.6814C10.5018 23.4466 9.70617 23.1075 8.96926 22.6641C8.23235 22.2206 7.55739 21.6989 6.94438 21.0989C6.33138 20.499 5.80641 19.824 5.36948 19.0741C4.93255 18.3241 4.5967 17.5253 4.36193 16.6775C4.12717 15.8297 4.00652 14.9428 4 14.0168C4 13.0973 4.11738 12.2104 4.35215 11.3561C4.58692 10.5018 4.92603 9.70617 5.36948 8.96926C5.81293 8.23235 6.33464 7.55739 6.9346 6.94438C7.53456 6.33138 8.20952 5.80641 8.95948 5.36948C9.70943 4.93255 10.5083 4.5967 11.3561 4.36193C12.2038 4.12717 13.0907 4.00652 14.0168 4ZM19.9153 10.7007L18.585 9.37032L12.1386 15.8167L9.44858 13.1266L8.11822 14.457L12.1386 18.4774L19.9153 10.7007Z" fill="#47BEB9" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_351_1327" x="0.524793" y="0.933641" width="31.0721" height="31.0725" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset dx="2.04424" dy="2.45309" />
                                                <feGaussianBlur stdDeviation="2.75972" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.937255 0 0 0 0.21 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_351_1327" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_351_1327" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="choose-account-bottom-details" >
                        <button className='button'>Choose Account</button>
                    </div>

                    <div className="close-icon-alignment" onClick={() => setAccountModal(false)}>
                        <img src={CloseIcon} alt="CloseIcon" />
                    </div>
                </div>

            </div>

            {chooseYourAccount && (
                <GoogleAccountSelect setChooseYourAccount={setChooseYourAccount} />
            )}
        </>
    )
}
