import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import livePreview from "../../../assets/images/textScreen/livePreview.svg";

const FullView = () => {
  const [PrevImg, setPrevImg] = useState();
  // const [Previmg, setPrevimg] = useState()
  const [PrevText, setPrevText] = useState();
  const [PrevColor, setPrevColor] = useState();
  const [PrevDesciption, setPrevDesciption] = useState();
  const [PrevLogoUrl, setPrevLogoUrl] = useState();
  const [PrevCallToA, setPrevCallToA] = useState();

  const params = useParams();

  const location = useLocation();
  const data = location.state?.data;

  useEffect(() => {
    let imgUrl = localStorage.getItem("imgurl");
    let text = localStorage.getItem("title");
    let bgcolor = localStorage.getItem("color");
    let discription = localStorage.getItem("description");
    let logoUrl = localStorage.getItem("logoUrl");
    let callToA = localStorage.getItem("calltoA");
    setPrevText(text);
    setPrevImg(imgUrl);
    setPrevColor(bgcolor);
    setPrevDesciption(discription);
    setPrevLogoUrl(logoUrl);
    setPrevCallToA(callToA);
  }, []);
  return (
    <div className="col-md-4 fix-box">
      <div className="card card-border border-0">
        <div className="card-header bg-transparent">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <img src={livePreview} style={{ height: "20px" }} alt="" />
              <div className="ms-3">
                <h6 className="card-heading fw-bold">Live Preview</h6>
              </div>
            </div>
            <div>
              <i className="fa-regular fa-star"></i>
            </div>
          </div>
        </div>
        <div className="card-body pt-0 fix-box">
          <div className="row">
            <div className="">
              <img src={PrevLogoUrl} className="watchLogo  text-start" alt="" ></img>
            </div>
            <div className="row text-center">
              <div
                className="border-div-img ms-4"
                style={{ backgroundColor: PrevColor }}
              >
                <img src={PrevImg} className="rounded-img" alt="" ></img>
                {/* <img src={person} className='rounded-img'></img> */}
              </div>
              <div>
                <p className="fw-bold"> {PrevCallToA} </p>
                {/* <p> giving information on its origins
          </p> */}
              </div>
            </div>
            <div className="col-12 ">
              <div className="row text-center">
                <div>
                  <h4 className="fashion2"> {PrevText}</h4>
                  {/* <h3>ELEVATE YOUR AUDIO EXPERIENCE</h3> */}
                </div>
                <div>
                  <p className="fw-bold"> {PrevDesciption} </p>
                  {/* <p> giving information on its origins
          </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div></div>
            {/* <Link to="/pages/textScreen">
      <button className="btn btn2 text-white">
        Edit <i className="fa-regular fa-pen-to-square text-white"></i>{" "}
      </button></Link> */}
            <button className="btn btn1 text-white">
              Download <i className="fa-solid fa-download"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullView;
