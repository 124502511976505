import React, { useState } from 'react'
import "./newgenerate.css";
import GenerateBanner from './generateBanner';
import GenerateDetails from './generateDetails';
import CreativesGeneration from './creativesGeneration';
import GeneratationStep3 from './generatationStep3';
import ImageGallery from './imageGallery';
import SearchImges from './searchImges';
import GenerateLoader from './generateLoader';
import { useNavigate } from 'react-router-dom';

export default function NewGenerate() {
    const [generationView, setGenerationView] = useState("A");
    const [searchImgesVIew, setSearchImgesVIew] = useState(false)
    const [imageGalleryVIew, setImageGalleryVIew] = useState(false)
const navigate=useNavigate()
    return (
        <>

            <div className={searchImgesVIew || imageGalleryVIew ? "generate-section none-view" : "generate-section view-design"}>
                <GenerateBanner setGenerationView={setGenerationView} generationView={generationView} />
                {generationView === "A" && (
                    <>
                        <GenerateDetails setGenerationView={setGenerationView} />


                        <div className='generate-next-button' onClick={() => navigate(-1)}>
                            <button>Back</button>
                        </div>
                    </>
                )
                }
                {generationView === "B" && (

                    <CreativesGeneration setGenerationView={setGenerationView} />
                )}

                {generationView === "C" && (

                    <GeneratationStep3 setGenerationView={setGenerationView} setSearchImgesVIew={setSearchImgesVIew} setImageGalleryVIew={setImageGalleryVIew} />
                )}

            </div>
            <div>
                {searchImgesVIew && (

                    <SearchImges setGenerationView={setGenerationView}  
                    setSearchImgesVIew={setSearchImgesVIew}/>
                )}
                {imageGalleryVIew && (

                    <ImageGallery  setGenerationView={setGenerationView}  
                    setImageGalleryVIew={setImageGalleryVIew}/>
                )}
                {/* <GenerateLoader /> */}
            </div>
        </>
    )

}
