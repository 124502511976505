import { createContext, useState } from "react";
const ToggleContext = createContext();

const ToggleProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [toggleVisible, setToggleVisible] = useState(false);

  const handleToggleBtn = () => {
    setToggle(!toggle);
  };
  const handleToggleVisibleBtn = (value) => {
    setToggleVisible(value);
  };
  const HandleActiveStep = (value) => {
    setActiveStep(value);
  };
  return (
    <ToggleContext.Provider
      value={{
        toggle,
        handleToggleBtn,
        handleToggleVisibleBtn,
        toggleVisible,
        HandleActiveStep,
        activeStep,
      }}
    >
      {children}
    </ToggleContext.Provider>
  );
};

export { ToggleProvider, ToggleContext };
