import React from 'react'

export default function LandingIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_191_243)">
                <path d="M0.555018 9.16254L0.555042 9.16255L5.84462 11.2055L6.16448 11.329V11.6719V18.1065C6.16448 18.1463 6.1918 18.1809 6.23059 18.1901L6.23063 18.1901C6.26932 18.1993 6.30934 18.1807 6.32731 18.1451L8.51499 13.8039L8.78236 13.2733L9.25946 13.6274L14.5983 17.5895L14.5983 17.5895C14.6457 17.6247 14.7145 17.602 14.7318 17.5456C16.0511 13.2201 17.0061 10.0892 17.6974 7.82285C18.3121 5.80756 18.7183 4.4758 18.9868 3.59547C19.272 2.66008 19.4015 2.23487 19.4603 2.0409C19.4829 1.96628 19.4944 1.92806 19.5001 1.90876C19.511 1.84346 19.4461 1.79068 19.3849 1.81397L0.555018 9.16254ZM0.555018 9.16254C0.521833 9.14972 0.499957 9.11779 0.500026 9.08215C0.500094 9.04662 0.522055 9.01473 0.555332 9.00201C0.555344 9.00201 0.555356 9.002 0.555368 9.002L19.3848 1.81399L0.555018 9.16254ZM15.6301 4.48456L15.1618 3.61018L2.04239 8.61841L0.82586 9.08281L2.04057 9.55194L5.98728 11.0762L6.23828 11.1731L6.45746 11.017L15.6301 4.48456ZM6.5464 11.1648L6.33645 11.3143V11.5721V15.6415L7.28295 15.8665C7.64265 15.1527 7.91041 14.6209 8.11023 14.224C8.33353 13.7805 8.47199 13.5055 8.55912 13.3334C8.63023 13.1929 8.66397 13.1272 8.68077 13.0957C8.68785 13.088 8.69867 13.0764 8.71479 13.0592C8.79164 12.9772 8.96336 12.7956 9.35078 12.3867C9.72843 11.9881 10.31 11.3747 11.2063 10.4293C12.1466 9.4374 13.4335 8.08008 15.1948 6.22199L14.5419 5.47074L6.5464 11.1648ZM14.0335 16.9562L14.6029 17.3788L14.8097 16.7006L18.6075 4.24894L17.7663 3.7591L9.26918 12.7231L8.88115 13.1325L9.33408 13.4686L14.0335 16.9562Z" fill="black" stroke="black" stroke-width="0.99999" />
            </g>
            <defs>
                <clipPath id="clip0_191_243">
                    <rect width="20.0017" height="20.0017" fill="white" transform="translate(0 -0.000854492)" />
                </clipPath>
            </defs>
        </svg>

    )
}
