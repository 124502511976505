
import sidebarbg from "../../../assets/images/backgroundImg/sidebar.png"
import creative from "../../../assets/images/sidebar/Creative.png"
import add from "../../../assets/images/sidebar/ad.png"
import brand from "../../../assets/images/sidebar/brand.png"
import social from "../../../assets/images/sidebar/social.png"
import text from "../../../assets/images/sidebar/text.png"
import setting from "../../../assets/images/sidebar/setting.png"

function Sidebar() {
    return (
        <div className="pt-5">
            <ul className=" pt-4" style={{ listStyle: "none" }} >
                <li className="my-4  align-item-center">
                    <div className="pt-3"><h6 className="title text-white "> <span> <img src={brand} alt=""/>
                    </span>  Generate </h6></div>
                </li>

                <li className="my-4  align-item-center">
                    <div className="pt-3"><h6 className="title text-white "> <span> <img src={brand} alt="" /> </span>  Brands </h6></div>
                </li>

                <li className="list-group-item my-4">
                    <div className="nav-active">
                        <p className="title theme-color pt-3">  <span> <img src={add} alt=""/> </span> Add Creative </p></div>
                </li>

                <li className="my-4  align-item-center">
                    <div className="pt-3"><h6 className="title text-white ">  <span> <img src={social} alt="" /> </span> Social Creative </h6></div>
                </li>

                <li className="my-4  align-item-center">
                    <div className="pt-3"><h6 className="title text-white "> <span> <img src={text} alt=""/> </span>  Text projects </h6></div>
                </li>

                <li className="my-4  align-item-center">
                    <div className="pt-3"><h6 className="title text-white ">  <span> <img src={creative} alt="" /> </span> Creative Insights Pro </h6></div>
                </li>

                <li className="my-4  align-item-center">
                    <div className="pt-3"><h6 className="title text-white "> <span> <img src={setting} alt="" /> </span>  Setting </h6></div>
                </li>

                <li className="float-right d-flex justify-content-end me-1">
                    <img src={sidebarbg} alt="" style={{ height: "150px" }} />
                </li>

            </ul>
        </div>
    );
}
export default Sidebar