import React from 'react'
import "./generatationStep3.css";
import ImageSelection from './imageSelection';
export default function GeneratationStep3({ setGenerationView, setSearchImgesVIew, setImageGalleryVIew }) {
    return (
        <div className="generatationStep3">
            <ImageSelection setGenerationView={setGenerationView} setSearchImgesVIew={setSearchImgesVIew} setImageGalleryVIew={setImageGalleryVIew} />
        </div>
    )
}
