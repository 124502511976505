import React from 'react'

export default function FacebookIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.0002 14.5714C30.0002 6.52798 23.4722 0 15.4288 0C7.3854 0 0.857422 6.52798 0.857422 14.5714C0.857422 21.6239 5.86997 27.4962 12.5145 28.8513V18.9428H9.60025V14.5714H12.5145V10.9285C12.5145 8.11625 14.8022 5.82855 17.6145 5.82855H21.2573V10.2H18.3431C17.5416 10.2 16.8859 10.8557 16.8859 11.6571V14.5714H21.2573V18.9428H16.8859V29.0699C24.2445 28.3413 30.0002 22.1339 30.0002 14.5714Z" fill="#47BEB9" />
        </svg>

    )
}
