
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../axios/auth";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";

let LoginToken;
export const userLogin = createAsyncThunk("userlogin", async (loginData) => {
  try {
    const res = await axios.post("/login", loginData);

    if (
      res?.data &&
      res?.data?.data &&
      res?.data?.data[0] &&
      res?.data?.token &&
      res?.data?.token?.length > 0
    ) {
      LoginToken = res?.data?.token;
      Cookies.set("token", LoginToken);
      return res?.data?.data[0];
    } else {
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });  toast.error(res?.data?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }
  } catch (error) {
    // toast.error("Invalid Credential");
    toast.error("Invalid Credential", {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
  }
});

const loginState = {
  token: "",
  user: null,
  status: "idle", // Change the initial status to 'idle'
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: loginState,
  reducers: {
    adduserdata: (state, action) => {
      state.user = action.payload;
    },
    clearRedux: (state) => {
      state.user = null;
      state.token = "";
      Cookies.remove("token"); // Remove the token cookie
    },
    logout: (state) => {
      state.user = null;
      state.token = "";
      Cookies.remove("token"); // Remove the token cookie
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.status = "loading"; // Updated the status to 'loading'
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = "success"; // Updated the status to 'success'
        state.user = action.payload;
        state.token = LoginToken;
      })
      .addCase(userLogin.rejected, (state) => {
        state.status = "rejected"; // Updated the status to 'rejected'
      });
  },
});

export const { adduserdata, clearRedux, logout } = loginSlice.actions;
export default loginSlice.reducer;
