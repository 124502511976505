// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.test{
    background: linear-gradient(180deg, #B37FCA 0%, #F5A4A1 40.63%, #F1A2A9 100%);
}

.tagline{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    text-align: center;
    color: #000000;
/* Header Section Start */
}`, "",{"version":3,"sources":["webpack://./src/component/assets/userAssets/Wrapper.css"],"names":[],"mappings":";;AAEA;IACI,6EAA6E;AACjF;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;IAC5B,kBAAkB;IAClB,cAAc;AAClB,yBAAyB;AACzB","sourcesContent":["\r\n\r\n.test{\r\n    background: linear-gradient(180deg, #B37FCA 0%, #F5A4A1 40.63%, #F1A2A9 100%);\r\n}\r\n\r\n.tagline{\r\n    font-family: 'Poppins';\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 16px;\r\n    line-height: 24px;\r\n    /* identical to box height */\r\n    text-align: center;\r\n    color: #000000;\r\n/* Header Section Start */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
