import Header from "../header/Header"
import AdminSidebar from "../sidebar/AdminSidebar"
import StylesContaent from "./AdminContent.module.css"
import styles from '../sidebar/AdminSidebar.module.css'
function ManageAccess() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-2 p-0 ">
                        <div className={styles.mobileSidebar}> 
                            <AdminSidebar className={styles.mobileSidebar} />
                        </div>
                    </div>
                    <div className="col-sm-12  col-md-10 bg-white">
                        <div className="container">
                            <Header title="Add Creative" btnname="Create Campaigns" />
                        </div>
                        <div className="container">
                            <div className="container">
                                <div className="row">
                                    <h4 className=" my-4 ps-0"><b> Role Access </b></h4>
                                    <hr />
                                    <div className={StylesContaent.tableHeading} >
                                        <table className="table table-striped table-border text-center">
                                            <tr>
                                                <th className="border">Plan Description</th>
                                                <th className="border">Price: Monthly</th>
                                                <th className="border"> Price: Yearly</th>
                                                <th className="border">  Number of Brands</th>
                                                <th className="border"> Number of Users</th>
                                                <th className="border">Number of  Ads</th>
                                                <th className="border">   Free Trail Days</th>
                                                <th className="border">  Number of Campaign</th>
                                                <th className="border">  Number of Creatives</th>
                                            </tr>
                                            <tr>
                                                <td className="border">Plan Description</td>
                                                <td className="border">Price: Monthly</td>
                                                <td className="border"> Price: Yearly</td>
                                                <td className="border">  Number of Brands</td>
                                                <td className="border"> Number of Users</td>
                                                <td className="border">Number of  Ads</td>
                                                <td className="border">   Free Trail Days</td>
                                                <td className="border">  Number of Campaign</td>
                                                <td className="border">

                                                    <div className="dropdown">
                                                        <button
                                                            className="btn text-center common-btn dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            Action
                                                        </button>

                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <a className="dropdown-item" href="#">
                                                                    View
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item" href="#">
                                                                    Upload
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item" href="#">
                                                                    Rename
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item" href="#">
                                                                    Delete
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>


                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ManageAccess