import { createSlice } from "@reduxjs/toolkit";
const initialState = {
brand:[]
 
  // selectedLogo:""
};
const generatedBrandList = createSlice({
  name: "addCreate",
  initialState,
  reducers: {  
    addBrandArray: (state, action) => {
      state.brand=(action.payload);
    },
    removeBrandArray:(state,action)=>{
      state.brand=(action.payload)
    }
  },  
}); 

export const {
  addBrandArray,
  removeBrandArray
} = generatedBrandList.actions;
export default generatedBrandList.reducer;
