// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.AdminSidebar_sidebarBgColor__kIOkM{
    background: linear-gradient(135deg, #47beb9 0%, #b7b2c6 100%);
    height: 100vh;
    position: fixed;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 15px;
}

@media(max-width:500px){
    .AdminSidebar_sidebarBgColor__kIOkM{
        position: unset;
    }
    .AdminSidebar_mobileSidebar__2CdDb{
        display: none;
        
      }

}   

.AdminSidebar_active__X0rgD{
 color: white;
 font-weight: bold;
}
.AdminSidebar_inactive__AWHNW{
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/component/panel/admin/sidebar/AdminSidebar.module.css"],"names":[],"mappings":";AACA;IACI,6DAA6D;IAC7D,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,aAAa;;MAEf;;AAEN;;AAEA;CACC,YAAY;CACZ,iBAAiB;AAClB;AACA;IACI,YAAY;AAChB","sourcesContent":["\r\n.sidebarBgColor{\r\n    background: linear-gradient(135deg, #47beb9 0%, #b7b2c6 100%);\r\n    height: 100vh;\r\n    position: fixed;\r\n    border-top-right-radius: 50px;\r\n    border-bottom-right-radius: 50px;\r\n    padding: 15px;\r\n}\r\n\r\n@media(max-width:500px){\r\n    .sidebarBgColor{\r\n        position: unset;\r\n    }\r\n    .mobileSidebar{\r\n        display: none;\r\n        \r\n      }\r\n\r\n}   \r\n\r\n.active{\r\n color: white;\r\n font-weight: bold;\r\n}\r\n.inactive{\r\n    color: black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarBgColor": `AdminSidebar_sidebarBgColor__kIOkM`,
	"mobileSidebar": `AdminSidebar_mobileSidebar__2CdDb`,
	"active": `AdminSidebar_active__X0rgD`,
	"inactive": `AdminSidebar_inactive__AWHNW`
};
export default ___CSS_LOADER_EXPORT___;
