import React, { useEffect, useState } from "react";
import "./AdTraffice.css";
import { Col, Container, Row } from "react-bootstrap";
import {
  googleSvg,
  // faceBook,
  // igLogo,
  settingSvg,
} from "../../Home Campaigns/SVG";

import { PlayIcon, PauseIcon, RedDotIcon } from "./SVg";
import { useSelector } from "react-redux";

const AdTrafficeOnly = () => {
  const [AdsDetail, setAdsDetail] = useState(null);


  const {SingleGoogleAdDetail}=useSelector(state=>state.AdsAnalyticsDetail)


  useEffect(()=>{
    if(SingleGoogleAdDetail ){
     
      setAdsDetail(SingleGoogleAdDetail)
    }
  },[SingleGoogleAdDetail])


  return (
    <Container className="adTrffice-main" style={{ margin: "5px 20px" }}>
      <Row className="adTrffice-row-1" style={{ gap: "10px" }}>
        <AddBox detail={AdsDetail}  heading={"Google Ad 1"} logo={googleSvg} />
        {/* <AddBox heading={"Facebook Ad 1"} logo={faceBook} />
        <AddBox heading={"Instagram Ad 1"} logo={igLogo} /> */}
      </Row>

      <Row style={{ height: "100%", gap: "10px" }}>
        <Col lg={3} className="adTrffice-row2-col1">
          <div>
            <p>Performance</p>
            <span>{settingSvg}</span>
          </div>

          <div className="adTrffice-row2-col1-center">
            <h5>Coming Soon</h5>
            <span>Performance tracking will be available soon.</span>
          </div>
        </Col>

        <Col className="adTrffice-row2-col2">
          <h5>Ad Run</h5>
          <AdRunItems
            Icon={RedDotIcon}
            Icon2={PlayIcon}
            PlatformName={"Facebook"}

            detail={AdsDetail}
          />
          {/* <AdRunItems
            Icon={GreenDotIcon}
            Icon2={PauseIcon}
            PlatformName={"Google"}
          />

          <AdRunItems
            Icon={GreenDotIcon}
            Icon2={PauseIcon}
            PlatformName={"Instagram"}
          /> */}
        </Col>
      </Row>
    </Container>
  );
};

const AddBox = ({ heading, logo ,detail}) => {

  function truncateName(name, maxLength) {
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  }

  return (
    <Col className="adTrffice-details-box">
      <div className="adTrffice-details-box-header">
        <span>{logo}</span>
        <p> {truncateName(detail?.adData?.adGroup?.name, 20)}</p>
      </div>

      <div className="adTrffice-details-box-main">
        <Items value={detail?.metricData?.impressions} title={"Impressions"} />
        <div style={{ background: "#B2BBC6", width: "1px", height: "100%" }} />
        <Items value={detail?.metricData?.clicks} title={"Clicks"} />
        <div style={{ background: "#B2BBC6", width: "1px", height: "100%" }} />
        <Items value={detail?.metricData?.activeViewMeasurableImpressions} title={"Visits"} />
        <div style={{ background: "#B2BBC6", width: "1px", height: "100%" }} />
        <Items value={detail?.metricData?.conversions} title={"Converted"} />
      </div>
    </Col>
  );
};

const Items = ({ value, title }) => {
  return (
    <div className="adTrffice-details-box-main-item ">
      <span>{value}</span>
      <p>{title}</p>
    </div>
  );
};

export const AdRunItems = ({ Icon, Icon2, PlatformName,detail }) => {

  function truncateName(name, maxLength) {
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + '...';
    } else {
      return name;
    }
  }
  return (
    <div className="AdrunItemsMain">
      {/* <p>{Icon}</p> */}
      {/* <p>01</p> */}
      <p>{detail?.adData?.campaign?.status==="ENABLED" &&PlayIcon} {detail?.adData?.campaign?.status==="PAUSED" &&PauseIcon}  </p>
      <p >{truncateName(detail?.adData?.adGroup?.name, 16)}</p>
      <p>{detail?.adData?.campaign?.startDate}</p>
      <p>{detail?.adData?.campaign?.endDate}</p>
    </div>
  );
  
};

export default AdTrafficeOnly;
