import React from 'react'
import "./AddBudgetModal.css";
import CloseIcon from "../../../../../assets/icons/close.png";
export default function AddBudgetModal({ setAddBudgetView }) {
  return (
    <div className='add-budget-modal-section'>
      <div className='add-budget-modal-box'>
        <div className="add-budget-modal-heading">
          <div className="budget-details-flex">
            <h2>Ad Budget</h2>
            <p>$200.00</p>
          </div>
          <div className="budget-details-flex">
            <h2>Commission</h2>
            <p>$40.00</p>
          </div>
          <div className="budget-details-flex">
            <h2>Total</h2>
            <p>$240.00</p>
          </div>
        </div>

        <div className="close-icon-alignment" onClick={() => setAddBudgetView(false)}>
          <img src={CloseIcon} alt="CloseIcon" />
        </div>

        <div className="launch-add-alignment" onClick={() => setAddBudgetView(false)}>
          <button>Launch Ad</button>
        </div>
      
      </div>
    </div>
  )
}
