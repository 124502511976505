import React, { useEffect, useRef, useState } from "react";
import "./creativesGeneration.css";

import AngleImg from "../../../../../../assets/images/angle-img.svg";
import BottomAngleImg from "../../../../../../assets/images/bottom-angle-img.svg";
import ImageIcon from "../../../../../icons/imageIcon";
import RightArrowGreen from "../../../../../icons/rightArrowGreen";
import DownBlackIcon from "../../../../../icons/downBlackIcon";
import LandingIcon from "../../../../../icons/landingIcon";
import LivePreview from "../../../../../icons/livePreview";
import PickUpImg from "../../../../../../assets/images/pick-up-img.png";
import EmojiPicker from "emoji-picker-react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "../../../../../axios/auth";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addBrandData } from "../../../../../../Redux/brandSetupData";
import { topImagesIcon } from "./IconListing";

export default function CreativesGeneration({ setGenerationView }) {
  const { onlyCreativeState } = useSelector((state) => state?.creativeData);

  const userToken = Cookies.get("token");
  const {brandData} =useSelector((state)=>state?.brandSetupData)
  const [titleDetail, setTitleDetails] = useState("");

  const [dropdown, setDropdown] = useState(false);
  const [headingPopUp, setheadingPopUp] = useState(false);
  const [descriptionPopUp, setdescriptionPopUp] = useState(false);
  const [CTAToggle, setCTAToggle] = useState(false);
  const [CtaIconPicked, setCtaIconPicked] = useState();
  const [selectIcon, setSelectIcon] = useState(brandData?.ctaIcon);
  const [uploadIcon, setUploadIcon] = useState(brandData?.ctaIcon);
  const [SelectedAiHeading, setSelectedAiHeading] = useState(brandData?.heading);
  const [SelectedAiDesc, setSelectedAiDesc] = useState(brandData?.description);
  const [promotionText, setPromotionText] = useState(brandData?.promotionText);
  const [ctaText, setCtaText] = useState(brandData?.ctaText);
  const [pythonHeading, setPythonHeading] = useState("");
  const [pythonDes, setPythonDes] = useState("");
  const [mainCtaIcon, setmainCtaIcon] = useState("");
const [iconList,seticonList]=useState(false)
  const navigate = useNavigate();
const dispatch=useDispatch()
  // pyton API
  

  const brandName = brandData?.brandname
  // const brandName = "Mango"

 

  const FetchAiTitle = async (token) => {

    try {
      const url = "/fetch-ai/website";
      // const url = "https://backend.exoads.ai/api/v1/fetch-ai/website";

      
      await axios
        .post(
          url,    
          { websiteUrl: brandName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {

          setTitleDetails(response?.data?.data);
         
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    FetchAiTitle(token );
  }, []);

  const FetchAiHeadlineDescription = async (titleDetail) => {
    try {
      const url = "/fetch-ai/tempalte-data";

      await axios
        .post(
          url,
          { websiteDetail: titleDetail },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          
          setPythonHeading(response?.data?.Headline);
          setPythonDes(response?.data?.Description);
        });
    } catch (error) {
      console.error(error);
    }
  };
 
  useEffect(() => {
    if (titleDetail.length > 0) {
      FetchAiHeadlineDescription(titleDetail);
     
    }
  }, [titleDetail]);
  // ai API for Getting title details and then another call for headline description end

  // const AI_heading = [
  //   "Create Your Own Website1",
  //   "Create Your Own Website_2",
  //   "Create Your Own Website_3",
  //   "Create Your Own Website_4",
  // ];
  const AI_heading = [...pythonHeading];

  const AI_Description = [...pythonDes];

  const handelAiHeading = (element) => {
    setheadingPopUp(false);
    // localStorage.setItem("Aiheading",element)
    setSelectedAiHeading(element);
  };

  const handelAidescription = (element) => {
    setdescriptionPopUp(false);

    setSelectedAiDesc(element);
  };

  const imageLogo = brandData.logo;  
  const handlePromotionText = (e) => {
    setPromotionText(e.target.value);
  };

  const handleCtaText = (e) => {
    setCtaText(e.target.value);
  };



  useEffect(() => {
    setUploadIcon("");
  }, [selectIcon]);


  // Handle Picked Emoji Fuction Close
  // Example usage
  const handleNext=()=>{
    dispatch(addBrandData({heading:SelectedAiHeading,
      description:SelectedAiDesc,
      promotionText:promotionText,
      ctaText:ctaText,
      ctaIcon:uploadIcon?uploadIcon:selectIcon,}))
    setGenerationView("C")
    
  }
  const handleBack=()=>{
    if(onlyCreativeState){
      setGenerationView("A")
      // navigate("/main/brand-setup")
    }
    else{
      setGenerationView("A")
    }
  }

  const selectIconFun = (icon) => {

    seticonList(!iconList)

  };

  
  const handleCloseIcon =()=>{
    seticonList(false)
  }





const uploadIconFun = (e) => {
  setSelectIcon("");
  const files = e.target.files;
  const paths = [];
  dispatch(addBrandData({mainCtaIcon:files[0]}))
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const reader = new FileReader();
    reader.onloadend = () => {
      paths.push(reader.result);
      if (paths?.length === files?.length) {
        // All files have been read, update the state with the paths
        // setImagePaths(paths);
        // Do something with imagePaths, for example, save them in localStorage
        // localStorage.setItem("accountImg", [...paths]);
        setUploadIcon([...paths]);
       
      }
    };
    reader.readAsDataURL(file);
  }
  setTimeout(() => {
    e.target.value = null;
  }, 1000);
};


const handelSelectIcon = async (picUrl) => {

  const base64Image = await urlToBase64(picUrl);
  setUploadIcon(base64Image);
  seticonList(false)
};

const urlToBase64 = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image to base64:", error);
    return null;
  }
};



  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setdescriptionPopUp(false); // Close the modal
        setheadingPopUp(false)
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setdescriptionPopUp]);


  return (
    <div className="creative-generation-section">
      <h2>Select Text on Image  </h2>

      <div className="creative-generation-details-alignment">
        <div className="creative-generation-flex">
          <div className="creative-generation-left-side">
            <div className="top-box-alignment">
              <div className="top-box-left">
                <ImageIcon />

                <div className="uploaded-img-name">
                  <span>Post </span>
                  <p>{brandData?.size}</p>
                </div>
              </div>

              <div className="text-ai-button">
                <button>
                  {!titleDetail.length > 0 ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Text AI"
                  )}
                  <RightArrowGreen />
                </button>
              </div>
            </div>

            <div className="bottom-details-alignmnt">
              <div className="bottom-box-alignment">
                <div className="dropdown-input-alignment">
                  <label>Enter your main Headline </label>

                  <div className="dropdown-input">
                    <input
                      type="text"
                      placeholder="Max 40 characters"
                      maxLength={40}
                      value={SelectedAiHeading}
                      onChange={(e) => setSelectedAiHeading(e.target.value)}
                    />

                    {titleDetail.length > 0 && (
                      <div
                        onClick={() => {
                          setheadingPopUp(!headingPopUp);
                          setdescriptionPopUp(false);
                        }}
                        className="anglebox-alignment"
                      >
                        {/* <DownBlackIcon /> */}
                        <div className="pick-up-alignment">
                          <img src={PickUpImg} alt="PickUpImg" />

                          <p>Pick Headline</p>
                        </div>
                      </div>
                    )}

                    <div
                      className={
                        dropdown
                          ? "dropdown-box-alignment openDropdown"
                          : "dropdown-box-alignment hiddenDropdown"
                      }
                    >
                      {/* <p>Max 18 word</p>
                      <p>Max 18 word 1</p>
                      <p>Max 18 word 2</p>
                      <p>Max 18 word 3</p> */}
                    </div>
                    {headingPopUp && (
                      <div className="side-box-alignment" ref={modalRef}>
                        <div className="side-box-heading">
                          <h2>AI Suggestions</h2>
                          <p> Headline</p>
                        </div>

                        <div className="sidebar-details-alignment">
                          {AI_heading.map((element, index) => {
                            return (
                              <div
                                className="sidebar-name"
                                onClick={() => handelAiHeading(element)}
                              >
                                <p> {element}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="dropdown-input-alignment">
                  <label>Enter your Description</label>

                  <div className="dropdown-input">
                    <input
                      type="text"
                      placeholder="Max 40 characters"
                      value={SelectedAiDesc}
                      maxLength={40}
                      onChange={(e) => setSelectedAiDesc(e.target.value)}
                    />

                    {titleDetail?.length > 0 && (
                      <div
                        onClick={() => {
                          setdescriptionPopUp(!descriptionPopUp);
                          setheadingPopUp(false);
                        }}
                        className="anglebox-alignment"
                      >
                        <div className="pick-up-alignment">
                          <img src={PickUpImg} alt="PickUpImg" />

                          <p className="description">Pick Description</p>
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        dropdown
                          ? "dropdown-box-alignment openDropdown"
                          : "dropdown-box-alignment hiddenDropdown"
                      }
                    >
                      {/* <p>Max 18 word</p>
                      <p>Max 18 word 1</p>
                      <p>Max 18 word 2</p>
                      <p>Max 18 word 3</p> */}
                    </div>

                    {descriptionPopUp && (
                      <div className="side-box-alignment" ref={modalRef}>
                        <div className="side-box-heading">
                          <h2>AI Suggestions</h2>
                          <p>Description</p>
                        </div>

                        <div className="sidebar-details-alignment">
                          {AI_Description.map((element, index) => {
                            return (
                              <div
                                className="sidebar-name"
                                onClick={() => handelAidescription(element)}
                              >
                                <p>{element}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  </div>
                </div>

                <div className="dropdown-input-alignment">
                  <label>Enter your promotional text</label>

                  <div className="dropdown-input">
                    <input
                      type="text"
                      placeholder="Max 15 characters"
                      value={promotionText}
                      onChange={(e) => handlePromotionText(e)}
                      maxLength={15}
                    />
                  </div>
                </div>

                <div className="dropdown-input-alignment">
                  <label>Call to Action Text </label>

                  <div className="dropdown-input">
                    <div className="left-side">
                      <input
                        type="text"
                        placeholder="Max 24 characters"
                        maxLength={24}
                        value={ctaText}
                        onChange={(e) => handleCtaText(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="dropdown-input-alignment">
                  <label>Call to Action Icon </label>

                  <div className="ImageSDetails">
                    <div className="imgSelectionBox">
                      <label for="fileUpload" class="customFileUpload">
                        <p>Upload Icon</p>
                      </label>
                      <input
                        type="file"
                        id="fileUpload"
                        accept="image/*"

                        onChange={(e) => uploadIconFun(e)}
                      />
                    </div>

                    {/* EmojiButton  */}

                    <div
                      className="imgSelectionBox"
                      style={{ cursor: "pointer" }}
                      onClick={selectIconFun}
                    >
                      <p>Select Icon</p>
                    </div>

                 
                      <div
                        style={{
                          position: "absolute",
                          top: "200px",
                          left: "40px",
                          // border:"2px solid black",
                          // height :"200px",
                          // width:"200px"
                        }}
                      >
                        

                        {/* test  */}
                        {iconList&&  <div className="side-box-alignment_1" >
                        <div className="side-box-heading">
                        <div onClick={handleCloseIcon} style={{marginTop:"-20px",textAlign:"end"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
</svg>
 </div>
                          <h2>Choose Icon </h2>

                        
                        </div>

                        <div className="sidebar-details-alignment">
                        <div
                                className="sidebar-name1" 
                               
                              >
                          {topImagesIcon.map((element, index) => {

                            return ( 
                            
                                <img width={50}  src={`https://backend.exoads.ai/api/v1/public/${element}`}  onClick={() =>  handelSelectIcon(`https://backend.exoads.ai/api/v1/public/${element}`)} />

                        
                            );
                          })}
                                </div>
                        </div>  
                      </div>}
                      

{/* test End */}

                      </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="creative-generation-right-side">
            <div className="right-box-details ">
              <div className="second-right-heading">
                <LivePreview />
                <h4>Live Preview</h4>
              </div>
              <div className="right-bottom-details-alignment">
                <div className="right-details-child">
                  <div className="top-amgle-alignment">
                    <img src={AngleImg} alt="AngleImg" />
                  </div>
                  {/*                   <div className="bottom-angle-img">
                     <img src={BottomAngleImg} alt="BottomAngleImg" />
                  </div>
                  */}

                  <div className="box-details-alignment">
                    <div>
                      <img
                        src={imageLogo} alt=""
                        style={{
                          maxHeight: "60px",
                          maxWidth: "100px",
                          marginLeft: "20px", 
                        }}
                      />
                    </div>

                    <div className="text-alignment">
                    {/* <pre style={{ width: "ch", maxWidth: "40ch", overflow: "hidden", whiteSpace: "pre-wrap", wordWrap: "break-word" }}> */}
                      <h6 className="heading" style={{wordWrap:"break-word", fontFamily:brandData?.selectedFont,color:brandData?.headingColor}} >{SelectedAiHeading}</h6>
                      {/* </pre> */}
                      <p style={{wordWrap:"break-word", fontFamily:brandData?.selectedFont,color:brandData?.descriptionColor}}>{SelectedAiDesc}</p>
                    </div>

                    {promotionText?.length > 0 && (
                      <div
                        className="call-action-button"
                        style={{ width: "auto", backgroundColor:"unset" }}
                      >
                        <button style={{ width: "auto",fontFamily:brandData?.selectedFont,color:brandData?.promotionTextColor }}>
                          {promotionText}
                        </button>
                      </div>
                    )}

                    {ctaText?.length > 0 && (
                      <div
                        className="call-action-button"
                        style={{ width: "auto" }}
                      >
                        <button style={{ fontSize: "12px", width: "auto",fontFamily:brandData?.selectedFont,color:brandData?.ctaColor }} >
                          {" "}
                          {(uploadIcon || selectIcon) && (
                            <img
                              src={uploadIcon ? uploadIcon : selectIcon}
                              style={{ height: "20px", width: "20px" }}
                              alt="imgIcon"
                            />
                          )}{" "}
                          {ctaText}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-button">
        <div className="back-button">
          <button onClick={() =>handleBack()} >Back</button>
        </div>
        <div className="continue-button" onClick={() =>  handleNext()}>
          <button>Next</button>
        </div>
      </div>
    </div>
  );
}
