import React from 'react'

export default function ImageIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.3333 3.99976H6.66683C5.19408 3.99976 4.00018 5.19365 4.00018 6.6664V25.3329C4.00018 26.8056 5.19408 27.9995 6.66683 27.9995H25.3333C26.8061 27.9995 28 26.8056 28 25.3329V6.6664C28 5.19365 26.8061 3.99976 25.3333 3.99976Z" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M27.9999 19.9997L21.3332 13.333L6.66653 27.9997" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.3337 13.3337C12.4384 13.3337 13.3339 12.4382 13.3339 11.3335C13.3339 10.2288 12.4384 9.33325 11.3337 9.33325C10.229 9.33325 9.33348 10.2288 9.33348 11.3335C9.33348 12.4382 10.229 13.3337 11.3337 13.3337Z" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
