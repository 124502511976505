import React from 'react'

export default function SocialIcon() {
    return (
        <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66251 28.5638C4.27623 28.5638 2.94674 28.0131 1.96649 27.0328C0.986243 26.0526 0.435547 24.7231 0.435547 23.3368C0.435547 21.9505 0.986243 20.621 1.96649 19.6408C2.94674 18.6605 4.27623 18.1099 5.66251 18.1099C7.04879 18.1099 8.37829 18.6605 9.35853 19.6408C10.3388 20.621 10.8895 21.9505 10.8895 23.3368C10.8895 24.7231 10.3388 26.0526 9.35853 27.0328C8.37829 28.0131 7.04879 28.5638 5.66251 28.5638ZM31.0969 20.722L20.643 2.61451C19.9499 1.41365 18.8081 0.537319 17.4688 0.178304C16.1295 -0.180711 14.7025 0.00699791 13.5017 0.700137C12.3008 1.39328 11.4245 2.53507 11.0654 3.87433C10.7064 5.21359 10.8941 6.64062 11.5873 7.84148L22.0425 25.949C22.7434 27.1343 23.8837 27.9953 25.2157 28.3448C26.5477 28.6944 27.9638 28.5043 29.1565 27.8159C30.3491 27.1275 31.222 25.9964 31.5856 24.6681C31.9492 23.3399 31.7727 21.9219 31.0969 20.722ZM10.2544 4.93006L2.47929 18.3947C3.42828 17.7828 4.53336 17.4571 5.66251 17.4565C9.00777 17.446 11.7062 20.2764 11.535 23.6178L15.7388 16.3353L11.0215 8.16816C10.4565 7.18628 10.19 6.06104 10.2544 4.93006Z" fill="#47BEB9" />
        </svg>

    )
}
