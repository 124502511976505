import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../component/axios/auth";
import {  toast } from "react-toastify";
export const userSignUp = createAsyncThunk("signUpdata", async (userData) => {
  try {
    const res = await axios.post("/signup/otp", userData);
    if (res?.data?.data) {
      toast.success(res?.data?.message, {
        className: 'custom-toast-success',
        hideProgressBar: true
      });
    } else {
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 
    }

    return res?.data;
  } catch (error) {
  }
});
const signUpState = {  user: null,  status: "idal",};
const signUpSlice = createSlice({
  name: "signUpSlice",
  initialState: signUpState,
  reducers: {
    createAccount: (state, action) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userSignUp.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(userSignUp.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload;
      })
      .addCase(userSignUp.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});
export const { createAccount } = signUpSlice.actions;
export default signUpSlice.reducer;
