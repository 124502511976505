import React, { useEffect, useState } from "react";
import { Col, Container, Row} from "react-bootstrap";
import style from "./Blog.module.css";

// import user from "../../Images/userImg.png";
// import BigImg from "../../Images/BigImg.png";

// import SebastianUser from "../../Images/userImg.png";
// import Sebastian from "../../Images/Sebastian.png";
// import Sebastian2 from "../../Images/Sebastian2.png";
// import Sebastian3 from "../../Images/Sebastian3.png";
// 
// import { useParams } from "react-router-dom";
import { nodeUrl } from "../../../component/axios/auth";
// import API from "../../../component/axios/auth";

const Blog = () => {
  const [blog, setBlog] = useState();
  useEffect(() => {
    let blogData = sessionStorage.getItem("blogSummery");
    setBlog(JSON.parse(blogData));
  }, []);

  function formatDate(timestamp) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(timestamp);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = day + " " + months[monthIndex] + ", " + year;
    return formattedDate;
  }

  return (
    <Container fluid style={{ padding: "85px 40px" }}>
      <Row>
        <Col className={style.landingpageblogmain} lg={5} sty>
          {blog?.heading}
        </Col>

        <div className={style.landingpageblogUser}>
          <div className={style.landingpageblogUserSubDiv}>
            <img
              style={{ width: "36px", height: "36px", borderRadius: "100px",objectFit:"cover" }}
              src={`${nodeUrl}/public/${blog?.personImage}`}
              alt="user"
            />
            <label>{blog?.personName}</label>
          </div>

          <p>{formatDate(blog?.createdAt)}</p>
        </div>
      </Row>
      <Row>
        <img
          src={`${nodeUrl}/public/${blog?.displayImage}`}
          style={{
            // height: "100",
            maxHeight: "600px",
            width: "100%",
            backgroundSize: "100% 100%",
            objectFit: "cover !important",
            borderRadius: "80px",
          }}
          alt="bigpic"
        />
      </Row>
      <Row className={style.blogTextBox}>
        <span>{blog?.description}</span>
      </Row>
    </Container>
  );
};

export default Blog;
