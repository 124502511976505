import React from 'react'

export default function UploadIcon() {
    return (
        <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 25.5H21V22.5H0V25.5ZM0 10.5H6V19.5H15V10.5H21L10.5 0L0 10.5Z" fill="white" />
        </svg>

    )
}
