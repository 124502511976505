import { nodeUrl } from "../../../../../axios/auth";

  
  const baseUrl =`${nodeUrl}/public` //for server

 export const topImagesIcon = [

        `ad_black.png`,
        `ad_white.png`,
        `address_white.png`,
        `adress_black.png`,
        `atrate_black.png`,
        `atrate_white.png`,
        `calendar_white.png`,
        `camera_black.png`,
        `camera_white.png`,
        `chat_black.png`,
        `chat_white.png`,
        `check_black.png`,
        `check_white.png`,
        `clock_black.png`,
        `clock_white.png`,

           `cloud_black.png`,
        `cloud_white.png`,
        `contact_black.png`,
        `contact_white.png`,
        `facebook_black.png`,
        `facebook_white.png`,
        `gmail_black.png`,
        `gmail_white.png`,
        `hand_black.png`,
        `hand_white.png`,
        `image_black.png`,
        `image_white.png`,
        `instagram_black.png`,
        `instagram_white.png`,
        `internet_balck.png`,
        `internet_white.png`,


         `like_black.png`,
        `like_white.png`,
        `linkldin_black.png`,
        `linkldin_white.png`,
        `location_balck.png`,
        `location_white.png`,
        `mai_balck.png`,
        `mail_white.png`,
        `messanger_black.png`,
        `messanger_white.png`,
        `money_black.png`,
        `money_white.png`,
        `music.png`,
        `notes_black.png`,
        `notes_white.png`,
        `page_black.png`,
        
        `page_white.png`,
        `pencil_balck.png`,
        `pencil_white.png`,
        `phone_balck.png`,
        `phone_white.png`,
        `setting_black.png`,
        `setting_white.png`,
        `sound_black.png`,
        `sound_white.png`,
        `spotify_balck.png`,
        `spotify_white.png`,
        `youtube_balck.png`,
        `youtube_white.png`,
        `notes_black.png`
        
       
    ];