import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Facebook: [],
  Google: [],
  Instagram: [],
  Bing: [],
  Google2: [],
  websiteUrl: "",
  CompanyName: "",
  websiteDetails: "",
  websiteDetailsForIg: "",
  websiteDetailsForFb: "",
  websiteDetailsForGoogle: "",
  websiteDetailsForGoogle2: "",
  websiteDetailsForBing: "",
};
const aiSuggestionSlice = createSlice({
  name: "AiSuggestion",
  initialState,
  reducers: {
    addFaceBookSuggestion: (state, action) => {
      state.Facebook = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },

    addInstagramSuggestion: (state, action) => {
      state.Facebook = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addGoogleSuggestion: (state, action) => {
      state.Google = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addGoogle2Suggestion: (state, action) => {
      state.Google2 = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addIgSuggestion: (state, action) => {
      state.Instagram = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addBingSuggestion: (state, action) => {
      state.Bing = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addWebsiteDeatil: (state, action) => {
      state.websiteDetails = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addWebsiteUrl: (state, action) => {
      state.websiteUrl = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addCompanyName: (state, action) => {
      state.CompanyName = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addWebsiteDetailForIg: (state, action) => {
      state.websiteDetailsForIg = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addWebsiteDetailForFb: (state, action) => {
      state.websiteDetailsForFb = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addWebsiteDetailForGoogle: (state, action) => {
      state.websiteDetailsForGoogle = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addWebsiteDetailForGoogle2: (state, action) => {
      state.websiteDetailsForGoogle2 = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addWebsiteDetailForBing: (state, action) => {
      state.websiteDetailsForBing = action.payload;
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
  },
});

export const {
  addFaceBookSuggestion,
  addWebsiteDeatil,
  addIgSuggestion,
  addBingSuggestion,
  addGoogleSuggestion,
  addInstagramSuggestion,
  addWebsiteUrl,
  addWebsiteDetailForBing,
  addWebsiteDetailForFb,
  addWebsiteDetailForIg,
  addWebsiteDetailForGoogle,
  addGoogle2Suggestion,
  addWebsiteDetailForGoogle2,
  addCompanyName,
} = aiSuggestionSlice.actions;
export default aiSuggestionSlice.reducer;
