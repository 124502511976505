// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#progress-bar {
  position: relative;
  /*animation: progress 6s;*/

  text-align: center;
  color: rgba(255,255,255,100);
  /* background-image: linear-gradient(135deg, #8db795, #2b7f56, #2f5443, #2b7f56, #8db795); */
  /* background-image: linear-gradient(135deg, #b380c9 , #b36461  ,#b36461,#b380c9); */
  background-image: linear-gradient(135deg, #d6d4d4, #eaeaea, #eaeaea, #d6d4d4);
/* background-color: #B37FCA; */
  border-radius: 4px 4px 4px 4px;
  animation: anim 6s linear infinite;
  -o-animation: anim 6s linear infinite;
  -moz-anination: anim 6s linear infinite;
  -webkit-animation: anim 6s linear infinite;
  filter:alpha(opacity=50);
  opacity:.85;
}

@keyframes anim {
  0% { background-position: 0 0; }
  100% { background-position: 1500px 0px; }
} 
`, "",{"version":3,"sources":["webpack://./src/component/panel/user/pages/SelectBrand.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;EAClB,0BAA0B;;EAE1B,kBAAkB;EAClB,4BAA4B;EAC5B,4FAA4F;EAC5F,oFAAoF;EACpF,6EAA6E;AAC/E,+BAA+B;EAC7B,8BAA8B;EAC9B,kCAAkC;EAClC,qCAAqC;EACrC,uCAAuC;EACvC,0CAA0C;EAC1C,wBAAwB;EACxB,WAAW;AACb;;AAEA;EACE,KAAK,wBAAwB,EAAE;EAC/B,OAAO,+BAA+B,EAAE;AAC1C","sourcesContent":["\r\n\r\n#progress-bar {\r\n  position: relative;\r\n  /*animation: progress 6s;*/\r\n\r\n  text-align: center;\r\n  color: rgba(255,255,255,100);\r\n  /* background-image: linear-gradient(135deg, #8db795, #2b7f56, #2f5443, #2b7f56, #8db795); */\r\n  /* background-image: linear-gradient(135deg, #b380c9 , #b36461  ,#b36461,#b380c9); */\r\n  background-image: linear-gradient(135deg, #d6d4d4, #eaeaea, #eaeaea, #d6d4d4);\r\n/* background-color: #B37FCA; */\r\n  border-radius: 4px 4px 4px 4px;\r\n  animation: anim 6s linear infinite;\r\n  -o-animation: anim 6s linear infinite;\r\n  -moz-anination: anim 6s linear infinite;\r\n  -webkit-animation: anim 6s linear infinite;\r\n  filter:alpha(opacity=50);\r\n  opacity:.85;\r\n}\r\n\r\n@keyframes anim {\r\n  0% { background-position: 0 0; }\r\n  100% { background-position: 1500px 0px; }\r\n} \r\n@-o-keyframes anim {\r\n  0% { background-position: 0 0; }\r\n  100% { background-position: 1500px 0px; }\r\n} \r\n@-moz-keyframes anim {\r\n  0% { background-position: 0 0; }\r\n  100% { background-position: 1500px 0px; }\r\n} \r\n@-webkit-keyframes anim {\r\n  0% { background-position: 0 0; }\r\n  100% { background-position: 1500px 0px; }\r\n} \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
