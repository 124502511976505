// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/setting-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-page-section {
    width: 100%;
    height: 424px;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/component/account/Pages/Settings Page/NewSettingPage/NewSettingPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mDAA8D;IAC9D,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,sBAAsB;AAC1B","sourcesContent":[".setting-page-section {\r\n    width: 100%;\r\n    height: 424px;\r\n    background: url(\"../../../../../assets/images/setting-bg.png\");\r\n    width: 100%;\r\n    height: 100%;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
