import React from 'react'

export default function ImageGalleryIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M36.6667 13.0167V23.1667L33.95 20.8334C32.65 19.7167 30.55 19.7167 29.25 20.8334L22.3167 26.7834C21.0167 27.9 18.9167 27.9 17.6167 26.7834L17.05 26.3167C15.8667 25.2834 13.9834 25.1834 12.65 26.0834L4.45004 31.5834L4.26671 31.7167C3.65004 30.3834 3.33337 28.8 3.33337 26.9834V13.0167C3.33337 6.95004 6.95004 3.33337 13.0167 3.33337H26.9834C33.05 3.33337 36.6667 6.95004 36.6667 13.0167Z" fill="white" />
            <path d="M15 17.3C17.1907 17.3 18.9667 15.5241 18.9667 13.3334C18.9667 11.1426 17.1907 9.3667 15 9.3667C12.8093 9.3667 11.0333 11.1426 11.0333 13.3334C11.0333 15.5241 12.8093 17.3 15 17.3Z" fill="white" />
            <path d="M36.6667 23.1667V26.9834C36.6667 33.05 33.0501 36.6667 26.9834 36.6667H13.0167C8.76672 36.6667 5.70006 34.8834 4.26672 31.7167L4.45006 31.5834L12.6501 26.0833C13.9834 25.1833 15.8667 25.2833 17.0501 26.3167L17.6167 26.7833C18.9167 27.9 21.0167 27.9 22.3167 26.7833L29.2501 20.8333C30.5501 19.7167 32.6501 19.7167 33.9501 20.8333L36.6667 23.1667Z" fill="white" />
        </svg>

    )
}
