import "../panel/user/header/header.css";
import "../assets/userAssets/Wrapper.css";
import "../panel/user/sidebar/Sidebar.css";
import { Routes, Route } from "react-router-dom";
// User Panel Route Start

import Dashboard from "../panel/user/pages/Dashboard";
import SignUp from "../account/SignUp";
import Login from "../account/Login";
import SelectImage from "../panel/user/pages/SelectImage";
import TextScreen from "../panel/user/pages/TextScreen";
import CreatedListing from "../panel/user/pages/CreatedListing";
import Payments from "../panel/user/pages/Payments";
import SelectSize from "../panel/user/pages/SelectSize";
import SelectBrand from "../panel/user/pages/SelectBrand";
// Admin Panel Route Start
import ImageRepository from "../panel/admin/pages/ManageImageRepo";
import ManageAccess from "../panel/admin/pages/ManageAccess";
import ManageCampaign from "../panel/admin/pages/ManageCampaign";
import ManageCustomer from "../panel/admin/pages/ManageCustomer";
import PlanManagement from "../panel/admin/pages/ManagePlan";
import ManageDiscount from "../panel/admin/pages/ManageDiscount";
import ManageTransaction from "../panel/admin/pages/ManageTransaction";
import FullView from "../panel/user/pages/FullView";
import Login1 from "../account/Auth Pages/Login";

function WrapperRoute() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/user">
          <Route path="dashboard" element={<Dashboard />}></Route>
          <Route path="signup" element={<SignUp />}></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="login1" element={<Login1 />} />
          <Route path="selectImage" element={<SelectImage />}></Route>
          <Route path="textScreen" element={<TextScreen />}></Route>
          <Route path="createdListing" element={<CreatedListing />}></Route>
          <Route path="fullView" element={<FullView />}></Route>
          <Route path="payments" element={<Payments />}></Route>
          <Route path="selectSize" element={<SelectSize />}></Route>
          <Route path="SelectBrand" element={<SelectBrand />}></Route>
        </Route>
        Admin Routes
        <Route path="/admin">
          <Route path="dashboard" element={<ManageCustomer />}></Route>
          <Route path="imagerepository" element={<ImageRepository />} />
          <Route path="manageaccess" element={<ManageAccess />} />
          <Route path="managecampaign" element={<ManageCampaign />} />
          <Route path="managecustomer" element={<ManageCustomer />} />
          <Route path="planmanagement" element={<PlanManagement />} />
          <Route path="managediscount" element={<ManageDiscount />} />
          <Route path="managetransaction" element={<ManageTransaction />} />
        </Route>
      </Routes>
    </>
  );
}
export default WrapperRoute;
