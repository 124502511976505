import React from 'react'

export default function RightArrow() {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
