// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><path fill=\"white\" d=\"M0 0 Q20 0 20 20 L20 0Z\" /></svg>", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><path fill=\"white\" d=\"M0 20 Q20 20 20 0 L20 20Z\" /></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-active {
    /* width: 200px; */
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    /* margin: 30px; */
  }
  
  .nav-active::before,
  .nav-active::after {
    content: ""; 
    width: 20px;
    height: 20px;
    right: 0;
    position: absolute;
  }
  
  .nav-active::before {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    bottom: -20px;
  }
  
  .nav-active::after {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    top: -20px;
  }

  .theme-color{
    color: #3D84A7;
    text-align: center;
    vertical-align: middle;
  }

  .sidebar-bg-color{
    background: linear-gradient(180deg, #B37FCA 0%, #F5A4A1 40.63%, #F1A2A9 100%);
    /* height: 100vh; */
}

.sidebar-border{
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.sidebar-bg-color{
  position: sticky !important;
  top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/component/panel/user/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,oCAAoC;IACpC,8BAA8B;IAC9B,iCAAiC;IACjC,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,kBAAkB;EACpB;;EAEA;IACE,yDAAgJ;IAChJ,aAAa;EACf;;EAEA;IACE,yDAAkJ;IAClJ,UAAU;EACZ;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,6EAA6E;IAC7E,mBAAmB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,2BAA2B;EAC3B,SAAS;AACX","sourcesContent":[".nav-active {\r\n    /* width: 200px; */\r\n    height: 50px;\r\n    background-color: rgb(255, 255, 255);\r\n    border-top-left-radius: 9999px;\r\n    border-bottom-left-radius: 9999px;\r\n    position: relative;\r\n    /* margin: 30px; */\r\n  }\r\n  \r\n  .nav-active::before,\r\n  .nav-active::after {\r\n    content: \"\"; \r\n    width: 20px;\r\n    height: 20px;\r\n    right: 0;\r\n    position: absolute;\r\n  }\r\n  \r\n  .nav-active::before {\r\n    background-image: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><path fill=\"white\" d=\"M0 0 Q20 0 20 20 L20 0Z\" /></svg>');\r\n    bottom: -20px;\r\n  }\r\n  \r\n  .nav-active::after {\r\n    background-image: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><path fill=\"white\" d=\"M0 20 Q20 20 20 0 L20 20Z\" /></svg>');\r\n    top: -20px;\r\n  }\r\n\r\n  .theme-color{\r\n    color: #3D84A7;\r\n    text-align: center;\r\n    vertical-align: middle;\r\n  }\r\n\r\n  .sidebar-bg-color{\r\n    background: linear-gradient(180deg, #B37FCA 0%, #F5A4A1 40.63%, #F1A2A9 100%);\r\n    /* height: 100vh; */\r\n}\r\n\r\n.sidebar-border{\r\n  border-top-right-radius: 50px;\r\n  border-bottom-right-radius: 50px;\r\n}\r\n\r\n.sidebar-bg-color{\r\n  position: sticky !important;\r\n  top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
