import React, { useEffect, useState } from 'react';
// import TestingPage from '../../../../Testing/TestingPage';
import dashboard from "../../Auth Pages/Images/dashboard.png";
// import LoadingTextAnimation, {
//     AILoaderSvg,
// } from '../../../../Testing/LoadingAnimation';
import "../../../../Testing/Testing.css";
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../axios/auth';
import Cookies from "js-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { 
    // addFaceBookSuggestion,
     addInstagramSuggestion } from '../../../../Redux/AiSuggestion';
// import { AddTargetingDetailsFacebook} from '../../../../Redux/FacebbokAdsSlice';
import { addInstagramDeatils } from '../../../../Redux/CampaignDetailSlice';
import { AddTargetingDetailsInstagram } from '../../../../Redux/InstagramAdSlice';
// Testing.css

const AdsLoadInstagram = () => {
const [adId,setAdId] = useState()
    const [adsDetails, setAdsDetails] = useState(null)
    const [formDetails,setFormDetails] =  useState() 
    const dispatch = useDispatch()
    const { id } = useParams();
    const userToken = Cookies.get("token");
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.loginuser);

    let currency=user?.plan[0]?.currency ;

    useEffect(() => {
        loadertext();
        // Parsing the URL
        let urlParams = new URLSearchParams(window.location.search);
        // Getting the value of the 'adType' parameter
       setAdId(sessionStorage.getItem("adId"))

        let adParams = urlParams.get("adType");
       
        if(adParams==="Instagram"){
            setAdsDetails( sessionStorage.getItem("instagramAdsComplateData"))
            setFormDetails(sessionStorage.getItem("instaData"))
        }
      
    }, []);

    // /createads/
    const createAd = async () => {
        try {
            const res = await axios.post(`/create-insta-ads/${id}`, { adsDetails ,formDetails,currency,adId}, {
                headers: {
                    Authorization: `Bearer ${userToken}`, // Correct spelling
                },
            });

            if (res?.data?.status === true) {
                sessionStorage.removeItem("websiteUrl")
                sessionStorage.removeItem("websiteTitle")
                sessionStorage.removeItem("websiteDetail")
                sessionStorage.removeItem("account_id")
                sessionStorage.removeItem("adId")
                sessionStorage.removeItem("instagramAdsComplateData")
                dispatch(addInstagramDeatils({ headline: '',
                  descriptions: '',
                  image: '',}))
              
                  dispatch(
                    addInstagramSuggestion({
                      descriptions: '',
                      headlines: '',
                      keyword: '',
                    })
                  );
              
                  dispatch(AddTargetingDetailsInstagram({
                  
                  }))
                // navigate("/main/campaign/?status=true", { replace: true })
                navigate("/main/campignLoder?adType=Instagram")


            }
             else if (res?.data?.status !== true) {
                navigate("/main/campaign/?status=false", { replace: false })

            }

        } catch (e) {
            navigate("/main/campaign/?status=false", { replace: false })


        }
    }
useEffect(()=>{
    if(adsDetails&&id){
        createAd()
    }
    
},[adsDetails])



    return (
        
        <div
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "999",
                backgroundImage: `url(${dashboard})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    position: "relative", // Added position property
                    backdropFilter: "blur(1px)", // Adjusted blur value
                    // background: "transparent",
                    zIndex: "100",
                    background: "rgba(0, 0, 0, 0.3)",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "40vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#edf9f8",
                        borderStartStartRadius: "50%",
                        borderStartEndRadius: "50%",
                    }}
                >
                    <div class="text-container">
                        <>
                            <div id="loadingText" class="loading-text">
                                Instagram Ad. Running
                            </div>
                        </>

                        <div className="AI-Loader-Text ">
                        <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g opacity="0.6" clip-path="url(#clip0_3080_6659)">
      <path
        d="M0.999807 1.08862C-0.257526 2.39462 -0.000192679 3.78195 -0.000192679 7.99662C-0.000192679 11.4966 -0.610859 15.0053 2.58514 15.8313C3.58314 16.0879 12.4258 16.0879 13.4225 15.83C14.7531 15.4866 15.8358 14.4073 15.9838 12.5253C16.0045 12.2626 16.0045 3.73528 15.9831 3.46728C15.8258 1.46262 14.5918 0.307283 12.9658 0.0732829C12.5931 0.0192829 12.5185 0.00328288 10.6065 -5.04497e-05C3.82447 0.00328288 2.33781 -0.298717 0.999807 1.08862Z"
        fill="url(#paint0_linear_3080_6659)"
      />
      <path
        d="M7.99887 2.09262C5.5782 2.09262 3.27954 1.87728 2.40154 4.13062C2.03887 5.06128 2.09154 6.26995 2.09154 8.00062C2.09154 9.51928 2.04287 10.9466 2.40154 11.8699C3.27754 14.1246 5.59487 13.9086 7.99754 13.9086C10.3155 13.9086 12.7055 14.1499 13.5942 11.8699C13.9575 10.9299 13.9042 9.73928 13.9042 8.00062C13.9042 5.69262 14.0315 4.20262 12.9122 3.08395C11.7789 1.95062 10.2462 2.09262 7.9962 2.09262H7.99887ZM7.46954 3.15728C12.5189 3.14928 13.1615 2.58795 12.8069 10.3859C12.6809 13.1439 10.5809 12.8413 7.99953 12.8413C3.29287 12.8413 3.15754 12.7066 3.15754 7.99795C3.15754 3.23462 3.53087 3.15995 7.46954 3.15595V3.15728ZM11.1522 4.13795C10.7609 4.13795 10.4435 4.45528 10.4435 4.84662C10.4435 5.23795 10.7609 5.55528 11.1522 5.55528C11.5435 5.55528 11.8609 5.23795 11.8609 4.84662C11.8609 4.45528 11.5435 4.13795 11.1522 4.13795ZM7.99887 4.96662C6.32353 4.96662 4.96554 6.32528 4.96554 8.00062C4.96554 9.67595 6.32353 11.0339 7.99887 11.0339C9.6742 11.0339 11.0315 9.67595 11.0315 8.00062C11.0315 6.32528 9.6742 4.96662 7.99887 4.96662ZM7.99887 6.03128C10.6022 6.03128 10.6055 9.96995 7.99887 9.96995C5.3962 9.96995 5.3922 6.03128 7.99887 6.03128Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3080_6659"
        x1="1.03049"
        y1="14.978"
        x2="15.9008"
        y2="2.10793"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFDD55" />
        <stop offset="0.5" stop-color="#FF543E" />
        <stop offset="1" stop-color="#C837AB" />
      </linearGradient>
      <clipPath id="clip0_3080_6659">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>

                            <p style={{ fontWeight: "bold" }}>Ad processing in progress...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const loadertext = () => {
    let loadingText = document.getElementById("loadingText");
    let characters;
    let currentIndex = 0;
    let timerId;
    function startAnimation() {
        characters = loadingText.innerText.split(" ");
        clearInterval(timerId); // Clear previous interval (if any)
        changeColor();
    }

    function changeColor() {
        timerId = setInterval(() => {
            if (currentIndex < characters.length) {
                characters[currentIndex] =
                    '<span style="color: #358F8B;">' +
                    characters[currentIndex] +
                    "</span>";
                loadingText.innerHTML = characters.join(" ");
                currentIndex++;
            } else {
                clearInterval(timerId); // Clear the interval after completing one cycle
                currentIndex = 0; // Reset index for the next cycle
                resetColor();
            }
        }, 500); // Change color every 500ms (adjust as needed)
    }

    function resetColor() {
        loadingText.innerHTML = "Instagram Ad. Running"; // Reset the text content
        loadingText.style.color = "black"; // Set text color to black
        setTimeout(startAnimation, 500); // Start the animation after 1 second delay
    }

    startAnimation(); // Start the animation initially
};




export default AdsLoadInstagram;
