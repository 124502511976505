import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import style from "./Header.module.css";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../Images/Logo.png";
import classNames from "classnames";

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  // for the navigation of pricing when pricing section is not available on current page
  const [notPricing, setNotPricing] = useState(false);
  const [notFeature, setNotFeature] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCollapse = () => {
    setExpanded(!expanded);
  };

  const currentURL = window.location.href;
  useEffect(() => {
    // getting the current Url

    if (currentURL.includes("blog") || currentURL.includes("aboutus")) {
      setNotPricing(true);
    } else {
      setNotPricing(false);
    }
  }, [notPricing, currentURL]);

  useEffect(() => {
    // getting the current Url

    if (currentURL.includes("blog")) {
      setNotFeature(true);
    } else {
      setNotFeature(false);
    }
  }, [currentURL]);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Navbar
        className={`${isScrolled ? style.scrolled : style.bgBodyTertiary} ${style.HideOnMobile}`}
        // style={{ backgroundColor: "#FFF" }}
        fixed="top"
      >
        <Container fluid >
          <Navbar.Brand href="/landingpage/home" className={style.landingExoad}>
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleCollapse}
          />

          {/* {/ for mobile screen /} */}
          <Navbar.Collapse id="basic-navbar-nav" style={{ width: "50%" }}>
            <Nav className={`${"me-auto"} ${style.navitems}`}>
              <div className={style.HeaderLinksMain}>
                <NavLink
                  className="Landing-HeaderMain"
                  to="/landingpage/home"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  Home
                </NavLink>
                <NavLink
                  className="Landing-HeaderMain"
                  to="/landingpage/aicreative"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  AI Creative
                </NavLink>
                <NavLink
                  className="Landing-HeaderMain"
                  to="/landingpage/aiad"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  Smart Ads
                </NavLink>
                <NavLink
                  className="Landing-HeaderMain"
                  to="/landingpage/aboutus"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  About Us
                </NavLink>
                {notFeature ? (
                  <HashLink
                    className="Landing-HeaderMain"
                    scroll={(el) =>
                      el.scrollIntoView({
                        behavior: "auto",
                        block: "start",
                        inline: "nearest",
                      })
                    }
                    to={"/landingpage/home#features"}
                  >
                    Feature
                  </HashLink>
                ) : (
                  <a
                    href="#features"
                    className="Landing-HeaderMain"
                    onClick={() => {
                      handleCollapse();
                    }}
                  >
                    Feature
                  </a>
                )}

                {notPricing ? (
                  <HashLink
                    className="Landing-HeaderMain"
                    scroll={(el) =>
                      el.scrollIntoView({
                        behavior: "auto",
                        block: "start",
                        inline: "nearest",
                      })
                    }
                    to={"/landingpage/home#Pricing"}
                  >
                    Pricing
                  </HashLink>
                ) : (
                  <a
                    href="#Pricing"
                    className="Landing-HeaderMain"
                    onClick={() => {
                      handleCollapse();
                    }}
                  >
                    Pricing
                  </a>
                )}

                <NavLink
                  className="Landing-HeaderMain"
                  to="/landingpage/blog"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  Blog
                </NavLink>
              </div>
              <div className={style.landingpageHeader}>
                {/* <NavLink
                  to={"/plans"}
                  target="_blank"
                  replace={true}
                  className={style.tryFreeBtn}
                >
                  Try Exoad Free
                </NavLink> */}
                  <NavLink to={"/login"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="138"
                  height="67"
                  viewBox="0 0 218 67"
                  fill="none"
                >
                  <g filter="url(#filter0_i_9_791)">
                    <path
                      d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H194.48C198.946 1.51845 202.856 4.51751 204.013 8.83113L215.622 52.1071C217.304 58.3763 212.58 64.5343 206.089 64.5343H11.3884C5.93737 64.5343 1.51845 60.1153 1.51845 54.6643V11.3884Z"
                      stroke="url(#paint0_linear_9_791)"
                      stroke-width="3.03691"
                    />
       
                    <text
                      x="13%"
                      y="60%"
                      font-family="Arial"
                      font-size="20"
                      fill="white"
                      className={style.TryExoadSvgText}
                      
                    >
                      Try Exoad Free
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_i_9_791"
                      x="0"
                      y="0"
                      width="231.15"
                      height="78.2004"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_9_791"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_9_791"
                      x1="0"
                      y1="0"
                      x2="210.685"
                      y2="59.9789"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#47BEB9" />
                      <stop offset="1" stop-color="#215856" />
                    </linearGradient>
                  </defs>
                </svg>

                </NavLink>
                <NavLink to={"/login"} >
                  <svg   
                    xmlns="http://www.w3.org/2000/svg"
                    width="138"
                    height="67"
                    viewBox="0 0 218 67"
                    fill="none"
                  >
                    <g filter="url(#filter0_i_9_793)">
                      <path
                        d="M218 54.6643C218 60.954 212.901 66.0527 206.612 66.0527H23.5202C18.3669 66.0527 13.8558 62.5923 12.5207 57.615L0.911591 14.3391C-1.02892 7.10531 4.42155 7.62939e-06 11.9111 7.62939e-06H206.612C212.901 7.62939e-06 218 5.09877 218 11.3884V54.6643Z"
                        fill="#5BBCBB"
                      />
                      <text
                        x="35%"
                        y="60%"
                        font-family="Arial"
                        font-size="20"
                        fill="white"
                        className={style.LoginSvgText}
                      >
                        Log In
                      </text>
                    </g>
                    <defs>
                      <filter
                        id="filter0_i_9_793"
                        x="0.516418"
                        y="0"
                        width="231.15"
                        height="78.2004"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="13.6661" dy="12.1476" />
                        <feGaussianBlur stdDeviation="18.6011" />
                        <feComposite
                          in2="hardAlpha"
                          operator="arithmetic"
                          k2="-1"
                          k3="1"
                        />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="shape"
                          result="effect1_innerShadow_9_793"
                        />
                      </filter>
                    </defs>
                  </svg>
                </NavLink>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar
        expand="lg"
        expanded={expanded}
        className={`${isScrolled ? style.scrolled : style.bgBodyTertiary} ${style.ShowOnMobile}`}
        // className={`${style.bgBodyTertiary} ${style.ShowOnMobile}`}

        fixed="top"
      >
        <Container fluid style={{ padding: "6px 10px" }}>
          <Navbar.Brand href="/landingpage/home" className={style.landingExoad}>
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleCollapse}
          />

          {/* {/ for mobile screen /} */}
          <Navbar.Collapse id="basic-navbar-nav" style={{ width: "50%" }}>
            <Nav className={`${"me-auto"} ${style.navitems}`}>
              <div className={classNames(style.mobileViewLink)}>
                <NavLink
                  to="/landingpage/home"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  Home
                </NavLink>
                <NavLink
                  to="/landingpage/aicreative"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  AI Creative
                </NavLink>
                <NavLink
                  to="/landingpage/aiad"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  AI AD
                </NavLink>
                <NavLink
                  to="/landingpage/aboutus"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  About Us
                </NavLink>
                <a href="#features" onClick={handleCollapse}>
                  Feature
                </a>
                <NavLink to="#Pricing" onClick={handleCollapse}>
                  Pricing
                </NavLink>
                <NavLink
                  to="/landingpage/blog"
                  onClick={() => {
                    handleCollapse();
                    scrollToTop();
                  }}
                >
                  Blog
                </NavLink>
              </div>
              <div className={style.landingpageHeader}>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="138"
                  height="67"
                  viewBox="0 0 218 67"
                  fill="none"
                >
                  <g filter="url(#filter0_i_9_791)">
                    <path
                      d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H194.48C198.946 1.51845 202.856 4.51751 204.013 8.83113L215.622 52.1071C217.304 58.3763 212.58 64.5343 206.089 64.5343H11.3884C5.93737 64.5343 1.51845 60.1153 1.51845 54.6643V11.3884Z"
                      stroke="url(#paint0_linear_9_791)"
                      stroke-width="3.03691"
                    />

                    <text
                      x="13%"
                      y="60%"
                      font-family="Arial"
                      font-size="20"
                      fill="white"
                      className={style.TryExoadSvgText}
                    >
                      Try Exoad Free
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_i_9_791"
                      x="0"
                      y="0"
                      width="231.15"
                      height="78.2004"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_9_791"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_9_791"
                      x1="0"
                      y1="0"
                      x2="210.685"
                      y2="59.9789"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#47BEB9" />
                      <stop offset="1" stop-color="#215856" />
                    </linearGradient>
                  </defs>
                </svg>

                <NavLink to={"/login"} >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="138"
                    height="67"
                    viewBox="0 0 218 67"
                    fill="none"
                  >
                    <g filter="url(#filter0_i_9_793)">
                      <path
                        d="M218 54.6643C218 60.954 212.901 66.0527 206.612 66.0527H23.5202C18.3669 66.0527 13.8558 62.5923 12.5207 57.615L0.911591 14.3391C-1.02892 7.10531 4.42155 7.62939e-06 11.9111 7.62939e-06H206.612C212.901 7.62939e-06 218 5.09877 218 11.3884V54.6643Z"
                        fill="#5BBCBB"
                      />
                      <text
                        x="35%"
                        y="60%"
                        font-family="Arial"
                        font-size="20"
                        fill="white"
                        className={style.LoginSvgText}
                      >
                        Log In
                      </text>
                    </g>
                    <defs>
                      <filter
                        id="filter0_i_9_793"
                        x="0.516418"
                        y="0"
                        width="231.15"
                        height="78.2004"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="13.6661" dy="12.1476" />
                        <feGaussianBlur stdDeviation="18.6011" />
                        <feComposite
                          in2="hardAlpha"
                          operator="arithmetic"
                          k2="-1"
                          k3="1"
                        />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="shape"
                          result="effect1_innerShadow_9_793"
                        />
                      </filter>
                    </defs>
                  </svg>
                </NavLink>

                <div className={style.HeaderLinksMain}>
                  <NavLink
                    to="/landingpage/home"
                    onClick={() => {
                      handleCollapse();
                      scrollToTop();
                    }}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/landingpage/aicreative"
                    onClick={() => {
                      handleCollapse();
                      scrollToTop();
                    }}
                  >
                    AI Creative
                  </NavLink>
                  <NavLink
                    to="/landingpage/aiad"
                    onClick={() => {
                      handleCollapse();
                      scrollToTop();
                    }}
                  >
                    AI AD
                  </NavLink>
                  <NavLink
                    to="/landingpage/aboutus"
                    onClick={() => {
                      handleCollapse();
                      scrollToTop();
                    }}
                  >
                    About Us
                  </NavLink>
                  <a href="#features" onClick={handleCollapse}>
                    Feature
                  </a>
                  <NavLink to="#Pricing" onClick={handleCollapse}>
                    Pricing
                  </NavLink>
                  <NavLink
                    to="/landingpage/blog"
                    onClick={() => {
                      handleCollapse();
                      scrollToTop();
                    }}
                  >
                    Blog
                  </NavLink>
                </div>

                <div>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
