import React from 'react'

export default function DownloadIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4994 12.5V15.8336C17.4994 16.2756 17.3238 16.6996 17.0112 17.0122C16.6987 17.3248 16.2747 17.5004 15.8326 17.5004H4.16508C3.72302 17.5004 3.29907 17.3248 2.98648 17.0122C2.6739 16.6996 2.49829 16.2756 2.49829 15.8336V12.5" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.83179 8.33276L9.99881 12.4998L14.1658 8.33276" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.99878 12.5001V2.49927" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
