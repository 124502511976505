// import "./Login.css";
// import Freetrial from "../../../Auth Pages/Images/Freetrial.png";

import { useForm } from "react-hook-form";
import { Col, Container,  Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";

import style from "./FreeTrial.module.css";
import { nodeUrl } from "../../../../axios/auth";
import { useSelector } from "react-redux";

const FreeTrial = () => {

  const user = useSelector((state) => state.loginuser.user);
  const [carderror, setCardError] = useState();
  const [buttonVisible, setButtonVisible] = useState(false);


  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();

  const token = Cookies.get("token");

  const stripe = useStripe();
  const elements = useElements();

  const Submit = async (data) => {

    
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure you have loaded it correctly.
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setCardError(error.message);
    } else {
      // Send the payment method ID and email to your server for further processing
      setButtonVisible(true)
      const response = await fetch(
        // `${API}/Create-freetrial`,
        `${nodeUrl}/Create-freetrial`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            email: data.email,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        
        if (responseData.message === "Card Saved Succesfully") {
          // toast.success(responseData.message);
          toast.success(responseData.message, {
            className: 'custom-toast-success',
            hideProgressBar: true
          });
          setButtonVisible(false)
          navigate("/main/dashboard");
        } else {
          setButtonVisible(false)
          // toast.error(responseData.message);
          toast.error(responseData.message, {
            className: 'custom-toast-error',
             hideProgressBar: true
           });
        }
      } else {
        // Handle server error
        console.error("Server error:", response.statusText);
      }
    }
  };

  return (
    <Container fluid className="layout">
      <Row className="align-items-center">
        <Col
          className="liginImg   d-flex justify-content-end align-items-center "
          lg={6}
          md={12}
          sm={12}
        >
          {/* <img
            src={Freetrial}
            style={{
              width: "70%",
            }}
            alt="login-img"
          /> */}
        </Col>
        <Col style={{ textAlign: "center" }} lg={6} md={12} sm={12}>
          <div
            className="right d-flex justify-content-center align-items-center "
            style={{
              //   padding: "3rem 1rem 1rem 1rem",
              //   height: "70vh",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <h5 className={style.h5ForFreeTrial} style={{fontFamily:"Paytone One",fontWeight:"500"}}>Enter payment details</h5>
            <div className="" style={{ width: "70%" }}>
              <form onSubmit={handleSubmit(Submit)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div className="input-div">
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Email
                    </label>
                    <input
                      className={style.inputFreetrial}
                      type="email"
                      value={user?.email}
                      placeholder="Enter Your Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                    />

                    <p
                      className=""
                      style={{
                        color: "red",
                        fontSize: "10px",
                        fontWeight: "600",
                        paddingLeft: "6px",
                      }}
                    >
                      {errors.email && errors.email.message}
                    </p>
                  </div>

                  <div className="input-div">
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Name on Card
                    </label>
                    <input
                      className={style.inputFreetrial}
                      type="text"
                      placeholder="Enter Card-Holder's Name"
                      {...register("name", { required: true })}
                    />
                  </div>
                  <div id="card-element" style={{ textAlign: "start" }}>
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Card Details
                    </label>
                    <div
                      style={{
                        background: "#f5f7f9",
                        padding: "8px",
                        borderRadius: "4px",
                      }}
                    >
                      <CardElement />
                    </div>
                  </div>

                    {/* <div className="input-div" style={{ marginBottom: 10 }}>
                      <label style={{ fontSize: 13 }} className="signup-label">
                        Billing on address
                      </label>
                      <input
                        className={style.inputFreetrial}
                        type="text"
                        placeholder="India"
                        {...register("billing-address", { required: true })}
                      />
                    </div> */}

                  {/*<div className="input-div" style={{ marginBottom: 10 }}>
                    <label style={{ fontSize: 13 ,}} className="signup-label">
                      Enter Country Name
                    </label>
                    <input
                      className={style.inputFreetrial}
                      type="text"
                      placeholder="Enter Country"
                      {...register("full-address", { required: true })}
                    />
                  </div>*/}
                  <div className="input-div" style={{ marginBottom: 10 }}>
                    <label style={{ fontSize: 13, }} className="signup-label">
                      Enter Country Name
                    </label>

                    <input
                      className={style.inputFreetrial}
                      type="text"
                      placeholder="Enter Country"
                      {...register("full-address", { required: true })}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                      }}
                    />

                  </div>


                </div>

                {
                  buttonVisible ?
                    <button style={{cursor:"not-allowed"}}
                 className=" button-container login-button"
                 disabled 
                    >
                     <Spinner />
                    </button>
                    :
                    <button
                      type="submit"
                      className=" button-container login-button"
                    >
                      Send
                    </button>
                }
              </form>
            </div>
          </div>
        </Col>
      </Row>
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default FreeTrial;
