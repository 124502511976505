// Steps.js

import React, { useContext, useEffect, useState } from "react";
import "./Steps.css";
import { ToggleContext } from "../ContextFile";

const YourComponent = () => {
  const { activeStep } = useContext(ToggleContext);

  const data = [
    { num: 1, type: "Content", path: "services" },
    { num: 2, type: "Targeting" },
    { num: 3, type: "Funding" },
  ];

  return (
    <div className={`process-wrap active-step${activeStep} process-width`}>
      <div className="process-main">
        {data.map((step) => (
          <div className="col-3" key={`step-${step.num}`} style={{}}>
            <div className="process-step-cont">
              <span className="process-label"> {step.type}</span>
              <div
                className={`process-step step-${step.num}`}
                // onClick={() => handleProcessStepClick(`active-step${step.num}`)}
              >
                <div
                  className={`innerdot`}
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "100%",
                  }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YourComponent;
