import React, { useEffect, useState } from "react";
import "./Generate.css";
import { Col, Container, Row } from "react-bootstrap";
import { NavItems } from "../../../UI-Components/ReusableComponents";
import {
  CreativeSizeSvg,
  BackgroundSvg,
  TextAndBtnSvg,
  PostSizeSvg,
  LandScape,
  VerticalSize,
  StorySize,
  LeaderBoardSvg,
  LargerLeaderBoardSvg,
  MediumBannerSvg,
  SkyScraper,
  // HalfPage,
} from "./SVg";
import {  useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCreativeEmpty,
  addCreativeNames,
  addCreativeUniqEmpty,
  // addCreatives,
  addScreenSize,
} from "../../../../Redux/addCreateSlice";
const SocailMediaSize = [
  { type: "Post Size", icon: PostSizeSvg, size: "1080x1080" },
  { type: "Landscape Size", icon: LandScape, size: "1200x628" },
  { type: "Story Size", icon: StorySize, size: "1080x1920" },
  { type: "Vertical Size", icon: VerticalSize, size: "1080x1350" },
  { type: "Pin Size", icon: StorySize, size: "1000x1500" },
];
const DisplaySize = [
  { type: "Leaderboard", icon: LeaderBoardSvg, size: "728x90" },
  { type: "Large Leaderboard", icon: LargerLeaderBoardSvg, size: "970x90" },
  { type: "Medium Banner", icon: MediumBannerSvg, size: "300x250" },
  { type: "Half Page", icon: StorySize, size: "300x600" },
  { type: "Wide Skyscraper", icon: SkyScraper, size: "160x600" },
];

const Generate = () => {
  // const [active] = useState("logo1");
  // const [size, setSize] = useState("1080x1080");
  const dispatch = useDispatch();
  const addcreative = useSelector((state) => state.addcreative.addCreative);
  const { editAll, editOne, convertSize } = useSelector(
    (state) => state.addcreative
  );
  // const edits = useSelector((state) => state.addcreative.editAll);
  // const tempData = useSelector((state) => state.template.tempData);
  const [imgCreative, setimgCreative] = useState(false);

  useEffect(() => {
    if (addcreative.length >= 1) {
      dispatch(addCreativeEmpty([]));
      dispatch(addCreativeUniqEmpty([]));
      dispatch(addCreativeNames(""));
      setimgCreative(true);
    }
  }, []);

  const navigate = useNavigate();
  function postsize(size) {
    dispatch(addScreenSize(size));
    if (editAll) {
      navigate("/main/brand/brandsetup");
    } else if (editOne) {
      navigate("/main/brand/brandsetup");
    } else if (convertSize) {
      navigate("/main/brand/templates");
    } else {
      navigate("/main/brand/brandsetup");
    }
  }

  return (
    <Container fluid className="generate-scroller-class">
      <Row
        style={{ margin: "10px 20px", gap: "10px" }}
        // style={{}}
      >
        <div className="left-navbar" style={{ border: "none" }}>
          <NavItems
            logo={CreativeSizeSvg}
            active={true}
            text={"logo1"}
            label={"Select Creative Size"}
          />
          <NavItems logo={BackgroundSvg} active={false} text={"logo2"} />
          <NavItems logo={TextAndBtnSvg} active={false} text={"logo3"} />
        </div>
        <MainGenerate postsize={postsize} />
      </Row>
    </Container>
  );
};

export const MainGenerate = ({ gap, postsize }) => {
  return (
    <div>
      <Row style={{ marginBottom: "10px" }}>
        <ItemsContainer
          Heading={"Social Media Size Create Post"}
          data={SocailMediaSize}
          postsize={postsize}
          // size={size}
        />
      </Row>
      <Row>
        <ItemsContainer
          Heading={"Display Sizes"}
          data={DisplaySize}
          postsize={postsize}
        />
      </Row>
    </div>
  );
};

const ItemsContainer = ({ Heading, data, postsize }) => {
  return (
    <>
      <Col lg={4} style={{ display: "flex", justifyContent: "center" }}>
        <div className="generate-sizing-div">
          <h1>{Heading}</h1>
          <p>Most Common sizes for social media advertising</p>
        </div>
      </Col>
      <Col>
        <Row className="SizeSelectionBoxMain">
          {data.map((item, i) => (
            <GenerateItem
              postsize={postsize}
              sizeTitle={item.type}
              icon={item.icon}
              size={item.size}
              index={i + 1}
            />
          ))}
        </Row>
      </Col>
    </>
  );
};

const GenerateItem = ({ sizeTitle, index, icon, size, postsize }) => {
  return (
    <div className="generate-select-size-box" onClick={() => postsize(size)}>
      <p className="generate-select-size-box-p">{index}</p>
      <div
        style={{
          width: "100%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </div>
      <div className="generate-select-size-text-box">
        <h3 className="generate-select-size-text-box-p">{sizeTitle}</h3>
        <h4>{size}</h4>
      </div>
    </div>
  );
};
export default Generate;
