// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/create-brand-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand-setup-section {
    padding: 18px 29px 18px 40px;
    height: calc(100vh - 74px);
    overflow-y: auto;
}

@media (max-width: 767px) {
    .brand-setup-section {
        padding: 18px 16px;
    }
}

.brand-setup-section .create-brand-button {
    padding: 43px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.brand-setup-section .create-brand-button button {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 247px;
    height: 61px;
    border: none;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    color: var(--text-white);
}
`, "",{"version":3,"sources":["webpack://./src/component/account/Pages/Brands Page/NewBrandSetup/NewBrandSetup.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,mDAAmE;IACnE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,wBAAwB;IACxB,4BAA4B;IAC5B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;IACjC,wBAAwB;AAC5B","sourcesContent":[".brand-setup-section {\r\n    padding: 18px 29px 18px 40px;\r\n    height: calc(100vh - 74px);\r\n    overflow-y: auto;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .brand-setup-section {\r\n        padding: 18px 16px;\r\n    }\r\n}\r\n\r\n.brand-setup-section .create-brand-button {\r\n    padding: 43px 0 0 0;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.brand-setup-section .create-brand-button button {\r\n    background: url(\"../../../../../assets/images/create-brand-bg.png\");\r\n    width: 247px;\r\n    height: 61px;\r\n    border: none;\r\n    background-size: contain;\r\n    background-repeat: no-repeat;\r\n    font-size: 24px;\r\n    line-height: 34px;\r\n    font-weight: 500;\r\n    font-family: \"Roboto\", sans-serif;\r\n    color: var(--text-white);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
