import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Cities: [],
  startDate: "",
  EndDate: "",
  CountryCode: "",
  amount: "",
};
const InstagramAdSlice = createSlice({
  name: "InstagramSlice",
  initialState,
  reducers: {
   
    AddTargetingDetailsInstagram: (state, action) => {
      
      state.CountryCode = action.payload[0]?.country;
      state.Cities = action.payload[1]?.cityList;
      state.keywords = action.payload[2]?.keywords;
     
      // state.Cities = action.payload[0].cityList;
    },
  },
});

export const {
    AddTargetingDetailsInstagram
} = InstagramAdSlice.actions;
export default InstagramAdSlice.reducer;
