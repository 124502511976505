import React, { useEffect, useState } from 'react'
import "./CampaignsLoader.css";
import { useNavigate } from 'react-router-dom';

// import SubmissionImg from "../../../../../assets/images/submission-img.png";
// import LiveImg from "../../../../../assets/images/live-img.png";
import SubmissionImg from "../../../../assets/images/submission-img.png";
// import dashboard from "../../Auth Pages/Images/dashboard.png";
// import dashboard from "../../Auth Pages/Images/dashboard.png";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Cookies from "js-cookie";

// import axios from "../../../../axios/auth";
import axios from "../../../axios/auth";

import LiveImg from "../../../../assets/images/live-img.png";
import { useDispatch } from 'react-redux';
import { addGoogleAnalytics } from '../Redux/AdsAnalyticsDetailsSlice';


export default function CampaignsLoader() {

  const [adType, setAdType] = useState()
  let token = Cookies.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate(); 


  useEffect(() => {
    
console.log();

    fetchDataAndSetData();
  }, []);




  const fetchData = async (tkn) => {
    
    const url = `/get-all-ads`;
    try {
      const ads = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token||tkn}`,
        },
      });
      if (ads?.data) {
        return ads.data;
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    // Getting the value of the 'adType' parameter
    let adParams = urlParams.get("adType");
    setAdType(adParams)
  }, [])

 


  const fetchDataAndSetData = async () => {

    try {
      let tkn = Cookies.get("token");

      const fetchedData = await fetchData(tkn);
      console.log();
      
       if(fetchedData?.data) {
        // Assuming fetchedData.data is an array
        const sortedData = fetchedData?.data?.sort((a, b) => {
          return new Date(b?.adData?.adMapData?.createdAt) - new Date(a?.adData?.adMapData?.createdAt);
        });
      
      dispatch(addGoogleAnalytics(sortedData));
      const newUrl = 'dashboard';
      window.history.replaceState(null, '', newUrl);

      setTimeout(() => {
      
        navigate("/main/dashboard")
      }, 2000);


      }
    } catch (error) {
      // console.log(error);
      // Handle errors, maybe show a message to the user
    }
  };

  return (
    <div className='container'>
      <div className='responsive'
      
    >
      <div className='campaignsLoaderWrapper'>
        <div className="campains-loader-alignment">
          <div className="campains-details-alignment">
            <div className='submission-details-details'>
              <img src={SubmissionImg} alt='SubmissionImg' />
              <h2>Submission</h2>
            </div>
            <div class="progress-container">
              <div class="progress-bar"></div>
            </div>
            <div>
              <div className='loader-height-alignment'>
                <div className="step-round-alignment">
                  <div className="second-round">

                  </div>
                </div>
              </div>
              {["top"].map((placement) => (
                <OverlayTrigger
                  key={placement}
                  placement={placement}
                  overlay={
                    <Tooltip id={`tooltip-${placement}`}>

                      Approve in 24 Hours
                    </Tooltip>
                  }
                >
                  <h2 style={{ cursor: "pointer" }}>

                    <span>Exoads Approval</span>

                  </h2>
                </OverlayTrigger>
              ))}
            </div>
            <div>
              <div className='loader-height-alignment'>
                <div className="step-round-alignment deactive-alignment">
                  <div className="second-round">
                  </div>
                </div>
              </div>
              <h2 >{adType}<br />
                Approval</h2>
            </div>
            <div className='live-img'>
              <img src={LiveImg} alt="LiveImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
