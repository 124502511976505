// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ctext-section {
    width: 50%;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .ch1 {
    font-size: 8rem;
  }
  
  .cp {
    max-width: 500px;
    line-height: 160%;
  }
  
  .ca {
    display: block;
    padding: 0.4em;
    color: #8dd5f7;
    text-decoration: none;
  }
  
  .cimg {
    width: 50%;
    height: 100vh;
    background-image: url(https://images.unsplash.com/photo-1525550557089-27c1bfedd06c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80);
    background-position: top left;
  }

  .fashion {
    transform: rotate(-90deg);
  }
  .para-text1{
    z-index: 88888;
  }`, "",{"version":3,"sources":["webpack://./src/component/panel/user/pages/Creative.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,cAAc;IACd,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,oLAAoL;IACpL,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,cAAc;EAChB","sourcesContent":[".ctext-section {\r\n    width: 50%;\r\n    height: 100vh;\r\n    text-align: center;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  .ch1 {\r\n    font-size: 8rem;\r\n  }\r\n  \r\n  .cp {\r\n    max-width: 500px;\r\n    line-height: 160%;\r\n  }\r\n  \r\n  .ca {\r\n    display: block;\r\n    padding: 0.4em;\r\n    color: #8dd5f7;\r\n    text-decoration: none;\r\n  }\r\n  \r\n  .cimg {\r\n    width: 50%;\r\n    height: 100vh;\r\n    background-image: url(https://images.unsplash.com/photo-1525550557089-27c1bfedd06c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80);\r\n    background-position: top left;\r\n  }\r\n\r\n  .fashion {\r\n    transform: rotate(-90deg);\r\n  }\r\n  .para-text1{\r\n    z-index: 88888;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
