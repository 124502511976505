import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  addCreative: [],
  size: "",
  convertSize: "",
  creativeName: "",
  addCreativeUniqs: [],
  editAll: "",
  editOne: "",
  editOneData: [],
  brandsetupData: [],
  chunk: "", 
  previewImage: "",
  preMainColor: "",
  preHeadingColor: "",
  PreDescription: "",
  PrePromoColor: "",
  setcreateNewProject: "",
brandList:[],
updateBrandLIst:[],
updateBrandNumber:"",
editBrand:"",
coinMangment:"",
profileImage:"",
profileName:""
};
const addCreateSlice = createSlice({
  name: "addCreate",
  initialState,
  reducers: {
    addCreatives: (state, action) => {
      state.addCreative.push(action.payload);
      // state.addCreative = Array.from(new Set([...state.addCreative, action.payload]));
    },
    addScreenSize: (state, action) => {
      state.size = action.payload;
    },
    addBrandList: (state, action) => {
      const { index, data } = action.payload; // Assuming action.payload contains both index and data
if(index){
  addBrandList.splice(index,1)
  state.brandList.push(action.payload);
}else{

  state.brandList.push(action.payload);
}
    },
    addUpdateBrandNumber: (state, action) => {
      state.updateBrandNumber=(action.payload);
    },
    EditedBrand: (state, action) => {
      state.editBrand=(action.payload);
    },
    addUpdateBrandLIst: (state, action) => {
      
      state.updateBrandLIst=action.payload;
    },
    addConvertedSize: (state, action) => {
      state.convertSize = action.payload;
    },
    addCreativeEmpty: (state, action) => {
      state.addCreative = action.payload;
    },
    addCreativeEditAll: (state, action) => {
      // state.addCreativeUniqs = Array.from(new Set([...state.addCreativeUniqs, action.payload]));
      state.editAll = action.payload;
    },
    addCreativeNames: (state, action) => {
      state.creativeName = action.payload;
    },
    addCreativeUniq: (state, action) => {
      // state.addCreativeUniqs = Array.from(new Set([...state.addCreativeUniqs, action.payload]));
      state.addCreativeUniqs.push(action.payload);
    },
    addCreativeUniqEmpty: (state, action) => {
      // state.addCreativeUniqs = Array.from(new Set([...state.addCreativeUniqs, action.payload]));
      state.addCreativeUniqs = action.payload;
    },
    addCreativeEdit: (state, action) => {
      // state.addCreativeUniqs = Array.from(new Set([...state.addCreativeUniqs, action.payload]));
      state.editAll = action.payload;
    },
    addCreativeUniqEdit: (state, action) => {
      // state.addCreativeUniqs = Array.from(new Set([...state.addCreativeUniqs, action.payload]));
      state.editOne = action.payload;
    },
    addSingleEditDetails: (state, action) => {
      // state.addCreativeUniqs = Array.from(new Set([...state.addCreativeUniqs, action.payload]));
      state.editOneData.push(action.payload);
    },
    addSingleEditDetailsEmpty: (state, action) => {
      // state.addCreativeUniqs = Array.from(new Set([...state.addCreativeUniqs, action.payload]));
      state.editOneData = action.payload;
    },
    addBrandsetupData: (state, action) => {
      state.brandsetupData = action.payload;
    },
    addChunkData: (state, action) => {
      state.chunk = action.payload;
    },
    addPreviewImgkData: (state, action) => {
      state.previewImage = action.payload;
    },
    addPreviewManinColor: (state, action) => {
      state.preMainColor = action.payload;
    },
    addPreviewHeadingColor: (state, action) => {
      state.preHeadingColor = action.payload;
    },
    addPreviewDescriptionColor: (state, action) => {
      state.PreDescription = action.payload; 
    },
    addPreviewPromoColor: (state, action) => {
      state.PrePromoColor = action.payload;
    },
    // removeAddCreativeSlice: (state, action) => {
    //   state = action.payload;
    // },
    removePreviewData: (state, action) => {
      state.previewImage = action.payload;
      state.PreDescription = action.payload;
      state.PrePromoColor = action.payload;
      state.preMainColor = action.payload;
      // state.brandsetupData ="";
    },
    addCreateNewProject: (state, action) => {
      state.setcreateNewProject = action.payload;
   
    },
    handleCoinChange: (state, action) => {
      state.coinMangment = action.payload;
   
    },
    handleprofileImage: (state, action) => {
      state.profileImage = action.payload;
   
    },
    handleFullName: (state, action) => {
      state.profileName= action.payload;
   
    },
    logoColorPikedFun: (state, action) => {
      state.logoColorPiked = action.payload;
   
    }
  },
});

export const {
  addCreatives,
  addScreenSize,
  addCreativeEmpty,
  addCreativeNames,
  addCreativeUniq,
  addCreativeUniqEmpty,
  addCreativeEdit,
  addCreativeUniqEdit,
  addSingleEditDetails,
  addSingleEditDetailsEmpty,
  addBrandsetupData,
  addConvertedSize,
  addChunkData,
  addPreviewImgkData,
  addPreviewDescriptionColor,
  addPreviewManinColor,
  addPreviewHeadingColor,
  addPreviewPromoColor,
  removeAddCreativeSlice,
  removePreviewData,
  addCreateNewProject,
  addBrandList,
  addCreativeEditAll,
  addUpdateBrandLIst,
  addUpdateBrandNumber,
  EditedBrand,
  handleCoinChange,
  handleprofileImage,
  logoColorPikedFun,
  handleFullName
} = addCreateSlice.actions;
export default addCreateSlice.reducer;
