import React, { useEffect, useState } from "react";
import style from "./FontChange.module.css";
import styleBrands from "../brandsetup.module.css";
// import { ImageSvg } from "../../../.PagesSVG";
import { Col } from "react-bootstrap";
import { SearchSvg } from "../../../Campaigns/FacebookCampaign/SideNavContainer/SVG";

const FontChanger = ({ HandleCurrentFonts, fonts }) => {
  // const [selectedFont, setSelectedFont] = useState("Arial");
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  // const changeFontFamily = (event) => {
  //   setSelectedFont(event.target.value);
  // };


  const fetchFonts = () => {
    fetch(
      "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCw-xAHIS7gMRgOcG5iyl-EKSv4VTzJb34"
    )
      .then((response) => response.json())
      .then((responseData) => {
        const fontFamilies = responseData?.items.map((font) => font.family);
        setData(fontFamilies);
      })
      .catch((error) => {
        // console.error("Error fetching fonts:", error);
      });
  };

  const HandleCurrentFont = (item) => {
    HandleCurrentFonts(item);
    setToggle(false);
    setSearchValue("");
  };

  useEffect(() => {
    fetchFonts();
  }, []);

  const Fonts = [
    "Poppins",
    "Roboto",
    "Open Sans",
    "Noto Sans JP",
    "Montserrat",
    "Lato",

    "Roboto Condensed",
    "Inter",
    "Material Icons",
    "Roboto Mono",
    "Oswald",
    "Noto Sans",
    "Raleway",
    "Nunito Sans",
    "Playfair Display",
    "Nunito",
    "Ubuntu",
    "Rubik",
    "Roboto Slab",
  ];

  const filteredFonts = data.filter((font) =>
    font.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div
      className={style.MainContainer}
      style={{ minWidth: "250px", width: "auto", position: "relative" }}
    >
      <div style={{ width: "100%" }} >
        <Col
          className={styleBrands.colorSelectMain}
          style={{ width: "100%", border:"2px solid #5bbcbb"  }}
          onClick={() => setToggle(!toggle)}
        >
          <div >
            <h6 style={{ fontFamily: fonts }}>
              {!fonts ? "Select Text Style" : fonts}{" "}
            </h6>
          </div>

          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 4V20M17 12V20M6 20H10M15 20H19M13 7V4H3V7M21 14V12H13V14"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Col>

        {toggle && (
          <div className={style.fontSearchBox}>
            <div style={{ position: "relative" }}>
              <div className={style.fontSearchBoxMain}>
                {SearchSvg}
                <input
                  type="text"
                  style={{
                    width: "100%",
                    border: "#000",
                    background: "white",
                    height: "20px",
                    outline: "none",
                  }}
                  placeholder="Search Font"
                  // value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
              </div>
              {!searchValue.length > 0 ? (
                <ul className={style.FontListing}>
                  {Fonts.map((item, i) => (
                    <li key={i} onClick={() => HandleCurrentFont(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className={style.FontListing}>
                  {filteredFonts.map((item, i) => (
                    <li key={i} onClick={() => HandleCurrentFont(item)}>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FontChanger;
