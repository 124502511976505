export const Coins = (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="#FFD700"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        fill="#FFD700"
        d="M264.4 95.01c-35.6-.06-80.2 11.19-124.2 34.09C96.27 152 61.45 182 41.01 211.3c-20.45 29.2-25.98 56.4-15.92 75.8 10.07 19.3 35.53 30.4 71.22 30.4 35.69.1 80.29-11.2 124.19-34 44-22.9 78.8-53 99.2-82.2 20.5-29.2 25.9-56.4 15.9-75.8-10.1-19.3-35.5-30.49-71.2-30.49zm91.9 70.29c-3.5 15.3-11.1 31-21.8 46.3-22.6 32.3-59.5 63.8-105.7 87.8-46.2 24.1-93.1 36.2-132.5 36.2-18.6 0-35.84-2.8-50.37-8.7l10.59 20.4c10.08 19.4 35.47 30.5 71.18 30.5 35.7 0 80.3-11.2 124.2-34.1 44-22.8 78.8-52.9 99.2-82.2 20.4-29.2 26-56.4 15.9-75.7zm28.8 16.8c11.2 26.7 2.2 59.2-19.2 89.7-18.9 27.1-47.8 53.4-83.6 75.4 11.1 1.2 22.7 1.8 34.5 1.8 49.5 0 94.3-10.6 125.9-27.1 31.7-16.5 49.1-38.1 49.1-59.9 0-21.8-17.4-43.4-49.1-59.9-16.1-8.4-35.7-15.3-57.6-20zm106.7 124.8c-10.2 11.9-24.2 22.4-40.7 31-35 18.2-82.2 29.1-134.3 29.1-21.2 0-41.6-1.8-60.7-5.2-23.2 11.7-46.5 20.4-68.9 26.1 1.2.7 2.4 1.3 3.7 2 31.6 16.5 76.4 27.1 125.9 27.1s94.3-10.6 125.9-27.1c31.7-16.5 49.1-38.1 49.1-59.9z"
      />
    </g>
  </svg>
);

export const DropDownArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="22"
    viewBox="0 0 25 22"
    fill="none"
  >
    <path
      d="M10.768 0.999999C11.5378 -0.333335 13.4622 -0.333333 14.232 1L24.1913 18.25C24.9611 19.5833 23.9989 21.25 22.4593 21.25H2.54071C1.0011 21.25 0.0388563 19.5833 0.808657 18.25L10.768 0.999999Z"
      fill="#EDF9F8"
    />
  </svg>
);

export const inputIcone = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.16561 3.33276H3.33183C2.88977 3.33276 2.46582 3.50837 2.15323 3.82096C1.84065 4.13354 1.66504 4.5575 1.66504 4.99956V16.6671C1.66504 17.1092 1.84065 17.5331 2.15323 17.8457C2.46582 18.1583 2.88977 18.3339 3.33183 18.3339H14.9994C15.4414 18.3339 15.8654 18.1583 16.178 17.8457C16.4906 17.5331 16.6662 17.1092 16.6662 16.6671V10.8333"
      stroke="#B2BBC6"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4158 2.08226C15.7473 1.75072 16.197 1.56445 16.6659 1.56445C17.1348 1.56445 17.5845 1.75072 17.916 2.08226C18.2476 2.41381 18.4338 2.86349 18.4338 3.33237C18.4338 3.80125 18.2476 4.25093 17.916 4.58248L9.99866 12.4998L6.66504 13.3332L7.49844 9.99961L15.4158 2.08226Z"
      stroke="#B2BBC6"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const BellIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.9999 7.9997C17.9999 6.40842 17.3677 4.88231 16.2425 3.7571C15.1173 2.63189 13.5912 1.99976 11.9999 1.99976C10.4086 1.99976 8.88252 2.63189 7.75732 3.7571C6.63211 4.88231 5.99997 6.40842 5.99997 7.9997C5.99997 14.9996 3 16.9996 3 16.9996H20.9998C20.9998 16.9996 17.9999 14.9996 17.9999 7.9997Z"
      stroke="#358F8B"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7299 20.9994C13.5541 21.3025 13.3017 21.5541 12.9981 21.729C12.6944 21.9039 12.3502 21.996 11.9997 21.996C11.6493 21.996 11.305 21.9039 11.0014 21.729C10.6977 21.5541 10.4454 21.3025 10.2695 20.9994"
      stroke="#358F8B"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const NotificationBell = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.001 6.66615C15.001 5.33997 14.4741 4.0681 13.5364 3.13035C12.5986 2.1926 11.3268 1.66577 10.0006 1.66577C8.67439 1.66577 7.40252 2.1926 6.46477 3.13035C5.52702 4.0681 5.00019 5.33997 5.00019 6.66615C5.00019 12.4999 2.5 14.1667 2.5 14.1667H17.5011C17.5011 14.1667 15.001 12.4999 15.001 6.66615Z"
      stroke="#1D242D"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.4425 17.5001C11.296 17.7527 11.0856 17.9624 10.8326 18.1082C10.5795 18.2539 10.2926 18.3307 10.0006 18.3307C9.70851 18.3307 9.42159 18.2539 9.16853 18.1082C8.91546 17.9624 8.70513 17.7527 8.55859 17.5001"
      stroke="#1D242D"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const NotificationSvg = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M11.9918 1.21108C12.0503 0.938764 11.7834 0.709149 11.5229 0.808523L0.22617 5.12098C0.0902107 5.17289 0.000281909 5.30321 6.6158e-07 5.44873C-0.000280586 5.59427 0.0891326 5.72494 0.224905 5.77737L3.39838 7.00302V10.8634C3.39838 11.0263 3.5102 11.1678 3.66864 11.2055C3.82602 11.2429 3.99013 11.168 4.06391 11.0216L5.37642 8.4171L8.57945 10.7942C8.77428 10.9388 9.05433 10.8468 9.12523 10.6144C12.1158 0.809297 11.9865 1.23571 11.9918 1.21108ZM9.20325 2.44665L3.70011 6.3658L1.33229 5.45133L9.20325 2.44665ZM4.1015 6.94313L8.89836 3.52699C4.77073 7.8814 4.9863 7.65221 4.9683 7.67644C4.94156 7.71242 5.01483 7.57219 4.1015 9.38459V6.94313ZM8.59813 9.93246L5.77872 7.84008L10.8766 2.46212L8.59813 9.93246Z"
        fill="white"
      />
    </g>
  </svg>
);
