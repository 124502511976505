// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-container {
  /* Headings/H2 */
  font-family: Montserrat Alternates;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.AI-Loader-Text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  & p {
    color: var(--text-dark, #1d242d);
    text-align: center;

    /* Body-16/Regular */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.loading-text{
  font-family: Paytone One;
   font-weight: 500
}
.botLoader{
  width: 250px;
  position: absolute;
  bottom: 140px;
  left: 240px;
}


@media (max-width: 767px) {
  .loading-text{
    font-family: Paytone One;
    font-weight: 100;
    font-size: 1.4rem;
    text-align: center;
  }

  .botLoader{
    width: 250px;
    position: absolute;
    bottom: 140px;
    left: 82px;
  }
  }

`, "",{"version":3,"sources":["webpack://./src/Testing/Testing.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kCAAkC;EAClC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;;EAET;IACE,gCAAgC;IAChC,kBAAkB;;IAElB,oBAAoB;IACpB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;AACF;;AAEA;EACE,wBAAwB;GACvB;AACH;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,WAAW;AACb;;;AAGA;EACE;IACE,wBAAwB;IACxB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,UAAU;EACZ;EACA","sourcesContent":[".text-container {\r\n  /* Headings/H2 */\r\n  font-family: Montserrat Alternates;\r\n  font-size: 48px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n}\r\n\r\n.AI-Loader-Text {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 16px;\r\n\r\n  & p {\r\n    color: var(--text-dark, #1d242d);\r\n    text-align: center;\r\n\r\n    /* Body-16/Regular */\r\n    font-family: Poppins;\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    line-height: normal;\r\n  }\r\n}\r\n\r\n.loading-text{\r\n  font-family: Paytone One;\r\n   font-weight: 500\r\n}\r\n.botLoader{\r\n  width: 250px;\r\n  position: absolute;\r\n  bottom: 140px;\r\n  left: 240px;\r\n}\r\n\r\n\r\n@media (max-width: 767px) {\r\n  .loading-text{\r\n    font-family: Paytone One;\r\n    font-weight: 100;\r\n    font-size: 1.4rem;\r\n    text-align: center;\r\n  }\r\n\r\n  .botLoader{\r\n    width: 250px;\r\n    position: absolute;\r\n    bottom: 140px;\r\n    left: 82px;\r\n  }\r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
