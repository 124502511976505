import img1 from "../../../assets/images/selectPage/1.png";
import img2 from "../../../assets/images/selectPage/2.png";
import img3 from "../../../assets/images/selectPage/3.webp";
import img4 from "../../../assets/images/selectPage/4.webp";
import img5 from "../../../assets/images/selectPage/5.webp";
import img6 from "../../../assets/images/selectPage/6.webp";
import img7 from "../../../assets/images/selectPage/7.webp";
import img8 from "../../../assets/images/selectPage/8.webp";
import img9 from "../../../assets/images/selectPage/9.webp";
import img10 from "../../../assets/images/selectPage/10.webp";

import "./SelectBrand.css";
import "./SelectSize.css";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addScreenSize } from "../../../../Redux/addCreateSlice";
function SelectBrand() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (size) => {
    navigate("/user/textScreen");
    dispatch(addScreenSize(size));
  };
  return (
    <>
      <div className="row  h-100 m-0 p-0">
        <div className="col-sm-2 sidebar-bg-color px-0 mx-0 sidebar-border">
          <div className="">
            <Sidebar className="" />
          </div>
        </div>
        <div className="col-sm-10 bg-white">
          {/* <div className="container-fluid"> */}
          {/* <div className="container-fluid"> */}
          <div className="container">
            <div className="container">
              <Header title="Creative Format" btnname="Upgrade Plan" />
            </div>
            <div className="row">
              <div className="row">
                <h6 className="title text-dark text-center mt-2">
                  Select your creative size below depending on the platform you
                  want to advertise on.
                </h6>
              </div>
              <div className="row align-items-center">
                <div className="col-sm-3">
                  <h4 className="pageheadline">
                    Social Media Sizes Create Post
                  </h4>
                  <h6 className="paragraph fw-bold">
                    Most Common sizes for social media advertising
                  </h6>
                </div>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card-group">
                        {/*size 1080x1080 */}
                        <div
                          className="card  card-border bg-gradiant ms-2 me-2"
                          onClick={() => handleClick("1080x1080")}
                        >
                          <div className=" card-body ">
                            <div className="flex-column justify-content-between">
                              <div className="d-flex  justify-content-center mt-3">
                                <img src={img1} className="" alt="" />
                              </div>
                              <div className="mt-2">
                                <h5 className="card-title img-title text-center">
                                  Post Size
                                </h5>
                                <p className="card-text text-center">
                                  1080x1080
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* size 1200x628 */}
                        <div
                          className="card card-border  ms-2 me-2"
                          onClick={() => handleClick("1200x628")}
                        >
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img2} className="" alt="" />
                            </div>
                            <div className="mt-2">
                              <h5 className="card-title img-title text-center">
                                Landscape Size
                              </h5>
                              <p className="card-text text-center">1200x628</p>
                            </div>
                          </div>
                        </div>

                        {/* size 1080x1920 */}
                        <div
                          className="card card-border  ms-2 me-2"
                          onClick={() => handleClick("1080x1920")}
                        >
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img3} className="" alt="" />
                            </div>
                            <div className="d-flex justify-content-center">
                              <div className="mt-2">
                                <h5 className="card-title img-title text-center">
                                  Story Size
                                </h5>
                                <p className="card-text text-center">
                                  1080x1920
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* size 1080x1350 */}
                        <div
                          className="card card-border  ms-2 me-2"
                          onClick={() => handleClick("1080*1350")}
                        >
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img4} className="" alt="" />
                            </div>
                            <div className="d-flex justify-content-center">
                              <div className="mt-2">
                                <h5 className="card-title img-title text-center">
                                  Vertical Size
                                </h5>
                                <p className="card-text text-center">
                                  1080x1350
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* size 1000x1500 */}
                        <div
                          className="card card-border  ms-2 me-2"
                          onClick={() => handleClick("1000x1500")}
                        >
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img5} className="" alt="" />
                            </div>
                            <div className="mt-2">
                              <h5 className="card-title img-title text-center">
                                Pin Size
                              </h5>
                              <p className="card-text text-center">1000x1500</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Row */}
              <div className="row align-items-center mt-5">
                <div className="col-sm-3">
                  <h4 className="pageheadline">Display Sizes</h4>
                  <h6 className="paragraph fw-bold text-start">
                    Most Common sizes for Display advertising
                  </h6>
                </div>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card-group">
                        <div className="card  card-border bg-gradiant ms-2 me-2">
                          <div className=" card-body">
                            <div className="d-flex  justify-content-center mt-4">
                              <img src={img6} className="" alt="" />
                            </div>
                          </div>
                          <div className=" card-footer">
                            <h5 className="card-title img-title text-center">
                              Post Size
                            </h5>
                            <p className="card-text text-center">1080x1080</p>
                          </div>
                        </div>

                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body">
                            <div className="d-flex justify-content-center mt-4">
                              <img src={img7} className="" alt="" />
                            </div>
                          </div>
                          <div className=" card-footer">
                            <div>
                              <h5 className="card-title img-title text-center">
                                Landscape Size
                              </h5>
                              <p className="card-text text-center">1200x628</p>
                            </div>
                          </div>
                        </div>
                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body">
                            <div className="d-flex  justify-content-center mt-3">
                              <img src={img8} className="" alt="" />
                            </div>
                          </div>
                          <div className="card-footer">
                            <h5 className="card-title img-title text-center">
                              Story Size
                            </h5>
                            <p className="card-text text-center">1080x1920</p>
                          </div>
                        </div>
                        <div className="card card-border  ms-2 me-2">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center ">
                              <img src={img9} className="" alt="" />
                            </div>
                          </div>
                          <div className="card-footer">
                            <h5 className="card-title img-title text-center">
                              Vertical Size
                            </h5>
                            <p className="card-text text-center">1080x1350</p>
                          </div>
                        </div>

                        <div className="card card-border  ms-2 ">
                          <div className="card-body ">
                            <div className="d-flex  justify-content-center ">
                              <img src={img10} className="" alt="" />
                            </div>
                          </div>
                          <div className="card-footer">
                            <h5 className="card-title img-title text-center">
                              Pin Size
                            </h5>
                            <p className="card-text text-center">1000x1500</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3 ">
                <button
                  className="btn btn-prime"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                >
                  Next Step{" "}
                </button>
              </div>
            </div>
            {/* Modal start */}
            <div className="modal modal fade" id="staticBackdrop" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content rounded-5 border-0">
                  <div className="modal-body rounded-5 border-0 p-4">
                    <h6 className=" theme-card-title text-center mb-4">
                      Create a Brand
                    </h6>
                    <h6 className="title text-dark  px-4 mb-4">
                      This is where all yours campaigns for one business will
                      live.
                    </h6>
                    <h6 className="title text-dark px-4 "> Brand Title</h6>
                    <h6 className="px-4">
                      {" "}
                      <input
                        type="text"
                        className="form-control theme-card-border"
                      />
                    </h6>
                    <div className="d-flex justify-content-center mt-3 ">
                      <button
                        className="btn theme-bg-submit text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#weburl"
                      >
                        Submit <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Modal Start */}

            <div className="modal modal fade" id="weburl" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content rounded-5 border-0">
                  <div className="row m-0">
                    <div className="col-11"></div>
                    <div className="col-1 theme-bg-danger theme-close-btn">
                      {" "}
                      <button
                        type="button"
                        class="btn-close theme-bg-danger"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa-regular fa-circle-xmark fw-bold"></i>
                      </button>
                    </div>
                  </div>

                  <div className="modal-body rounded-5 border-0 ">
                    <h6 className=" theme-amount text-dark text-center p-0 m-0  mb-4">
                      Which website would you like to promote?
                    </h6>
                    <h6 className="title text-dark  px-4 my-3">
                      Based on your website, we will generate a briefing for
                      your brand which will be used to guide our AI.
                    </h6>
                    <h6 className="title text-dark  px-4 my-3">
                      This is also the website that will open when users click
                      your ad.
                    </h6>
                    <h6 className="title text-dark px-4 "> Website URL</h6>
                    <h6 className="px-4">
                      {" "}
                      <input
                        type="text"
                        className="form-control theme-card-border"
                      />
                    </h6>
                    <h6 className="text-end px-4 theme-text-danger">
                      Please provide a value for this field.
                    </h6>
                    <div className="d-flex justify-content-center my-3 ">
                      <button
                        className="btn theme-bg-submit text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#g_Summary"
                      >
                        Scan Website <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                    <div className="text-center my-3">
                      <button class=" theme-bg-submit theme-rounded-circle"></button>
                      <button class=" btn-secondary theme-rounded-circle ms-1"></button>
                      <button class=" btn-secondary theme-rounded-circle ms-1"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Third Modal Start */}
            <div className="modal modal fade" id="g_Summary" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content rounded-5 border-0">
                  <div className="row m-0">
                    <div className="col-11"></div>
                    <div className="col-1 theme-bg-danger theme-close-btn">
                      {" "}
                      <button
                        type="button"
                        class="btn-close theme-bg-danger"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa-regular fa-circle-xmark fw-bold"></i>
                      </button>
                    </div>
                  </div>

                  <div className="modal-body rounded-5 border-0 ">
                    <h6 className=" theme-amount text-dark text-center p-0 m-0  mb-4">
                      Generating your business summary...
                    </h6>

                    <h6 className="px-4 my-3">
                      <div className="progress">
                        <div
                          className="progress-bar theme-bg-submit"
                          role="progressbar"
                          style={{ width: "25%" }}
                          aria-valuenow={25}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </h6>

                    <h6 className="title text-dark px-4 my-3 ">
                      {" "}
                      What's the name of your business?
                    </h6>
                    <h6 className="px-4">
                      <div className="form-control py-3">
                        <div className="progress ">
                          <div id="progress-bar" style={{ width: "100%" }}>
                            Loading...
                          </div>
                        </div>
                      </div>
                    </h6>

                    <h6 className="title text-dark px-4 my-3 ">
                      {" "}
                      What's the name of your business?
                    </h6>
                    <h6 className="px-4">
                      <div className="form-control py-3">
                        <div className="progress my-2">
                          <div id="progress-bar" style={{ width: "100%" }}>
                            Loading...
                          </div>
                        </div>
                        <div className="progress my-2">
                          <div id="progress-bar" style={{ width: "100%" }}>
                            Loading...
                          </div>
                        </div>
                        <div className="progress my-2">
                          <div id="progress-bar" style={{ width: "100%" }}>
                            Loading...
                          </div>
                        </div>
                      </div>
                    </h6>

                    <div className="text-center my-3">
                      <button class=" theme-bg-submit theme-rounded-circle"></button>
                      <button class=" theme-bg-submit theme-rounded-circle ms-1"></button>
                      <button
                        class=" btn-secondary theme-rounded-circle ms-1"
                        data-bs-toggle="modal"
                        data-bs-target="#reviewAns"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Fourth Modal Start */}
            <div className="modal modal fade" id="reviewAns" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content rounded-5 border-0">
                  <div className="row m-0">
                    <div className="col-11"></div>
                    <div className="col-1 theme-bg-danger theme-close-btn">
                      {" "}
                      <button
                        type="button"
                        class="btn-close theme-bg-danger"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa-regular fa-circle-xmark fw-bold"></i>
                      </button>
                    </div>
                  </div>

                  <div className="modal-body rounded-5 border-0">
                    <h6 className=" theme-amount text-dark ps-4   mb-4">
                      Here's what we learned about your business
                    </h6>
                    <h6 className="title text-dark  px-4 my-4">
                      Please review and confirm the following answers.
                    </h6>

                    <h6 className="title text-dark px-4 ">
                      {" "}
                      What's the name of your business?
                    </h6>
                    <h6 className="px-4 mb-5">
                      {" "}
                      <input
                        type="text"
                        className="form-control theme-card-border"
                        placeholder="IT Spark Technology"
                      />
                    </h6>

                    <h6 className="title text-dark px-4 "> Description</h6>
                    <h6 className="px-4 mb-5">
                      {" "}
                      <textarea
                        type="text"
                        className="form-control theme-card-border"
                        placeholder="IT Spark Technology"
                      />
                    </h6>
                    <div className="text-center my-3 pt-5 mt-5">
                      <div className="mt-5">
                        <button class=" theme-bg-submit theme-rounded-circle"></button>
                        <button class=" theme-bg-submit  theme-rounded-circle ms-1"></button>
                        <button
                          class=" btn-secondary theme-rounded-circle ms-1"
                          data-bs-toggle="modal"
                          data-bs-target="#pickPlatfrom"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Fifth Modal Start */}
            <div className="modal modal fade" id="pickPlatfrom" tabIndex={-1}>
              <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content rounded-5 border-0">
                  <div className="row m-0">
                    <div className="col-11"></div>
                    <div className="col-1 theme-bg-danger theme-close-btn">
                      {" "}
                      <button
                        type="button"
                        class="btn-close theme-bg-danger"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa-regular fa-circle-xmark fw-bold"></i>
                      </button>
                    </div>
                  </div>

                  <div className="modal-body rounded-5 border-0">
                    <h6 className=" theme-amount  text-dark ps-4   mb-3">
                      Which platform is for you?
                    </h6>
                    <div className=" ps-4 my-3">
                      <button type="button" class="btn theme-platfrom-bg  me-2">
                        <i class="fa-solid fa-g text-white"></i>
                      </button>
                      <button type="button" class="btn theme-platfrom-bg me-2">
                        <i class="fa-brands fa-facebook text-white"></i>
                      </button>
                      <button type="button" class="btn theme-platfrom-bg me-2">
                        <i class="fa-brands fa-instagram text-white"></i>
                      </button>
                      <button type="button" class="btn theme-platfrom-bg ">
                        <i class="fa-brands fa-linkedin text-white"></i>
                      </button>
                    </div>

                    <h6 className="title text-dark px-4 "> Pick a platform</h6>
                    <h6 className="px-4 mb-5">
                      <textarea className="form-control theme-card-border"></textarea>
                    </h6>

                    <div className="text-center my-3 pt-5 mt-5">
                      <div className="mt-5">
                        <button class=" theme-bg-submit theme-rounded-circle"></button>
                        <button class=" theme-bg-submit  theme-rounded-circle ms-1"></button>
                        <button class=" btn-secondary theme-rounded-circle ms-1"></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SelectBrand;
