import React from 'react'

export default function UploadIcon2() {
    return (
        <svg style={{marginLeft:"20px"}} width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 18.8887H13.5C14.325 18.8887 15 18.2137 15 17.3887V9.88867H17.385C18.72 9.88867 19.395 8.26867 18.45 7.32367L11.565 0.438666C11.4262 0.299611 11.2614 0.189289 11.0799 0.114017C10.8985 0.0387449 10.704 0 10.5075 0C10.311 0 10.1165 0.0387449 9.93506 0.114017C9.7536 0.189289 9.58877 0.299611 9.45 0.438666L2.565 7.32367C1.62 8.26867 2.28 9.88867 3.615 9.88867H6V17.3887C6 18.2137 6.675 18.8887 7.5 18.8887ZM1.5 21.8887H19.5C20.325 21.8887 21 22.5637 21 23.3887C21 24.2137 20.325 24.8887 19.5 24.8887H1.5C0.675 24.8887 0 24.2137 0 23.3887C0 22.5637 0.675 21.8887 1.5 21.8887Z" fill="#358F8B" />
        </svg>

    )
}
