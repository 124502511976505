// NoPageFound.js
import React from 'react';
import noData from "../assets/images/backgroundImg/404image.png"

const NoPageFound = () => {
  return (
    <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        flexDirection: 'column', 
        textAlign: 'center', 
        padding: '20px' 
      }}>
        <h1>404 - Page Not Found</h1>
        <img 
          src={noData} 
          alt="Page Not Found" 
          style={{ 
            width: '100%', 
            maxWidth: '400px', 
            height: 'auto' 
          }} 
        />
      </div>
      
  );
};

export default NoPageFound;
