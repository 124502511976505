import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";

import "./Payments.css"


function Payments() {
    return (
        <>
 <div className="row  h-100 m-0 p-0">
                <div className="col-sm-2 sidebar-bg-color px-0 mx-0 sidebar-border">
                    <div className="">
                        < Sidebar className=""/>
                    </div>
                </div>
                <div className="col-sm-10 bg-white">
                    {/* <div className="container-fluid"> */}
                    {/* <div className="container-fluid"> */}
                    <div className="container">
                       
 <div className="container">
                        <Header title="Payment Details" btnname="Upgrade Plan"/>
                    </div>
            <div className="row pt-5 m-0">

                <div className="col-md-6 pt-2">
                    <div className="theme-card-border">
                        <h6 className="theme-card-title mt-2 ms-2">Summary</h6>
                        <div className="row align-items-center pb-4 pt-4 m-0">
                            <div className="col-sm-4 theme-bg-info">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="title text-dark">Paid</h6>
                                    <div><span className="theme-amount">0.00</span><br />
                                        <span className="title text-dark">US$</span></div>
                                </div>
                            </div>
                            <div className="col-sm-4 theme-bg-warning">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="title text-dark">Spent</h6>
                                    <div><span className="theme-amount">0.00</span><br />
                                        <span className="title text-dark">US$</span></div>
                                </div>
                            </div>
                            <div className="col-sm-4 theme-bg-danger">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="title text-dark">Allocated</h6>
                                    <div><span className="theme-amount">0.00</span><br />
                                        <span className="title text-dark">US$</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 pt-2 ">
                    <div className="theme-card-border">
                        <h6 className="theme-card-title mt-2 ms-2">Credit</h6>
                        <div className="row align-items-center pb-4 pt-4 m-0">
                            <div className="col theme-bg-info">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="title text-dark">Available credit</h6>
                                    <div><span className="theme-amount">0.00</span><br />
                                        <span className="title text-dark">US$</span></div>
                                </div>
                            </div>
                            <div className="col theme-bg-primary">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="title text-dark">Spent</h6>
                                    <div><span className="theme-amount">0.00</span><br />
                                        <span className="title text-dark">US$</span></div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>



            </div>


            <div className="row  m-0 mt-5 h-100">
                <div className="col-sm-12 ">
                    <div className=" theme-empty-box card">
                        <div className="row p-3">
                            <div className="col-sm-6">
                                <h6 className="theme-card-title ">Event</h6>
                            </div>
                            <div className="col-sm-3">
              
                                <h6 className="theme-card-title m-0 p-0">Brand</h6>
                                <select className="form-control form-select theme-card-border m-0">
                                    <option>Select Brand</option>
                                    <option> Brand -1</option>
                                    <option> Brand -2</option>
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <h6 className="theme-card-title m-0 p-0">Time Rang</h6>
                                <select className="form-control form-select theme-card-border">
                                    <option>Select Brand</option>
                                    <option> Last 30 Days</option>
                                    <option> Last 15 Days</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    </div>
                </div>
            </div>






        </>
    )
}
export default Payments