import React from 'react'

export default function UploadPlusIcon() {
    return (
        <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_di_70_472)">
                <circle cx="33.8164" cy="33.8164" r="24.804" fill="#47BEB9" stroke="url(#paint0_linear_70_472)" stroke-width="2.02482" />
                <path d="M44.4474 34.7533H37.0852V42.2978H33.2219V34.7533H25.8596V31.2544H33.2219V23.71H37.0852V31.2544H44.4474V34.7533Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_di_70_472" x="0.913144" y="0.913144" width="75.9306" height="75.9306" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5.06204" dy="5.06204" />
                    <feGaussianBlur stdDeviation="6.07445" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_70_472" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_70_472" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="7.08686" dy="9.11167" />
                    <feGaussianBlur stdDeviation="8.60547" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_70_472" />
                </filter>
                <linearGradient id="paint0_linear_70_472" x1="33.8164" y1="8" x2="33.8164" y2="59.6328" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    )
}
