import React from 'react'

export default function PlusIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0005 4.16595V15.8336" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.16699 9.99969H15.8347" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}
