import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const CustomModal = ({ children ,modalVisible,setModalVisible,setModalNo }) => {
  // const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  const modalStyle = {
    display: modalVisible ? "block" : "none",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    maxHeight: "1900px",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  };

  const modalContentStyle = {
    backgroundColor: "transparent",
    padding: "20px",
    border: "1px solid #888",
    width: "1200px",
    height: "auto",
  };

  const closeStyle = {
    color: "#aaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
  };

  return (
    <>
      {/* <button id="myBtn" onClick={openModal}>
        Open Modal
      </button> */}
      {modalVisible && (
        <div
          id="myModal"
          className="modal"
          style={modalStyle}
          onClick={handleOutsideClick}
        >
          <div className="modal-content" style={modalContentStyle}>
            <span className="close" style={closeStyle} onClick={closeModal}>
              &times;
            </span>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;
