import React from 'react';
import {  Navigate, Outlet } from 'react-router-dom';
import Cookies from "js-cookie";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  

    const cookiesId = Cookies.get('session_id');
      const sessionId =sessionStorage.getItem("session_id")

    
    if (cookiesId !== sessionId) {
        // Redirect to the login page if not authenticated
        return <Navigate to="/login" />;
    }

    // Render the child routes if authenticated
    return <Outlet />;
};

export default ProtectedRoute;
