// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main {
   padding: 70px 0;
  text-align: center;
}

.logoTitle {
    font-family: 'Aclonica';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 57px;
}
.getStarted {
    font-size: 16px;
    color: #FFFFFF;
}

.login-box {
    width: 280px;
    height: 50px;
    justify-content: center;
    display: block;
    background: #B37FCA;
    border-radius: 15px;
}

.signWith {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: #FFFFFF;
}

.term-service {
    width: 280px;
    height: 50px;
    justify-content: center;
    /* display: block; */
}

/* Login Page Css Start */
.horizontal-line {
    width: 50px;
    height: 1px;
    background-color: black;
  }`, "",{"version":3,"sources":["webpack://./src/component/account/SignUp.css"],"names":[],"mappings":";AACA;GACG,eAAe;EAChB,kBAAkB;AACpB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,cAAc;IACd,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;IAC5B,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA,yBAAyB;AACzB;IACI,WAAW;IACX,WAAW;IACX,uBAAuB;EACzB","sourcesContent":["\r\n.main {\r\n   padding: 70px 0;\r\n  text-align: center;\r\n}\r\n\r\n.logoTitle {\r\n    font-family: 'Aclonica';\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    font-size: 50px;\r\n    line-height: 57px;\r\n}\r\n.getStarted {\r\n    font-size: 16px;\r\n    color: #FFFFFF;\r\n}\r\n\r\n.login-box {\r\n    width: 280px;\r\n    height: 50px;\r\n    justify-content: center;\r\n    display: block;\r\n    background: #B37FCA;\r\n    border-radius: 15px;\r\n}\r\n\r\n.signWith {\r\n    font-family: 'Poppins';\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    font-size: 16px;\r\n    line-height: 24px;\r\n    /* identical to box height */\r\n    color: #FFFFFF;\r\n}\r\n\r\n.term-service {\r\n    width: 280px;\r\n    height: 50px;\r\n    justify-content: center;\r\n    /* display: block; */\r\n}\r\n\r\n/* Login Page Css Start */\r\n.horizontal-line {\r\n    width: 50px;\r\n    height: 1px;\r\n    background-color: black;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
