export const PlusSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M10.9972 4.16602V15.8337"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.16357 9.99988H16.8312"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const DownloadSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M18.4994 12.5V15.8336C18.4994 16.2756 18.3238 16.6996 18.0112 17.0122C17.6987 17.3248 17.2747 17.5004 16.8326 17.5004H5.16508C4.72302 17.5004 4.29907 17.3248 3.98648 17.0122C3.6739 16.6996 3.49829 16.2756 3.49829 15.8336V12.5"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.83191 8.33289L10.9989 12.4999L15.166 8.33289"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9988 12.5002V2.49939"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const Circles = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_1123_27721)">
      <path
        d="M7.99986 14.6666C11.6817 14.6666 14.6665 11.6818 14.6665 7.99998C14.6665 4.31811 11.6817 1.33337 7.99986 1.33337C4.31799 1.33337 1.33325 4.31811 1.33325 7.99998C1.33325 11.6818 4.31799 14.6666 7.99986 14.6666Z"
        stroke="#358F8B"
        stroke-width="1.66678"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
        stroke="#358F8B"
        stroke-width="1.66678"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99999 9.33348C8.73646 9.33348 9.33348 8.73646 9.33348 7.99999C9.33348 7.26353 8.73646 6.6665 7.99999 6.6665C7.26353 6.6665 6.6665 7.26353 6.6665 7.99999C6.6665 8.73646 7.26353 9.33348 7.99999 9.33348Z"
        stroke="#358F8B"
        stroke-width="1.66678"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1123_27721">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Star = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M10.0008 1.66589L12.576 6.88296L18.3348 7.72469L14.1678 11.7833L15.1512 17.5171L10.0008 14.8086L4.85045 17.5171L5.83385 11.7833L1.66687 7.72469L7.42564 6.88296L10.0008 1.66589Z"
      stroke="#EDF9F8"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const Edit = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.99976 16.6667H17.5003"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7502 2.91576C14.0818 2.58421 14.5315 2.39795 15.0003 2.39795C15.2325 2.39795 15.4624 2.44368 15.6769 2.53252C15.8914 2.62137 16.0863 2.75159 16.2504 2.91576C16.4146 3.07993 16.5448 3.27482 16.6337 3.48931C16.7225 3.70381 16.7683 3.9337 16.7683 4.16587C16.7683 4.39803 16.7225 4.62793 16.6337 4.84242C16.5448 5.05691 16.4146 5.25181 16.2504 5.41597L5.83289 15.8335L2.49927 16.6669L3.33267 13.3333L13.7502 2.91576Z"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const HeaderEdit = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.99976 16.6667H17.5003"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7502 2.91576C14.0818 2.58421 14.5315 2.39795 15.0003 2.39795C15.2325 2.39795 15.4624 2.44368 15.6769 2.53252C15.8914 2.62137 16.0863 2.75159 16.2504 2.91576C16.4146 3.07993 16.5448 3.27482 16.6337 3.48931C16.7225 3.70381 16.7683 3.9337 16.7683 4.16587C16.7683 4.39803 16.7225 4.62793 16.6337 4.84242C16.5448 5.05691 16.4146 5.25181 16.2504 5.41597L5.83289 15.8335L2.49927 16.6669L3.33267 13.3333L13.7502 2.91576Z"
      stroke="white"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);


export const CovertSvg=(<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20" fill="none">
<g clip-path="url(#clip0_3703_2967)">
  <path d="M19.1634 3.33264V8.33365H14.1624" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.0713 12.5001C16.5296 14.0335 15.5041 15.3493 14.1495 16.2492C12.7949 17.1491 11.1846 17.5843 9.56107 17.4893C7.93758 17.3943 6.38894 16.7743 5.14851 15.7226C3.90808 14.6709 3.04307 13.2445 2.68383 11.6584C2.32459 10.0723 2.49058 8.41247 3.15678 6.92892C3.82299 5.44538 4.95332 4.21854 6.37744 3.4333C7.80156 2.64805 9.44232 2.34693 11.0525 2.57532C12.6626 2.80371 14.1549 3.54922 15.3045 4.69953L19.1631 8.33314" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
  <clipPath id="clip0_3703_2967">
    <rect width="20.0017" height="20.0017" fill="white" transform="translate(-0.00512695 -0.000854492)"/>
  </clipPath>
</defs>
</svg>)