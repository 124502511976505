import React, { useEffect,  useState } from "react";
import styles from "./Payments.module.css";
import ReactPaginate from "react-paginate";

import { Col, Container, Row } from "react-bootstrap";
// import { CommonBtn } from "../../../UI-Components/ReusableComponents";
// import { DownloadSvg } from "../../../UI-Components/ReUsableSVG";
import { InfoSvg } from "./Svg";
import PaymentBg from "../../../account/Main Pages/Images/Paymentsbg.png";
// import { GoogleAds, googleSvg, inceasingSvg } from "../Campaigns/Home Campaigns/SVG";
// import { PauseIcon, PlayIcon } from "../Campaigns/Performance Campaigns/Ad Traffice Only/SVg";
import axios from "../../../axios/auth";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
// import { googleIcon } from "../Campaigns/FacebookCampaign/SVG";
import { FacebookAdsSvg, InstagramAdsSvg } from "../../../../LandingPage/Images/landingSvg";
import nodData from "./nodata.jpg"
const Payments = () => {
  const [toggle, setToggle] = useState(false);
const [planHistory,setPlanHistory] = useState([])
const [googleData,setgoogleData] = useState([])
const [fecbookData,setFacebookData] = useState([])
const [instaData,setInstaData] = useState([])
const [selectedValue, setSelectedValue] = useState("plans");
const [totalAmount,setTotalAmount] = useState(0)
  const [bgColor, setBgColor] = useState("#adafb1");
  const [textColor, setTextColor] = useState("#000"); // Default text color
const token  = Cookies.get("token");
const user = useSelector((state) => state.loginuser.user);
let { coinMangment } = useSelector((state) => state.addcreative);


const ITEMS_PER_PAGE = 5; // Change this to your desired number of items per page

  const [currentPage, setCurrentPage] = useState(0);
  const [dataType, setDataType] = useState(selectedValue);

  const handlePageClick = (data) => {

    setCurrentPage(data.selected);
  };

  const getPaginatedData = (data) => {
    if(data){

    const start = currentPage * ITEMS_PER_PAGE;
    return data.slice(start, start + ITEMS_PER_PAGE);

    }
  };

  const paginatedData = {
    plans: getPaginatedData(planHistory),
    google: getPaginatedData(googleData),
    facebook: getPaginatedData(fecbookData),
    instagram: getPaginatedData(instaData),
  };

  const totalPages = {
    plans: Math.ceil(planHistory.length / ITEMS_PER_PAGE),
    google: Math.ceil(googleData.length / ITEMS_PER_PAGE),
    facebook: Math.ceil(fecbookData.length / ITEMS_PER_PAGE),
    instagram: Math.ceil(instaData.length / ITEMS_PER_PAGE),
  };
useEffect(()=>{
  console.log(selectedValue,"value se");
  
  
  if(selectedValue==="plans"){
    setCurrentPage(0)
    setSelectedValue("plans")
    setDataType("plans")

  fetchPlandata()
  }
  if(selectedValue==="google"){
    setCurrentPage(0)
    setSelectedValue("google")
    setDataType("google")

    fetchGoogledata()
  }
  if(selectedValue==="facebook"){
    setCurrentPage(0)
    setSelectedValue("facebook")
    setDataType("facebook")

    fetchFacebookdata()
  }
  if(selectedValue==="instagram"){
    setCurrentPage(0)
    setSelectedValue("instagram")
    setDataType("instagram")



    fetchInstagramdata()
  }
},[selectedValue])




    const fetchPlandata = async () =>{
      try{
      const res = await axios.get("getUserPlanHistory",{
        headers:{
          "Authorization" : `Bearer ${token}`
        }
      });

      if (res?.data.status===true) {
         setPlanHistory(res?.data?.data)
        
      } else {
          toast.error(res?.data?.message, {
              className: 'custom-toast-error',
              hideProgressBar: true
          });
      }
  } 
     catch (error) {
      // toast.error("Invalid Credential");
      toast.error("Something went wrong", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }
  }

  const fetchGoogledata = async () =>{
    try{
    const res = await axios.get("/get-ads-payment",{
      headers:{
        "Authorization" : `Bearer ${token}`
      }
    });

    if (res?.data.status===true) {
      setgoogleData(res?.data?.data)
      
    } else {
        toast.error(res?.data?.message, {
            className: 'custom-toast-error',
            hideProgressBar: true
        });
    }
} 
   catch (error) {
    // toast.error("Invalid Credential");
    toast.error("Something went wrong", {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
  }
}

const fetchFacebookdata = async () =>{
  try{
  const res = await axios.get("/get-facebook-ads-payment",{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  });

  if (res?.data.status===true) {
    setFacebookData(res?.data?.data)
    
  } else {
      toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
      });
  }
} 
 catch (error) {
  // toast.error("Invalid Credential");
  toast.error("Something went wrong", {
    className: 'custom-toast-error',
    hideProgressBar: true
  });
}
}

const fetchInstagramdata = async () =>{
  try{
  const res = await axios.get("/get-instagram-ads-payment",{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  });

  if (res?.data.status===true) {
    setInstaData(res?.data?.data)
    
  } else {
      toast.error(res?.data?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
      });
  }
} 
 catch (error) {
  // toast.error("Invalid Credential");
  toast.error("Something went wrong", {
    className: 'custom-toast-error',
    hideProgressBar: true
  });
}
}

  useEffect(() => {
    if (selectedValue === "plans") {
      let total = planTotalAmount();
      
      setTotalAmount(total);
    }
    if (selectedValue === "google") {
      let total = googleTotalAmount();
      
      setTotalAmount(total);
    }
    if (selectedValue === "facebook") {
      let total = facebookTotalAmount();
      
      setTotalAmount(total);
    }
    if (selectedValue === "instagram") {
      let total = instaTotalAmount();
      
      setTotalAmount(total);
    }
  }, [selectedValue,planHistory,googleData,instaData,fecbookData]); 

  const planTotalAmount = () => {
    return planHistory?.reduce((total, plan) => {
      return total + (plan.amount || 0); // Ensure amount exists and add it to the total
    }, 0); // Initialize total to 0
  };

  const googleTotalAmount = () => {
    return googleData?.reduce((total, plan) => {
      return total + (plan.amount || 0); // Ensure amount exists and add it to the total
    }, 0); // Initialize total to 0
  };
  

  const facebookTotalAmount = () => {
    return fecbookData?.reduce((total, plan) => {
      return total + (plan.amount || 0); // Ensure amount exists and add it to the total
    }, 0); // Initialize total to 0
  };


  const instaTotalAmount = () => {
    return instaData?.reduce((total, plan) => {
      return total + (plan.amount || 0); // Ensure amount exists and add it to the total
    }, 0); // Initialize total to 0
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(event.target.value);
    
    // setSelectedValue(value);
    
    // Update background color based on the selected value
    switch (value) {
      case "google":
        setBgColor("#2bb444");
        setTextColor("#2bb444");
         // Google blue
        break;
      case "facebook":
        setBgColor("#3b5998"); 
        setTextColor("#3b5998");// Facebook blue
        break;
      case "instagram":
        setBgColor("#E1306C");
        setTextColor("#E1306C"); // Instagram pink
        break;
      default:
        setBgColor("#61c9d6");
        setTextColor("#000"); 
         // Default color
        break;
    }// Update the state with the selected option
  };


  // const Select2 = ["Campaign 1", "Campaign 2", "Campaign 3", "Campaign 4"];
  // const Select3 = [
  //   "Last 7 days",
  //   "Last 14 days",
  //   "Last 30 days",
  //   "Last 1 Month",
  //   "Last 3 Months",
  // ];

  const currencySymbol = (currency) => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'INR':
        return '₹';
      case 'EUR':
        return '€';
      default:
        return '';
    }
  };

   
  // Function to convert the date string to the desired format
function formatDate(dateString) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  // Parse the date string
  const date = new Date(dateString);
  
  // Get the day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  
  // Format the date as "DD MMM YYYY"
  return `${day} ${month} ${year}`;
}


const truncateText = (text, wordLimit) => {
  const words = text?.split(' ');
  if (words?.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};

  return (
    <>
      <Container fluid className={styles.adContainer}>
        <Row>
          <p className={styles.paymentsHeading}>Payments Summary</p>
        </Row>
        <Row className="p-0 m-0"
          style={{ maxWidth: "1200px", width: "100%", justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              gap: "24px",
              position: "relative",
              background: `url(${PaymentBg}) lightgray 0% 0% / 54.017215967178345px 54.017215967178345px `,
              objectFit: "cover",
              backgroundSize: "100% 100%",
            }}
            className={styles.PaymentsMain}
          >
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }}
                className={styles.mobileViewBoxAlignment}
              >
                <div className={styles.mobileWidth}>
                  <h4 className={styles.PaymentsHeading}>Summary</h4>
                  {selectedValue==="plans"&&
                     <div
                     className={styles.PaymentsSummeryBox}
                     style={{ width: "200px" }}
                   >
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6>{currencySymbol(user?.plan[0]?.currency)} {totalAmount}</h6>
                       <p>Paid</p>
                     </div>
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6> 0.00</h6>
                       <p>Refunded</p>
                     </div>
                     {/* <div className={styles.PaymentsSummeryItem}>
                       <h6>€ 0.00</h6>
                       <p>Allocated</p>
                     </div> */}
                   </div>}
                   {selectedValue==="google"&&
                     <div
                     className={styles.PaymentsSummeryBox}
                     style={{ width: "200px" }}
                   >
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6>{currencySymbol(user?.plan[0]?.currency)} {totalAmount}</h6>
                       <p>Paid</p>
                     </div>
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6> 0.00</h6>
                       <p>Refunded</p>
                     </div>
                     {/* <div className={styles.PaymentsSummeryItem}>
                       <h6>€ 0.00</h6>
                       <p>Allocated</p>
                     </div> */}
                   </div>}
                   {selectedValue==="facebook"&&
                     <div
                     className={styles.PaymentsSummeryBox}
                     style={{ width: "200px" }}
                   >
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6>{currencySymbol(user?.plan[0]?.currency)} {totalAmount}</h6>
                       <p>Paid</p>
                     </div>
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6> 0.00</h6>
                       <p>Refunded</p>
                     </div>
                     {/* <div className={styles.PaymentsSummeryItem}>
                       <h6>€ 0.00</h6>
                       <p>Allocated</p>
                     </div> */}
                   </div>}
                   {selectedValue==="instagram"&&
                     <div
                     className={styles.PaymentsSummeryBox}
                     style={{ width: "200px" }}
                   >
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6>{currencySymbol(user?.plan[0]?.currency)} {totalAmount}</h6>
                       <p>Paid</p>
                     </div>
                     <div
                       className={styles.PaymentsSummeryItem}
                       style={{
                         borderBottom: "0.5px solid #7f60f9",
                       }}
                     >
                       <h6> 0.00</h6>
                       <p>Refunded</p>
                     </div>
                     {/* <div className={styles.PaymentsSummeryItem}>
                       <h6>€ 0.00</h6>
                       <p>Allocated</p>
                     </div> */}
                   </div>}
                </div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <div className={styles.mobileWidth}>
                  <h4
                    className={styles.PaymentsHeading}
                    style={{ position: "relative" }}
                  >
                    Credit
                    <i
                      style={{
                        position: "absolute",
                        top: -15,
                        cursor: "pointer",
                      }}
                      onClick={() => setToggle(!toggle)}
                    >
                      {InfoSvg}
                    </i>
                  </h4>
                  <div
                    className={styles.PaymentsSummeryBox}
                    style={{ width: "200px" }}
                  >
                    <div
                      className={styles.PaymentsSummeryItem}
                      style={{
                        borderBottom: "0.5px solid #7f60f9",
                      }}
                    >
                      <h6>{coinMangment}</h6>
                      <p>Available</p>
                    </div>
                    <div
                      className={styles.PaymentsSummeryItem}
                      style={{
                        borderBottom: "0.5px solid #7f60f9",
                      }}
                    >
                      <h6>{user?.plan[0]?.fixedCredit-coinMangment}</h6>
                      <p>Spent</p>
                    </div>
                    <div
                      className={styles.PaymentsSummeryItem}
                      style={{
                        borderBottom: "0.5px solid #7f60f9",
                      }}
                    >
                      <h6>{user?.plan[0]?.fixedCredit}</h6>
                      <p>Allocated</p>
                    </div>
                  </div>
                </div>
              </div>
            
            </Col>
          </div>
       
          <div className={styles.PaymentSelectMain}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className={styles.paymentsEvent}>Events</p>

              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
      <select
        className={styles.customselect}
        aria-label="Default select example"
        value={selectedValue}
        onChange={handleChange}
        style={{ border: `1px solid ${bgColor}`, color: textColor,   }}
      >
        <option value="plans">Plans</option>
        <option value="google">Google</option>
        <option value="facebook">Facebook</option>
        <option value="instagram">Instagram</option>
      </select>
    </div>
             


            </div>
          </div>
        </Row>
        <Row></Row>
      </Container>

      {dataType === "plans" && (
  paginatedData?.plans.length > 0 ? (
    paginatedData?.plans?.map((data, index) => (
      <Container
        key={index}
        fluid
        className="main-container-camp"
        style={{ marginBottom: "10px", width: "90%" }}
      >
        <Row className="row-gap">
          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Plan Name</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={data?.name} />
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Plan Type</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={`${data?.name === "Free Plan" ? "7 Days" : data?.subscriptionType}`} />
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={1}>
            <p className="camp-div1-p">Credits</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={data?.fixedCredit} />
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={1}>
            <p className="camp-div1-p">Amount</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={` ${currencySymbol(data?.currency)} ${data?.amount}`} />
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Purchase Date</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={formatDate(data?.createdAt)} />
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Status</p>
            <div className="camp-div1-bottom">
              <p style={{
                color: data?.isDeleted ? "red" : "green",
                fontSize: "1rem",
                fontWeight: "bold",
                borderRadius: "10px",
                textAlign: "center",
              }}>
                {`${data?.isDeleted ? "InActive" : "Active"}`}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    ))
  ) : (
    <div className="no-data-container" style={{ textAlign: "center", margin: "20px 0" }}>
      <img
        src={nodData} // Replace with your actual image path
        alt="No Data Available"
        style={{ width: "100%", maxWidth: "300px", height: "auto" }} // Responsive styling
      />
      {/* <p>No Data Available</p> */}
    </div>
  )
)}


      {dataType === "plans" &&  planHistory?.length > 5 &&  (
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages.plans}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={styles.pagi}
        pageClassName={styles.pageitem}
        pageLinkClassName={styles.pagelink}
        previousClassName={styles.previous}
        previousLinkClassName={styles.pagelink}
        nextClassName={styles.next}
        nextLinkClassName={styles.pagelink}
        breakClassName={styles.break}
        breakLinkClassName={styles.pagelink}
        activeClassName={styles.active}
      />
    )}


{dataType === "google" && (
  paginatedData?.google?.length > 0 ? (
    paginatedData?.google?.map((data, index) => (
      <Container
        key={index}
        fluid
        className="main-container-camp"
        style={{ marginBottom: "10px", width: "90%" }}
      >
        <Row className="row-gap">
          <Col className="main-container-camp-div1" lg={4}>
            <p className="camp-div1-p">Ad Type</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={data?.adName} />
              {/* <span>{truncateText(data?.adName, 10)}</span> */}
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={1}>
            <p className="camp-div1-p">Amount</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={` ${currencySymbol(data?.currency)} ${data?.amount}`} />
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Start Date</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={formatDate(data?.createdAt)} />
            </div>
          </Col>
          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Status</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={`${data?.paymentStatus === "paid" ? "Paid" : "Unpaid"}`} />
            </div>
          </Col>
        </Row>
      </Container>
    ))
  ) : (
    <div className="no-data-container" style={{ textAlign: "center", margin: "20px 0" }}>
      <img
        src={nodData} // Replace with your actual image path
        alt="No Data Available"
        style={{ width: "100%", maxWidth: "300px", height: "auto" }} // Responsive styling
      />
      {/* <p>No Data Available</p> */}
    </div>
  )
)}


{dataType === "google" && googleData?.length > 5 && (
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages.google}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={styles.pagi}
        pageClassName={styles.pageitem}
        pageLinkClassName={styles.pagelink}
        previousClassName={styles.previous}
        previousLinkClassName={styles.pagelink}
        nextClassName={styles.next}
        nextLinkClassName={styles.pagelink}
        breakClassName={styles.break}
        breakLinkClassName={styles.pagelink}
        activeClassName={styles.active}
      />
    )}



  

{dataType === "facebook" && (
  paginatedData?.facebook.length > 0 ? (
    paginatedData.facebook.map((data, index) => (
      <Container
        key={index}
        fluid
        className="main-container-camp"
        style={{ marginBottom: "10px", width: "90%" }}
      >
        <Row className="row-gap">
          <Col className="main-container-camp-div1" lg={4}>
            <p className="camp-div1-p">Ad Type</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={FacebookAdsSvg} /><span>{truncateText(data?.adName, 10)}</span>
            </div>
          </Col>
  
          <Col className="main-container-camp-div1" lg={1}>
            <p className="camp-div1-p">Amount</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={`${currencySymbol(data?.currency)} ${data?.amount}`} />
            </div>
          </Col>

          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Start Date</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={formatDate(data?.createdAt)} />
            </div>
          </Col>

          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Status</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={`${data?.paymentStatus === "paid" ? "Paid" : "Unpaid"}`} />
            </div>
          </Col>
        </Row>
      </Container>
    ))
  ) : (
    <div className="no-data-container" style={{ textAlign: "center", margin: "20px 0" }}>
      <img
        src={nodData} // Replace with your actual image path
        alt="No Data Available"
        style={{ width: "100%", maxWidth: "300px", height: "auto" }} // Responsive styling
      />
      {/* <p>No Data Available</p> */}
    </div>
  )
)}



{dataType === "facebook" && fecbookData?.length > 5 && (
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages.facebook}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={styles.pagi}
        pageClassName={styles.pageitem}
        pageLinkClassName={styles.pagelink}
        previousClassName={styles.previous}
        previousLinkClassName={styles.pagelink}
        nextClassName={styles.next}
        nextLinkClassName={styles.pagelink}
        breakClassName={styles.break}
        breakLinkClassName={styles.pagelink}
        activeClassName={styles.active}
      />
    )}



       
{dataType === "instagram" && (
  paginatedData?.instagram?.length > 0 ? (
    paginatedData?.instagram?.map((data, index) => (
      <Container
        key={index}
        fluid
        className="main-container-camp"
        style={{ marginBottom: "10px", width: "90%" }}
      >
        <Row className="row-gap">
          <Col className="main-container-camp-div1" lg={4}>
            <p className="camp-div1-p">Ad Type</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={InstagramAdsSvg} /><span>{truncateText(data?.adName, 10)}</span>
            </div>
          </Col>

          <Col className="main-container-camp-div1" lg={1}>
            <p className="camp-div1-p">Amount</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={`${currencySymbol(data?.currency)} ${data?.amount}`} />
            </div>
          </Col>

          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Start Date</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={formatDate(data?.createdAt)} />
            </div>
          </Col>

          <Col className="main-container-camp-div1" lg={2}>
            <p className="camp-div1-p">Status</p>
            <div className="camp-div1-bottom">
              <Google googleSvg={`${data?.paymentStatus === "paid" ? "Paid" : "Unpaid"}`} />
            </div>
          </Col>
        </Row>
      </Container>
    ))
  ) : (
    <div className="no-data-container" style={{ textAlign: "center", margin: "20px 0" }}>
      <img
        src={nodData} // replace with your actual image path
        alt="No Data Available"
        style={{ width: "100%", maxWidth: "300px", height: "auto" }} // Responsive styling
      />
      {/* <p>No Data Available</p> */}
    </div>
  )
)}



     {dataType === "instagram" && instaData?.length > 5 &&(
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={totalPages?.instagram}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={styles.pagi}
        pageClassName={styles.pageitem}
        pageLinkClassName={styles.pagelink}
        previousClassName={styles.previous}
        previousLinkClassName={styles.pagelink}
        nextClassName={styles.next}
        nextLinkClassName={styles.pagelink}
        breakClassName={styles.break}
        breakLinkClassName={styles.pagelink}
        activeClassName={styles.active}
      />
    )}


    </>
  );
};

// const BoxItems = ({ label, amount }) => {
//   return (
//     <div className={styles.paymentsItem}>
//       <h5>{label}</h5>
//       <p>{`€${amount} `}</p>
//     </div>
//   );
// };

// const Select = ({ selectHeading, options }) => {
//   const dropdownRef = useRef();

//   const handleDropdownClick = () => {
//     dropdownRef.current.classList.add("clicked");
//   };
//   return (
//     <select
//       className={styles.paymentSelect}
//       ref={dropdownRef}
//       onClick={handleDropdownClick}
//     >
//       <option
//         value=""
//         className="placeholder"
//         style={"clicked" ? { display: "none" } : {}}
//       >
//         {selectHeading}
//       </option>
//       {options.map((item, i) => (
//         <>
//           <option value={i + 1}>{item} </option>
//         </>
//       ))}
//     </select>
//   );
// };

export default Payments;

// const Center = ({ title, Svg, value, color, label }) => {
//   return (
//     <div className="center-container">
//       <p className="camp-div2-p">{title}</p>
//       <div className="progress-div">
//         {Svg}
//         <p className="progress-text" style={{ color: color }}>
//           {value ? Math.round(value) : label}
//         </p>
//       </div>
//     </div>
//   );
// };



const Google = ({ googleSvg, className }) => {
  return (
    <div className={`camp-div1-bottom ${className}`}>
      <div className="camp-div1-bottom-container">
        {googleSvg}
      </div>
    </div>
  );
};

