import React, { useEffect, useState } from 'react';
// import TestingPage from '../../../../Testing/TestingPage';
import dashboard from "../../Auth Pages/Images/dashboard.png";
// import LoadingTextAnimation, {
//     AILoaderSvg,
// } from '../../../../Testing/LoadingAnimation';

import "../../../../Testing/Testing.css";
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../axios/auth';
import Cookies from "js-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { addFaceBookDeatils } from '../../../../Redux/CampaignDetailSlice';
import { addFaceBookSuggestion } from '../../../../Redux/AiSuggestion';
import { AddTargetingDetailsFacebook } from '../../../../Redux/FacebbokAdsSlice';
// Testing.css

const AdsLoadFacebook = () => {
const [adId,setAdId] = useState()
    const [adsDetails, setAdsDetails] = useState(null)
    const [formDetails,setFormDetails] =  useState() 
    const dispatch = useDispatch()
    const { id } = useParams();
    const userToken = Cookies.get("token");
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.loginuser);

    let currency=user?.plan[0]?.currency ;
    useEffect(() => {
        loadertext();
        // Parsing the URL
        let urlParams = new URLSearchParams(window.location.search);
        // Getting the value of the 'adType' parameter
        let adParams = urlParams.get("adType");
       setAdId(sessionStorage.getItem("adId"))
        if(adParams === "facebook"){
            setAdsDetails( sessionStorage.getItem("facebookAdsComplateData"))
            setFormDetails(sessionStorage.getItem("fbData"))
        }
      
    }, []);

    // /createads/
    const createAd = async () => {
        try {
            const res = await axios.post(`/create-facebook-ads/${id}`, { adsDetails ,formDetails,currency,adId}, {
                headers: {
                    Authorization: `Bearer ${userToken}`, // Correct spelling
                },
            });

            if (res?.data?.status === true) {
                sessionStorage.removeItem("websiteUrl")
                sessionStorage.removeItem("websiteTitle")
                sessionStorage.removeItem("websiteDetail")
                sessionStorage.removeItem("account_id")
                sessionStorage.removeItem("adId")
                sessionStorage.removeItem("facebookAdsComplateData")
                dispatch(addFaceBookDeatils({ headline: '',
                  descriptions: '',
                  image: '',}))
              
                  dispatch(
                    addFaceBookSuggestion({
                      descriptions: '',
                      headlines: '',
                      keyword: '',
                    })
                  );
              
                  dispatch(AddTargetingDetailsFacebook({
              
                  }))
                // navigate("/main/campaign/?status=true", { replace: true })
                navigate("/main/campignLoder?adType=Facebook")


            } else if (res?.data?.status !== true) {
                navigate("/main/campaign/?status=false", { replace: false })

            }

        } catch (e) {
            navigate("/main/campaign/?status=false", { replace: false })

        }
    }
useEffect(()=>{
    if(adsDetails&&id&&adId){
        createAd()
    }
    
},[adsDetails])






    return (
        
        <div
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "999",
                backgroundImage: `url(${dashboard})`,
                backgroundSize: "cover",
                backgroundPosition: "center",

            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    position: "relative", // Added position property
                    backdropFilter: "blur(1px)", // Adjusted blur value
                    // background: "transparent",
                    zIndex: "100",
                    background: "rgba(0, 0, 0, 0.3)",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "40vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#edf9f8",
                        borderStartStartRadius: "50%",
                        borderStartEndRadius: "50%",
                    }}
                >
                    <div class="text-container">
                        <>
                            <div id="loadingText" class="loading-text">
                                Facebook Ad. Running
                            </div>
                        </>

                        <div className="AI-Loader-Text ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 16 16" fill="none">
    <path d="M15.117 0H0.883C0.395 0 0 0.395 0 0.883V15.117C0 15.605 0.395 16 0.883 16H8.62V9.803H6.455V7.4H8.62V5.672C8.62 3.57 9.931 2.485 11.801 2.485C12.683 2.485 13.502 2.562 13.748 2.594V4.848H12.465C11.4 4.848 11.198 5.38 11.198 6.124V7.399H13.664L13.27 9.802H11.197V16H15.118C15.606 16 16.001 15.605 16.001 15.117V0.883C16 0.395 15.606 0 15.118 0H15.117Z" fill="#1877F2"/>
    <path d="M11.197 16V9.803H13.27L13.664 7.4H11.197V6.124C11.197 5.38 11.4 4.848 12.465 4.848H13.748V2.594C13.502 2.562 12.683 2.485 11.801 2.485C9.931 2.485 8.62 3.57 8.62 5.672V7.399H6.455V9.803H8.62V16H11.197Z" fill="white"/>
</svg>

                            <p style={{ fontWeight: "bold" }}>Ad processing in progress...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const loadertext = () => {
    let loadingText = document.getElementById("loadingText");
    let characters;
    let currentIndex = 0;
    let timerId;
    function startAnimation() {
        characters = loadingText.innerText.split(" ");
        clearInterval(timerId); // Clear previous interval (if any)
        changeColor();
    }

    function changeColor() {
        timerId = setInterval(() => {
            if (currentIndex < characters.length) {
                characters[currentIndex] =
                    '<span style="color: #358F8B;">' +
                    characters[currentIndex] +
                    "</span>";
                loadingText.innerHTML = characters.join(" ");
                currentIndex++;
            } else {
                clearInterval(timerId); // Clear the interval after completing one cycle
                currentIndex = 0; // Reset index for the next cycle
                resetColor();
            }
        }, 500); // Change color every 500ms (adjust as needed)
    }

    function resetColor() {
        loadingText.innerHTML = "Facebook Ad. Running"; // Reset the text content
        loadingText.style.color = "black"; // Set text color to black
        setTimeout(startAnimation, 500); // Start the animation after 1 second delay
    }

    startAnimation(); // Start the animation initially
};




export default AdsLoadFacebook;
