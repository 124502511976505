import "./Login.css";
import LoginImg from "./Images/Login.png";
import Exoad from "./Images/EXOAD.png";
import vector from "./Images/Vector 3.png";
import google from "./Images/google.png";
import faceBook from "./Images/Facebook.png";
import apple from "./Images/apple.png";
import password from "./Images/password.png";
import eyeOpen from "./Images/openEye.png";

import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../axios/auth";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  adduserdata,
  clearRedux,
  userLogin,
} from "../../../Redux/login/loginSlice";
import { createAccount } from "../../../Redux/login/signupSlice";
import { addGoogleAnalytics } from "../Pages/Redux/AdsAnalyticsDetailsSlice";
import { handleprofileImage } from "../../../Redux/addCreateSlice";
import { handleCoinChange, handleFullName } from "../Pages/Redux/addCreateSlice";
import { clearToken } from "../Pages/Redux/login/loginSlice";

// import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleEye, setToggleEye] = useState(false);
  const [googleData, setGoogleData] = useState();
  const [googlers, setGoogleres] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (loginData) => {
    setIsSubmitting(true)
    // dispatch(userLogin(loginData));
    
    dispatch(userLogin(loginData));
    setFormSubmitted(true); 

    setTimeout(() => {
      setIsSubmitting(false)
      
    }, 1000);
  };


  const { user } = useSelector((state) => state.loginuser);

if (user && user?.role =="admin"){
  navigate("/admin");

}else{



  if (user && user.credits > 0) {
    navigate("/main");
  } else if (user && user.credits <= 0) {
    navigate("/plans");
  }
}
  const createAcc = () => {
    dispatch(createAccount());
    navigate("/signup");
  };


//   useEffect(() => {
//     Cookies.remove("token");
//    dispatch(addGoogleAnalytics(null)) 
// dispatch(handleprofileImage(""))
// dispatch(handleFullName(""))
// dispatch(handleCoinChange(""))
// // dispatch(clearToken(""))
//     dispatch(clearRedux());
//     localStorage.clear();
   

//   }, []);


  

  const googleSignin = async () => {
    const res = await axios.post("/google/signup", {
      email: googleData.email,
      name: googleData.name,
    });
    
    let LoginToken = res?.data?.token;
    let sessionId= Math.random().toString(36).substring(2) + Date.now().toString(36);
        
    Cookies.set("session_id", sessionId); // Store token in a cookie
     sessionStorage.setItem("session_id",sessionId)
    Cookies.set("token", LoginToken);
    setGoogleres(res?.data?.data);
    dispatch(adduserdata(res?.data?.data?.user[0]));

    if (res?.data?.data?.user[0]?.credits > 0) {
      setIsLoading(false);
      
      navigate("/main");
    } else if (res?.data?.data?.user[0]?.credits == 0) {
      setIsLoading(false);
      navigate("/plans");
    }
  };



  

  useEffect(() => {
    Cookies.remove("_TWk_Id");
    if (googleData?.email || googleData?.name) {
      
      googleSignin();
    }
  }, [googleData]); // Run the effect when googleData changes


  const userToken = Cookies.get("token");

   const handleClick = () => {
    navigate('/landingpage/home');
  };

  const fetchdata = async () => {
    try {
      const res = await axios.get("/getUser", {
        headers: {
          Authorization: `Bearer ${userToken}`, // Correct spelling
        },
      });
     
      dispatch(adduserdata(res?.data?.data[0]));
      if (res?.data?.data?.role == "admin") {
        navigate("/admin");
      }
    } catch (e) {
      setIsLoading(false)
      if (e.response?.data?.message === "User Unauthorised") {
        navigate("/login");
        // return <Navigate to="/login" />;
      } else if (e.response?.statusText === "Unauthorized") {
        navigate("/login");
      }
    }
  };
  
  
 
  return (
    <Container fluid className="layout">
      {isLoading && <Spinner className="login-spinner" />}

      <Row
        className="login-row"
        style={isLoading ? { filter: "blur(5px)" } : {}}
      >
        <Col
          className=" liginImg   d-flex justify-content-end align-items-center "
          lg={6}
          md={12}
          sm={12}
        >
        
        </Col>

        <Col
          style={{
            textAlign: "center",
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
          lg={6}
          md={12}
          sm={12}
        >
          <div
            className="right d-flex justify-content-center align-items-center "
            style={{
              padding: "3rem 2rem 1rem 2rem",
              paddingTop: "40px",
              border: "3px solid white",
              maxHeight: "650px",
            }}
          >
            <div className="">
              {/* <img style={{ padding: "14px 0px" }} src={Exoad} alt="Exoad" />
               */}
                 <img
      style={{ padding: "14px 0px", cursor: "pointer" }}  // Add cursor pointer for better UX
      src={Exoad}
      alt="Exoad"
      onClick={handleClick}
    />

              <div style={{ width: "100%", paddingBottom: "14px" }}>
                <h1 className="welcome">Welcome Back !</h1>
                <p className="p" style={{ width: "100%", fontSize: "14px" }}>
                  Sign In To engage your audience with your <br />{" "}
                  <span>best creatives</span>
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className="input-div" style={{ marginBottom: 10 }}>
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Email
                    </label>
                    <input
                      className="input"
                      type="email"
                      maxLength={50}
                      required
                      autoComplete="off"
                    
                      placeholder="Enter your email address"
                      {...register("email", { required: true })}
                    />
                  </div>

                  <div className="input-div">
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Password
                    </label>

                    <div className="password-container">
                      <div className="password-sub-container">
                        <input
                          className="password-container password-input"
                          type={!toggleEye ? "password" : "text"}
                          required
                          maxLength={50}
                          placeholder="********"
                          autoComplete="off"
                          {...register("password", { required: true })}
                        />
                        {!toggleEye ? (
                          <img
                            className="password-icon"
                            src={password}
                            alt="password"
                            onClick={() => setToggleEye(!toggleEye)}
                          />
                        ) : (
                          <img
                            className="password-icon"
                            src={eyeOpen}
                            alt="password"
                            onClick={() => setToggleEye(!toggleEye)}
                          />
                        )}
                      </div>
                    </div>
                    <Link
                      to={"/forgetpassword"}
                      className="forgot-pass"
                      style={{ paddingBottom: "10px" }}
                    >
                      Forgot Password ?
                    </Link>
                  </div>
                </div>
                <div className="button-container">
                  <button type="submit" className="login-button"  disabled={isSubmitting}>
                  {isSubmitting ? "Submitting..." : "Sign In"}
                    {/* {"Sign In"} */}
                  </button>
                </div>
              </form>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p className="belowbutton"> &nbsp; Or log in with &nbsp; </p>
              </div>

              <div
                className="footer-container"
                style={!toggle ? { gap: 48 } : { gap: 24 }}
              >
                <div className="footer-images" style={{ paddingTop: "6px" }}>
                  <LoginSocialGoogle
                    client_id="222990346622-vjf4q7poq52ca1ksl05njpdpi7okp11h.apps.googleusercontent.com"
                    scope="email profile"
                    // discoveryDocs="claims_supported"
                    // access_type="offline"
                    onResolve={({ provider, data }) => {
                      setGoogleData(data);
                    }}
                    onReject={({ error }) => {
                      setIsLoading(false);
                    }}
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      src={google}
                      alt="google"
                      onClick={() => setIsLoading(true)}
                    />
                  </LoginSocialGoogle>

                  {/* <LoginSocialFacebook
                    appId="2368016560049525"
                    ProfileFields={"displayName,email,name"}
                    onResolve={({ provider, data }) => {
                      const { email } = data;
                      // Now you can use the email in your application logic
                    }}
                    onReject={({ error }) => {
                    }}
                  >
                    <img src={faceBook} alt="google" />
                  </LoginSocialFacebook> */}
                </div>
                <div style={{ paddingBottom: "12px" }}>
                  <p className="footer-bottom" style={{ cursor: "pointer" }}>
                    Don’t have an account ?&nbsp;
                    <span
                      onClick={() => createAcc()}
                      className="footer-span-login"
                    >
                      Create Account
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default Login;
