export const data = [
    ["#F7CAC9", "#A6A5A1", "#5A5858"],
    ["#8A7A59", "#B6985A", "#D9BF77"],
    ["#006994", "#008C9E", "#00AAB5"],
    ["#FFD1DC", "#FFABAB", "#FFC3A0"],
    ["#E63946", "#F1FAEE", "#A8DADC"],
    ["#D9BF77", "#D08159", "#6A0572"],
    ["#1B4332", "#2D6A4F", "#66CC99"],
    ["#FFD700", "#FFA500", "#FF4500"],
    ["#FF0000", "#FF7F00", "#FFFF00"],
    ["#2E282A", "#635DFF", "#9C92AC"],
    ["#F4A261", "#E76F51", "#2A9D8F"],
    ["#D8A47F", "#8E8D8A", "#9D9B97"],
    ["#FFFFFF", "#000000", "#C2F7F7"],
    ["#96E1D4", "#F4A261", "#E76F51"],
    ["#FF6B6B", "#FFE66D", "#98C1D9"],
    ["#F76D57", "#FFD166", "#4A4E4D"],
    ["#FF6B6B", "#F76D57", "#EA9999"],
    ["#C2DFFF", "#8DB0CC", "#3D509A"],
    ["#FFDAC1", "#B7D1E5", "#A2A8D3"],
    ["#1B4332", "#2D6A4F", "#66CC99"],
    ["#F7CAC9", "#A6A5A1", "#5A5858"],
    ["#8A7A59", "#B6985A", "#D9BF77"],
    ["#006994", "#008C9E", "#00AAB5"],
    ["#FFD1DC", "#FFABAB", "#FFC3A0"],
    ["#E63946", "#F1FAEE", "#A8DADC"],
  ];