import React, { useContext, useEffect, useState } from "react";
import "./GoogleTargeting.css";
import { plusIcon, SearchSvg,RemoveSVG } from "./SVG";
import { OnlyCountry, Countries_Data } from "../../CampaignData";

import {  nodeUrl } from "../../../../../axios/auth";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AddTargetingDetailsDisplay } from "../../../../../../Redux/GoogleDisplaySlice";
import { minusIcon } from "../../GoogleCampaign/SideNavContainer/SVG";
import { ToggleContext } from "../ContextFile";
import { Link } from "react-router-dom";
import ChooseAccountPopup from "./ChooseAccountPopup";

const GoogleTargeting = () => {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [accountModal, setAccountModal] = useState(false);

  const [city, setCity] = useState("");
  const [cityArray, setCityArray] = useState([]);
  const [country, setCountry] = useState("");
  const [pendingAdData,setPendingAdData] = useState()

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  // const [data3, setData3] = useState(["English"]);
  const [data4, setData4] = useState([]);
  const { keyword } = useSelector((state) => state.AiSuggestion.Google2);
  const { HandleActiveStep } = useContext(ToggleContext);
  const { keywords, Cities, CountryCode } = useSelector(
    (state) => state.GoogleDisplayDetails
  );
  // googleDisplayAdsCompleteData

  useEffect(() => {
    HandleActiveStep(2);
    // OR || beacuse we want to show even if there is only on parameter in the state
    if (keywords?.length > 0 || Cities?.length > 0 || CountryCode?.length > 0) {
      if (CountryCode) {
        let Country = Countries_Data?.find(
          (country) => country?.country_code === CountryCode
        );
        setData1([Country?.country_name]);
        setCountry(CountryCode);
      }
      if (keywords?.length > 0) {
        setData2(keywords);
      }
      if (Cities?.length > 0) {
        setData4(Cities);
      }
    } else if (sessionStorage.getItem("googleDisplayAdsCompleteData")) {
      let data = JSON.parse(
        sessionStorage.getItem("googleDisplayAdsCompleteData")
      );
      if (data?.CountryCode) {
        let Country = Countries_Data.find(
          (country) => country?.country_code === data?.CountryCode
        );
        setData1([Country?.country_name]);
        setCountry(data?.CountryCode);
      }
      setData4(data?.Cities);
      setData2(data?.keywords);
    }
  }, [keywords, Cities, CountryCode]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data1?.length > 0) {
      let country = Countries_Data.find(
        (country) => country.country_name === data1[0]
      );
      setCountry(country.country_code);
    }

    if (country?.length > 0 || data4?.length > 0 || data2?.length > 0) {
      let data = [
        { country: country },
        { cityList: data4 },
        { keywords: data2 },
      ];
      dispatch(AddTargetingDetailsDisplay(data));
    }
  }, [data1, data4, data2]);

  const addIntoArray1 = (item) => {
    if (!data1.includes(item)) {
      setData1([item]);
    }
    setActive1(false);
  };

  const addIntoArray2 = (item) => {
    if (!data2.includes(item)) {
      setData2([...data2, item]);
    }
  };


  useEffect(()=>{
    if(sessionStorage.getItem("pendingAdData")){
      setPendingAdData(JSON.parse(sessionStorage.getItem("pendingAdData")))
      setCountry((JSON.parse(sessionStorage.getItem("pendingAdData")))?.CountryCode)
      setData4((JSON.parse(sessionStorage.getItem("pendingAdData")))?.Cities)
      setData2((JSON.parse(sessionStorage.getItem("pendingAdData")))?.keywords)
    }
   }
  ,[])

  // const addIntoArray3 = (item) => {
  //   if (!data3.includes(item)) {
  //     setData3([item]);
  //   }
  //   setActive3(false);
  // };

  const addIntoArray4 = (item) => {
    if (!data4.includes(item)) {
      setData4([...data4, item]);
    }
    setCity("");
  };

  const DeleteItemFromListArry1 = (array, i) => {
    const updatedData1 = [...data1]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData1(updatedData1); // Update the state with the new array
    setCountry("");
  };

  const DeleteItemFromListArry2 = (array, i) => {
    const updatedData1 = [...data2]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData2(updatedData1); // Update the state with the new array
  };

  // const DeleteItemFromListArry3 = (array, i) => {
  //   const updatedData1 = [...data3]; // Create a copy of the array
  //   updatedData1.splice(i, 1); // Remove the item at index i
  //   setData3(updatedData1); // Update the state with the new array
  // };

  const DeleteItemFromListArry4 = (array, i) => {
    const updatedData1 = [...data4]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData4(updatedData1); // Update the state with the new array
  };

  // Debouncing Process start from here--------------------

  // const [searchTerm, setSearchTerm] = useState("");
  // const [pinCode, setPinCode] = useState("");

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (city.trim() !== "") {


        // axios
        //   .get(
        //     `http://3.6.128.153:5000/v1/google/geo-targets/locations?country=${country}&q=${city}`
        //   )

          axios
          .get(
            `${nodeUrl}/get-city?country=${country}&q=${city}`
          )

          .then((response) => {
            // setCityArray(() => response.data);
            setCityArray(() => response.data.data);



          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [city]);

  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   setCity(value);

  // };

  //

  const handleAccordian1 = (active) => {
    setActive1(active);
    setActive2(false);
    setActive3(false);
    setActive4(false);
  };

  const handleAccordian2 = (active) => {
    setActive1(false);
    setActive2(active);
    setActive3(false);
    setActive4(false);
  };

  // const handleAccordian3 = (active) => {
  //   setActive1(false);
  //   setActive2(false);
  //   setActive3(active);
  //   setActive4(false);
  // };

  const handleAccordian4 = (active) => {
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(active);
  };

  return (
    <div className="targerting-main-box Fb-funding-scroller" style={{}}>
      <div style={{ alignSelf: "center", maxWidth: "700px", width: "100%" }}>
        <Accordion
          title={"Select Location"}
          placeholder={"The Entire World"}
          active={active1}
          setActive={handleAccordian1}
          dataArr={OnlyCountry}
          addIntoArray={addIntoArray1}
          DeleteItemFromList={DeleteItemFromListArry1}
          data={data1}
          scrollHeight={0}
        />

        {data1?.length > 0 && (
          <Accordion2
            title={"Select City/State"}
            placeholder={"Select City/State"}
            active={active4}
            setActive={handleAccordian4}
            dataArr={cityArray}
            addIntoArray={addIntoArray4}
            DeleteItemFromList={DeleteItemFromListArry4}
            data={data4}
            setCity={setCity}
            city={city}
            scrollHeight={100}
          />
        )}

        <Accordion
          title={"Select Keyword"}
          dataArr={keyword ? keyword : ["Please select headline first"]}
          placeholder={"Keyword"}
          active={active2}
          setActive={handleAccordian2}
          addIntoArray={addIntoArray2}
          DeleteItemFromList={DeleteItemFromListArry2}
          data={data2}
          scrollHeight={200}
        />
        {/* <Accordion
          title={"Language"}
          placeholder={"All Language"}
          active={active3}
          setActive={handleAccordian3}
          scrollHeight={400}
          dataArr={arr3}
          addIntoArray={addIntoArray3}
          DeleteItemFromList={DeleteItemFromListArry3}
          data={data3}
        /> */}
            <div className="language-details-alignment">
          <label htmlFor="">Language</label>

          <div className="language-input-alignment">
            <span>English</span>
          </div>
        </div>
      </div>
      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/googlecompaign2/google-add-1/content"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7895)">
              <path
                d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H154.693C159.278 1.51845 163.259 4.67535 164.304 9.13933L173.249 47.3625C174.698 53.555 169.998 59.4815 163.639 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                stroke="url(#paint0_linear_392_7895)"
                stroke-width="3.03691"
              />
              <text
                x="34%"
                y="64%"
                font-family="Arial"
                fill="white"
                className="Back-svg-btn"
              >
                Back
              </text>
            </g>

            <defs>
              <filter
                id="filter0_i_392_7895"
                x="0"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7895"
                />
              </filter>
              <linearGradient
                id="paint0_linear_392_7895"
                x1="0"
                y1="0"
                x2="172.838"
                y2="42.9259"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#47BEB9" />
                <stop offset="1" stop-color="#215856" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
        {/* to={"/main/campaign/googlecompaign2/google-add-1/funding"} */}
        <Link  onClick={() => setAccountModal(true)}  >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Next
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
      {accountModal && (

<ChooseAccountPopup setAccountModal={setAccountModal} />
)}
    </div>
  );
};

const Accordion = ({
  active,
  setActive,
  dataArr,
  addIntoArray,
  title,
  placeholder,
  data,
  DeleteItemFromList,
  scrollHeight,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [manualInput, setManualInput] = useState("");


  const HandleScroll = () => {
    const mainBox = document.querySelector(".targerting-main-box");
    setTimeout(() => {
      mainBox.scrollTo({ top: scrollHeight, behavior: "smooth" });
    }, 100);
  };
  const handleManualAdd = () => {
    if (manualInput.trim() !== "") {
      addIntoArray(manualInput.trim());
      setManualInput("");
    }
  };

  return (
    <div>
      <button
        className="targerting-box"
        style={{}}
        onClick={(e) => {
          setActive(!active);
          HandleScroll();
        }}
      >
        <p className="targerting-text">{title}</p>
        <div className="tagert-accordion-main" style={{height:"auto",padding: "8px 16px"}}>
          <div className="tagert-accordion">
            <p className="targer-accordion-text">
              {data?.length > 0
                ? data?.map((item, i) => (
                    <div className="item-in-data" key={i}>
                      <p className="item-in-data-text">{item}</p>
                      <span
                        style={{}}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          DeleteItemFromList(data, i);
                        }}
                      >
                        {RemoveSVG}
                      </span>
                    </div>
                  ))
                : placeholder}
            </p>
            <span style={{ width: "20px" }}>
              {active ? minusIcon : plusIcon}
            </span>
          </div>
        </div>
      </button>

      {active && (
        <div className="search-body" style={{}}>
          <div className="search-main">
            <div>
              {SearchSvg}
              <input
                autoFocus
                type="text"
                style={{
                  border: "transparent",
                  background: "inherit",
                  outline: "none",
                }}
                placeholder="Search Country"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
             {/* <div className="manual-input-section" style={{width:"100%"}}> */}
            {/* <input
            className="manual-input"
              type="text"
              placeholder="Type your own keyword"
              maxLength={20}
              value={manualInput}
              onChange={(e) => setManualInput(e.target.value)}
            /> */}
            {/* <button  className="manual-btn" onClick={handleManualAdd}><span style={{marginRight:"-124px"}}>{plusIcon}</span></button>
          </div> */}
          <div
            style={{
              maxHeight: "200px",
              overflow: "hidden",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            {dataArr
              ?.filter((item) =>
                item.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((item, i) => (
                <div
                  className="item-main"
                  style={{ cursor: "pointer" }}
                  onClick={() => addIntoArray(item)}
                >
                  <p className="item-text">{item}</p>
                  <span>{plusIcon}</span>
                </div>
              ))}
          </div>
        </div>
      )}
      
    </div>
  );
};

const Accordion2 = ({
  active,
  setActive,
  dataArr,
  addIntoArray,
  title,
  placeholder,
  data,
  DeleteItemFromList,
  city,
  setCity,
}) => {
  return (
    <div>
      <button
        className="targerting-box"
        style={{}}
        onClick={() => setActive(!active)}
      >
        <p className="targerting-text">{title}</p>
        <div className="tagert-accordion-main" >
          <div className="tagert-accordion">
            <p className="targer-accordion-text">
              {data.length > 0
                ? data.map((item, i) => (
                    <div className="item-in-data" key={item.id}>
                      <p className="item-in-data-text">{item}</p>

                      <span
                        style={{}}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          DeleteItemFromList(data, i);
                        }}
                      >
                        {RemoveSVG}
                      </span>
                    </div>
                  ))
                : placeholder}
            </p>
            <span style={{ width: "20px" }}>
              {active ? minusIcon : plusIcon}
            </span>
          </div>
        </div>
      </button>

      {active && (
        <div className="search-body" style={{}}>
          <div className="search-main">
            <div>
              {SearchSvg}
              <input
                autoFocus
                type="text"
                style={{
                  border: "transparent",
                  background: "inherit",
                  outline: "none",
                }}
                placeholder="Search Keyword"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: "200px",
              overflow: "hidden",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            {dataArr?.length > 0 &&
              dataArr
                .filter((item) =>
                  item.name.toLowerCase().includes(city.toLowerCase())
                )
                .map((item, i) => (
                  <div
                    className="item-main"
                    style={{ cursor: "pointer" }}
                    onClick={() => addIntoArray(item.name)}
                  >
                    <p className="item-text">
                      {item.name} ,{item.canonical_name}
                    </p>
                    <p className="item-text"></p>

                    <span>{plusIcon}</span>
                  </div>
                ))}
          </div>
        </div>
      )}

    </div>
  );
};
export default GoogleTargeting;
