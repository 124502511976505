// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/next-button-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generate-section {
    padding: 12px 29px 12px 40px;
    height: calc(100vh - 74px);
    overflow-y: auto;
}

.generate-section.view-design {
    display: block;
}

.generate-section.none-view {
    display: none;
}

@media (max-width: 1279px) { /* Assuming "lg-max" means large screen max */
    .generate-section {
        padding: 12px 16px 12px 16px;
    }
}

@media (max-width: 767px) { /* Assuming "sm-max" means small screen max */
    .generate-section {
        padding: 18px 16px;
    }
}

.generate-section .generate-next-button {
    padding: 22px 0 0 0;
    text-align: end;
}

.generate-section .generate-next-button button {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 120px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 20px; /* Assuming "text-20-meduim" sets font size to 20px */
    font-weight: 500; /* Assuming "meduim" implies medium weight, usually 500 */
    color: #fff; /* Assuming var(--text-white) translates to white color */
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/component/account/Pages/Generate Page/NewGenerate/newgenerate.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA,6BAA6B,6CAA6C;IACtE;QACI,4BAA4B;IAChC;AACJ;;AAEA,4BAA4B,6CAA6C;IACrE;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mDAAkE;IAClE,YAAY;IACZ,YAAY;IACZ,4BAA4B;IAC5B,wBAAwB;IACxB,eAAe,EAAE,qDAAqD;IACtE,gBAAgB,EAAE,yDAAyD;IAC3E,WAAW,EAAE,yDAAyD;IACtE,YAAY;AAChB","sourcesContent":[".generate-section {\r\n    padding: 12px 29px 12px 40px;\r\n    height: calc(100vh - 74px);\r\n    overflow-y: auto;\r\n}\r\n\r\n.generate-section.view-design {\r\n    display: block;\r\n}\r\n\r\n.generate-section.none-view {\r\n    display: none;\r\n}\r\n\r\n@media (max-width: 1279px) { /* Assuming \"lg-max\" means large screen max */\r\n    .generate-section {\r\n        padding: 12px 16px 12px 16px;\r\n    }\r\n}\r\n\r\n@media (max-width: 767px) { /* Assuming \"sm-max\" means small screen max */\r\n    .generate-section {\r\n        padding: 18px 16px;\r\n    }\r\n}\r\n\r\n.generate-section .generate-next-button {\r\n    padding: 22px 0 0 0;\r\n    text-align: end;\r\n}\r\n\r\n.generate-section .generate-next-button button {\r\n    background: url(\"../../../../../assets/images/next-button-bg.png\");\r\n    width: 120px;\r\n    height: 40px;\r\n    background-repeat: no-repeat;\r\n    background-size: contain;\r\n    font-size: 20px; /* Assuming \"text-20-meduim\" sets font size to 20px */\r\n    font-weight: 500; /* Assuming \"meduim\" implies medium weight, usually 500 */\r\n    color: #fff; /* Assuming var(--text-white) translates to white color */\r\n    border: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
