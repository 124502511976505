import React from 'react'
import AHeader from './AHeader'
import ASidebar from './ASidebar'
import AhomeScreen from './AhomeScreen'

function AMain() {
    return (
        <>

        <>
     
        </>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-2'>
                        <ASidebar />
                    </div>
                    <div className='col-10'>
                        <div className='row'>
                            <AHeader />
                        </div>
                        <div className='row'>
                            <AhomeScreen />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AMain