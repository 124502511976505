import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
// import { ActiveSvg, CircleNav } from "../SVG";

const FacebookAdd1 = () => {
  const [active, setActive] = useState("first");

  // const navigate = useNavigate();
  useEffect(() => {
    let pageName = document.URL.split("/").at(-1);
    if (pageName === "content") {
      setActive("first");
    } else if (pageName === "targeting") {
      setActive("second");
    } else if (pageName === "funding") {
      setActive("third");
    }
  }, []);

  // const HandleClick = (e, title) => {
  //   setActive(e);
  //   navigate(title);
  // };

  // scroller  for on scroll navigation.
  // const handleWheel = (e) => {
  //   const deltaY = e.deltaY;

  //   if (deltaY < 0) {
  //     if (active === "third") {
  //       setActive("second");
  //       navigate("targeting");
  //     } else if (active === "second") {
  //       setActive("first");
  //       navigate("content");
  //     }
  //   } else if (deltaY > 0) {
  //     if (active === "first") {
  //       setActive("second");
  //       navigate("targeting");
  //     } else if (active === "second") {
  //       setActive("third");
  //       navigate("funding");
  //     }
  //   }
  // };

  return (
    <>
      <Col lg={1} className="google-side-nav">
        {/* <div className="NavCircleDiv" onWheel={handleWheel}>
          <NavCricles
            title={"Content"}
            display={"none"}
            active={
              active === "first" || active === "second" || active === "third"
            }
            e={"first"}
            HandleClick={HandleClick}
          />
          <NavCricles
            title={"Targeting"}
            e={"second"}
            active={active === "second" || active === "third"}
            HandleClick={HandleClick}
          />
          <NavCricles
            title={"Funding"}
            e={"third"}
            active={active === "third"}
            HandleClick={HandleClick}
          />
          <div className="line" />
        </div> */}
      </Col>
      <Col style={{ padding: "0px 0px 0px 80px" }} className="mobile-view-padding-alignmentv">
        <Outlet />
      </Col>
    </>
  );
};

// const NavCricles = ({ title, display, active, HandleClick, e }) => {
//   const smalltitle = title.toLowerCase();
//   return (
//     <div className="google-side-nav-circles">
//       <div className="google-side-nav-circles-subdiv">
//         <div>
//           <div
//             className={`line ${active && "active"}`}
//             style={{ display: display }}
//           />
//           <div onClick={() => HandleClick(e, smalltitle)}>
//             {active ? ActiveSvg : CircleNav}
//           </div>
//         </div>
//         <p className="google-side-nav-text">{title}</p>
//       </div>
//     </div>
//   );
// };

export default FacebookAdd1;
