import houseplan from "../../../assets/images/dashboard/digitalMarketing.webp";
import digitalmarketing from "../../../assets/images/dashboard/housePlan.webp";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";

function Dashboard() {
  return (
    <>
      <div className="row  container m-0 p-0">
        <div className="col-lg-2 col-sm-1 col-xs-4  col-md-1 sidebar-bg-color px-0  mx-0 sidebar-border">
          <div className="">
            <Sidebar className="" />
          </div>
        </div>
        <div className="col-sm-10  bg-white">
          {/* <div className="container-fluid"> */}
          {/* <div className="container-fluid"> */}
          <div className="container">
            <div className="container headerbar ">
              <Header title="Payment Details" btnname="Create Campaigns" />
            </div>
            <div className="row mt-5 mb-1">
              <h6 className="theme-card-title">Recent Creatives</h6>
            </div>

            <div
              id="carouselExampleDark"
              className="carousel carousel-dark slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval={10000}>
                  <div className="card-group">
                    <div className="card border-0 me-3">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img
                        src={digitalmarketing}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Digital Marketing</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">SEO Services</small>
                        <small className="text-muted">Last Week</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img
                        src={digitalmarketing}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Digital Marketing</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">SEO Services</small>
                        <small className="text-muted">Last Week</small>
                      </div>
                    </div>
                    <div className="card border-0">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval={2000}>
                  <div className="card-group">
                    <div className="card border-0 me-3">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img
                        src={digitalmarketing}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Digital Marketing</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">SEO Services</small>
                        <small className="text-muted">Last Week</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img
                        src={digitalmarketing}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Digital Marketing</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">SEO Services</small>
                        <small className="text-muted">Last Week</small>
                      </div>
                    </div>
                    <div className="card border-0">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="card-group">
                    <div className="card border-0 me-3">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img
                        src={digitalmarketing}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Digital Marketing</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">SEO Services</small>
                        <small className="text-muted">Last Week</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                    <div className="card border-0 me-3">
                      <img
                        src={digitalmarketing}
                        className="card-img-top"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title">Digital Marketing</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">SEO Services</small>
                        <small className="text-muted">Last Week</small>
                      </div>
                    </div>
                    <div className="card border-0">
                      <img src={houseplan} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">House Plan</h5>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <small className="text-muted">
                          Architectural services
                        </small>
                        <small className="text-muted">Today</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>
            </div>

            <div className="row pt-5 m-0">
              <div className="col-md-3 pt-2">
                <div className="border rounded-5">
                  <h6 className="title text-dark mt-3 mb-3 ms-4">
                    Campaign Name
                  </h6>
                  <div className="row text-center">
                    <div className="col-sm-6 border-end">
                      <span className="text-danger">4</span>
                      <br />
                      <span className="title text-dark">Impressions</span>
                    </div>
                    <div className="col-sm-6">
                      <h6>0</h6>
                      <h6>Visit</h6>
                    </div>
                  </div>

                  <div className="row align-items-center  pt-4 m-0">
                    <div className="col-sm-4 theme-bg-warning theme-left-border-curve">
                      <div className=" justify-content-center text-center align-items-center ">
                        <span className="title text-dark">
                          <i class="fa-brands fa-google text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-primary">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-facebook text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-danger theme-right-border-curve">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-instagram text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 pt-2">
                <div className="border rounded-5">
                  <h6 className="title text-dark mt-3 mb-3 ms-4">
                    Campaign Name
                  </h6>
                  <div className="row text-center">
                    <div className="col-sm-6 border-end">
                      <span className="text-danger">4</span>
                      <br />
                      <span className="title text-dark">Impressions</span>
                    </div>
                    <div className="col-sm-6">
                      <h6>0</h6>
                      <h6>Visit</h6>
                    </div>
                  </div>

                  <div className="row align-items-center  pt-4 m-0">
                    <div className="col-sm-4 theme-bg-warning theme-left-border-curve">
                      <div className=" justify-content-center text-center align-items-center ">
                        <span className="title text-dark">
                          <i class="fa-brands fa-google text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-primary">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-facebook text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-danger theme-right-border-curve">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-instagram text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* TEST */}
            <div className="row pt-5 m-0">
              <div className="col-md-3 pt-2">
                <div className="border rounded-5">
                  <h6 className="title text-dark mt-3 mb-3 ms-4">
                    Campaign Name
                  </h6>
                  <div className="row text-center">
                    <div className="col-sm-6 border-end">
                      <span className="text-danger">4</span>
                      <br />
                      <span className="title text-dark">Impressions</span>
                    </div>
                    <div className="col-sm-6">
                      <h6>0</h6>
                      <h6>Visit</h6>
                    </div>
                  </div>

                  <div className="row align-items-center  pt-4 m-0">
                    <div className="col-sm-4 theme-bg-warning theme-left-border-curve">
                      <div className=" justify-content-center text-center align-items-center ">
                        <span className="title text-dark">
                          <i class="fa-brands fa-google text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-primary">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-facebook text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-danger theme-right-border-curve">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-instagram text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 pt-2">
                <div className="border rounded-5">
                  <h6 className="title text-dark mt-3 mb-3 ms-4">
                    Campaign Name
                  </h6>
                  <div className="row text-center">
                    <div className="col-sm-6 border-end">
                      <span className="text-danger">4</span>
                      <br />
                      <span className="title text-dark">Impressions</span>
                    </div>
                    <div className="col-sm-6">
                      <h6>0</h6>
                      <h6>Visit</h6>
                    </div>
                  </div>

                  <div className="row align-items-center  pt-4 m-0">
                    <div className="col-sm-4 theme-bg-warning theme-left-border-curve">
                      <div className=" justify-content-center text-center align-items-center ">
                        <span className="title text-dark">
                          <i class="fa-brands fa-google text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-primary">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-facebook text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4 theme-bg-danger theme-right-border-curve">
                      <div className=" justify-content-center text-center align-items-center">
                        <span className="title text-dark">
                          <i class="fa-brands fa-instagram text-white"></i>
                        </span>
                        <br />
                        <span className="title text-dark">
                          <i class="fa-thin fa-percent text-white"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* TEST */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
