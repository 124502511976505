import React from 'react'

export default function BrandIcon() {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.49011 29.3546H23.0734M11.7401 18.0213H18.8234M21.9401 25.0762H8.62344C8.02844 25.0762 7.36261 24.6088 7.16428 24.0421L1.29928 7.63708C0.463443 5.28542 1.44094 4.56292 3.45261 6.00792L8.97761 9.96042C9.89844 10.5979 10.9468 10.2721 11.3434 9.23792L13.8368 2.59375C14.6301 0.46875 15.9476 0.46875 16.7409 2.59375L19.2343 9.23792C19.6309 10.2721 20.6793 10.5979 21.5859 9.96042L26.7709 6.26292C28.9809 4.67625 30.0434 5.48375 29.1368 8.04792L23.4134 24.0704C23.2009 24.6088 22.5351 25.0762 21.9401 25.0762Z" stroke="#333435" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
