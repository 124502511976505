// import { useForm } from "react-hook-form";
import "./Reusable.css";
import { Link } from "react-router-dom";

export const CommonBtn = ({
  children,
  icon,
  color,
  padding,
  gap,
  Left,
  textColor,
  width,
  handleSubmit,
  align,
  borderRadius,
  displaytype,
}) => {
  return (
    <button
      className="common-btn"
      style={{
        borderRadius: borderRadius,
        background: color,
        padding: padding,
        gap: gap,
        color: textColor,
        width: width,
        justifyContent: align,
        display: displaytype,
      }}
      onClick={handleSubmit}
    >
      <span style={Left && { display: "none" }}>{icon}</span>
      {children}
      {Left && <span> {icon}</span>}
    </button>
  );
};

export const NavItems = ({ logo, active, text, label, url }) => {
  return (
    <Link to={url ? url : "#"} className="main-navbar">
      <div className={`navbar-img-div ${active && "active"}`}>{logo}</div>

      {active && (
        <div className="main-navbar-text ">
          <span className="main-navbar-span">
            {text === "logo1" && "Step 1/3"}
            {text === "logo2" && "Step 2/3"}
            {text === "logo3" && "Step 3/3"}
          </span>
          <p className="main-navbar-p">{label ? label : "Getting Details"}</p>
        </div>
      )}
    </Link>
  );
};
