
import './SignUp.css'

import google from "../assets/images/loginWith/google.png";

import fb from "../assets/images/loginWith/fb.png";
import email from "../assets/images/loginWith/email.png"
import { Link } from 'react-router-dom';
function SignUp() {
    return (
        <div className=" container-fluid backgroundImg">
            <div className="row pt-5 align-items-center">
                <div className="col-sm-12 text-center">
                    <div className=" ">
                        <h6 className="logoTitle">EXOAD</h6>
                    </div>
                    <div>
                        <h6 className="getStarted title mt-2">Let's get started</h6>
                    </div>
                    <div className="d-flex justify-content-center my-3">
                        <div className="login-box d-flex align-items-center">
                            <img src={google} alt="" ></img>
                            <span className="title ms-2">Sign up with Google</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-3">
                        <div className="login-box d-flex align-items-center">
                            <img src={fb} alt="" ></img>
                            <span className=" title ms-2">Sign up with Facebook</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-3">
                        <div className="login-box d-flex align-items-center">
                            <img src={email} alt="" ></img>
                            <span className=" title ms-2">Sign up with Email</span>

                        </div>
                    </div>


                    <div className="d-flex justify-content-center my-3">
                        <div className=" term-service d-flex align-items-center">
                            <h6 className="paragraph">By continuing, you indicate that you agree
                                to Balloonary's  <span className="text-decoration-underline"> terms of service </span>
                            </h6>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center my-3">
                        <div className=" term-service d-flex align-items-center">
                            <h6 className=" no-wrap paragraph">Already have an account? <Link  to="/user/login">  <span className="fw-bold"> Login </span></Link>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SignUp 