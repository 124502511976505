import React from "react";
import { Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
// import { ActiveSvg, CircleNav } from "../SVG";

const BingAdd1 = () => {
  return (
    <>
      <Col lg={1} className="google-side-nav NavCircleDiv"></Col>
      <Col>
        <Outlet />
      </Col>
    </>
  );
};

export default BingAdd1;
