export const PlansItemRectangle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="379"
    height="614"
    viewBox="0 0 379 614"
    fill="none"
  >
    <g filter="url(#filter0_b_1613_17905)">
      <path
        d="M0 20C0 8.9543 8.9543 0 20 0H89.2816C92.8615 0 96.3758 0.960887 99.4577 2.78237L137.417 25.2176C140.499 27.0391 144.014 28 147.593 28H189.5H231.407C234.986 28 238.501 27.0391 241.583 25.2176L279.542 2.78238C282.624 0.96089 286.139 0 289.718 0H359C370.046 0 379 8.95431 379 20V594C379 605.046 370.046 614 359 614H20C8.9543 614 0 605.046 0 594V20Z"
        fill="white"
      />
      <path
        d="M0.5 20C0.5 9.23044 9.23045 0.5 20 0.5H89.2816C92.772 0.5 96.1984 1.43687 99.2033 3.21281L137.163 25.6481C140.322 27.5151 143.924 28.5 147.593 28.5H189.5H231.407C235.076 28.5 238.678 27.5151 241.837 25.6481L279.797 3.21282C282.802 1.43687 286.228 0.5 289.718 0.5H359C369.77 0.5 378.5 9.23045 378.5 20V594C378.5 604.77 369.77 613.5 359 613.5H20C9.23044 613.5 0.5 604.77 0.5 594V20Z"
        stroke="#B2BBC6"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1613_17905"
        x="-54"
        y="-54"
        width="487"
        height="722"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="27" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1613_17905"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1613_17905"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const facebookForPlan2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.6663 10.7933C14.6663 13.2199 13.2197 14.6666 10.793 14.6666H9.99967C9.63301 14.6666 9.33301 14.3666 9.33301 13.9999V10.1533C9.33301 9.97325 9.47967 9.81992 9.65967 9.81992L10.833 9.79992C10.9263 9.79325 11.0063 9.72659 11.0263 9.63325L11.2597 8.35992C11.2642 8.33106 11.2624 8.30156 11.2545 8.27345C11.2465 8.24535 11.2325 8.21931 11.2135 8.19713C11.1945 8.17494 11.1709 8.15715 11.1443 8.14497C11.1178 8.13279 11.0889 8.12652 11.0597 8.12659L9.63968 8.14659C9.45301 8.14659 9.30634 7.99992 9.29967 7.81992L9.27301 6.18659C9.27301 6.07992 9.35967 5.98659 9.47301 5.98659L11.073 5.95992C11.1863 5.95992 11.273 5.87325 11.273 5.75992L11.2463 4.15992C11.2463 4.04659 11.1597 3.95992 11.0463 3.95992L9.24634 3.98659C8.98358 3.99058 8.72419 4.04638 8.48304 4.1508C8.24189 4.25522 8.02371 4.4062 7.84101 4.59509C7.65831 4.78398 7.51469 5.00707 7.41836 5.25157C7.32204 5.49607 7.27491 5.75717 7.27967 6.01992L7.31301 7.85325C7.31967 8.03992 7.17301 8.18659 6.98634 8.19325L6.18634 8.20659C6.07301 8.20659 5.98634 8.29325 5.98634 8.40659L6.00634 9.67325C6.00634 9.78659 6.09301 9.87325 6.20634 9.87325L7.00634 9.85992C7.19301 9.85992 7.33967 10.0066 7.34634 10.1866L7.40634 13.9866C7.41301 14.3599 7.11301 14.6666 6.73967 14.6666H5.20634C2.77967 14.6666 1.33301 13.2199 1.33301 10.7866V5.20659C1.33301 2.77992 2.77967 1.33325 5.20634 1.33325H10.793C13.2197 1.33325 14.6663 2.77992 14.6663 5.20659V10.7933Z"
      className="facebookForPlan2"
    />
  </svg>
);

export const googleforplan2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.4935 7.37992C14.477 7.21662 14.4003 7.06531 14.2782 6.95558C14.1562 6.84585 13.9976 6.7856 13.8335 6.78659H8.80013C8.43346 6.78659 8.13346 7.08659 8.13346 7.45325V8.59325C8.13346 8.95992 8.43346 9.25992 8.80013 9.25992H11.8068C11.7335 9.87325 11.3335 10.7999 10.4468 11.4199C9.88013 11.8133 9.1268 12.0866 8.13346 12.0866C8.0868 12.0866 8.0468 12.0866 8.00013 12.0799C6.30013 12.0266 4.86013 10.8866 4.34013 9.31992C4.19593 8.8947 4.12163 8.44892 4.12013 7.99992C4.12013 7.53992 4.20013 7.09325 4.33346 6.67992C4.37346 6.55992 4.42013 6.43992 4.47346 6.31992C5.0868 4.93992 6.4268 3.96659 8.00013 3.91992C8.04013 3.91325 8.0868 3.91325 8.13346 3.91325C9.0868 3.91325 9.80013 4.22659 10.3001 4.57325C10.5601 4.75325 10.9068 4.71325 11.1335 4.49325L12.0601 3.58659C12.3535 3.29992 12.3268 2.81325 11.9935 2.57325C10.9335 1.79325 9.64013 1.33325 8.13346 1.33325C8.0868 1.33325 8.0468 1.33325 8.00013 1.33992C6.7877 1.36139 5.6043 1.71448 4.57831 2.36086C3.55233 3.00724 2.72294 3.92224 2.18013 5.00659C1.7268 5.91325 1.4668 6.92659 1.4668 7.99992C1.4668 9.07325 1.7268 10.0866 2.18013 10.9933H2.1868C2.72885 12.0768 3.55714 12.9913 4.5819 13.6377C5.60665 14.284 6.78877 14.6375 8.00013 14.6599C8.0468 14.6666 8.0868 14.6666 8.13346 14.6666C9.93346 14.6666 11.4468 14.0733 12.5468 13.0533C13.8068 11.8866 14.5335 10.1799 14.5335 8.14659C14.5335 7.85992 14.5201 7.61325 14.4935 7.37992Z"
      className="facebookForPlan2"
    />
  </svg>
);

export const GreenDiamond = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="54"
    viewBox="0 0 66 54"
    fill="none"
  >
    <circle cx="41.8945" cy="26.0828" r="17.5" fill="#DBEDE6" />
    <path
      d="M8.33462 25.9139L27.5101 40.5584L16.7699 24.5026L8.33462 25.9139ZM32.9134 42.5269L38.6545 20.841L20.426 23.8908L32.9134 42.5269ZM16.2889 21.1467L19.9386 10.2271L13.0964 11.3719L7.25303 22.6585L16.2889 21.1467L16.2889 21.1467ZM37.3817 38.9068L50.7458 18.8179L42.3106 20.2292L37.3817 38.9068ZM20.0756 20.5131L37.8863 17.5332L30.8809 8.39627L23.7253 9.59349L20.0756 20.5131ZM41.673 16.8996L50.7088 15.3878L41.5098 6.6179L34.6676 7.76269L41.673 16.8996ZM43.2317 3.59149L55.4971 15.2847C55.7933 15.5573 55.9669 15.8996 56.0179 16.3116C56.069 16.7237 55.98 17.101 55.7509 17.4436L35.1546 48.3803C34.8994 48.7809 34.5194 49.0235 34.0145 49.108C33.5097 49.1924 33.0714 49.0868 32.6997 48.791L3.15476 26.2436C2.82667 25.9943 2.6197 25.6665 2.53383 25.2602C2.44797 24.854 2.5007 24.4738 2.69202 24.1196L10.4831 9.07079C10.7208 8.56566 11.1269 8.26503 11.7014 8.16891L41.7862 3.13532C42.3607 3.03919 42.8425 3.19123 43.2317 3.59149Z"
      fill="#14C081"
    />
  </svg>
);

export const YellowDiamond = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="54"
    viewBox="0 0 66 54"
    fill="none"
  >
    <circle cx="41.8945" cy="26.0828" r="17.5" fill="#EDEDDB" />
    <path
      d="M8.33462 25.9139L27.5101 40.5584L16.7699 24.5026L8.33462 25.9139ZM32.9134 42.5269L38.6545 20.841L20.426 23.8908L32.9134 42.5269ZM16.2889 21.1467L19.9386 10.2271L13.0964 11.3719L7.25303 22.6585L16.2889 21.1467L16.2889 21.1467ZM37.3817 38.9068L50.7458 18.8179L42.3106 20.2292L37.3817 38.9068ZM20.0756 20.5131L37.8863 17.5332L30.8809 8.39627L23.7253 9.59349L20.0756 20.5131ZM41.673 16.8996L50.7088 15.3878L41.5098 6.6179L34.6676 7.76269L41.673 16.8996ZM43.2317 3.59149L55.4971 15.2847C55.7933 15.5573 55.9669 15.8996 56.0179 16.3116C56.069 16.7237 55.98 17.101 55.7509 17.4436L35.1546 48.3803C34.8994 48.7809 34.5194 49.0235 34.0145 49.108C33.5097 49.1924 33.0714 49.0868 32.6997 48.791L3.15476 26.2436C2.82667 25.9943 2.6197 25.6665 2.53383 25.2602C2.44797 24.854 2.5007 24.4738 2.69202 24.1196L10.4831 9.07079C10.7208 8.56566 11.1269 8.26503 11.7014 8.16891L41.7862 3.13532C42.3607 3.03919 42.8425 3.19123 43.2317 3.59149Z"
      fill="#F5C451"
    />
  </svg>
);

export const BlueDiamong = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="54"
    viewBox="0 0 66 54"
    fill="none"
  >
    <circle cx="41.8945" cy="26.0828" r="17.5" fill="#E2E1F4" />
    <path
      d="M8.33462 25.9139L27.5101 40.5584L16.7699 24.5026L8.33462 25.9139ZM32.9134 42.5269L38.6545 20.841L20.426 23.8908L32.9134 42.5269ZM16.2889 21.1467L19.9386 10.2271L13.0964 11.3719L7.25303 22.6585L16.2889 21.1467L16.2889 21.1467ZM37.3817 38.9068L50.7458 18.8179L42.3106 20.2292L37.3817 38.9068ZM20.0756 20.5131L37.8863 17.5332L30.8809 8.39627L23.7253 9.59349L20.0756 20.5131ZM41.673 16.8996L50.7088 15.3878L41.5098 6.6179L34.6676 7.76269L41.673 16.8996ZM43.2317 3.59149L55.4971 15.2847C55.7933 15.5573 55.9669 15.8996 56.0179 16.3116C56.069 16.7237 55.98 17.101 55.7509 17.4436L35.1546 48.3803C34.8994 48.7809 34.5194 49.0235 34.0145 49.108C33.5097 49.1924 33.0714 49.0868 32.6997 48.791L3.15476 26.2436C2.82667 25.9943 2.6197 25.6665 2.53383 25.2602C2.44797 24.854 2.5007 24.4738 2.69202 24.1196L10.4831 9.07079C10.7208 8.56566 11.1269 8.26503 11.7014 8.16891L41.7862 3.13532C42.3607 3.03919 42.8425 3.19123 43.2317 3.59149Z"
      fill="#3E38F7"
    />
  </svg>
);

export const BasicDiamond = (
  <svg xmlns="http://www.w3.org/2000/svg"  width="50"
  height="54" viewBox="0 0 76 82" fill="none">
  <path d="M48.0193 59.858C59.0972 59.858 68.0777 50.8775 68.0777 39.7996C68.0777 28.7217 59.0972 19.7412 48.0193 19.7412C36.9414 19.7412 27.9609 28.7217 27.9609 39.7996C27.9609 50.8775 36.9414 59.858 48.0193 59.858Z" fill="#DBEDE6"/>
  <path d="M9.55393 39.6055L31.5327 56.3909L19.2224 37.9879L9.55393 39.6055ZM37.7259 58.6472L44.3064 33.791L23.413 37.2866L37.7259 58.6472ZM18.6711 34.1414L22.8543 21.6254L15.0118 22.9376L8.31421 35.8742L18.6711 34.1414ZM42.8475 54.4979L58.1653 31.4721L48.4969 33.0897L42.8475 54.4979ZM23.0114 33.4151L43.4258 29.9996L35.3963 19.5269L27.1946 20.8992L23.0114 33.4151ZM47.7661 29.2734L58.1229 27.5406L47.5791 17.4886L39.7366 18.8007L47.7661 29.2734ZM49.5527 14.0197L63.6112 27.4224C63.9507 27.7348 64.1497 28.1272 64.2082 28.5994C64.2667 29.0718 64.1647 29.5042 63.9021 29.8969L40.2948 65.3563C40.0023 65.8155 39.5667 66.0936 38.988 66.1904C38.4094 66.2872 37.907 66.1661 37.481 65.8271L3.61681 39.9834C3.24075 39.6977 3.00353 39.3219 2.9051 38.8562C2.80669 38.3906 2.86713 37.9549 3.08642 37.5489L12.0165 20.3C12.2889 19.7211 12.7544 19.3765 13.4129 19.2663L47.8959 13.4969C48.5544 13.3867 49.1066 13.5609 49.5527 14.0197Z" fill="#4285F4"/>
</svg>
);

export const googleforplan = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.4935 7.37992C14.477 7.21662 14.4003 7.06531 14.2782 6.95558C14.1562 6.84585 13.9976 6.7856 13.8335 6.78659H8.80013C8.43346 6.78659 8.13346 7.08659 8.13346 7.45325V8.59325C8.13346 8.95992 8.43346 9.25992 8.80013 9.25992H11.8068C11.7335 9.87325 11.3335 10.7999 10.4468 11.4199C9.88013 11.8133 9.1268 12.0866 8.13346 12.0866C8.0868 12.0866 8.0468 12.0866 8.00013 12.0799C6.30013 12.0266 4.86013 10.8866 4.34013 9.31992C4.19593 8.8947 4.12163 8.44892 4.12013 7.99992C4.12013 7.53992 4.20013 7.09325 4.33346 6.67992C4.37346 6.55992 4.42013 6.43992 4.47346 6.31992C5.0868 4.93992 6.4268 3.96659 8.00013 3.91992C8.04013 3.91325 8.0868 3.91325 8.13346 3.91325C9.0868 3.91325 9.80013 4.22659 10.3001 4.57325C10.5601 4.75325 10.9068 4.71325 11.1335 4.49325L12.0601 3.58659C12.3535 3.29992 12.3268 2.81325 11.9935 2.57325C10.9335 1.79325 9.64013 1.33325 8.13346 1.33325C8.0868 1.33325 8.0468 1.33325 8.00013 1.33992C6.7877 1.36139 5.6043 1.71448 4.57831 2.36086C3.55233 3.00724 2.72294 3.92224 2.18013 5.00659C1.7268 5.91325 1.4668 6.92659 1.4668 7.99992C1.4668 9.07325 1.7268 10.0866 2.18013 10.9933H2.1868C2.72885 12.0768 3.55714 12.9913 4.5819 13.6377C5.60665 14.284 6.78877 14.6375 8.00013 14.6599C8.0468 14.6666 8.0868 14.6666 8.13346 14.6666C9.93346 14.6666 11.4468 14.0733 12.5468 13.0533C13.8068 11.8866 14.5335 10.1799 14.5335 8.14659C14.5335 7.85992 14.5201 7.61325 14.4935 7.37992Z"
      className="facebookForPlan"
    />
  </svg>
);

export const facebookForPlan = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.6663 10.7933C14.6663 13.2199 13.2197 14.6666 10.793 14.6666H9.99967C9.63301 14.6666 9.33301 14.3666 9.33301 13.9999V10.1533C9.33301 9.97325 9.47967 9.81992 9.65967 9.81992L10.833 9.79992C10.9263 9.79325 11.0063 9.72659 11.0263 9.63325L11.2597 8.35992C11.2642 8.33106 11.2624 8.30156 11.2545 8.27345C11.2465 8.24535 11.2325 8.21931 11.2135 8.19713C11.1945 8.17494 11.1709 8.15715 11.1443 8.14497C11.1178 8.13279 11.0889 8.12652 11.0597 8.12659L9.63968 8.14659C9.45301 8.14659 9.30634 7.99992 9.29967 7.81992L9.27301 6.18659C9.27301 6.07992 9.35967 5.98659 9.47301 5.98659L11.073 5.95992C11.1863 5.95992 11.273 5.87325 11.273 5.75992L11.2463 4.15992C11.2463 4.04659 11.1597 3.95992 11.0463 3.95992L9.24634 3.98659C8.98358 3.99058 8.72419 4.04638 8.48304 4.1508C8.24189 4.25522 8.02371 4.4062 7.84101 4.59509C7.65831 4.78398 7.51469 5.00707 7.41836 5.25157C7.32204 5.49607 7.27491 5.75717 7.27967 6.01992L7.31301 7.85325C7.31967 8.03992 7.17301 8.18659 6.98634 8.19325L6.18634 8.20659C6.07301 8.20659 5.98634 8.29325 5.98634 8.40659L6.00634 9.67325C6.00634 9.78659 6.09301 9.87325 6.20634 9.87325L7.00634 9.85992C7.19301 9.85992 7.33967 10.0066 7.34634 10.1866L7.40634 13.9866C7.41301 14.3599 7.11301 14.6666 6.73967 14.6666H5.20634C2.77967 14.6666 1.33301 13.2199 1.33301 10.7866V5.20659C1.33301 2.77992 2.77967 1.33325 5.20634 1.33325H10.793C13.2197 1.33325 14.6663 2.77992 14.6663 5.20659V10.7933Z"
      className="facebookForPlan"
    />
  </svg>
);

export const successBtn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clip-path="url(#clip0_3051_16387)">
      <path
        d="M16 0C7.1752 0 0 7.1752 0 16C0 24.8248 7.1752 32 16 32C24.8248 32 32 24.8248 32 16C32 7.1752 24.8248 0 16 0ZM23.5288 9.38C23.8459 9.37078 24.1583 9.45753 24.4253 9.62887C24.6922 9.80022 24.9012 10.0482 25.0248 10.3403C25.1485 10.6324 25.181 10.955 25.1182 11.266C25.0554 11.5769 24.9001 11.8616 24.6728 12.0828L14.5952 22.16C14.2999 22.4551 13.8995 22.6209 13.482 22.6209C13.0645 22.6209 12.6641 22.4551 12.3688 22.16L7.3296 17.1212C7.1794 16.9759 7.05963 16.8021 6.97726 16.61C6.8949 16.418 6.8516 16.2114 6.84987 16.0024C6.84815 15.7934 6.88804 15.5862 6.96722 15.3928C7.0464 15.1994 7.16328 15.0236 7.31107 14.8759C7.45885 14.7281 7.63456 14.6112 7.82798 14.532C8.02139 14.4528 8.22863 14.4129 8.43762 14.4147C8.6466 14.4164 8.85316 14.4597 9.04523 14.5421C9.23731 14.6244 9.41108 14.7442 9.5564 14.8944L13.482 18.8204L22.446 9.8564C22.7312 9.56277 23.1196 9.39176 23.5288 9.3796V9.38Z"
        fill="#03BB63"
      />
    </g>
    <defs>
      <clipPath id="clip0_3051_16387">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
