import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./Redux/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppWithStripe = () => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripe = await loadStripe(
          "pk_live_51NrbwaE4NavywtTSiJMAFCIYESpSHXjaQXFJFVJpPH7kMc56ayEQPtIVslD18GaiFFBNSWwH1hZrYYxrD9CJy0qH00kmjGtuqM"
        );
        
        setStripePromise(stripe);
      } catch (error) {
        console.error("Failed to load Stripe.js:", error);
        setStripePromise(null); // Optionally handle this fallback
      }
    };

    initializeStripe();
  }, []);

  // if (!stripePromise) {
  //   return <div>Stripe is not available. Please check your connection.</div>;
  // }

  return (
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  );
};

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppWithStripe />
      <ToastContainer style={{ fontSize: "12px" }} />
    </PersistGate>
  </Provider>
);

reportWebVitals();
