import React from 'react'

export default function DownBlackIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.99868 7.49976L9.99911 12.5002L14.9995 7.49976" stroke="black" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
