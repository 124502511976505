// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FontChange_MainContainer__5zSPo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FontChange_fontSearchBox__i9Hnp {
  border-radius: var(--Spacing-24, 24px);
  border: 2px solid var(--Linear,#5bbcbb);
  background: rgba(255, 255, 255);
  z-index: 1000;
  height: 30vh;
  width: 100%;
  zindex: 100;
  position: absolute;
  overflow-y: scroll;
  min-width: 250px;
}

.FontChange_fontSearchBoxMain__VvM-7 {
  border-radius: 20px;
  border: 1px solid gray;

  /* position: fixed; */
  align-items: flex-start;
  width: 100%;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 8px;
}

.FontChange_FontListing__UnbnC {
  & li {
    padding: 4px 12px;
    font-family: Poppins;
    cursor: pointer;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/account/Pages/Brands Page/BrandsEntry/FontChange/FontChange.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;EACtC,uCAAuC;EACvC,+BAA+B;EAC/B,aAAa;EACb,YAAY;EACZ,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;;EAEtB,qBAAqB;EACrB,uBAAuB;EACvB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE;IACE,iBAAiB;IACjB,oBAAoB;IACpB,eAAe;EACjB;AACF","sourcesContent":[".MainContainer {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.fontSearchBox {\r\n  border-radius: var(--Spacing-24, 24px);\r\n  border: 2px solid var(--Linear,#5bbcbb);\r\n  background: rgba(255, 255, 255);\r\n  z-index: 1000;\r\n  height: 30vh;\r\n  width: 100%;\r\n  zindex: 100;\r\n  position: absolute;\r\n  overflow-y: scroll;\r\n  min-width: 250px;\r\n}\r\n\r\n.fontSearchBoxMain {\r\n  border-radius: 20px;\r\n  border: 1px solid gray;\r\n\r\n  /* position: fixed; */\r\n  align-items: flex-start;\r\n  width: 100%;\r\n  display: flex;\r\n  padding: 10px 16px;\r\n  align-items: center;\r\n  gap: 8px;\r\n}\r\n\r\n.FontListing {\r\n  & li {\r\n    padding: 4px 12px;\r\n    font-family: Poppins;\r\n    cursor: pointer;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainContainer": `FontChange_MainContainer__5zSPo`,
	"fontSearchBox": `FontChange_fontSearchBox__i9Hnp`,
	"fontSearchBoxMain": `FontChange_fontSearchBoxMain__VvM-7`,
	"FontListing": `FontChange_FontListing__UnbnC`
};
export default ___CSS_LOADER_EXPORT___;
