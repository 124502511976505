import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Cities: [],
  startDate: "",
  EndDate: "",
  CountryCode: "",
  amount: "",
};
const FecbookAdSlice = createSlice({
  name: "FacebookSlice",
  initialState,
  reducers: {
    AddFundingDetailsFacebook: (state, action) => {
      //   state.websiteUrl = action.payload;
    },
    AddTargetingDetailsFacebook: (state, action) => {
      
      state.CountryCode = action.payload[0]?.country;
      state.Cities = action.payload[1]?.cityList;
      state.keywords = action.payload[2]?.keywords;
     
      // state.Cities = action.payload[0].cityList;
    },

  },
});

export const {
    AddFundingDetailsFacebook,
  AddTargetingDetailsFacebook,
} = FecbookAdSlice.actions;
export default FecbookAdSlice.reducer;
