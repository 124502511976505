// this Campaign Details is for running ads 
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  FbHeadline: "",
  FbImage: "",
  FbDescription: "",
  GoogleSearchHeadline: [],
  GoogleSearchDescription: [],

  GoogleDisplayHeadline: "",
  GoogleDisplayDescription: "",
  GoogleDisplayImage: "",
  BingHeadline: "",
  BingDescription: "",
  IgDescription: "",
  IgImage: "",
};
const CampaignDetailSlice = createSlice({
  name: "CampaignDetail",
  initialState,
  reducers: {
    addFaceBookDeatils: (state, action) => {
      state.FbDescription = action.payload.descriptions;
      state.FbHeadline = action.payload.headline;
      state.FbImage = action.payload.image;
    },
    addInstagramDeatils: (state, action) => {
      state.FbDescription = action.payload.descriptions;
      state.FbHeadline = action.payload.headline;
      state.FbImage = action.payload.image;
    },
    addGoogleSearchDeatils: (state, action) => {
      state.GoogleSearchDescription = action.payload.descriptions;
      state.GoogleSearchHeadline = action.payload.headline;
    },
    addGoogleDisplayDeatils: (state, action) => {
      state.GoogleDisplayDescription = action.payload.descriptions;
      state.GoogleDisplayHeadline = action.payload.headline;
      state.GoogleDisplayImage = action.payload.image;
    },
    addIgDeatils: (state, action) => {
      state.IgDescription = action.payload.descriptions;
      state.IgImage = action.payload.image;
    },
    addBingDeatils: (state, action) => {
      state.BingDescription = action.payload.descriptions;
      state.BingHeadline = action.payload.headline;
    },
  },
});

export const {
  addFaceBookDeatils,
  addInstagramDeatils,
  addIgDeatils,
  addBingDeatils,
  addGoogleSearchDeatils,
  addGoogleDisplayDeatils,
} = CampaignDetailSlice.actions;
export default CampaignDetailSlice.reducer;
