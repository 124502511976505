import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import axios, { nodeUrl } from "../../../axios/auth";
import Cookies from "js-cookie";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
// import { ToastContainer, toast } from "react-toastify";
import StylesContaent from "./AdminContent.module.css";
import styles from "../sidebar/AdminSidebar.module.css";
import loader from "../images/ZZ5H.gif";
import { FaFilePdf } from 'react-icons/fa';
function ManageJobs() {
  const token = Cookies.get("token");
  const [customer, setCustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message,setmessage] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [resume,setResume] = useState()
  async function fetchData() {
    try {
      setIsLoading(true);
      const res = await axios.get("/get-job", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      setCustomer(res?.data?.data);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [token]);

  

  // filter fetch inactive data
  async function fetchDataInactive() {
    try {
      setIsLoading(true);
      const res = await axios.get("/fetch-inactivec-ustomer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomer(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }
  // Filtered customer data based on searchQuery
  const filteredCustomers = customer.filter((data) =>
    data?.firstName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  // Calculate the total number of pages for filtered data
  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

  // Pagination logic
  const pageRange = 5;
  const maxPageButtons = Math.min(pageRange, totalPages);

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const pageNumbers = Array.from(
    { length: maxPageButtons },
    (_, index) => startPage + index
  );

  // Calculate the indexes of the customers to display on the current page
  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  let currentCustomers = filteredCustomers.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );
  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to open the delete confirmation modal
  const openDeleteModal = (data) => {
    setmessage(data?.message)
    setResume(`${nodeUrl}/${data?.resume}`)
    setShowDeleteModal(true);
  };

  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setDeleteCustomerId(null);
    setShowDeleteModal(false);
  };

 

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-sm-2 p-0">
          <div className={styles.mobileSidebar}>
            <AdminSidebar className={styles.mobileSidebar} />
          </div>
        </div>
        <div className="col-sm-10 bg-white">
          <div className="container">
            <Header title="Add Creative" btnname="Create Campaigns" />
          </div>
          <div className="container">
            <div className="container">
              <div className="row">
                <h4 className="my-4 ps-0">
                  <b>Manage Jobs</b>
                </h4>
                <hr />
                <div className="mt-2 p-0">
                  {isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "50vh",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={loader} alt=""
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-group mb-3">
                              <input
                                style={{ borderRadius: 10 }}
                                type="text"
                                className="form-control"
                                placeholder="Search by Name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                              <button
                                className="btn btn-primary common-btn"
                                type="button"
                                style={{
                                  backgroundColor: "#47beb9",
                                  marginLeft: 10,
                                  borderRadius: 10,
                                }}
                                onClick={() => setSearchQuery("")}
                              >
                                Clear
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className={StylesContaent.tableHeading}>
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th className="border">FullName</th>
                              <th className="border">Email</th>
                              <th className="border">Phone No</th>
                              <th className="border">Employement Status</th>
                              <th className="border">Position</th>
                              <th className="border">Start Date</th>
                              <th className="border">Location</th>

                              <th className="border">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentCustomers.map((data) => (
                                
                              <tr key={data.id}>
                                <td
                                  className="border"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {data?.firstName +" " + data?.lastName}
                                </td>
                                <td className="border">{data?.email}</td>
                                <td className="border">{data?.countryCode+data?.phoneNo}</td>
                                <td className="border">{data?.employementStatus}</td>
                                <td className="border">{data?.position}</td>
                                <td className="border" style={{ whiteSpace: 'nowrap' }}>
  {data?.startDate.split("T")[0]}
</td>

                                <td className="border">{data?.state}</td>
                               
                        
                           
                                <td className="border">
                                  <div className="dropdown">
                                    <button
                                    className="btn btn-primary"
                                      onClick={() =>
                                        openDeleteModal(data)}
                                    >
                                      View
                                    </button>
                                 
                                  </div>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Pagination */}
                      <div className="d-flex justify-content-center mt-3">
                        <ul className="pagination">
                          {currentPage > pageRange && (
                            <li className="page-item">
                              <button
                                className="btn btn-primary"
                                style={{ backgroundColor: "#47beb9" }}
                                onClick={() => paginate(1)}
                              >
                                1
                              </button>
                            </li>
                          )}
                          {currentPage > pageRange && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {pageNumbers.map((pageNumber) => (
                            <li
                              key={pageNumber}
                              className={
                                `${currentPage === pageNumber}`
                                  ? `${StylesContaent.activePage}`
                                  : ""
                              }
                            >
                              <button
                                className={`${StylesContaent.activePage} page-link`}
                                onClick={() => paginate(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          ))}
                          {currentPage + Math.floor(pageRange / 2) <
                            totalPages && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {currentPage + Math.floor(pageRange / 2) <
                            totalPages && (
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={() => paginate(totalPages)}
                              >
                                {totalPages}
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}

        {/* Delete Confirmation Modal */}
   
        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
      <Modal.Header closeButton>
        <Modal.Title>Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
        <br />
        <br />
       
       
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-open">Click to download</Tooltip>}
        >
          <a href={resume} target="_blank" rel="noopener noreferrer">
            <FaFilePdf size={30} style={{ marginLeft: '20px', cursor: 'pointer' }} />
          </a>
        </OverlayTrigger>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeDeleteModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
      </div>
    </div>
  );
}

export default  ManageJobs;
