import { useSelector } from "react-redux";

import { useEffect } from "react";
import { useState } from "react";
function SingleSelectTemp() {
  const creativeName = useSelector((state) => state.addcreative.creativeName);
  const tempData = useSelector((state) => state.template.tempData);
  // const{image,logo,heading,description,promotionText,ctaIcon,ctaText}=tempData
  const [temp, selectTemp] = useState([]);
  const gc1 = [
   
  ];
  const gc2 = [
   ];
  const gc3 = [
   ];
  const gc4 = [
   ];
  const gc5 = [
   ];
  const gc6 = [
    ];
  const gc7 = [
   ];
  const gc8 = [
   ];
  const gc9 = [
   ];
  const gc10 = [
   ];
  const gc11 = [
   ];
  const gc12 = [
    ];

  const categoryArray = [
  ];

  useEffect(() => {
    let selectCategory = categoryArray.filter((item) => {
      return item.category == creativeName;
    });
    setState(selectCategory[0].templates);
  }, []);

  function setState(tempalteAray) {
    selectTemp(tempalteAray);
  }

  return (
    <>
      {temp.map((Item, i) => {
        return (
          <>
            {" "}
            <Item
              image={tempData && tempData.image} //pass
              logo={tempData && tempData.logo} //pass
              heading={tempData && tempData.heading} //pass
              description={tempData && tempData.description} //pass
              promotionText={tempData && tempData.promotionText} //pass
              ctaIcon={tempData && tempData.ctaIcon} //pass
              ctaText={tempData && tempData.ctaText} //pass
              mainColor={tempData && tempData.mainColor}
              HeadingColor={tempData && tempData.HeadingColor}
              descriptionColor={tempData && tempData.descriptionColor}
              promotionTextColor={tempData && tempData.promotionTextColor}
              ctaColor={tempData && tempData.ctaColor}
              selectedFontFamily={tempData && tempData.selectedFontFamily}
            />
          </>
        );
      })}
    </>
  );
}

export default SingleSelectTemp;
