import React from "react";
import { Outlet } from "react-router-dom";

const Campaigns = () => {
  return (
    <div style={{}}>
      <Outlet />
    </div>
  );
};

export default Campaigns;
