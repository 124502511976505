import React, { useEffect, useState } from "react";
import "./brandSetupColor.css";
// import ColorSelectionPopup from '../colorSelectionPopup';
import ColorPicker from "../../../../../icons/colorPicker";
import ColorSelectionPopup from "../colorSelectionPopup";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from "react-color";
import { addBrandData } from "../../../../../../Redux/brandSetupData";
export default function BrandSetupColor({
  selectedColor1,
  setSelectedColor1,
  selectedColor2,
  setSelectedColor2,
  selectedColor3,
  setSelectedColor3,
  setCtaColor,
  ctaColor,
  promotionTextColor,
  setPromotionText,
}) {
  const { brandData } = useSelector((state) => state?.brandSetupData);
  const [selectColorModal, setSelectColorModal] = useState(false);
  const [flag, setFlag] = useState(false);
  const { logoColorPiked } = useSelector((state) => state.addcreative);
  const dispatch = useDispatch();
  // useEffect(()=>{
  //     dispatch(addBrandData({...brandData, mainBgColor:selectedColor1,headingColor:selectedColor2,descriptionColor:selectedColor3}));

  // },[])

  // const HandleSelectLogoColor = (color1, color2, color3) => {
  //      setSelectedColor1(color1);
  //     setSelectedColor2(color2);
  //     setSelectedColor3(color3);
  //     setFlag(true);
  //   };

  const handlePromotionColor = (e) => {
    setPromotionText(e.target.value);
  };
  const handleCtaColor = (e) => {
    setCtaColor(e.target.value);
  };

  return (
    <div className="brand-set-color-section">
      <div className="brand-set-color-grid">
        <div className="brand-set-color-grid-item">
          <div className="child-box">
            <div className="brand-color-heading">
              <ColorPicker />
              <h4>Color Select</h4>
            </div>

            <div className="brand-color-option-alignment">
              <div>
                <div className="brand-color-option-name">
                  <h6>Main Color</h6>
                  <p>Background, Button</p>
                </div>

                <div
                  className="brand-color-button"
                  onClick={() => setSelectColorModal(true)}
                  style={{ backgroundColor: selectedColor1 }}
                >
                  {/*  * <button>#47BEB9</button>*/}
                  <button style={{ backgroundColor: selectedColor1 }}></button>
                </div>
              </div>
              <div>
                <div className="brand-color-option-name">
                  <h6>Sub Heading</h6>
                  <p>Used for the title</p>
                </div>

                <div
                  className="brand-color-button second-bg"
                  onClick={() => setSelectColorModal(true)}
                  style={{ backgroundColor: selectedColor2 }}
                >
                  <button style={{ backgroundColor: selectedColor2 }}></button>
                </div>
              </div>
              <div>
                <div className="brand-color-option-name">
                  <h6>Description</h6>
                  <p>Used For The Discription</p>
                </div>

                <div
                  className="brand-color-button third-bg"
                  onClick={() => setSelectColorModal(true)}
                  style={{ backgroundColor: selectedColor3 }}
                >
                  <button style={{ backgroundColor: selectedColor3 }}></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="brand-set-color-grid-item">
          <div className="child-box">
            <div className="brand-color-heading">
              <ColorPicker />
              <h4>Extra Color</h4>
            </div>

            <div className="brand-color-option-alignment">
              <div>
                <div className="brand-color-option-name">
                  <h6>Promotion Text</h6>
                  <p>Headline</p>
                </div>

                <div
                  className="brand-color-button"
                  style={{ overflow: "hidden" }}
                >
                  {/* <button>#47BEB9</button>*/}

                  <input
                    type="color"
                    value={promotionTextColor}
                    style={{
                      width: "148px",
                      height: "42px",
                      outline: "none",

                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                    onChange={handlePromotionColor}
                  />
                </div>
             
              </div>
              <div>
                <div className="brand-color-option-name">
                  <h6>Call To Action Text</h6>
                  <p>Button Text Color</p>
                </div>

                <div className="brand-color-button">
                  {/*  <button>#47BEB9</button> */}
                  <input
                    type="color"
                    value={ctaColor}
                    style={{
                      width: "148px",
                      height: "42px",
                      borderRadius: "10px",
                    }}
                    onChange={handleCtaColor}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectColorModal && (
        <ColorSelectionPopup
          color1={selectedColor1}
          color2={selectedColor2}
          color3={selectedColor3}
          setSelectedColor1={setSelectedColor1}
          setSelectedColor2={setSelectedColor2}
          setSelectedColor3={setSelectedColor3}
          ctaColor={ctaColor}
          setSelectColorModal={setSelectColorModal}
        />
      )}
    </div>
  );
}
