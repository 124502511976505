import React, { useEffect, useState } from 'react'
import "./downloadModal.css";
import Img1 from "../../../../../../assets/icons/sqpost.svg";
import DefImg1 from "../../../../../../assets/icons/sqpost.png";
import DefImg2 from "../../../../../../assets/icons/landscape.svg";
import Img2 from "../../../../../../assets/icons/hover-img2.svg";
import DefImg3 from "../../../../../../assets/icons/story-img.svg";
import Img3 from "../../../../../../assets/icons/hover-img-3.svg";
import DeLaadImg from "../../../../../../assets/icons/deflt-lead.svg"
import LaadImg from "../../../../../../assets/icons/lead.svg"
import DeLgLaadImg from "../../../../../../assets/icons/Lglead.svg"
import LgLaadImg from "../../../../../../assets/icons/hover-lg-lead.svg"
import DeMDImg from "../../../../../../assets/icons/MD.svg";
import MDImg from "../../../../../../assets/icons/hover-MD.svg";
import DeHPImg from "../../../../../../assets/icons/halfpage.svg";
import HPImg from "../../../../../../assets/icons/hover-hp.svg";
import DeSkyScnnerImg from "../../../../../../assets/icons/hover-skyscannerImg.svg";
import SkyScnnerImg from "../../../../../../assets/icons/skyscannerImg.svg";
export default function DownloadModal({ downloadImage, setDownloadModal, difSizeImageLIst,selectedDownload, setSelectedDownload }) {
    const[downloadImageArray,setDownloadImageArray]=useState([])
    const [loader,setLoder]=useState(true)

    // useEffect(()=>{
    //    if(difSizeImageLIst.length>0){
    //     setLoder(false)
    //    }
    // },[difSizeImageLIst])

     // Function to handle adding/removing index numbers
  const handleIndexNumber = (index) => {
    // Check if index is already in the array
    const indexExists = downloadImageArray?.includes(index);

    if (indexExists) {
      // If index exists, remove it from the array
      const filteredArray = downloadImageArray.filter((item) => item !== index);
      setDownloadImageArray(filteredArray);
    } else {
      // If index does not exist, add it to the array
      setDownloadImageArray([...downloadImageArray, index]);
    }
  };

  const handleDownload = (all)=>{
    // if(all){
   
    //     [0,1,2,3,4,5,6,7,8,9].map((element)=>{
    //         downloadImage(difSizeImageLIst[element]?.imagePath, `creative${difSizeImageLIst[element].originalWidth}X${difSizeImageLIst[element].originalHeight}`, difSizeImageLIst[element].originalWidth, difSizeImageLIst[element].originalHeight)
    //     })
    // }
    if (all) {
        const totalImages = 10; // Total images to download
        let processedImages = 0;
    
        // Show the progress bar
        const progressContainer = document.getElementById("progressContainer");
        // progressContainer?.style.display = "block";
    
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(async (element) => {
            await downloadImage(
                difSizeImageLIst[element]?.imagePath,
                `creative${difSizeImageLIst[element].originalWidth}X${difSizeImageLIst[element].originalHeight}`,
                difSizeImageLIst[element].originalWidth,
                difSizeImageLIst[element].originalHeight
            );
    
            // Update progress
            processedImages++;
            const progressPercentage = (processedImages / totalImages) * 100;
            document.getElementById("progressBar").style.width = `${progressPercentage}%`;
    
            // Hide the progress bar when all images are processed
            if (processedImages === totalImages) {
                setTimeout(() => {
                    setDownloadModal(false)
                }, 500); // Optional delay before hiding
            }
        });
    }
  else{
    downloadImageArray.map((element)=>{
        downloadImage(difSizeImageLIst[element]?.imagePath, `creative${difSizeImageLIst[element].originalWidth}X${difSizeImageLIst[element].originalHeight}`, difSizeImageLIst[element].originalWidth, difSizeImageLIst[element].originalHeight)
    })
    setDownloadModal(false)
  }
 
    //  setDownloadModal(false)
    //  setDownloadModal(false)
  }

    return (
        <div className="generated-selection-wrapper">
            <div className="generated-selection-box">



                <div className="generated-selection-grid">
                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(0)}>
                        <div className="number-alignment">
                            <span>1</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment' >
                            {/* <img src={DefImg1} alt="DefImg1" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[0]?.imagePath}`} alt="DefImg1" />

                        </div>

                        <div className='hover-img-alignment'>
                            {/* <img src={Img1} alt="Img1" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[0]?.imagePath}`} alt="DefImg1" />
                        </div>

                        <div className="name-name-alignment">
                            <h4>Post Size</h4>
                            <p>1080x1080</p>
                        </div>
                    </div>

                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(1)}>
                        <div className="number-alignment">
                            <span>2</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox'/>
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DefImg2} alt="DefImg2" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[1]?.imagePath}`} alt="DefImg1" style={{marginTop:"20px"}} />

                        </div>

                        <div className='hover-img-alignment'>
                            {/* <img src={Img2} alt="Img2" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[1]?.imagePath}`} alt="DefImg1" style={{marginTop:"20px"}} />

                        </div>

                        <div className="name-name-alignment">
                            <h4>Landscape
                                Size</h4>
                            <p>1200x628</p>
                        </div>
                    </div>


                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(2)}>
                        <div className="number-alignment">
                            <span>3</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DefImg3} alt="DefImg3" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[2]?.imagePath}`} alt="DefImg1" style={{width:"90px",height:"90px"}} />
                        </div>

                        <div className='hover-img-alignment'>
                            {/* <img src={Img3} alt="Img3" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[2]?.imagePath}`} alt="DefImg1" style={{width:"90px",height:"90px"}} />

                        </div>

                        <div className="name-name-alignment">
                            <h4>Story  Size</h4>
                            <p>1080x1920</p>
                        </div>
                    </div>

                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(3)}>
                        <div className="number-alignment">
                            <span>4</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DefImg3} alt="DefImg3" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[3]?.imagePath}`} alt="DefImg1" style={{width:"90px",height:"90px"}} />

                            {/* <img src={`data:image/png;base64,${difSizeImageLIst[4].imagePath}`} alt="DefImg1" /> */}
                        </div>

                        <div className='hover-img-alignment'>
                            {/* <img src={Img3} alt="Img3" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[3]?.imagePath}`} alt="DefImg1" style={{width:"90px",height:"90px"}} />

                        </div>

                        <div className="name-name-alignment">
                            <h4>Vertical  Size  </h4>
                            <p>1080x1350</p>
                        </div>
                    </div>

                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(4)}>
                        <div className="number-alignment">
                            <span>5</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DefImg3} alt="DefImg3" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[4]?.imagePath}`} alt="DefImg1" style={{width:"80px",height:"100px"}} />

                            {/* <img src={`data:image/png;base64,${difSizeImageLIst[4].imagePath}`} alt="DefImg1" /> */}
                        </div>

                        <div className='hover-img-alignment'>
                        <img src={`data:image/png;base64,${difSizeImageLIst[4]?.imagePath}`} alt="DefImg1" style={{width:"80px",height:"100px"}} />
                        </div>

                        <div className="name-name-alignment">
                            <h4>Pin  Size</h4>
                            <p>1080x1500</p>
                        </div>
                    </div>


                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(5)}>
                        <div className="number-alignment">
                            <span>1</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DeLaadImg} alt="DeLaadImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[5]?.imagePath}`} alt="DefImg1" style={{marginTop:"30px",width:"100px",height:"30px"}} />

                            {/* <img src={`data:image/png;base64,${difSizeImageLIst[5].imagePath}`} alt="DefImg1" /> */}
                        </div>

                        <div className='hover-img-alignment'>
                            {/* <img src={LaadImg} alt="LaadImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[5]?.imagePath}`} alt="DefImg1" style={{marginTop:"30px",width:"100px",height:"30px"}} />

                        </div>

                        <div className="name-name-alignment">
                            <h4>Leaderboard</h4>
                            <p>728x90</p>
                        </div>
                    </div>
                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(6)}>
                        <div className="number-alignment">
                            <span>2</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DeLgLaadImg} alt="DeLgLaadImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[6]?.imagePath}`} alt="DefImg1" style={{width:"100px",height:"40px", marginTop:"30px"}} />
                        </div>

                        <div className='hover-img-alignment'>
                        <img src={`data:image/png;base64,${difSizeImageLIst[6]?.imagePath}`} alt="DefImg1" style={{width:"100px",height:"40px" ,marginTop:"30px"}} />
                        </div>

                        <div className="name-name-alignment">
                            <h4>Large
                                Leaderboard</h4>
                            <p>970x90</p>
                        </div>
                    </div>

                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(7)}>
                        <div className="number-alignment">
                            <span>3</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DeMDImg} alt="DeMDImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[7]?.imagePath}`} alt="DefImg1" style={{width:"90px",height:"90px"}} />
                        </div>

                        <div className='hover-img-alignment'>
                            {/* <img src={MDImg} alt="MDImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[7]?.imagePath}`} alt="DefImg1" style={{width:"90px",height:"90px"}} />

                        </div>

                        <div className="name-name-alignment">
                            <h4>Medium
                                Banner</h4>
                            <p>300x250</p>
                        </div>
                    </div>
                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(8)}>
                        <div className="number-alignment">
                            <span>4</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={DeHPImg} alt="DeHPImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[8]?.imagePath}`} alt="DefImg1" style={{width:"58px",height:"102px"}} />

                            {/* <img src={`data:image/png;base64,${difSizeImageLIst[8].imagePath}`} alt="DefImg1" /> */}
                        </div>

                        <div className='hover-img-alignment'>
                        <img src={`data:image/png;base64,${difSizeImageLIst[8]?.imagePath}`} alt="DefImg1" style={{width:"58px",height:"102px"}} />
                        </div>

                        <div className="name-name-alignment">
                            <h4>Half page</h4>
                            <p>300x600</p>
                        </div>
                    </div>
                    <div className="generated-selection-details-box" onClick={()=>handleIndexNumber(9)}>
                        <div className="number-alignment">
                            <span>5</span>
                        </div>

                        <div className='checkbox-alignment'>
                            <input type='checkbox' />
                        </div>

                        <div className='number-img-alignment'>
                            {/* <img src={SkyScnnerImg} alt="SkyScnnerImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[9]?.imagePath}`} alt="DefImg1" style= {{width:"60px",height:"100px"}}/>
                        </div>

                        <div className='hover-img-alignment'>
                            {/* <img src={DeSkyScnnerImg} alt="DeSkyScnnerImg" /> */}
                            <img src={`data:image/png;base64,${difSizeImageLIst[9]?.imagePath}`} alt="DefImg1" style= {{width:"60px",height:"100px"}}/>


                        </div>

                        <div className="name-name-alignment">
                            <h4>wide
                                skyscraper</h4>
                            <p>160x600</p>
                        </div>
                    </div>
                </div>

                <div className="generate-bottom-alignment">
                <div className="generate-button-alignment" onClick={() => setDownloadModal(false)}>
                        <button> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 11.9993H5" stroke="#FFFFFF" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 18.9995L5 11.9995L12 4.99951" stroke="#FFFFFF" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                           Back</button>
                    </div>
                    <div className="generate-button-alignment" onClick={() => handleDownload()}>
                        <button> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5011 12.5V15.8336C17.5011 16.2756 17.3255 16.6996 17.013 17.0122C16.7004 17.3248 16.2764 17.5004 15.8343 17.5004H4.16679C3.72473 17.5004 3.30078 17.3248 2.98819 17.0122C2.67561 16.6996 2.5 16.2756 2.5 15.8336V12.5" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.8335 8.33276L10.0005 12.4998L14.1675 8.33276" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.0005 12.5001V2.49927" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                            Download</button>
                    </div>

                    <div className="generate-button-alignment" onClick={() =>handleDownload("All") } id="progressContainer" >

                        <button    id="progressBar"  > <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5011 12.5V15.8336C17.5011 16.2756 17.3255 16.6996 17.013 17.0122C16.7004 17.3248 16.2764 17.5004 15.8343 17.5004H4.16679C3.72473 17.5004 3.30078 17.3248 2.98819 17.0122C2.67561 16.6996 2.5 16.2756 2.5 15.8336V12.5" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.8335 8.33276L10.0005 12.4998L14.1675 8.33276" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.0005 12.5001V2.49927" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                            Download All</button>

                    </div>
                    {/* <div
  id="progressContainer"
  style={{ width: "100%", height:"20px", background: "red",  }}
>progressContainer
  <div
    id="progressBar"
    style={{ width: "0%", height: "20px", background: "#76c7c0" }}
  />
</div> */}

                </div>
            
            </div>
        </div>
    )
}
