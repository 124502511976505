import React, { useState } from 'react'
import "./InstragramAccountSelect.css";
import BottomArrow from "../../../../../assets/icons/select-bottom-arrow.svg";
import CloseIcon from "../../../../../assets/icons/close.png";
// import { Link } from 'react-router-dom';
export default function InstragramAccountSelect({ setChooseYourAccount }) {
    const [dropdownMenu, setdropdownMenu] = useState(false);
    return (
        <div className='instragramAccountSelectWrapper'>
            <div className="instragramAccountSelectBOx">
                <h4>Connect your own
                    Instagram AD Page</h4>
                <div className='selectFormAlignment'>
                    <div className="selectFormInput">
                        <label>Your Account</label>
                        <input type="text" placeholder='' />
                    </div>

                    <div className="selectFormInput">
                        <label>Ad Account</label>
                        <div className="selectionInput">
                            <input type="text" placeholder='' />
                            <div className="bottom-arrow" onClick={() => setdropdownMenu(!dropdownMenu)}>
                                <img src={BottomArrow} alt="BottomArrow" />
                            </div>

                            <div className={dropdownMenu ? "dropdownBox open-box" : "dropdownBox hidden-box"}>
                                <p>Page 1</p>
                                <p>Page 2</p>
                                <p>Page 3</p>
                                <p>Page 4</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Link to="/main/campaign/instagramcampaign/instagram-add-1/content"> */}
                    <div className="next-button">
                        <button>Next</button>
                    </div>
                {/* </Link> */}
                <div className="close-icon-alignment" onClick={() => setChooseYourAccount(false)}>
                    <img src={CloseIcon} alt="CloseIcon" />
                </div>
            </div>
        </div>
    )
}
