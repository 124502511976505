import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import axios, { nodeUrl } from "../../../axios/auth";
import Cookies from "js-cookie";
import { Modal, Button} from "react-bootstrap";
// import { ToastContainer, toast } from "react-toastify";
import StylesContaent from "./AdminContent.module.css";
import styles from "../sidebar/AdminSidebar.module.css";
import loader from "../images/ZZ5H.gif";
// import { FaFilePdf, FaExternalLinkAlt } from 'react-icons/fa';
function ManageAdsGoogle() {
  const token = Cookies.get("token");
  const [customer, setCustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [message,setmessage] = useState("")

  const [isLoading, setIsLoading] = useState(false);
  // const [resume,setResume] = useState()
  async function fetchData() {
    try {
      setIsLoading(true);
      const res = await axios.get("/get-ads-admin", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      setIsLoading(false);
      setCustomer(res?.data?.data);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }

  
  

  useEffect(() => {
    fetchData();
  }, [token]);

  const currencySymbol = (currency) => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'INR':
        return '₹';
      case 'EUR':
        return '€';
      default:
        return '';
    }
  };

  // filter fetch inactive data
  async function fetchDataInactive() {
    try {
      setIsLoading(true);
      const res = await axios.get("/fetch-inactivec-ustomer", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomer(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }
  // Filtered customer data based on searchQuery
  const filteredCustomers = customer.filter((data) =>
    data?.result?.fullName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
  // Calculate the total number of pages for filtered data
  const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

  // Pagination logic
  const pageRange = 5;
  const maxPageButtons = Math.min(pageRange, totalPages);

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const pageNumbers = Array.from(
    { length: maxPageButtons },
    (_, index) => startPage + index
  );

  // Calculate the indexes of the customers to display on the current page
  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  let currentCustomers = filteredCustomers.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );
  // Function to handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to open the delete confirmation modal
  const openDeleteModal = (data) => {

    
    if(data?.apiStatus ===false){

let passData=JSON.parse(data?.googleData)
      setmessage(passData )
    }
    else{
      setmessage(data)

    }
    // setmessage(data)
    setShowDeleteModal(true);

   
    
  };


 

  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setDeleteCustomerId(null);
    setShowDeleteModal(false);
  };
  

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     const response = await fetch(imageSrc);
  //     const blob = await response.blob();
  //     const url = URL.createObjectURL(blob);
  //     setDownloadUrl(url);
  //   };
  //   fetchImage();
  // }, [imageSrc]);

 

  const getStatusStyle = (status) => {
    switch (status) {
      case true:
        return { color: 'green',fontWeight:700 };
      case false :
        return { color: 'red',fontWeight:700 };
      default:
        return { color: 'black' };
    }
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-sm-2 p-0">
          <div className={styles.mobileSidebar}>
            <AdminSidebar className={styles.mobileSidebar} />
          </div>
        </div>
        <div className="col-sm-10 bg-white">
          <div className="container">
            <Header title="Add Creative" btnname="Create Campaigns" />
          </div>
          <div className="container">
            <div className="container">
              <div className="row">
                <h4 className="my-4 ps-0">
                  <b>Manage Google Ads</b>
                </h4>
                <hr />
                <div className="mt-2 p-0">
                  {isLoading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "50vh",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={loader} alt=""
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-group mb-3">
                              <input
                                style={{ borderRadius: 10 }}
                                type="text"
                                className="form-control"
                                placeholder="Search by Name"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                              />
                              <button
                                className="btn btn-primary common-btn"
                                type="button"
                                style={{
                                  backgroundColor: "#47beb9",
                                  marginLeft: 10,
                                  borderRadius: 10,
                                }}
                                onClick={() => setSearchQuery("")}
                              >
                                Clear
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className={StylesContaent.tableHeading}>
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th className="border">FullName</th>
                              <th className="border">Email</th>
                              <th className="border">Amount</th>
                              <th className="border">Start Date</th>
                              <th className="border">End Date</th>
                              <th className="border">Payment Status</th>
                              <th className="border">Ad Status</th>

                              <th className="border">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentCustomers.map((data) => (
                                
                              <tr key={data?.id}>
                                <td
                                  className="border"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {data?.result?.fullName}
                                </td>
                                <td className="border">{data?.result?.email}</td>
                                <td className="border">{currencySymbol(data?.currency)+ " "+data?.amount}</td>
                                <td className="border" style={{ whiteSpace: 'nowrap' }}>
  {data?.startDate?.split("T")[0]}
</td>
<td className="border" style={{ whiteSpace: 'nowrap' }}>
  {data?.endDate?.split("T")[0]}
</td>

                                <td className="border">{data?.paymentStatus}</td>
                               
                                <td
  className="border"
  style={getStatusStyle(data?.apiStatus)}
>

  {data?.apiStatus===true?"Success":"Failed"}

</td>
                           
                                <td className="border">
                                  <div className="dropdown">
                                    <button
                                    className="btn btn-primary"
                                      onClick={() =>
                                        openDeleteModal(data)}
                                    >
                                      View
                                    </button>
                                 
                                  </div>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {/* Pagination */}
                      <div className="d-flex justify-content-center mt-3">
                        <ul className="pagination">
                          {currentPage > pageRange && (
                            <li className="page-item">
                              <button
                                className="btn btn-primary"
                                style={{ backgroundColor: "#47beb9" }}
                                onClick={() => paginate(1)}
                              >
                                1
                              </button>
                            </li>
                          )}
                          {currentPage > pageRange && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {pageNumbers.map((pageNumber) => (
                            <li
                              key={pageNumber}
                              className={
                                `${currentPage === pageNumber}`
                                  ? `${StylesContaent.activePage}`
                                  : ""
                              }
                            >
                              <button
                                className={`${StylesContaent.activePage} page-link`}
                                onClick={() => paginate(pageNumber)}
                              >
                                {pageNumber}
                              </button>
                            </li>
                          ))}
                          {currentPage + Math.floor(pageRange / 2) <
                            totalPages && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {currentPage + Math.floor(pageRange / 2) <
                            totalPages && (
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={() => paginate(totalPages)}
                              >
                                {totalPages}
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer /> */}

        {/* Delete Confirmation Modal */}
   


        <Modal show={showDeleteModal} onHide={closeDeleteModal}>
  <Modal.Header closeButton>
    <Modal.Title>Ad Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
   
    {
      message?.apiStatus === true ? (
        <div>
          {/* <h5>Ad Type</h5>
          {message.type} */}
          
          <h5>API status</h5>
          <h6
            style={getStatusStyle(message.apiStatus)}>
            {"API is running"}
          </h6>
        </div>
      ) : (
        <div>
          <h5>Description</h5>
          {message?.GoogleDisplayDescription}
          <h5>Headline</h5>
          {message?.GoogleDisplayHeadline}
          <br /> <br/>
          <h5>Website Url :     {message?.WebsiteUrl} </h5>
          <br /> 
          <h5>Website Title :     {message?.websiteTitle} </h5>
          <br /> 
          <h5>Country :     {message?.CountryCode} </h5>
          <br /> 
          <h5>Cities :</h5>
          <ul>
            {message?.Cities?.map((city, index) => (
              <li key={index}>{city}</li>
            ))}
          </ul>
          <br /> 
          <h5>keyWords :</h5>
          <ul>
            {message?.keywords?.map((city, index) => (
              <li key={index}>{city}</li>
            ))}
          </ul>
          <br></br>
          <h5>Image :</h5>
          <div>
          {
      message?.image ? (

        <img src={`${nodeUrl}/${(JSON.parse(message?.image))?.imageUrl}`} alt="" style={{ maxWidth: 'auto', height: 'auto' }} />
      ) : (

        <h5>No Image</h5>

      )
    }
  
  <br />
  {/* <button >Download Image</button> */}
</div>  
        </div>
      )
    }
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={closeDeleteModal}>
      Cancel
    </Button>
  </Modal.Footer>
</Modal>




      </div>
    </div>
  );
}

const handleDownload = (imagePath) => {
  const link = document.createElement('a');
  link.href = imagePath;
  link.download = `image_${Date.now()}.jpg`;
  link.click();
};

export default  ManageAdsGoogle;
