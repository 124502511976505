import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import axios, { nodeUrl } from "../../../axios/auth";
import { toast } from "react-toastify";
import { Button, Col, Modal, Row, Card, Spinner, Container } from "react-bootstrap";
import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import styles from "../sidebar/AdminSidebar.module.css";
import loader from "../images/ZZ5H.gif";

function ImageRepository() {
  const token = Cookies.get("token");
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [myImg, setMyImg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFile(files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      toast.error("Please select a file.", {
        className: "custom-toast-error",
        hideProgressBar: true,
      });
      return;
    }
    const formData = new FormData();
    selectedFile.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
    try {
      const response = await axios.post("/imageupload", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.message) {
        toast.success("Image uploaded successfully!", {
          className: "custom-toast-success",
          hideProgressBar: true,
        });
        getImage();
        fileInputRef.current.value = "";
        setSelectedFile([]);
      } else {
        toast.error("Image upload failed.", {
          className: "custom-toast-error",
          hideProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  async function getImage() {
    setIsLoading(true);
    try {
      const response = await axios.get("/get-image", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMyImg(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(console.error);
    }
  }

  useEffect(() => {
    getImage();
  }, [token]);

  const openDeleteModal = (imageId) => {
    setDeleteImageId(imageId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteImageId(null);
    setShowDeleteModal(false);
  };

  const handleDeleteCustomer = async () => {
    try {
      const response = await axios.post(`/imagedelete/${deleteImageId}`, "", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      getImage();
      toast.success("Image deleted successfully", {
        className: "custom-toast-success",
        hideProgressBar: true,
      });
      setMyImg(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
    closeDeleteModal();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <AdminSidebar className={styles.mobileSidebar} />
        </div>
        <div className="col-sm-10 bg-light">
          <Container className="pt-4">
            <Header title="Manage Creative Assets" btnname="Create Campaign" />
            <h4 className="my-4 ps-0">
              <b>Manage Image Repository</b>
            </h4>
            <div className="mb-4 p-4 bg-white shadow-sm rounded">
              <h5>Image Upload</h5>
              <form onSubmit={handleSubmit} className="d-flex align-items-center gap-3">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  className="form-control"
                />
                <Button type="submit" variant="success">
                  Upload
                </Button>
              </form>
            </div>

            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Row className="mt-4">
                {myImg?.map((data) => (
                  <Col sm={4} md={3} lg={3} key={data.id} className="mb-4">
                    <Card className="shadow-sm">
                      <Card.Img variant="top" src={`${nodeUrl}/${data?.path}`} style={{ height: "200px", objectFit: "cover" }} />
                      <Card.Body className="d-flex justify-content-between align-items-center">
                        <Button variant="outline-danger" onClick={() => openDeleteModal(data._id)}>
                          Delete
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </div>
      </div>

      <Modal show={showDeleteModal} onHide={closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this image?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteCustomer}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ImageRepository;
