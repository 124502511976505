import React, { useContext, useState, useEffect } from "react";

import livePreview from "../../../assets/images/textScreen/livePreview.svg";

import "./CreatedList.css";
// import Header from "";
import "./Creative.css";
import "./C.css";

import { Link, useLocation, useParams } from "react-router-dom";
import { DataContext } from "./DataProvider";
import Modal from "./Modal/Modal";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Template1 from "../AddTemplate/Template1/Template";
import Template2 from "../AddTemplate/Template2/Template";
import Template3 from "../AddTemplate/Template3/Template";
import Template4 from "../AddTemplate/Template4/Template";
import Template5 from "../AddTemplate/Template5/Template";
import Template6 from "../AddTemplate/Template6/Template";





function CreatedListing() {
  const { addCreative, size } = useSelector((state) => state.addcreative);
  const { logoUrl, imageUrl, description, title, callToAction, color } =
    addCreative;


  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");
  const [img5, setImg5] = useState("");
  const [img6, setImg6] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalNo, setModalNo] = useState('');



  const openModal = (e) => {
    setModalNo(e);
    setModalVisible(true);

  };

  const closeModal = () => {
    setModalVisible(false);
  };

  // const handleOutsideClick = (event) => {
  //   if (event.target === event.currentTarget) {
  //     closeModal();
  //   }
  // };


  useEffect(() => {
    setTimeout(() => {

      setImg1(localStorage.getItem('imageData'))
      setImg2(localStorage.getItem('imageData1'))
      setImg3(localStorage.getItem('imageData2'))
      setImg4(localStorage.getItem('imageData3'))
      setImg5(localStorage.getItem('imageData4'))
      setImg6(localStorage.getItem('imageData5'))

      // setImg1([...img1,( localStorage.getItem('imageData2'))])
      // setImg1([...img1,( localStorage.getItem('imageData3'))])
      // setImg1([...img1,( localStorage.getItem('imageData4'))])
      // setImg1([...img1,( localStorage.getItem('imageData5'))])


    }, 2000)

  }, [])


  //___________________________

  // Size of template
  const sizeIs = size ? size : "1080x1080";

  const inputString = sizeIs;
  const numbersArray = inputString.split("x");

  const width = parseInt(numbersArray[0]); // Convert to an integer
  const height = parseInt(numbersArray[1]); // Convert to an integer

let w,h;
if(width==height){
w=width/4;
h=height/4;
}else if(width<height) {
  w=width/4;
  h=height/3.5; 
}else  {
  w=width/3.5;
  h=height/4; 
}


  return (
    <>

      <div>
        <div style={{ position: "absolute", zIndex: "-9" }} >
          <Template1 />
        </div>
      </div>
      <div>
        <div style={{ position: "absolute", zIndex: "-9" }} >
          <Template2 />
        </div>
      </div>
      <div>
        <div style={{ position: "absolute", zIndex: "-9" }} >
          <Template3 />
        </div>
      </div>
      <div>
        <div style={{ position: "absolute", zIndex: "-9" }} >
          <Template4 />
        </div>
      </div>
      <div>
        <div style={{ position: "absolute", zIndex: "-9" }} >
          <Template5 />
        </div>
      </div>
      <div>
        <div style={{ position: "absolute", zIndex: "-9" }} >
          <Template6 />
        </div>
      </div>


      <div className="row  h-100 m-0 p-0">
        <div className="col-sm-2 sidebar-bg-color px-0 mx-0 sidebar-border">
          <div className="">
            <Sidebar className="" />
          </div>
        </div>


        <div className="col-sm-10 bg-white">
          <div className="container">
            <div className="container">
              <Header title="Created Images" btnname="Upgrade Plan" />
            </div>
            <p>{`Size is ${size ? size : "1080x1080"}`}</p>

            <div className="row text-end">
              <Link to="/user/textScreen">
                <button className="btn btn2 text-white">
                  Edit{" "}
                  <i className="fa-regular fa-pen-to-square text-white"></i>{" "}
                </button>
              </Link>
            </div>

            {/* Modal Code */}
            {
              <Modal modalVisible={modalVisible} setModalVisible={setModalVisible} setModalNo={setModalNo} >
                {/* {modalNo === 1 && <img style={{ width: width, height: height }} src={img1} />}
                 */}
                {modalNo === 1 && <Template1 width={width} height={height} />}
                
                {/* {modalNo === 2 && <img style={{ width: width, height: height }} src={img2} />}
                {modalNo === 3 && <img style={{ width: width, height: height }} src={img3} />}
                {modalNo === 4 && <img style={{ width: width, height: height }} src={img4} />}
                {modalNo === 5 && <img style={{ width: width, height: height }} src={img5} />}
                {modalNo === 6 && <img style={{ width: width, height: height }} src={img6} />} */}
              </Modal>
            }
            <div
              className="row mt-3 justify-content-center "
            >
              <div className="" onClick={() => 
                openModal(1)}>
                 <img src={img1} style={{ height: h, width: w}} alt="" ></img>
              </div>

              {/* Second Template image show */}

              <div className="col-md-4 fix-box" onClick={() => openModal(2)}>
                <div className="card card-border border-0">
                  <div className="card-header bg-transparent">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img src={livePreview} style={{ height: "20px" }} alt="" />
                        <div className="ms-3">
                          <h6 className="card-heading fw-bold">Live Preview</h6>
                        </div>
                      </div>
                      <div>
                        <i className="fa-regular fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 fix-box">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="">
                          <img
                            src={logoUrl} alt=""
                            className="watchLogo  text-start"
                          ></img>
                        </div>
                        <div className="row text-center">
                          <div>
                            <h4 className="fashion2"> {title}</h4>
                            {/* <h3>ELEVATE YOUR AUDIO EXPERIENCE</h3> */}
                          </div>
                          <div>
                            <p className="fw-bold"> {description} </p>
                            {/* <p> giving information on its origins
                      </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div
                          className="border-div-img ms-4"
                          style={{ backgroundColor: color }}
                        >
                          <img src={img2} className="rounded-img" alt="" ></img>
                          {/* <img src={person} className='rounded-img'></img> */}
                        </div>
                        <div>
                          <p className="fw-bold"> {callToAction} </p>
                          {/* <p> giving information on its origins
                      </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div></div>
                      {/* <Link to="/pages/textScreen">
                  <button className="btn btn2 text-white">
                    Edit <i className="fa-regular fa-pen-to-square text-white"></i>{" "}
                  </button></Link> */}
                      <button className="btn btn1 text-white">
                        Download <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 fix-box" onClick={() => openModal(3)}>
                <div className="card card-border border-0">
                  <div className="card-header bg-transparent">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img src={livePreview} style={{ height: "20px" }} alt="" />
                        <div className="ms-3">
                          <h6 className="card-heading fw-bold">Live Preview</h6>
                        </div>
                      </div>
                      <div>
                        <i className="fa-regular fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 fix-box">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="">
                          <img
                            src={logoUrl}
                            className="watchLogo  text-start" alt=""
                          ></img>
                        </div>
                        <div className="row text-center">
                          <div>
                            <h4 className="fashion2"> {title}</h4>
                          </div>
                          <div>
                            <p className="fw-bold"> {description} </p>

                          </div>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div
                          className="border-third-div ms-4"
                          style={{ backgroundColor: color }}
                        >
                          <div className="border-inner-third-div">
                            <img src={img3} className="rounded-img3" alt=""></img>
                          </div>
                        </div>
                        <div>
                          <p className="fw-bold"> {callToAction} </p>

                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div></div>
                      {/* <Link to="/pages/textScreen">
                  <button className="btn btn2 text-white">
                    Edit <i className="fa-regular fa-pen-to-square text-white"></i>{" "}
                  </button></Link> */}
                      <button className="btn btn1 text-white">
                        Download <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="row mt-3 justify-content-center gy-2">
              <div className="col-md-4 fix-box" onClick={() => openModal(4)}>
                <div className="card card-border border-0">
                  <div className="card-header bg-transparent">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img src={livePreview} style={{ height: "20px" }} alt="" />
                        <div className="ms-3">
                          <h6 className="card-heading fw-bold">Live Preview</h6>
                        </div>
                      </div>
                      <div>
                        <i className="fa-regular fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 fix-box">
                    <div className="row">
                      <div className="">
                        <img
                          src={logoUrl}
                          className="watchLogo  text-start" alt=""
                        ></img>
                      </div>
                      <div className="row text-center">
                        <div
                          className="border-div-img ms-4"
                          style={{ backgroundColor: color }}
                        >
                          <img src={img4} className="rounded-img" alt="" ></img>
                          {/* <img src={person} className='rounded-img'></img> */}
                        </div>
                        <div>
                          <p className="fw-bold"> {callToAction} </p>
                          {/* <p> giving information on its origins
                      </p> */}
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="row text-center">
                          <div>
                            <h4 className="fashion2"> {title}</h4>
                            {/* <h3>ELEVATE YOUR AUDIO EXPERIENCE</h3> */}
                          </div>
                          <div>
                            <p className="fw-bold"> {description} </p>
                            {/* <p> giving information on its origins
                      </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div></div>
                      {/* <Link to="/pages/textScreen">
                  <button className="btn btn2 text-white">
                    Edit <i className="fa-regular fa-pen-to-square text-white"></i>{" "}
                  </button></Link> */}
                      <button className="btn btn1 text-white">
                        Download <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 fix-box" onClick={() => openModal(5)}>
                <div className="card card-border border-0">
                  <div className="card-header bg-transparent">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img src={livePreview} style={{ height: "20px" }}  alt="" />
                        <div className="ms-3">
                          <h6 className="card-heading fw-bold">Live Preview</h6>
                        </div>
                      </div>
                      <div>
                        <i className="fa-regular fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 fix-box">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="">
                          <img
                            src={logoUrl}
                            className="watchLogo  text-start" alt=""
                          ></img>
                        </div>
                        <div className="row text-center">
                          <div>
                            <h4 className="fashion2"> {title}</h4>
                            {/* <h3>ELEVATE YOUR AUDIO EXPERIENCE</h3> */}
                          </div>
                          <div>
                            <p className="fw-bold"> {description} </p>
                            {/* <p> giving information on its origins
                      </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div
                          className="border-div-img ms-4"
                          style={{ backgroundColor: color }}
                        >
                          <img src={img5} className="rounded-img" alt="" ></img>
                          {/* <img src={person} className='rounded-img'></img> */}
                        </div>
                        <div>
                          <p className="fw-bold"> {callToAction} </p>
                          {/* <p> giving information on its origins
                      </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div></div>
                      {/* <Link to="/pages/textScreen">
                  <button className="btn btn2 text-white">
                    Edit <i className="fa-regular fa-pen-to-square text-white"></i>{" "}
                  </button></Link> */}
                      <button className="btn btn1 text-white">
                        Download <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 fix-box" onClick={() => openModal(6)}>
                <div className="card card-border border-0">
                  <div className="card-header bg-transparent">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img src={livePreview} style={{ height: "20px" }} alt="" />
                        <div className="ms-3">
                          <h6 className="card-heading fw-bold">Live Preview</h6>
                        </div>
                      </div>
                      <div>
                        <i className="fa-regular fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 fix-box">
                    <div className="row">
                      <div className="col-12 ">
                        <div className="">
                          <img
                            src={logoUrl}
                            className="watchLogo  text-start" alt=""
                          ></img>
                        </div>
                        <div className="row text-center">
                          <div>
                            <h4 className="fashion2"> {title}</h4>
                            {/* <h3>ELEVATE YOUR AUDIO EXPERIENCE</h3> */}
                          </div>
                          <div>
                            <p className="fw-bold"> {description} </p>
                            {/* <p> giving information on its origins
                      </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div
                          className="border-third-div ms-4"
                          style={{ backgroundColor: color }}
                        >
                          <div className="border-inner-third-div">
                            <img src={img6} className="rounded-img3" alt="" ></img>
                          </div>
                          {/* <img src={person} className='rounded-img'></img> */}
                        </div>
                        <div>
                          <p className="fw-bold"> {callToAction} </p>
                          {/* <p> giving information on its origins
                      </p> */}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div></div>
                      {/* <Link to="/pages/textScreen">
                  <button className="btn btn2 text-white">
                    Edit <i className="fa-regular fa-pen-to-square text-white"></i>{" "}
                  </button></Link> */}
                      <button className="btn btn1 text-white">
                        Download <i className="fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
}

export default CreatedListing;
