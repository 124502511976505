import React from 'react'
import "./settingHeader.css";
import {  useNavigate } from "react-router-dom";
// import DownloadIcon2 from '../../../../../icons/downloadIcon2';
// import Logo from "../../../../../../assets/images/bonton.png";

import axios from 'axios';
import Cookies from "js-cookie";
import {  toast } from "react-toastify";
import { nodeUrl } from '../../../../../axios/auth';
export default function SettingHeader({ setDeleteModal, setCancleModal }) {
  const navigate = useNavigate();


  const checkPlan = async () => {
 
    const token = Cookies.get("token");
    try {
      const res = await axios.get(
        `${nodeUrl}/userActivePlans`,
       
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
   
      let userActivePlan = res?.data?.status

       if(userActivePlan ===true){
        setCancleModal(true)
       }
       else{
        // toast.error("You Dont Have Subscription");
        toast.error("You Dont Have Subscription", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
        
       }

    } catch (error) { }
  };
  return (
    <div className='setting-header-section'>
      <div className='setting-header-left'>
       

        <div className="upgradeNow-button">
          <button onClick={() => navigate("plans")}>Upgrade Now</button>
        </div>
      </div>

      <div className="setting-header-right">
        <div className="option-box" onClick={() => setDeleteModal(true)}>
          <p>Delete Accounts</p>
        </div>
        <div className="option-box" onClick={() => checkPlan()}>
          <p>Cancel Subscription</p>
        </div>
      </div>
    </div>
  )
}
