import React from 'react'
import "./brandSetupBanner.css";
import { useSelector } from 'react-redux';
// import RightSideImg from "../../../../../../assets/images/brand-set-up-img.png";

export default function BrandSetupBanner() {
    // const { onlyCreativeState } = useSelector((state) => state.creativeData);
    const {brandData} =useSelector((state)=>state?.brandSetupData)

    return (
        <div className="brand-setup-main-section">

            <div className="brand-setup-banner-section">
                <div className="brand-left-side">
                    <h1>{brandData?.createProject ?"Project Setup" : "Brand Setup"   }</h1>
                    <p> To generate ad creatives, you must have a brand, so let's setup it up.</p>
                </div>
             
            </div>
        
        </div>
    )
}
