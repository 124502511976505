import React, { useCallback, useContext, useEffect, useState } from "react";
import "./brandSetupForm.css";
import { average, prominent } from "color.js";
import BottomArrow from "../../../../../../assets/icons/bottom-arrow.svg";
import BrandIcon from "../../../../../icons/brandIcon";
import UploadPlusIcon from "../../../../../icons/uploadPlusIcon";
import { useDispatch, useSelector } from "react-redux";
import { logoColorPikedFun } from "../../../../../../Redux/addCreateSlice";
import { toast } from "react-toastify";
import { addBrandData } from "../../../../../../Redux/brandSetupData";
import _ from 'lodash';
import FontChanger from "../../BrandsEntry/FontChange/FontChange";
import { MailContext } from "../../../../Auth Pages/mailContext";
export default function BrandSetupForm({setSelectedFont,selectedFont, HandelLogo,brandName,setBrandName,description,setDescription,fontFamily,
  setProjectName,
  projectName}) {
    
  const {brandData} =useSelector((state)=>state?.brandSetupData)
  const [dropdownox, setDropdownox] = useState(false);
  const { HandleFonts, fonts } = useContext(MailContext);
  
  const { onlyCreativeState } = useSelector((state) => state.creativeData);
  const dispatch = useDispatch();
  // handleLogo Function

  


  const handleBrandNameText = (e) => {
    const value = e.target.value;
    if(brandData?.createProject){
 setProjectName(value)
    }
    else{
      setBrandName(value);
    }
  
    // debouncedDispatch(value);
  };


  // const debouncedDispatch = useCallback(
  //   _.debounce((value) => {
  //     const updateData={
  //       brandName:value
  //     }
  //     dispatch(addBrandData({brandName:value}));
  //   }, 500), 
  //   []
  // );




const handleDesciption =(e)=>{
  const value = e.target.value;
  setDescription(e.target.value)
      dispatch(addBrandData({brandDescription:value}));
  // debouncedDispatchDesc(value);
}

// const debouncedDispatchDesc = useCallback(
//   _.debounce((value) => {
//     const updateData={
//       ...brandData,
//       brandDescription:value
//     }
//     dispatch(addBrandData(updateData)) }, 500), 
//   []
// );



  const handleBrandName = () => {
    if(!brandName&&brandName==""){
        // toast.error("Please enter the brand name first")
        toast.error("Please enter the brand name first", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
  
    }
  };
  const handleFont=(font)=>{
    setSelectedFont(font)
    dispatch(addBrandData({selectedFont:font}));
    setDropdownox(!dropdownox)
  }


  const HandleCurrentFonts = (e) => {
    dispatch(addBrandData({selectedFont:e}));
    // setCurrentFontFamily(e);
    HandleFonts(e);
  };
  useEffect(()=>{
setProjectName(brandData?.projectName)

  },[])

  return (
    <div className="brand-setupform-section">
      <div className="brand-setupform-flex-alignment">
        <div className="brand-name">
          <div className="brand-name-label">
            <BrandIcon />
            <label>{brandData?.createProject?" Project Name":"Brand Name" }</label>
          </div>

          <div className="brand-input-alignment">
            <input
              type="text"
              maxLength={50}
              placeholder="Example - EXOADS"
              // value={brandData?.createProject?projectName: brandName}
              value={projectName}
              onChange={(e) => handleBrandNameText(e)}
            />  
          </div>
        </div>
        <div className="logo-alignment">
          <div className="logo-box" onClick={handleBrandName}>
            <div className={brandData.logo? "child-boxImg" : "child-box"}>
              {brandData?.logo ? (
                
                <img
                  className="rounded-circle"
                  src={brandData.logo}
                 
                  style={{ height: "70px", width: "70px" }}
                />
              ) : (
                <UploadPlusIcon />
              )}
            </div>
             
              <input type="file" required accept="image/*" onChange={HandelLogo} />
          
          </div>
          <label>Logo Upload</label>
        </div>
        <div className="font-selection-alignment">
          <label>Font Selection</label>

{/* test */}
<FontChanger style={{border:"linear-gradient(135deg, #47beb9 0%, #565656 100%)"}}
                    HandleCurrentFonts={HandleCurrentFonts}
                    fonts={fonts}
                  />

          {/* <div className="font-selection-input-alignment">
            <div className="font-selection-input">
               <input type="text" style={{fontFamily:selectedFont}}   value={brandData.selectedFont??"Poppins"}  onClick={() => setDropdownox(!dropdownox)}/> 
              <div onClick={() => setDropdownox(!dropdownox)}>
                <img
                  src={BottomArrow}
                  alt="BottomArrow"
                  className={dropdownox ? "rotate-arrow" : ""}
                />
              </div>
            </div>
            <div
              className={
                dropdownox
                  ? "drop-down-alignment open-dropdown-box"
                  : "drop-down-alignment hidden-dropdown-box"
              }
            >
            {fontFamily.map((element,index)=>{
                return <p onClick={()=>handleFont(element)}>{element} </p>
            })}

            </div>
          </div> */}
        </div>  
      </div>
{!brandData?.createProject &&
      <div className="brand-description-alignment">
        <label>Describe Your Brand</label>

        <div className="brand-description-textarea">
          <textarea placeholder="Product/Service Description maximum characters 1000" maxLength={350} value={description} onChange={(e)=>handleDesciption(e)}></textarea>
        </div>
      </div>}
    </div>
  );
}
