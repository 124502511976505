// Features.module.css will be the main css for this component.
import React, { useEffect, useState } from "react";

import style from "./Features.module.css";

// import user from "../../../Images/userImg.png";
// import Sebastian from "../../../Images/Sebastian.png";
// import Sebastian2 from "../../../Images/Sebastian2.png";
// import Sebastian3 from "../../../Images/Sebastian3.png";
// import EditPick1 from "../../../Images/EditPick1.png";
// import EditPick2 from "../../../Images/EditPick2.png";
// import EditPick3 from "../../../Images/EditPick3.png";
// import FeatureFooterMain from "../../../Images/FeatureFooter2.png";

// import EditPick4 from "../../../Images/EditPick4.png";
// import ArticleSofa from "../../../Images/articleSofa.png";
// import ArticleCamera from "../../../Images/articlecamera.png";
// import ArticeCar from "../../../Images/articeCar.png";
// import CarUser from "../../../Images/CarUser.png";
// import FeatureFooter from "../../../Images/FeatureFooter.png";

// import SofaUser from "../../../Images/SofaUser.png";

// import Cipay from "../../../Images/Cipay.png";

import { Col, Container, Row, Spinner } from "react-bootstrap";
// import Main from "../../../Images/FeaturesMain.png";
import {
  EditSvg,
  // EyeSvg,
  // ShareSvg,
  // TimmerSvg,
  lineDivider,
} from "../../../Images/landingSvg";
import { useNavigate } from "react-router-dom";
import axios, { nodeUrl } from "../../../../component/axios/auth";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
// import API from "../../../../component/axios/auth";



const Features = () => {
  const [allBlogs, setAllBlogs] = useState(null);
  const [latestBlogs, setLatestBlogs] = useState(null);

  const [bannerBlog, setBannerBlog] = useState(null);

  const [selectedBlogs, setSelectedBlog] = useState(null);
  const [pagination, setPegination] = useState(0);

  const [tags, setTags] = useState("Technology");

  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const getAllBlog = async () => {
      try {
        let res = await axios.get("/get-blog");
        setAllBlogs(res?.data?.data);
        setPegination(allBlogs?.length);
      } catch (error) {
      }
    };
    getAllBlog();
  }, []);

  useEffect(() => {
    if (allBlogs?.length > 0) {
      let Selectedtag = allBlogs?.filter((item) => item?.tag === tags);
      setSelectedBlog(Selectedtag);
      setBannerBlog(allBlogs[0]);
    }
  }, [tags, allBlogs]);

  useEffect(() => {
    if (allBlogs?.length > 0) {
      setLatestBlogs(allBlogs.slice(currentPage * 2, currentPage * 2 + 2));
    }
  }, [allBlogs, currentPage]);

  function truncateName(name, maxLength) {
    if (name?.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    } else {
      return name;
    }
  }

  function formatDate(timestamp) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(timestamp);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = day + " " + months[monthIndex] + ", " + year;
    return formattedDate;
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const HandleBlogSummery = (item) => {
    sessionStorage.setItem("blogSummery", JSON.stringify(item));
    navigate(`/landingpage/blog-detail`);
    scrollToTop();
  };

  return (
    <Container fluid style={{ padding: "0px" }}>
      {/* Frame 1 */}
      <div className={style.featureContainer}>
        <Col
          lg={8}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <h5>Let’s Become</h5>

          <h3>
            Success <span>Entrepreneurs.</span>
          </h3>
          <p>with Dylan Nicholls</p>
        </Col>
      </div>

      {/* frame 2 */}

      <Row style={{ margin: "0px" }}>
        <Col lg={8} className={style.FeaturesBlogComponentCol} style={{}}>
          <div
            className={style.FeaturesBlogComponent}
            style={{
              background: `url(${nodeUrl}/public/${bannerBlog?.displayImage})`,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "32px",
                gap: "14px",
                maxWidth: "500px",
              }}
            >
              <div
                className={style.blogsGradient}
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <button>{bannerBlog?.tag}</button>
                </div>
                <div lg={8} className={style.FeaturesColForPara}>
                  <h3>{truncateName(bannerBlog?.heading, 40)}</h3>
                  <p>{truncateName(bannerBlog?.description, 100)}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "24px",
                  }}
                >
                  <div
                    className={style.FeaturesSvgDiv}
                    style={{
                      display: "flex",
                    }}
                  >
                    {EditSvg}
                    <p>{formatDate(bannerBlog?.createdAt)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Blogs from people */}
        </Col>

        <Col style={{ padding: "0px" }} lg={4}>
          {/* Latest solo user blog */}

          <div className={style.FeaturesPeopleBlogLatest}>
            <h4>Latest Stories</h4>

            {/*Latest first user blog */}
            <div
              style={{ display: "flex", gap: "20px", flexDirection: "column" }}
            >
              {latestBlogs?.map((blog) => (
                <div
                  onClick={() => HandleBlogSummery(blog)}
                  style={{ cursor: "pointer" }}
                >
                  <div className={style.FeaturesblogUserSubDiv}>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <img
                        style={{
                          width: "40px",
                          height: "36px",
                          borderRadius: "100%",
                        }}
                        src={`${nodeUrl}/public/${blog?.personImage}`}
                        alt="user"
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>{blog?.personName}</label>
                        <p>{blog?.personDesignation}</p>
                      </div>
                    </div>
                    <button>{blog?.tag}</button>
                  </div>
                  {/* 2 user text content */}
                  <div className={style.FeaturesBlogLatestUserText}>
                    <h2>{blog?.heading}</h2>
                    <p>{truncateName(blog?.description, 200)}</p>
                  </div>
                  <div>{lineDivider}</div>
                </div>
              ))}
            </div>

            <ResponsivePagination
              current={currentPage}
              total={allBlogs && Math.floor(allBlogs?.length / 2)}
              onPageChange={setCurrentPage}
            />
            {/* divider */}
          </div>
        </Col>
      </Row>
      {/* frame 3  EditPick*/}
      <Row style={{ margin: "0px" }}>
        <div className={style.FeatureEditPicks}>
          <div className={style.FeatureEditPicksHeader}>
            <h2>Tags</h2>
            <div className={style.buttonContainer}>
              {/* Svg btns */}
              <button onClick={() => setTags("Creative")} >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="140"
                  height="61"
                  viewBox="0 0 176 61"
                  fill={tags === "Creative" ? "#47BEB9" : "none"}
                >
                  <g filter="url(#filter0_i_246_5631)">
                    <path
                      d="M2.01845 11.3884C2.01845 5.93738 6.43738 1.51845 11.8884 1.51845H155.193C159.778 1.51845 163.759 4.67535 164.804 9.13933L173.749 47.3625C175.198 53.555 170.498 59.4815 164.139 59.4815H11.8884C6.43737 59.4815 2.01845 55.0626 2.01845 49.6116V11.3884Z"
                      stroke="url(#paint0_linear_246_5631)"
                      stroke-width="3.03691"
                    />
                  </g>
                  <text
                    x="26%"
                    y="64%"
                    font-family="Arial"
                    font-size="20"
                    // fill="#"
                    className={style.SvgText}
                    fill={tags !== "Creative" ? "#47beb9" : "#fff"}
                  >
                    Creative
                  </text>
                  <defs>
                    <filter
                      id="filter0_i_246_5631"
                      x="0.5"
                      y="0"
                      width="188.698"
                      height="73.1476"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_246_5631"
                      />
                    </filter>
                    {tags !== "Creative" && (

                      <linearGradient
                        id="paint0_linear_246_5631"
                        x1="0.5"
                        y1="0"
                        x2="173.338"
                        y2="42.9259"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#47BEB9" />
                        <stop offset="1" stop-color="#215856" />
                      </linearGradient>
                    )}
                  </defs>
                </svg>
              </button>



              {/* btn 2 */}
              <button onClick={() => setTags("Personal")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="140"
                  height="61"
                  viewBox="0 0 176 61"
                  fill={tags === "Personal" ? "#47BEB9" : "none"}
                >
                  <g filter="url(#filter0_i_246_5637)">
                    <path
                      d="M174.482 49.6116C174.482 55.0626 170.063 59.4815 164.612 59.4815H21.3066C16.722 59.4815 12.741 56.3246 11.6963 51.8607L2.75102 13.6375C1.30183 7.44503 6.00156 1.51845 12.3613 1.51845H164.612C170.063 1.51845 174.482 5.93738 174.482 11.3884V49.6116Z"
                      stroke="url(#paint0_linear_246_5637)"
                      stroke-width="3.03691"
                    />
                  </g>
                  <text
                    x="30%"
                    y="64%"
                    font-family="Arial"
                    font-size="20"
                    className={style.SvgText}
                    fill={tags !== "Personal" ? "#47beb9" : "#fff"}
                    // fill="#000"
                  >
                    Personal
                  </text>
                  <defs>
                    <filter
                      id="filter0_i_246_5637"
                      x="0.967773"
                      y="0"
                      width="188.698"
                      height="73.1476"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_246_5637"
                      />
                    </filter>
                    {tags !== "Personal" && (
                      <linearGradient
                        id="paint0_linear_246_5637"
                        x1="176"
                        y1="61"
                        x2="3.16197"
                        y2="18.0741"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#47BEB9" />
                        <stop offset="1" stop-color="#215856" />
                      </linearGradient>
                    )}
                  </defs>
                </svg>
              </button>
              {/* btn 3 */}
              <button onClick={() => setTags("Digital Marketing")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="214"
                  height="61"
                  viewBox="0 0 274 61"
                  fill={tags === "Digital Marketing" ? "#47BEB9" : "none"}
                >
                  <g filter="url(#filter0_i_246_5639)">
                    <path
                      d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H247.742C251.886 1.51845 255.589 4.10758 257.012 8.00013L270.982 46.2233C273.336 52.6629 268.569 59.4815 261.712 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                      stroke="url(#paint0_linear_246_5639)"
                      stroke-width="3.03691"
                    />
                  </g>

                  <text
                    x="20%"
                    y="64%"
                    font-family="Arial"
                    font-size="20"
                    // fill="#000"
                    className={style.SvgText}
                    fill={tags !== "Digital Marketing" ? "#47beb9" : "#fff"}
                  >
                    Digital Marketing
                  </text>
                  <defs>
                    <filter
                      id="filter0_i_246_5639"
                      x="0"
                      y="0"
                      width="286.776"
                      height="73.1476"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_246_5639"
                      />
                    </filter>
                    {tags !== "Digital Marketing" && (
                      <linearGradient
                        id="paint0_linear_246_5639"
                        x1="0"
                        y1="0"
                        x2="249.108"
                        y2="96.626"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#47BEB9" />
                        <stop offset="1" stop-color="#215856" />
                      </linearGradient>
                    )}
                  </defs>
                </svg>
              </button>

            </div>

            <div className={style.buttonContainer}>
              {/* Svg btns */}
              <button onClick={() => setTags("Brands")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="140"
                  height="61"
                  viewBox="0 0 176 61"
                  fill={tags === "Brands" ? "#47beb9" : "none"}
                >
                  <g filter="url(#filter0_i_246_5634)">
                    <path
                      d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H154.693C159.278 1.51845 163.259 4.67535 164.304 9.13933L173.249 47.3625C174.698 53.555 169.998 59.4815 163.639 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                      stroke="url(#paint0_linear_246_5634)"
                      stroke-width="3.03691"
                    />
                  </g>
                  <text
                    x="26%"
                    y="64%"
                    font-family="Arial"
                    font-size="20"
                    // fill="#"
                    className={style.SvgText}
                    fill={tags !== "Brands" ? "#47beb9" : "#fff"}
                  >
                    Brand
                  </text>
                  <defs>
                    <filter
                      id="filter0_i_246_5634"
                      x="0"
                      y="0"
                      width="188.698"
                      height="73.1476"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_246_5634"
                      />
                    </filter>
                    {tags !== "Brands" && (
                      <linearGradient
                        id="paint0_linear_246_5634"
                        x1="0"
                        y1="0"
                        x2="172.838"
                        y2="42.9259"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#47BEB9" />
                        <stop offset="1" stop-color="#215856" />
                      </linearGradient>
                    )}
                  </defs>
                </svg>
              </button>

              {/* btn 2 */}
              <button onClick={() => setTags("Technology")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="140"
                  height="61"
                  viewBox="0 0 176 61"
                  fill={tags === "Technology" ? "#47BEB9" : "none"}
                >
                  <g filter="url(#filter0_i_246_5637)">
                    <path
                      d="M174.482 49.6116C174.482 55.0626 170.063 59.4815 164.612 59.4815H21.3066C16.722 59.4815 12.741 56.3246 11.6963 51.8607L2.75102 13.6375C1.30183 7.44503 6.00156 1.51845 12.3613 1.51845H164.612C170.063 1.51845 174.482 5.93738 174.482 11.3884V49.6116Z"
                      stroke="url(#paint_linear_246_5637)"
                      stroke-width="3.03691"
                    />
                  </g>
                  <text
                    x="22%"
                    y="64%"
                    font-family="Arial"
                    font-size="20"
                    // fill="#"
                    className={style.SvgText}
                    fill={tags !== "Technology" ? "#47beb9" : "#fff"}
                  >
                    Technology
                  </text>
                  <defs>
                    <filter
                      id="filter0_i_246_5637"
                      x="0.967773"
                      y="0"
                      width="188.698"
                      height="73.1476"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="13.6661" dy="12.1476" />
                      <feGaussianBlur stdDeviation="18.6011" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect1_innerShadow_246_5637"
                      />
                    </filter>
                    {tags !== "Technology" && (
                      <linearGradient
                        id="paint_linear_246_5637"
                        x1="176"
                        y1="61"
                        x2="3.16197"
                        y2="18.0741"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#47BEB9" />
                        <stop offset="1" stop-color="#215856" />
                      </linearGradient>
                    )}
                  </defs>
                </svg>
              </button>
            </div>
          </div>

          {selectedBlogs ? (
            <div className={style.featureEditPickBody}>
              {selectedBlogs?.slice(0, 3)?.map((item) => (
                <div
                  style={{ cursor: "pointer" }}
                  className={style.featureEditPickBodyItem}
                  onClick={() => HandleBlogSummery(item)}
                >
                  <img
                    src={`${nodeUrl}/public/${item?.displayImage}`}
                    alt="editpick1"
                  />
                  <p>{truncateName(item?.heading, 30)}</p>
                </div>
              ))}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </Row>

      {/* frame 4  All Article*/}
      <Row style={{}} className={style.FeatureAllArticleRow}>
        <div
          className={style.FeatureAllArticle}
          style={{ marginBottom: "20px" }}
        >
          <Col className={style.FeatureAllArticleSube} lg={4}>
            <h2>All Articles</h2>
            <span>
              We share common trends, strategies ideas, opinions, short & long
              stories from the team behind company.
            </span>
          </Col>

          <p>All Blogs</p>
        </div>
        <div className={style.articleMainBox}>
          {allBlogs?.slice(0, 9)?.map((item) => (
            <div
              className={style.FeaturesArticleBody}
              onClick={() => HandleBlogSummery(item)}
              style={{ cursor: "pointer" }}
            >
              <img
                style={{ borderRadius: "20px" }}
                src={`${nodeUrl}/public/${item?.displayImage}`}
                alt=""
              />
              <div className={style.FeaturesArticleText}>
                <h1>{truncateName(item?.heading, 26)}</h1>
                {/* description */}
                <p>{truncateName(item?.description, 100)}</p>
              </div>

              <div className={style.FeaturesArticleBottom}>
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "100%",
                    objectFit: "cover",
                    backgroundSize: "100% 100%",
                  }}
                  src={`${nodeUrl}/public/${item?.personImage}`}
                  alt=""
                />
                <div>
                  <h4 className={style.FeaturesArticleBottomName}>
                    {item?.personName}
                  </h4>
                  <p className={style.FeaturesArticleBottomDate}>
                    {formatDate(item?.createdAt)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
};

export default Features;
