import React from 'react'
import "./brandScreen.css";
import {  useNavigate } from 'react-router-dom';
export default function BrandScreen() {
   
    const navigate = useNavigate()

    return (
        <div className="brand-screen-section">
            <div className="brand-screen-section-details">
                <h1>Create Powerful Ad Campaigns for Facebook, Google, & Instagram</h1>

                <div className="brand-scrren-option-alignment">
                   
                    <a >Make your first Ad <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    </a>

                    <a  onClick={()=>navigate("/main/brand-setup")} className='new-brand'>
                        <button>+ Create New Brand </button>
                    </a>
                </div>
            </div>
        </div>
    )
}
