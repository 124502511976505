import React from 'react'

export default function SocilaIcon3() {
    return (
        <svg width="37" height="29" viewBox="0 0 37 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4528 18.5825C3.79836 18.5445 0.820413 14.1277 0.849825 9.2173C0.879237 4.30693 3.9062 -0.0389271 10.5606 0.000259399C17.215 0.0369949 20.193 4.41837 20.1635 9.32996C20.1341 14.2391 17.1072 18.6217 10.4528 18.5825ZM10.5324 4.91062C7.97238 4.8947 7.61576 7.38295 7.60473 9.25771C7.59492 11.1325 7.9209 13.6587 10.4797 13.6746C13.0385 13.6881 13.3964 11.168 13.4074 9.29077C13.4185 7.41479 13.0925 4.92409 10.5324 4.91062Z" fill="#47BEB9" />
            <path d="M27.2191 18.5838C20.5659 18.5433 17.588 14.1265 17.6174 9.21732C17.6444 4.30818 20.6738 -0.0401249 27.327 0.000284195C33.9814 0.0382462 36.9593 4.42084 36.9311 9.32998C36.9029 14.2403 33.8735 18.6205 27.2191 18.5838ZM27.3 4.90943C24.7412 4.89474 24.3858 7.38176 24.3748 9.25774C24.3625 11.1325 24.6885 13.6575 27.2498 13.6734C29.8074 13.6893 30.1652 11.1668 30.175 9.29202C30.1848 7.41604 29.8576 4.9229 27.3 4.90943Z" fill="#47BEB9" />
            <path d="M3.38319 18.5837C8.18098 20.7254 13.1589 21.6757 18.583 21.7075C24.2815 21.7406 28.6026 20.6654 34.0731 18.5837L34.0413 24.4823C29.1454 26.9081 23.8354 28.2171 18.545 28.1865C12.779 28.1522 8.62339 26.9693 3.34766 24.4823L3.38319 18.5837Z" fill="#47BEB9" />
        </svg>

    )
}
