import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import "./header.css";
function Header({ title, btnname }) {
  return (
    <>
      <div className="headerbar">
        <nav className="navbar navbar-expand-lg navbar-light bg-white mt-2 headerbar position-sticky ">
          <div className="container-fluid bg-white py-2 px-3 headerbar shadow">
            <a className="navbar-brand" href="#">
              {title ?? "navbar"}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <a
                    className="nav-link active  ms-2"
                    aria-current="page"
                    href="#"
                  >
                    {" "}
                    <FontAwesomeIcon icon={faSearch} className="me-3" />{" "}
                    <input
                      type="text"
                      placeholder="Search Something"
                      className="custom-input-header"
                    />{" "}
                  </a>
                </li>
              </ul>

              <div className="ms-3">
                <button className="btn btn-outline-dark">
                  <FontAwesomeIcon icon={faBell} />{" "}
                </button>
              </div>
              <div className="ms-3">
                <button className="btn btn-outline-dark">
                  <FontAwesomeIcon icon={faSignOutAlt} />{" "}
                </button>
              </div>
              <div className="ms-3">
                <button className="btn btn-prime"> {btnname} </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <FontAwesomeIcon />

      <div></div>
    </>
  );
}
export default Header;
