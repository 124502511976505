import React from "react";
import style from "./AiGenerate.module.css";
import { InputField } from "../../../NewCampaigns/NewCampaigns";

const Brand = () => {
  return (
    <div className={style.brandBox}>
      <InputField
        label={"Brand Name"}
        labelSize={"10px"}
        gap={"2px"}
        fontWeight={300}
        inputHeight={"40px"}
      />
      <InputField
        label={"Differentiators"}
        labelSize={"10px"}
        gap={"2px"}
        fontWeight={300}
      />
      <InputField
        label={"Audiences"}
        labelSize={"10px"}
        gap={"2px"}
        fontWeight={300}
        inputHeight={"70px"}
      />
      <InputField
        label={"Location"}
        labelSize={"10px"}
        gap={"2px"}
        fontWeight={300}
        inputHeight={"70px"}
      />
    </div>
  );
};

export default Brand;
