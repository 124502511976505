import React from "react";
import "./BackGround.css";
import { Col, Container, Row } from "react-bootstrap";
import { NavItems } from "../../../UI-Components/ReusableComponents";
import {
  BackgroundSvg,
  CreativeSizeSvg,
  FreeStockSvg,
  PicDataArray,
  TextAndBtnSvg,
} from "./SVg";
import { useNavigate } from "react-router-dom";
// import pic1 from "./Images/pic1.jpg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTempData } from "../../../../Redux/templateSlice";
import { useEffect } from "react";
// import PixabayImageSearch from "./StockImages/StockImages";
import {
  addBrandsetupData,
  addPreviewImgkData,
  addSingleEditDetails,
} from "../../../../Redux/addCreateSlice";
import Cookies from "js-cookie";
import axios from "../../../axios/auth";
import { leftArrow } from "../Campaigns/GoogleCampaign/SVG";
const BackGroundImages = () => {
  const [toggle, setToggle] = useState(false);
  const template = useSelector((state) => state.template.tempData);
  const dispatch = useDispatch();
  const [accountImg, setAccountImg] = useState();
  const [imgPickUrl, setImgPickUrl] = useState();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("nature");
  const [previousQ, setPreviousQ] = useState("");
  const userToken = Cookies.get("token");
  const apiKey = "41195581-a6a30be41ff9bef3f4ca1e8a0";
  const apiUrl = "https://pixabay.com/api/";
  const imageType = "photo";
  const editOne = useSelector((state) => state.addcreative.editOne);
  useEffect(() => {
    fetchData();
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const fetchData = (page) => {
    axios
      .get(apiUrl, {
        params: {
          key: apiKey,
          q: searchQuery,
          image_type: imageType,
          page: page,
        },
      })
      .then((response) => {
        if (response.config.params.q === previousQ) {
          setImages([...response.data.hits]);
        } else if (response.config.params.q !== previousQ) {
          setImages([...response.data.hits]);
        } else if (response.data.hits.length > 0) {
          setImages([...images, ...response.data.hits]);
          setPreviousQ(response.config.params.q);
          setCurrentPage(page);
        } else {
          setImages([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Pixabay API:", error);
      });
  };
  const LoadMoreData = (page) => {
    axios
      .get(apiUrl, {
        params: {
          key: apiKey,
          q: searchQuery,
          image_type: imageType,
          page: page,
        },
      })
      .then((response) => {
        if (response.data.hits.length > 0) {
          setImages((prevImages) => [...prevImages, ...response.data.hits]);
          setCurrentPage(page);
        } else {
          setImages([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Pixabay API:", error);
      });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setImages([]);
    fetchData(1);
  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    LoadMoreData(nextPage);
  };

  const handleAccountImage = async (picUrl) => {
    setImgPickUrl(picUrl);
  };

  const bgRemove = async (imageUrl) => {
    setLoader(true);
    try {
      const res = await axios.post(
        "/back-remove",
        { image: imageUrl },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setAccountImg(`data:image/jpeg;base64,${res.data.data}`);
      dispatch(addPreviewImgkData(`data:image/jpeg;base64,${res.data.data}`));
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } catch (error) {
      setLoader(false);
      console.error("Error removing background:", error);
    }
  };

  // stock images states and  function end
  const withoutBg = async (picUrl) => {
    setLoader(true);
    const base64Image = await urlToBase64(picUrl);
    setAccountImg(base64Image);
    dispatch(addPreviewImgkData(base64Image));
    setLoader(false);
  };
  const urlToBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error converting image to base64:", error);
      return null;
    }
  };

  useEffect(() => {
    if (accountImg) {
      const obj = { image: accountImg };
      const updateData = { ...template, ...obj };
      if (editOne) {
        dispatch(addBrandsetupData({ ...obj }));
        dispatch(addSingleEditDetails({ ...updateData, editOne: editOne }));

        navigate("/main/Generate/inputpage");
      } else {
        dispatch(updateTempData(updateData));

        navigate("/main/Generate/inputpage");
      }
    }
  }, [accountImg]);

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Container fluid>
      <Row style={{ margin: "10px 20px", gap: "10px" }}>
        <div className="left-navbar" style={{ border: "none" }}>
          <span onClick={handleBack}>
            <NavItems
              logo={leftArrow}
              active={false}
              text={"logo1"}
              label={"back"}
            />
          </span>
          <NavItems
            logo={CreativeSizeSvg}
            active={false}
            text={"logo1"}
            label={"Select Creative Size"}
          />
          <NavItems
            label={"Background Image "}
            logo={BackgroundSvg}
            active={true}
            text={"logo2"}
          />
          <NavItems logo={TextAndBtnSvg} active={false} text={"logo3"} />
        </div>
        <Row>
          <Col>
            <div className="backround-title">
              <div className="TitleAndToggleMain ">
                <h3>Select image for your Creative</h3>
                <AiToggle setToggle={setToggle} toggle={toggle} />
              </div>
              <div className="backround-subtitle">
                {toggle && (
                  <>
                    {FreeStockSvg}
                    <p>Free Stock Images</p>
                  </>
                )}
              </div>
            </div>
          </Col>

          {toggle ? (
            <Row>
              <Col style={{ padding: "0px" }}>
                <div className="background-images-carosole">
                  {PicDataArray.map((pic, i) => (
                    <div
                      className="background-img-div"
                      // navigate("/Generate/inputpage")
                      // onClick={HandelAccountImage }
                    >
                      <img
                        className="background-Img"
                        src={pic}
                        alt=""
                        onClick={() => handleAccountImage(pic)}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <div className="container">
                <div className="stockImageSearchMain">
                  <h1
                    className=""
                    style={{
                      fontSize: "24px",
                      fontFamily: "Montserrat Alternates",
                      color: "#546881",
                    }}
                  >
                    Image Search 
                  </h1>

                  <div className="form-group">
                    <div className="stockImageSearchSub">
                      <div>
                        <input
                          type="text"
                          id="searchQuery"
                          className="form-control"
                          placeholder="e.g., nature"
                          style={{}}
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                      <button
                        onClick={handleSearch}
                        className="btn btn-primary "
                        style={{
                          background: "#358f8b",
                          border: "#358f8b",
                          fontSize: "12px",
                        }}
                      >
                        Search Images
                      </button>
                    </div>
                  </div>
                  <div className="">
                    {images.length > 0 && (
                      <button
                        onClick={loadMore}
                        id="loadMoreBtn"
                        className="btn btn-secondary"
                        style={{
                          background: "#358f8b",
                          border: "#358f8b",
                          fontSize: "12px",
                        }}
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </div>

                {loader ? (
                  <div className="d-flex justify-content-center mt-5">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <Col style={{ padding: "0px" }}>
                    <div className="background-images-carosole">
                      {images.map((pic, i) => (
                        <div
                          className="background-img-div"
                          // navigate("/Generate/inputpage")
                          // onClick={HandelAccountImage }
                        >
                          <img
                            className="background-Img"
                            src={pic.webformatURL}
                            alt=""
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => handleAccountImage(pic.webformatURL)}
                            // onClick={() => HandelAccountImage(pic)}
                          />
                        </div>
                      ))}
                    </div>
                  </Col>
                )}
              </div>
            </Row>
          )}
        </Row>
      </Row>

      <div
        className={`modal fade`}
        id="staticBackdrop"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className=" modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Background Remove Selection
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <p className="fw-bold"></p> Are You Sure Continue with Background
              Remove
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => withoutBg(imgPickUrl)}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary "
                data-bs-dismiss="modal"
                style={{
                  background: "#358f8b",
                  border: "#358f8b",
                }}
                onClick={() => bgRemove(imgPickUrl)}
              >
                yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const AiToggle = ({ toggle, setToggle }) => {
  return (
    <div
      className="plan-toggle-main"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <p
        className="plan-toggle-text"
        style={!toggle ? { color: "#358F8B" } : { color: "#B2BBC6" }}
      >
        Stock Images
      </p>
      <button
        onClick={() => setToggle(!toggle)}
        className={toggle ? "toggle-button-active" : "toggle-button"}
      >
        <span className="toggle-button-inside"></span>
      </button>
      <p
        className="plan-toggle-text"
        style={toggle ? { color: "#358F8B" } : { color: "#B2BBC6" }}
      >
        Exoad Library
      </p>
    </div>
  );
};

export default BackGroundImages;
