import React, { useState } from "react";
import "./imageSelection.css";
import ImageGalleryIcon from "../../../../../../icons/imageGalleryIcon";
import UploadFolder from "../../../../../../icons/uploadFolder";
import UploadIcon from "../../../../../../icons/uploadIcon";
import LivePreview from "../../../../../../icons/livePreview";
import AngleImg from "../../../../../../../assets/images/angle-img.svg";
// import BottomAngleImg from "../../../../../../../assets/images/bottom-angle-img.svg";
import DemoImg from "../../../../../../../assets/images/demo-img.png";
import InboxIcon from "../../../../../../icons/inboxIcon";
import { useDispatch, useSelector } from "react-redux";
import axios, { nodeUrl } from "../../../../../../axios/auth";
// import axios from "axios";

import {  
  addBgImage,
  addBrandData,
} from "../../../../../../../Redux/brandSetupData";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { handleCoinChange } from "../../../../../../../Redux/addCreateSlice";

// import { addBrandArray } from "../../../../../../../Redux/generatedBrandList";
// import { nodeUrl } from "../../../../../../axios/auth";

export default function ImageSelection({
  setGenerationView,
  setSearchImgesVIew,
  setImageGalleryVIew,
}) {
  const { brandData } = useSelector((state) => state?.brandSetupData);
  const userToken = Cookies.get("token");
  const SelectedAiHeading = brandData?.heading;
  const SelectedAiDesc = brandData?.description;
  const promotionText = brandData?.promotionText;
  const ctaText = brandData?.ctaText;
  const [isLoading, setIsLoading] = useState(true);

  const bgImage = brandData?.mainImage?.startsWith("h")
  ? brandData?.mainImage
  :brandData?.mainImage? `https://backend.exoads.ai/api/v1/${brandData?.mainImage}`:"";

  const ctaIcon = brandData?.ctaIcon; 
  const [opacity, setOpacity] = useState(brandData?.opacity??"1"); // Initial opacity value
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpacityChange = (event) => {
    const newOpacity = parseFloat(event.target.value);
    setOpacity(newOpacity);
  };


  const uploadImageFun = async(e) => {

    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      try {
        const response = await axios.post(`/update-brand-image`, formData, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response?.data?.Data) {
          dispatch(addBrandData({mainImage:response.data?.Data?.imagePath}))
          dispatch(addBrandData({bgImage:`${nodeUrl}/${response.data?.Data?.imagePath}`}))

        }
      }catch(e) {

// toast.error("Upload Another Image Less Than 1 Mb")
toast.error("Upload Another Image Less Than 1 Mb", {
  className: 'custom-toast-error',
  hideProgressBar: true
});
      }
    }
   
    const files = e.target.files;
    
    // dispatch(addBrandData({mainImage:files[0]}))
    const paths = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        paths.push(reader.result);
        if (paths.length === files.length) {
         

        }
      };
      reader.readAsDataURL(file);
    }
    setTimeout(() => {
      e.target.value = null;
    }, 1000);
  };


const data={
  size:brandData?.size,
  brandname:brandData?.brandname,
  projectName:brandData?.projectName,
  projectName:brandData?.projectName,
  heading:brandData?.heading,
description:brandData?.description,
promotionText:brandData?.promotionText,
ctaText:brandData?.ctaText,
ctaIcon:brandData?.ctaIcon?brandData?.ctaIcon[0]:"",
mainImage:brandData?.mainImage,
bgImage:"return base 64",
selectedFont:brandData?.selectedFont,
logo:brandData?.logo?brandData?.logo[0]:"",
ctaColor:brandData?.ctaColor,
promotionTextColor:brandData?.promotionTextColor,
mainBgColor:brandData?.mainBgColor,
headingColor:brandData?.headingColor,
descriptionColor:brandData?.descriptionColor,
brandId:brandData?.brandId,
startIndex:0,
opacity:opacity
}


const projectPayload= async(data)=>{
  try {
    const res = await axios.post(`${nodeUrl}/createtemplate`, data,{


      
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
  dispatch(addBrandData({template:res.data}))
  } catch (error) {
    
  }

}
// deduct Credits
const deductToken = async() => {

  try {
    const res = await axios.post("/deduct-token",{transactionType:"templateGeneration"},{
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              })
    if (res.data.status==true) {
      dispatch(handleCoinChange(res.data.credits))
    } else {
      // toast.error(res.data.message)
      toast.error(res.data.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }
  } catch (error) {

  }
};


  // check credits
  const checkcredits = async() => {
    try {
      const res = await axios.post("/check-credits",{transactionType:"templateGeneration"},{
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                  },
                })
                if(res?.data?.data>0){
                  return true
                }else{
                
                  // toast.error(res.data.message)
                  toast.error(res.data.message, {
                    className: 'custom-toast-error',
                    hideProgressBar: true
                  });
                }

//       if (res.data.status==true) {
//         toast.error(res.data.data.message)
//     // return true
//       }
    } catch (error) {


    }
  };



  const generatedCreative =async () => {
    // projectPayload(data) 
    
  if(brandData?.mainImage){
    
    const res =await checkcredits()
    if(res==true){    
    dispatch(addBrandData({opacity:opacity}))


    navigate("/main/generated-selection");
    }



  }  
  else{
    toast.error("Please Select Image")
  }
    

  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <div className="imgSelectionSection">
      <div className="imgSelectBoxBannr">
        <div className="imgSelectBox">
          <div className="imgSelectHeading">
            <div className="selectFIle">
              <InboxIcon />
            </div>

            <h2>Image Selection  </h2> 
          </div>

          <div className="imgSelectDetails">
            <div
              className="imgSelectionBox"
              onClick={() => setSearchImgesVIew(true)}
            >
              <p>Search Image</p>
              <ImageGalleryIcon />
            </div>
            <div
              className="imgSelectionBox"
              onClick={() => setImageGalleryVIew(true)}
            >
              <p>Exoads Gallery</p>
              <UploadFolder />
            </div>
            <div className="imgSelectionBox">
              <p>Upload Image</p>
              <UploadIcon />

              <input type="file" onChange={uploadImageFun} />
            </div>
          </div>
        </div>
      </div>
      <div className="livePreviewAlignment">
        
        <div className="livePreviewBox">
          <div className="livePreviewHeading">
            <LivePreview />
            <h6>Live Preview </h6>
            <input
        type="range"
        min="0"
        max="1"
        step="0.1"
        value={opacity}
        onChange={handleOpacityChange}
        id="opacity-slider"
        style={{marginLeft:"10px"}}
      />
            <div>
   
            {/* <h6>Opacity</h6>  */}
  
   
    </div>
          </div>

          {isLoading && 
          <div style={{display:"flex",justifyContent:"center"}}> 
          <div className="spinner-border spinner-border-sm text-dark" style={{display:"flex",justifyContent:"center",height:"30px", width:"30px"}} role="status" >
  
</div> </div>}
          <div className="live-preview-img-alignment">
            <img src={bgImage}
             onLoad={handleImageLoad}
   

            alt="DemoImg"  style={{opacity,display: isLoading ? 'none' : 'block' }} />
            <div className="top-amgle-alignment">
              <img src={AngleImg} alt="AngleImg" />
            </div>

         

            <div className="box-details-alignment">
              <div style={{marginLeft:"15px"}}>
                <img
                  src={brandData?.logo} alt="" 
                  style={{ height: "60px", objectFit:"contain", maxWidth: "60px" }}
                />
              </div>

              <div className="text-alignment">
                <h6
                  style={{
                    fontFamily: brandData?.selectedFont,
                    color: brandData?.headingColor,
                  }}
                >
                  {SelectedAiHeading}
                </h6>
                <p
                  style={{
                    fontFamily: brandData?.selectedFont,
                    color: brandData?.descriptionColor,
                  }}
                >
                  {SelectedAiDesc}
                </p>
              </div>
              <div
                className="call-action-button"
                style={{ marginTop: "10px", width: "auto",backgroundColor:"unset" }}
              >
                <button
                  style={{
                    marginTop: "10px",
                    width: "auto",
                    fontFamily: brandData?.selectedFont,
                    color: brandData?.promotionTextColor,
                  }}
                >
                  {promotionText} 
                </button>
              </div>

              <div
                className="call-action-button"
                style={{ marginTop: "10px", width: "auto", display: "flex" }}
              >
                <button
                  style={{
                    marginTop: "10px",
                    width: "auto",
                    paddingRight: "10px",
                    fontFamily: brandData?.selectedFont,
                    color: brandData?.ctaColor,
                  }}
                >
                  {
                    <img
                      src={ctaIcon} alt=""
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "7px",
                      }}
                    />
                  }{" "}
                  {ctaText}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="livePreview-bottom-button">
          <div className="livePreview-back-button">
            <button onClick={() => setGenerationView("B")}>Back</button>
          </div>
          <div className="livePreview-continue-button">
            <button onClick={() => generatedCreative()}>
              Generate Creative 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
