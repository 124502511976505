import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function SizeSelection() {
    const onlyCreativeState = useSelector((state) => state.creativeData.onlyCreativeState);

    if (onlyCreativeState) {
        return <Navigate to="/main/brand-setup" />;
    }

    // Return a valid JSX element, even if it's null
    return null;
}
