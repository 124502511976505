import React from 'react'

export default function StepperRight() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_10_2026)">
                <path d="M14.4152 4C15.3347 4 16.2216 4.11738 17.0759 4.35215C17.9302 4.58692 18.7258 4.92603 19.4627 5.36948C20.1996 5.81293 20.8746 6.33464 21.4876 6.9346C22.1006 7.53456 22.6256 8.20952 23.0625 8.95948C23.4994 9.70943 23.8353 10.5083 24.07 11.3561C24.3048 12.2038 24.4255 13.0907 24.432 14.0168C24.432 14.9363 24.3146 15.8232 24.0798 16.6775C23.8451 17.5318 23.506 18.3274 23.0625 19.0643C22.6191 19.8012 22.0973 20.4762 21.4974 21.0892C20.8974 21.7022 20.2225 22.2271 19.4725 22.6641C18.7226 23.101 17.9237 23.4368 17.0759 23.6716C16.2281 23.9064 15.3412 24.027 14.4152 24.0335C13.4957 24.0335 12.6088 23.9162 11.7545 23.6814C10.9002 23.4466 10.1046 23.1075 9.3677 22.6641C8.63078 22.2206 7.95583 21.6989 7.34282 21.0989C6.72982 20.499 6.20485 19.824 5.76792 19.0741C5.33099 18.3241 4.99514 17.5253 4.76037 16.6775C4.5256 15.8297 4.40496 14.9428 4.39844 14.0168C4.39844 13.0973 4.51582 12.2104 4.75059 11.3561C4.98536 10.5018 5.32447 9.70617 5.76792 8.96926C6.21137 8.23235 6.73308 7.55739 7.33304 6.94438C7.933 6.33138 8.60796 5.80641 9.35791 5.36948C10.1079 4.93255 10.9067 4.5967 11.7545 4.36193C12.6023 4.12717 13.4892 4.00652 14.4152 4ZM20.3138 10.7007L18.9834 9.37032L12.5371 15.8167L9.84701 13.1266L8.51666 14.457L12.5371 18.4774L20.3138 10.7007Z" fill="#B2BBC6" />
            </g>
            <defs>
                <filter id="filter0_d_10_2026" x="0.92323" y="0.933641" width="31.0725" height="31.0725" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2.04424" dy="2.45309" />
                    <feGaussianBlur stdDeviation="2.75972" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.937255 0 0 0 0.21 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_2026" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10_2026" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}
