import { createSlice } from "@reduxjs/toolkit";
const tempData={}
const templateSlice=createSlice({
    name:"templateSlice",
    initialState:tempData,
    reducers:{
        updateTempData:((state,action)=>{
            state.tempData=action.payload
        })
    }
})
export const {updateTempData}=templateSlice.actions;
 export default templateSlice.reducer