// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advance-options {
  width: 90%;
  display: flex;
  padding: 16px 16px;
  /* flex-direction: column; */
  align-items: flex-start;
  gap: 16px;
  background: #fff;
  justify-content: space-between;
}

.advance-p {
  color: var(--foundation-text-dark, #1d242d);

  /* Text md/Regular */
  /* font-family: Zoho Puvi; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.advance-svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/component/account/Pages/Campaigns/BrandSummery/BrandSummery.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,kBAAkB;EAClB,4BAA4B;EAC5B,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,2CAA2C;;EAE3C,oBAAoB;EACpB,4BAA4B;EAC5B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".advance-options {\r\n  width: 90%;\r\n  display: flex;\r\n  padding: 16px 16px;\r\n  /* flex-direction: column; */\r\n  align-items: flex-start;\r\n  gap: 16px;\r\n  background: #fff;\r\n  justify-content: space-between;\r\n}\r\n\r\n.advance-p {\r\n  color: var(--foundation-text-dark, #1d242d);\r\n\r\n  /* Text md/Regular */\r\n  /* font-family: Zoho Puvi; */\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: 24px; /* 150% */\r\n}\r\n\r\n.advance-svg {\r\n  width: 24px;\r\n  height: 24px;\r\n  flex-shrink: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
