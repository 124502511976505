import React from 'react'

export default function IntegrationIcon() {
    return (
        <div><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.72097 0C6.40802 0 4.18981 0.918814 2.55431 2.55431C0.918814 4.18981 0 6.40802 0 8.72097L0 20.3489C0 22.6619 0.918814 24.8801 2.55431 26.5156C4.18981 28.1511 6.40802 29.0699 8.72097 29.0699H20.3489C22.6619 29.0699 24.8801 28.1511 26.5156 26.5156C28.1511 24.8801 29.0699 22.6619 29.0699 20.3489V8.72097C29.0699 6.40802 28.1511 4.18981 26.5156 2.55431C24.8801 0.918814 22.6619 0 20.3489 0L8.72097 0ZM7.75197 14.5349C7.75197 12.736 8.4666 11.0107 9.73866 9.73866C11.0107 8.4666 12.736 7.75197 14.5349 7.75197C16.3339 7.75197 18.0592 8.4666 19.3312 9.73866C20.6033 11.0107 21.3179 12.736 21.3179 14.5349C21.3179 16.3339 20.6033 18.0592 19.3312 19.3312C18.0592 20.6033 16.3339 21.3179 14.5349 21.3179C12.736 21.3179 11.0107 20.6033 9.73866 19.3312C8.4666 18.0592 7.75197 16.3339 7.75197 14.5349ZM21.3179 7.75197H23.2559V5.81398H21.3179V7.75197Z" fill="#47BEB9" />
            <path d="M14.535 9.69006C13.2501 9.69006 12.0177 10.2005 11.1091 11.1091C10.2005 12.0177 9.69006 13.2501 9.69006 14.535C9.69006 15.82 10.2005 17.0524 11.1091 17.961C12.0177 18.8696 13.2501 19.38 14.535 19.38C15.82 19.38 17.0524 18.8696 17.961 17.961C18.8696 17.0524 19.38 15.82 19.38 14.535C19.38 13.2501 18.8696 12.0177 17.961 11.1091C17.0524 10.2005 15.82 9.69006 14.535 9.69006Z" fill="#47BEB9" />
        </svg>
        </div>
    )
}
