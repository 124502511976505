import { configureStore, combineReducers } from '@reduxjs/toolkit';
import addCreatereducer from './addCreateSlice';
import loginSlice from './login/loginSlice';
import signupSlice from './login/signupSlice';
import newCampaignSlice from './newCampaignSlice';
import templateSlice from './templateSlice';
import AiSuggestion from './AiSuggestion';
import CampaignDetail from './CampaignDetailSlice';
import GoogleSearchSlice from './GoogleSearchSlice';
import GoogleDisplaySlice from './GoogleDisplaySlice';
import AdsAnalyticsDetailsSlice from './AdsAnalyticsDetailsSlice';
import brandSetupData from './brandSetupData';
import generatedBrandList from './generatedBrandList';
import onlyCreativeData from './onlyCreativeData';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { LOGOUT } from './actions';
import {LOGOUT} from './action'
import FacebbokAdsSlice from './FacebbokAdsSlice';
import InstagramAdSlice from './InstagramAdSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const appReducer = combineReducers({
  addcreative: addCreatereducer,
  loginuser: loginSlice,
  signupuser: signupSlice,
  newcampaign: newCampaignSlice,
  template: templateSlice,
  AiSuggestion: AiSuggestion,
  CampaignDetail: CampaignDetail,
  GoogleSearchDetails: GoogleSearchSlice,
  GoogleDisplayDetails: GoogleDisplaySlice,
  AdsAnalyticsDetail: AdsAnalyticsDetailsSlice,
  brandSetupData: brandSetupData,
  brandList: generatedBrandList,
  creativeData: onlyCreativeData,
  FacebookAdDetails:FacebbokAdsSlice,
  InstagramAdDetails:InstagramAdSlice
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
