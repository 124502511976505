// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FreeTrial_inputFreetrial__4K2uj {
  width: 100%;
  border: none;
  display: flex;
  padding: 0.3rem 1rem;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  font-size: 14px;
  outline: none;
  align-self: stretch;
  border-radius: 0.25rem;
  background: var(--display-white-active, #f5f7f9);
}

.FreeTrial_inputFreetrial__4K2uj::placeholder {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  flex: 1 0;
  color: #b2bbc6;
}

.FreeTrial_h5ForFreeTrial__RHHax {
  color: var(--text-normal, #546881);

  /* Headings/H3 */
  font-family: Montserrat Alternates;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/component/account/Pages/Plans/Free-trial/FreeTrial.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;EACf,mBAAmB;EACnB,SAAW;EACX,cAAc;AAChB;;AAEA;EACE,kCAAkC;;EAElC,gBAAgB;EAChB,kCAAkC;EAClC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".inputFreetrial {\r\n  width: 100%;\r\n  border: none;\r\n  display: flex;\r\n  padding: 0.3rem 1rem;\r\n  align-items: center;\r\n  justify-content: center;\r\n  gap: 0.625rem;\r\n  font-size: 14px;\r\n  outline: none;\r\n  align-self: stretch;\r\n  border-radius: 0.25rem;\r\n  background: var(--display-white-active, #f5f7f9);\r\n}\r\n\r\n.inputFreetrial::placeholder {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  font-size: 12px;\r\n  align-items: center;\r\n  flex: 1 0 0;\r\n  color: #b2bbc6;\r\n}\r\n\r\n.h5ForFreeTrial {\r\n  color: var(--text-normal, #546881);\r\n\r\n  /* Headings/H3 */\r\n  font-family: Montserrat Alternates;\r\n  font-size: 32px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFreetrial": `FreeTrial_inputFreetrial__4K2uj`,
	"h5ForFreeTrial": `FreeTrial_h5ForFreeTrial__RHHax`
};
export default ___CSS_LOADER_EXPORT___;
