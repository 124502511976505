import React, { useEffect } from 'react'
import "./style.css"
import watch from "./images/layer_20.png";
import logo1 from "./images/logo_copy.png";
import phoneIcon from "./images/icon_4.png"

import html2canvas from 'html2canvas';
import { 
  // addCreativeEmpty, addCreativeNames,
   addCreativeUniq, addCreatives } from '../../../../Redux/addCreateSlice';
import { useDispatch, useSelector } from 'react-redux';




function Creative1(  {image,
  mainColor,
  HeadingColor,
  descriptionColor,
  // promotionTextColor,
  ctaColor,
  logo,   
  heading ,  
  description, 
  // promotionText,
  ctaIcon,
  ctaText,}) {
    const addcreative  = useSelector((state) => state.addcreative.addCreative);
    const editOneData = useSelector((state) => state.addcreative.editOneData);
    const creativeName = useSelector((state) => state.addcreative.creativeName);
    // const{}=editOneData
    const dispatch= useDispatch()
 const handleConvertToJpg = () => {
      const targetElement = document.getElementById("watch160x600"); // Change this to the element you want to capture
      html2canvas(targetElement).then(canvas => {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
  
        tempCanvas.width = 160;
        tempCanvas.height = 600;
  
        tempCtx.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          160,
          600
        );
  
    canvas.width = 160;
    canvas.height = 600;
  
    canvas.getContext('2d').drawImage(tempCanvas, 0, 0, 160, 600);
  
    const jpgImage = canvas.toDataURL('image/webp'); // Convert canvas to JPG data URL
  
    // If you want jpeg image with low size you can use this line By Nikhil
    //const jpgImage = canvas.toDataURL('image/jpeg', 0.8);
       
        if(creativeName){
        dispatch(addCreativeUniq({id:"watch160x600",img:jpgImage,size:"160x600",category:"blackwatch"}))
      }
      
      else if(addcreative.some(item => item.category === "blackwatch")) {
             }
           else{
             dispatch(addCreatives({id:"watch160x600",img:jpgImage,category:"blackwatch"}))
           }

        // dispatch(addCreativeNames("watch"))
      });
    };


    useEffect(()=>{
      handleConvertToJpg()
    },[])

   
      // useEffect(() => {
      //   if (addcreative.length >= 1) {
      //     dispatch(addCreativeEmpty([]));
      //     // setimgCreative(true);
      //   }
      // }, []);

  return (
<div className="main-content-wrapper1" id='watch160x600'>
  <div className="watch2-wrap1" style={{ background:`${mainColor}`}}>  
    <div className="col1">
      {/* <img class="layer-23" src="images/layer_23.png" alt="" width="1080" height="165"> */}
      {/* <div class="social-icon match-height group">
      <div class="shape-holder">
        <img class="icon" src="images/icon.png" alt="" width="21" height="21">
      </div>
      <div class="shape-holder-2">
        <img class="icon-2" src="images/icon_2.png" alt="" width="13" height="25">
      </div>
      <div class="shape-holder-3">
        <img class="icon-3" src="images/icon_3.png" alt="" width="24" height="19">
      </div>
    </div>  */}
    </div>
    <img
      className="layer-201"
      src={image ? image : watch}
      alt=""
      width={110}
      height={250}
    />
    <div className="text1">
      <div className="headline1">
        <p className="text-21" style={{color:`${editOneData?.HeadingColor?editOneData?.HeadingColor:HeadingColor}`}}>
         {editOneData?.heading?editOneData?.heading:heading?heading:"Timeless Elegance"} 
        </p>
        <p className="text-31" style={{color:`${descriptionColor}`}}>
         {  description?  description:"Unveiling the Ultimate Guide to Choosing the Ideal Timepiece"} 
        </p>
      </div>
      {/* <div class="price">
      <div class="group-3">
        <p class="only">ONLY</p>
        <p class="text-4">$25</p>
      </div>
    </div> */}
      {/* <div class="button">
      click here
    </div>  */}
      <div className="contact-info group1">
        <div className="ellipse-2-holder1">
          <img
            className="icon-41"
            src={ctaIcon?ctaIcon:phoneIcon}
            alt=""
            width={10}
            height={10}
          />
        </div>
        <div className="col-21">
          <p className="text-61" style={{color:`${ctaColor}`}}>{ ctaText? ctaText:"123-456-7890"}</p>
          {/* <p className="text-71">123-456-7890</p> */}
        </div>
      </div>
    </div>
    <img
      className="logo-copy1"
      src={logo?logo:logo1}
      alt=""
      width={80}
      height={80}
    />
  </div>
</div>

  )
}

export default Creative1