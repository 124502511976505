import React, { useEffect } from "react";
import "./css/style.css";
// import "./template.css";
import CarTest from "./images/car-test.png";
import Logo1 from "./images/logo-1.png";
import html2canvas from 'html2canvas';
import { useSelector } from "react-redux";


export const handleConvertToJpg = () => {
  const targetElement = document.getElementById("tempImg1"); // Change this to the element you want to capture

  html2canvas(targetElement).then(canvas => {
    const jpgImage = canvas.toDataURL('image/jpeg'); // Convert canvas to JPG data URL

    // Save the image data to localStorage
    localStorage.setItem('imageData', jpgImage);

    // Do other actions or navigation to other components as needed
  });
};

const Template = ({ height, width }) => {

const data =useSelector(state=>state.addcreative.addCreative)



  useEffect(()=>{
    handleConvertToJpg();
  },[])
const size1080=width==1080 && height==1080;  
const size1200=width==1200 && height==628;  

  return (
    <>
      <main className="main"  style={{ height, width }} id="tempImg1">
        <h1>{data.title}</h1>
        <div className="main-box">
          <div className=" d-flex justify-content-end">
            <img src={ data.logoUrl?data.logoUrl:Logo1} alt="" />
          </div>
          <div className="gray-bg">
            <div className="container">
              <div className="pt-60">
                <div className="center-box text-center">
                  <h1 className={((size1080)&&"title-1080")||((size1200)&&"title-1200")}>Luxury on wheels</h1>
                  <div className="center-tex">
                    {data.description?<p className={((size1080)&&"description-1080")||((size1200)&&"description-1280")}>{data.description}</p>:<p className={((size1080)&&"description-1080")||((size1200)&&"description-1280")}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Pariatur fugiat accusantium cupiditate, dignissimos
                      mollitia aut quibusdam laborum fuga nemo possimus
                      laboriosam laudantium, iusto alias? Repellat nisi eveniet
                      dolor vel.</p>}                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="car-bg">
            <div className="car-ruby">
              <svg
                id="svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-300 0 950 270"
              >
                <path
                  d="M-314,267 C105,364 400,100 812,279"
                  fill="none"
                  stroke="white"
                  strokeWidth={120}
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="center-car ">
            <img src={data.imageUrl?data.imageUrl: CarTest} alt="" />
          </div>
          <footer>
            <div className="container">
              <div className="footer-box">
                <div className="contact">
                  <div className="phone-icon">
                    <i className="fa-sharp fa-solid fa-phone-volume" />
                  </div>
                  <div className="phone-number">
                    <small>call for delivery</small>
                    <a href="tel:1234567890" className="contact-number">
                      123-456-7890
                    </a>
                  </div>
                </div>
                <div className="click-btn">
                  <a href="javascript:void(0)" className="click-hereBtn">
                    Click here
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </main>
      {/* <button onClick={handleConvertToJpg}>Convert to JPG</button> */}
    </>
  );
};

export default Template;
