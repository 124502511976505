// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img1{
    height: 78px;
    width: 78px;
}
.img-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    
    text-transform: uppercase;
}
.card-border{
border-radius: 30px !important;
border: 2px solid #b37fca !important

  }
  .card-footer{
    background-color:unset !important;
    border-top: unset !important;
  }`, "",{"version":3,"sources":["webpack://./src/component/panel/user/pages/SelectSize.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;;IAE5B,yBAAyB;AAC7B;AACA;AACA,8BAA8B;AAC9B;;EAEE;EACA;IACE,iCAAiC;IACjC,4BAA4B;EAC9B","sourcesContent":[".img1{\r\n    height: 78px;\r\n    width: 78px;\r\n}\r\n.img-title{\r\n    font-family: 'Poppins';\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    font-size: 16px;\r\n    line-height: 24px;\r\n    /* identical to box height */\r\n    \r\n    text-transform: uppercase;\r\n}\r\n.card-border{\r\nborder-radius: 30px !important;\r\nborder: 2px solid #b37fca !important\r\n\r\n  }\r\n  .card-footer{\r\n    background-color:unset !important;\r\n    border-top: unset !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
