import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Cities: [],
  startDate: "",
  EndDate: "",
  CountryCode: "",
  amount: "",
  keywords: [],
};
const GoogleSearchSlice = createSlice({
  name: "GoogleSearchSlice",
  initialState,
  reducers: {
    AddFundingDetails: (state, action) => {
      //   state.websiteUrl = action.payload;
    },
    AddTargetingDetails: (state, action) => {
      state.CountryCode = action.payload[0]?.country;
      state.Cities = action.payload[1]?.cityList;
      state.keywords = action.payload[2]?.keywords;

      // state.Cities = action.payload[0].cityList;
    },
  },
});

export const {
  AddFundingDetails,
  AddTargetingDetails,
} = GoogleSearchSlice.actions;
export default GoogleSearchSlice.reducer;
