import React, { useContext, useState } from "react";
import "./Brand.css";
import { Col, Container, Row } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CommonBtn } from "../../../UI-Components/ReusableComponents";
// import TestingPage from "../../../../Testing/TestingPage";
import JSZip from "jszip";
// import arrowUp2 from "../../../../LandingPage/Images/arrowUp2.png";
import {
  DownloadSvg,
  Circles,
  Star,
  Edit,
  CovertSvg,
  HeaderEdit,
} from "../../../UI-Components/ReUsableSVG";
import {  ToastContainer, toast } from "react-toastify";
import Creative1 from "./Images/creative1.png";
import Creative2 from "./Images/Creative2.png";
// import Creative3 from "./Images/Creative3.png";
// import Creative4 from "./Images/Creative4.png";
// import { PlusSvg } from "../Campaigns/Home Campaigns/SVG";
import {
  LandScape,
  LargerLeaderBoardSvg,
  LeaderBoardSvg,
  MediumBannerSvg,
  PostSizeSvg,
  SkyScraper,
  StorySize,
  VerticalSize,
} from "../Generate Page/SVg";
import axios from "../../../axios/auth";
import { useNavigate } from "react-router-dom";
import { MailContext } from "../../Auth Pages/mailContext";
import { useEffect } from "react";
import Creative13 from "../../creative/size160x600/Creative"; //important for css
import CreativeList from "../../listingTemp/CreativeList";
import { useDispatch, useSelector } from "react-redux";
import SingleSelectTemp from "../../listingTemp/SingleSelectTemp";
import {
  addBrandList,
  addConvertedSize,
  // addCreateNewProject,
  addCreativeEdit,
  // addCreativeEmpty,
  addCreativeNames,
  addCreativeUniqEdit,
  addCreativeUniqEmpty,
  // addSingleEditDetails,
  addSingleEditDetailsEmpty,
  // removePreviewData,
} from "../../../../Redux/addCreateSlice";
import { saveAs } from "file-saver";
// import { wait } from "@testing-library/user-event/dist/utils";
// import { updateTempData } from "../../../../Redux/templateSlice";
// import SingleSelectTemp from "../../listingTemp/listingTemp/SingleSelectTemp";
import Cookies from "js-cookie";
// import Cret1 from "../../creative/size160x600/Creative"
const SocailMediaSize = [
  { type: "Post Size", icon: PostSizeSvg, size: "1080x1080" },
  { type: "Landscape Size", icon: LandScape, size: "1200x628" },
  { type: "Story Size", icon: StorySize, size: "1080x1920" },
  { type: "Vertical Size", icon: VerticalSize, size: "1080x1350" },
  { type: "Pin Size", icon: StorySize, size: "1000x1500" },
];
const DisplaySize = [
  { type: "Leaderboard", icon: LeaderBoardSvg, size: "728x90" },
  { type: "Large Leaderboard", icon: LargerLeaderBoardSvg, size: "970x90" },
  { type: "Medium Banner", icon: MediumBannerSvg, size: "300x250" },
  { type: "Half page", icon: StorySize, size: "300x600" },
  { type: "wide skyscraper", icon: SkyScraper, size: "160x600" },
];

const downlaodSvg1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2.5809 19.0098L2.5609 19.0298C2.2909 18.4398 2.1209 17.7698 2.0509 17.0298C2.1209 17.7598 2.3109 18.4198 2.5809 19.0098Z"
      fill="#358F8B"
    />
    <path
      d="M9.00085 10.3801C10.3153 10.3801 11.3808 9.31456 11.3808 8.00012C11.3808 6.68568 10.3153 5.62012 9.00085 5.62012C7.68641 5.62012 6.62085 6.68568 6.62085 8.00012C6.62085 9.31456 7.68641 10.3801 9.00085 10.3801Z"
      fill="#358F8B"
    />
    <path
      d="M16.1909 2H7.81085C4.17085 2 2.00085 4.17 2.00085 7.81V16.19C2.00085 17.28 2.19085 18.23 2.56085 19.03C3.42085 20.93 5.26085 22 7.81085 22H16.1909C19.8309 22 22.0009 19.83 22.0009 16.19V13.9V7.81C22.0009 4.17 19.8309 2 16.1909 2ZM20.3709 12.5C19.5909 11.83 18.3309 11.83 17.5509 12.5L13.3909 16.07C12.6109 16.74 11.3509 16.74 10.5709 16.07L10.2309 15.79C9.52085 15.17 8.39085 15.11 7.59085 15.65L3.85085 18.16C3.63085 17.6 3.50085 16.95 3.50085 16.19V7.81C3.50085 4.99 4.99085 3.5 7.81085 3.5H16.1909C19.0109 3.5 20.5009 4.99 20.5009 7.81V12.61L20.3709 12.5Z"
      fill="#358F8B"
    />
  </svg>
);
const downlaodSvg2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2.5809 19.0098L2.5609 19.0298C2.2909 18.4398 2.1209 17.7698 2.0509 17.0298C2.1209 17.7598 2.3109 18.4198 2.5809 19.0098Z"
      fill="#160647"
    />
    <path
      d="M9.00085 10.3801C10.3153 10.3801 11.3808 9.31456 11.3808 8.00012C11.3808 6.68568 10.3153 5.62012 9.00085 5.62012C7.68641 5.62012 6.62085 6.68568 6.62085 8.00012C6.62085 9.31456 7.68641 10.3801 9.00085 10.3801Z"
      fill="#160647"
    />
    <path
      d="M16.1909 2H7.81085C4.17085 2 2.00085 4.17 2.00085 7.81V16.19C2.00085 17.28 2.19085 18.23 2.56085 19.03C3.42085 20.93 5.26085 22 7.81085 22H16.1909C19.8309 22 22.0009 19.83 22.0009 16.19V13.9V7.81C22.0009 4.17 19.8309 2 16.1909 2ZM20.3709 12.5C19.5909 11.83 18.3309 11.83 17.5509 12.5L13.3909 16.07C12.6109 16.74 11.3509 16.74 10.5709 16.07L10.2309 15.79C9.52085 15.17 8.39085 15.11 7.59085 15.65L3.85085 18.16C3.63085 17.6 3.50085 16.95 3.50085 16.19V7.81C3.50085 4.99 4.99085 3.5 7.81085 3.5H16.1909C19.0109 3.5 20.5009 4.99 20.5009 7.81V12.61L20.3709 12.5Z"
      fill="#160647"
    />
  </svg>
);

const creatives = [
  Creative1,
  Creative2,
  // Creative3,
  // Creative4,
];
const token = Cookies.get("token");
let toastShown = true; // Flag to track whether a toast is already shown

const TokenDeduction = async () => {
  try {
    const res = await axios.post(
      `/creditDeduction`,
      { transactionType: "creativeDownload" },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Only show toast if it's not already shown
    if (!toastShown) {
      // toast.success(res?.data?.message);
      toast.error(res?.data?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });

      toastShown = false; // Set the flag to true after showing the toast
    }

    return res?.data?.remaingBalance;
  } catch (error) {
    if (error.request.status === 404) {
      // toast.error(error.response?.data?.message);
        
    toast.error(error.response?.data?.message, {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
    } else if (error.request.status === 401) {
      // toast.error("Your token is expired" + " " + error.response?.data?.message);
    toast.error(`Your token is expired ${error.response?.data?.message}`, {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
    }
  }
};

const Brands = () => {
  // ranjit code
  // const imageLogo = localStorage.getItem("imageLogo");
  // const accountImg = localStorage.getItem("accountImg");

  const [imgwidth, setImgWidth] = useState();
  const [imgHeight, setImgHeight] = useState();
  const [lgColumn, setlgColumn] = useState();
  const [mdColumn, setmdColumn] = useState();
  const [smColumn, setsmColumn] = useState();
  const [isloader, setIsLoader] = useState();
  const [tempLoader, setTempLoader] = useState(false);
  const addcreative = useSelector((state) => state.addcreative.addCreative);
  // const { setcreateNewProject } = useSelector((state) => state.addcreative);
  const size = useSelector((state) => state.addcreative.size);
  const [activeSingleSelected, setSingleSelcted] = useState(false);
  const [singleSelectedData, setSingleSelectedData] = useState(true);
  // const tempData = useSelector((state) => state.template.tempData);
  // const [brandLogo, setBrandLogo] = useState();

 

  // const createBrand = async () => {
  //   // Create an object with 'planMasterId' instead of '_id'

  //   try {
  //     const res = await axios.post(
  //       `/create-brand`,
  //       { ...tempData, downloadedImage: addcreative[0].image },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //   } catch (error) {
  //   }
  // };



  const handleActive = (status) => {
    setSingleSelcted(status);
  };
  function handleSelectedData(data) {
    setSingleSelectedData(data);
  }

  // const scrollToTop = () => {
  //   const offset = 65; // Offset value in pixels
  //   const maxHeight = window.innerHeight - offset;

  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  // clear Manupulation like editOne, EditAll,ConverTedSize
  useEffect(() => {
    dispatch(addCreativeUniqEdit(""));
    dispatch(addCreativeEdit(""));
    dispatch(addConvertedSize(""));
  }, []);

  // useEffect(() => {
  //   dispatch(addCreativeEmpty([]))
  // }, [setcreateNewProject])

  useEffect(() => {
    const sizeIs = size ? size : "1080x1080";
    const inputString = sizeIs;
    const numbersArray = inputString.split("x");
    const width = parseInt(numbersArray[0]); // Convert to an integer
    const height = parseInt(numbersArray[1]);
    // if (width == height) {
    //   setImgWidth(width / 4);
    //   setImgHeight(height / 4);
    // } else if (width < height) {
    //   setImgWidth(width / 4);
    //   setImgHeight(height / 3.5);
    // } else {
    //   setImgWidth(width / 3.5);
    //   setImgHeight(height / 4);
    // }

    if (width === "1080" && height === "1080") {
      setImgWidth(300);
      setImgHeight(300);
      setlgColumn(4);
      setmdColumn(4);
      setsmColumn(12);
    } else if (width === "1200" && height === "628") {
      setImgWidth(520);
      setImgHeight(261);
      setlgColumn(5);
      setmdColumn(5);
      setsmColumn(12);
    } else if (width === "970" && height === "90") {
      setImgWidth(970);
      setImgHeight(90);
      setlgColumn(12);
      setmdColumn(12);
      setsmColumn(12);
    } else if (width === "300" && height === "250") {
      setImgWidth(300);
      setImgHeight(250);
      setlgColumn(4);
      setmdColumn(4);
      setsmColumn(12);
    } else if (width === "300" && height === "250") {
      setImgWidth(300);
      setImgHeight(250);
      setlgColumn(6);
      setmdColumn(6);
      setsmColumn(12);
    } else if (width === "728" && height === "90") {
      setImgWidth(600);
      setImgHeight(61);
      setlgColumn(6);
      setmdColumn(6);
      setsmColumn(12);
    } else if (width === "300" && height === "600") {
      setImgWidth(300);
      setImgHeight(600);
      setlgColumn(4);
      setmdColumn(4);
      setsmColumn(12);
    } else if (width === "160" && height === "600") {
      setImgWidth(160);
      setImgHeight(600);
      setlgColumn(4);
      setmdColumn(4);
      setsmColumn(6);
    } else if (width === "1080" && height === "1920") {
      setImgWidth(200);
      setImgHeight(350);
      setlgColumn(4);
      setmdColumn(4);
      setsmColumn(12);
    } else if (width === "1080" && height === "1350") {
      setImgWidth(200);
      setImgHeight(300);
      setlgColumn(4);
      setmdColumn(4);
      setsmColumn(12);
    } else if (width === "1000" && height === "1500") {
      setImgWidth(200);
      setImgHeight(320);
      setlgColumn(4);
      setmdColumn(4);
      setsmColumn(12);
    }

    // if (width == height) {
    //   setImgWidth(width / 4);
    //   setImgHeight(height / 4);
    // } else if (width < height) {
    //   setImgWidth(width / 4);
    //   setImgHeight(height / 3.5);
    // } else {
    //   setImgWidth(width / 3.5);
    //   setImgHeight(height / 4);
    // }
  }, [size]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const createNewProject = () => {
  //   // dispatch(addCreateNewProject("CreatenewProject"))
  //   dispatch(addCreativeEdit(""));
  //   dispatch(updateTempData({}));
  //   // dispatch(removePreviewData(""))
  //   navigate("/main/Generate");
  // };

  const convertDiffSize = () => {
    dispatch(addConvertedSize("handleConvertSize"));
    dispatch(addCreativeEdit(""));
    dispatch(addCreativeUniqEdit(""));
    navigate("/main/Generate");
  };
  const generating = () => {
    return;
  };
  async function downloadAll() {
    setIsLoader(true);
    const zip = new JSZip();
    await Promise.all(
      addcreative.map(async (ele) => {
        const response = await fetch(ele.img);
        const data = await response.blob();
        zip.file(`creative_${ele.id}.jpg`, data);
      })
    );

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "creatives.zip");
    });

    setIsLoader(false);
    // If you need to dispatch an action after the download, you can do it here
    // dispatch(addCreativeUniqEmpty([]));
  }

  const editProject = () => {
    dispatch(addCreativeEdit("editAll"));
    dispatch(addCreativeUniqEdit(""));
    dispatch(addConvertedSize(""));
    // dispatch(addSingleEditDetails(""))
    dispatch(addSingleEditDetailsEmpty([]));
    // navigate("/main/Generate");
    navigate("/main/brand/brandsetup");
  };

  useEffect(() => {
    if (addcreative.length === 10) {
      setTempLoader(false);
    } else {
      setTempLoader(true);
    }
  }, [addcreative]);

  useEffect(() => {
    if (addcreative.length > 0) {
      dispatch(addBrandList(addcreative[0]));
    }
  }, []);

  return (
    <>
      {/* < TestingPage/> */}
      {addcreative.length > 0 ? (
        <Container
          fluid
          style={{ overflowY: "scroll" }}
          className="BrandContainer brand-main"
        >
          <Row>
            <div className="brand-header">
              <p className="Brand-heading" id="GeneratedPost">
                Generated Post
              </p>
              <div className="brand-header-button-div">
                {tempLoader ? (
                  ""
                ) : (
                  <CommonBtn
                    gap={6}
                    borderRadius={24}
                    icon={HeaderEdit}
                    handleSubmit={() => editProject()}
                  >
                    Edit All
                  </CommonBtn>
                )}
                {/*        
      {tempLoader ? "" : 
                <CommonBtn
                  borderRadius={24}
                  icon={PlusSvg}
                  handleSubmit={createNewProject}
                >
                  Create A New Project
                </CommonBtn>
      }
      */}
                {tempLoader ? (
                  ""
                ) : (
                  <CommonBtn
                    gap={6}
                    borderRadius={24}
                    icon={CovertSvg}
                    handleSubmit={
                      tempLoader ? () => generating() : () => convertDiffSize()
                    }
                    className={
                      tempLoader ? "spinner-grow spinner-grow-sm" : " "
                    }
                  >
                    Convert All
                  </CommonBtn>
                )}
                <CommonBtn
                  borderRadius={24}
                  icon={
                    tempLoader ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : (
                      DownloadSvg
                    )
                  }
                  handleSubmit={
                    tempLoader ? () => generating() : () => downloadAll()
                  }
                  className={tempLoader ? "spinner-grow spinner-grow-sm" : " "}
                >
                  {tempLoader ? "Generating.." : " Download All"}
                </CommonBtn>
              </div>
            </div>
          </Row>
          <Row style={{ justifyContent: "space-around" }}>
            {addcreative.map((item, index) => (
              <>
                <BrandItems
                  category={item.category}
                  indexNum={index}
                  creativesImg={item.img}
                  imgHeight={imgHeight}
                  imgwidth={imgwidth}
                  isTrue={true}
                  lgColumn={lgColumn}
                  mdColumn={mdColumn}
                  smColumn={smColumn}
                  handleActive={handleActive}
                  handleSelectedData={handleSelectedData}
                />
              </>
            ))}
          </Row>

          {tempLoader && (
            <div className="d-flex m-3">
              {" "}
              <div
                className="spinner-border"
                role="status"
                style={{ color: "#47beb9" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>{" "}
              <h4 className="mx-2" style={{ color: "#47beb9" }}>
                Generating Templates...
              </h4>{" "}
            </div>
          )}
          <ToastContainer />
          {/* <img
            src={arrowUp2}
            alt=""
            onClick={scrollToTop}
            className="vert-move"
            style={{
              position: "fixed",
              bottom: "100px",
              right: "20px",
              zIndex: "100",
              cursor: "pointer",
              width: "70px",
            }}
          /> */}
        </Container>
      ) : (
        <>
          {" "}
          <div className="d-flex m-3">
            {" "}
            <div
              className="spinner-border"
              role="status"
              style={{ color: "#47beb9" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>{" "}
            <h4 className="mx-2" style={{ color: "#47beb9" }}>
              Generating Templates...
            </h4>{" "}
          </div>
        </>
      )}
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          opacity: "0",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0, 0, 0, 0.5)", / semi-transparent background /
          zIndex: "-9999", /// Set a high z-index value to overlap the body content /
        }}
      >
        <CreativeList />
      </div>
    </>
  );
};

const BrandItems = ({
  creativesImg,
  isTrue,
  imgHeight,
  imgwidth,
  indexNum,
  category,
  lgColumn,
  mdColumn,
  smColumn,
  handleSelectedData,
  handleActive,
}) => {
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [isDataReceived, setisDataReceived] = useState(false);
  const dispatch = useDispatch();
  function downloadThis(category) {
    setActive(!active);
    handleActive(!active);
    dispatch(addCreativeNames(category));
  }

  const addCreativeUniqs = useSelector(
    (state) => state.addcreative.addCreativeUniqs
  );

  useEffect(() => {
    if (addCreativeUniqs) {
      if (addCreativeUniqs.length > 0) {
        setisDataReceived(true);
        handleSelectedData(true);
      } else if (active === false) {
        setisDataReceived(false);
        handleSelectedData(false);
      }
    }
  }, [addCreativeUniqs]);

  const navigate = useNavigate();
  async function bulkDownload() {
    for (let i = 0; i < addCreativeUniqs.length; i++) {
      const imgUrl = await addCreativeUniqs[i].img;
      await saveAs(imgUrl, `creative_${addCreativeUniqs[i].id}`);
    }
    dispatch(addCreativeUniqEmpty([]));
  }

  const functionWillBeEdit = (category) => {
    if (!active) {
      dispatch(addCreativeNames(category));
      dispatch(addCreativeUniqEdit(category));
      dispatch(addCreativeEdit(""));
      dispatch(addConvertedSize(""));
      navigate("/main/brand/brandsetup");

      // navigate("/brand/brandsetup");
      // alert(category)
    }
  };

  const functionWillBe = (category) => {
    if (!active) {
      downloadThis(category);
    } else {
      dispatch(addCreativeUniqEmpty([]));
      setActive(false);
      handleActive(false);
    }
  };
  const { coin, handleCoin } = useContext(MailContext);
  let result;
  return (
    <Col
      lg={lgColumn}
      md={mdColumn}
      sm={smColumn}
      style={{ padding: "0px", marginTop: "20px" }}
    >
      <div
        className="brand-item"
        style={
          active
            ? { position: "absolute", zIndex: 100 }
            : { position: "relative" }
        }
      >
        <div className="brand-item-heading-box">
          <div className="brand-item-heading" style={{}}>
            <p>{Circles}</p>
            <p>Conversion Score 99/100</p>
          </div>
          <div className="brand-fav-box">{Star}</div>
        </div>

        <div className="brand-img-box">
          <img
            src={creativesImg}
            alt="creative1"
            style={{ height: imgHeight, width: imgwidth }}
            // style={{ height: "100px", width: "100px" }}
          />
        </div>

        {active && (
          <>
            <div
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                opacity: "0",
                width: "100px",
                height: "100px",
                // backgroundColor: "rgba(0, 0, 0, 0.5)", / semi-transparent background /
                zIndex: "-9999", /// Set a high z-index value to overlap the body content /
                // background:"green"
              }}
            >
              {isDataReceived ? "" : <SingleSelectTemp />}
            </div>

            {isDataReceived ? (
              <div
                style={{ width: "100%", padding: "0px 6px", cursor: "pointer" }}
              >
                <div
                  className="brand-subdownload-main"
                  onClick={async () => {
                    result = await TokenDeduction(coin, handleCoin);
                    handleCoin(result);
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    {downlaodSvg1}
                    <div className="brand-subdownload">
                      <span style={{ color: "#358F8B" }} onClick={bulkDownload}>
                        Bulk Download
                      </span>
                      <p> Download all the images you created</p>
                    </div>
                  </div>

                  <div className="brand-subdownload-div2">
                    <span>2</span>
                    <p>Credits</p>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ color: "#47beb9" }}>
                {" "}
                <div
                  className="spinner-border "
                  style={{ color: "#47beb9" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {isDataReceived ? (
              <div
                style={{ width: "100%", padding: "0px 6px", cursor: "pointer" }}
                onClick={() => setShow(true)}
              >
                <div className="brand-subdownload-main">
                  <div style={{ display: "flex", gap: "10px" }}>
                    {downlaodSvg2}
                    <div className="brand-subdownload">
                      <span> Download This</span>
                      <p>You download the image you see.</p>
                    </div>
                  </div>
                  <div className="brand-subdownload-div2">
                    <span>1</span>
                    <p>Credits</p>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}

        <div className="brands-item-btnBox">
          <CommonBtn
            icon={Edit}
            width={"100%"}
            color={"#160647"}
            padding={"4px 12px"}
            align={"center"}
            borderRadius={"50px"}
            handleSubmit={() => functionWillBeEdit(category)}
          ></CommonBtn>
          <CommonBtn
            width={"100%"}
            borderRadius={"50px"}
            handleSubmit={() => functionWillBe(category)}
            icon={!active ? DownloadSvg : null}
            displaytype={!active ? "flex" : "flow-root"}
            active={active} //to hide the download icon
            padding={"4px 12px"}
          >
            {!active ? "Download" : "Close"}
          </CommonBtn>
        </div>
        <CustomModal
          setShow={setShow}
          show={show}
          setActive={setActive}
          handleActive={handleActive}
        />
      </div>
    </Col>
  );
};

const CustomModal = ({ show, setShow, setActive, handleActive }) => {
  const closeModalonClick = () => {
    setShow(false);
    setActive(false);
    handleActive(false);
  };
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div
          style={{ padding: "40px" }}
          className="brand-modal brand-modal-content"
        >
          <MainGenerate
            closeModalonClick={closeModalonClick}
            setActive={setActive}
          />
        </div>
        {/* <SingleSelectTemp/> */}
      </Modal>
    </>
  );
};

const MainGenerate = ({ closeModalonClick, setActive }) => {
  return (
    <div>
      <Row style={{ marginBottom: "10px" }}>
        <ItemsContainer
          Heading={"Social Media Size Create Post"}
          data={SocailMediaSize}
          closeModalonClick={closeModalonClick}
          valueOfIndex={0}
          // size={size}
        />
      </Row>
      <Row>
        <ItemsContainer
          Heading={"Display Sizes"}
          data={DisplaySize}
          valueOfIndex={5}
          closeModalonClick={closeModalonClick}
          setActive={setActive}
        />
      </Row>
    </div>
  );
};

const ItemsContainer = ({
  Heading,
  data,
  closeModalonClick,
  valueOfIndex,
  setActive,
}) => {
  return (
    <>
      <Col lg={4} style={{ display: "flex", justifyContent: "center" }}>
        <div className="generate-sizing-div">
          <h1>{Heading}</h1>
          <p>Most Common sizes for social media advertising </p>
        </div>
      </Col>
      <Col>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {data.map((item, i) => (
            <GenerateItem
              valueOfIndex={valueOfIndex}
              sizeTitle={item.type}
              icon={item.icon}
              size={item.size}
              index={i + 1}
              closeModalonClick={closeModalonClick}
            />
          ))}
        </Row>
      </Col>
    </>
  );
};

const GenerateItem = ({
  sizeTitle,
  index,
  icon,
  size,
  closeModalonClick,
  valueOfIndex,
}) => {
  const dispatch = useDispatch();
  const addCreativeUniqs = useSelector(
    (state) => state.addcreative.addCreativeUniqs
  );
  async function downloadImgs(size) {
    let filterSize = addCreativeUniqs.filter((item) => {
      return item.size === size;
    });
    if (filterSize[0]) {
      const imgUrl = await filterSize[0].img;

      await saveAs(imgUrl, filterSize[0].id);
      dispatch(addCreativeUniqEmpty([]));
    } else {
      // toast.error(`${size} are not present`);
      toast.error(`${size} are not present`, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
    }
  }
  // async function bulkDownload(indexNo){
  //   const imgUrl=  await addCreativeUniqs[indexNo-1]
  //     saveAs(imgUrl, 'creative.jpg')
  //   dispatch(addCreativeUniqEmpty([]))
  //   }

  const { coin, handleCoin } = useContext(MailContext);
  let result;
  return (
    <div
      className="generate-select-size-box"
      onClick={async () => {
        result = await TokenDeduction(coin, handleCoin);
        handleCoin(result);
        closeModalonClick();
        downloadImgs(size);
      }}
    >
      <p className="generate-select-size-box-p">{index}</p>
      <div
        style={{
          width: "100%",
          height: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </div>
      <div className="generate-select-size-text-box">
        <h3 className="generate-select-size-text-box-p">{sizeTitle}</h3>
        <h4>{size}</h4>
      </div>
    </div>
  );
};

export default Brands;
