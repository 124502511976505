import React from 'react'
import "./brandbottom.css";

import {  useNavigate } from 'react-router-dom';
import RightArrow from '../../../../../icons/rightArrow';
import PlusIcon from '../../../../../icons/plusIcon';
import { useDispatch} from 'react-redux';
import { removeBrandData } from '../../../../../../Redux/brandSetupData';

export default function BrandBottom() {
    const dispatch =useDispatch()
    const navigate=useNavigate()

    // const {brandData} =useSelector((state)=>state?.brandSetupData)

    const handleNewBrand=()=>{
        dispatch(removeBrandData({}))
        navigate("/main/brand-setup")
    }
    return (
        <div className="brand-bottom-details-alignment">
            <h4>Create Powerful Ad Campaigns for Facebook, Google, & Instagram</h4>

            <div className="brand-bottom-right-side-details">
                <div className="make-add-button">
                    <button>Make your first Ad <RightArrow />
                    </button>
                </div>
                <div className="create-new-brand">

                    <a onClick={()=>handleNewBrand("/main/brand-setup")}>
                        <button>
                            <PlusIcon />
                            Create New Brand   </button>
                    </a>
                </div>
            </div>
        </div>
    )
}
