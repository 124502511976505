import React, { useContext, useEffect, useRef, useState } from "react";
import "./GoogleFunding.css";
import { Arrow, Caution, Excliamation, EyeSvg, Tick, googleSvg } from "./SVG";
import { useForm } from "react-hook-form";
// import { useSelector } from "react-redux";

import Cookies from "js-cookie";
import { Modal, Row } from "react-bootstrap";
import { ToggleContext } from "../ContextFile";
import { Link } from "react-router-dom";
import API from "../../../../../axios/auth";
import { toast } from "react-toastify";
const token = Cookies.get("token");

const GoogleFunding = () => {
  const [toggle1, setToggle1] = useState("One-off");
  // const [toggle2, setToggle2] = useState("eur");
  const [rangeValue, setRangeValue] = useState(100); // Initialize the range value state with 0.
  const [fundinginfo, setFundingInfo] = useState({});
  const [modal, setModal] = useState(false);
  const { HandleActiveStep } = useContext(ToggleContext);
  // const { Cities, CountryCode } = useSelector(
  //   (state) => state.GoogleSearchDetails
  // );

  // const handleRangeChange = (event) => {
  //   // Update the range value state when the input value changes.
  //   setRangeValue(event.target.value);
  // };

  const Monthly = [
    "Expert checks your ad",
    "Safety Net protects your budget",
    "Continuous optimization of your ad",
  ];

  const OnnOff = [
    "Expert checks your ad",
    "No long-term commitment",
    "Great for quick tests",
  ];

  // function getCurrentDate() {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   let month = today.getMonth() + 1; // Months are zero-indexed
  //   let day = today.getDate();

  //   // Add leading zero if month or day is a single digit
  //   month = month < 10 ? `0${month}` : month;
  //   day = day < 10 ? `0${day}` : day;

  //   return `${year}-${month}-${day}`;
  // }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFundingInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //format date in this format: Output: 20240315
  // const FormatDate = (Startdate) => {
  //   const date = new Date(Startdate);
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because getMonth() returns zero-based month index
  //   const day = date
  //     .getDate()
  //     .toString()
  //     .padStart(2, "0");

  //   const formattedDate = `${year}${month}${day}`;

  //   return formattedDate;
  // };

  // let EndDate = FormatDate(fundinginfo.endDate);
  const SelectArray = [7, 14, 21];

  // date calculation according to the Select of days 7 days ,14 days and 21days
  function getNextDate(numberOfDays) {
    // Get the current date
    let currentDate = new Date();

    // Calculate the next date based on the provided number of days
    let nextDate = new Date(
      currentDate.getTime() + numberOfDays * 24 * 60 * 60 * 1000
    );

    // Format the next date as YYYY/MM/DD
    let StartDate = `${currentDate.getFullYear()}/${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    // Format the next date as YYYY/MM/DD
    let formattedNextDate = `${nextDate.getFullYear()}/${(
      nextDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${nextDate
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    setFundingInfo((prevState) => ({
      ...prevState,
      endDate: formattedNextDate,
      StartDate,
    }));
  }

  useEffect(() => {
    HandleActiveStep(3);
    if (toggle1 === "monthly") {
      setRangeValue(300);
      setFundingInfo((prevState) => ({
        ...prevState,
        amount: 300,
        noOfDays: "30",
      }));
    } else if (toggle1 === "One-off") {
      setRangeValue(20);
      setFundingInfo((prevState) => ({
        ...prevState,
        amount: 20,
        noOfDays: "7",
      }));
    }
  }, [toggle1]);

  useEffect(() => {
    if (fundinginfo.noOfDays) {
      getNextDate(fundinginfo.noOfDays);

      // range setting
      if (fundinginfo?.noOfDays === "7") {
        setRangeValue(20);
        setFundingInfo((prevState) => ({
          ...prevState,
          amount: 20,
        }));
      } else if (fundinginfo?.noOfDays === "14") {
        setRangeValue(50);
        setFundingInfo((prevState) => ({
          ...prevState,
          amount: 50,
        }));
      } else if (fundinginfo?.noOfDays === "21") {
        setRangeValue(100);
        setFundingInfo((prevState) => ({
          ...prevState,
          amount: 100,
        }));
      }
    }
  }, [fundinginfo.noOfDays]);

  

  // ask an Expert from submission function-----> start

  const AskAnExpertForm = async (data) => {
    try {
      const res = await API.post("/create-expert", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Form Submitted");
      
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  // ask an Expert from submission function-----> End
  return (
    <div className="google-funding-box">
      <div className="google-funding-main">
        <div className="google-funding-div1">
          <p className="google-funding-div1-text">Budget</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <div className=" google-funding-toggle-btn">
              <div
                className={`google-funding-toggle-btn-box ${toggle1 ===
                  "One-off" && "active"}`}
                onClick={() => setToggle1("One-off")}
              >
                <p
                  className={`google-funding-toggle-btn-text ${toggle1 ===
                    "One-off" && "active"}`}
                >
                  One-off
                </p>
              </div>

              <div
                className={`google-funding-toggle-btn-box ${toggle1 ===
                  "monthly" && "active"}`}
                onClick={() => setToggle1("monthly")}
              >
                <p
                  className={`google-funding-toggle-btn-text ${toggle1 ===
                    "monthly" && "active"}`}
                >
                  Monthly
                </p>
              </div>
            </div>
            {/* <ToggleBtn
          toggle={toggle2}
          setToggle={setToggle2}
          display={"block"}
        /> */}
          </div>
        </div>

        <div className="google-funding-div2">
          <div className="google-funding-div2-box">
            <div className="google-funding-div2-sub-box">
              <p className="google-funding-div2-p">{`Rs. ${fundinginfo.amount}₹  `}</p>
              {toggle1 === "One-off" ? (
                <Select
                  options={SelectArray}
                  handleChange={handleInputChange}
                />
              ) : (
                <p className="google-funding-div2-p">30 days</p>
              )}
            </div>

            <span className="google-funding-div2-span">Incl. 20% Commi.</span>
          </div>
          <input
            style={{ width: "100%" }}
            type="range"
            value={fundinginfo.amount}
            min={rangeValue}
            max={1300}
            // onChange={handleRangeChange}
            name={"amount"}
            onChange={handleInputChange}
          />
        </div>

      
        <div className="google-funding-div3">
          <span className="google-funding-div3-title">Forecast</span>

          <div
            className="google-funding-div3-box"
            style={
              toggle1 !== "monthly"
                ? { background: "#EDF9F8 ", width: "65%", height: "auto" }
                : { width: "auto" }
            }
          >
            {toggle1 === "monthly" ? (
              <>
                <ForecastBox
                  title={"Impressions"}
                  views={"28000"}
                  icon={EyeSvg}
                />
                <ForecastBox title={"Clicks"} views={"589"} icon={Arrow} />
              </>
            ) : (
              <NoForecast
                body={`   You have selected 0 keywords. Please select between 3 and 40 keywords
          in order to generate a forecast.`}
                title={"No forecast available"}
                logo={Caution}
              />
            )}
          </div>
        </div>

        <div className="google-funding-div4">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <span className="google-funding-div4-text">Launch ON</span>
            <span style={{ marginBottom: "3px" }}>{googleSvg}</span>
          </div>

          <div className="google-funding-div4-main">
            {(toggle1 === "One-off" ? OnnOff : Monthly).map((item) => (
              <LaunchItems item={item} />
            ))}
          </div>
        </div>
        <div className="google-funding-div5">
          <button style={{ background: "none" }} onClick={() => setModal(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="61"
              viewBox="0 0 273 61"
              fill="none"
            >
              <g filter="url(#filter0_i_370_10606)">
                <path
                  d="M0 11.3884C0 5.09876 5.09876 0 11.3884 0H246.812C251.599 0 255.875 2.99341 257.513 7.49127L271.433 45.7145C274.138 53.1427 268.638 61 260.732 61H11.3884C5.09876 61 0 55.9012 0 49.6116V11.3884Z"
                  fill="url(#paint0_linear_370_10606)"
                />
              </g>
              <text
                x="24%"
                y="64%"
                font-family="Arial"
                className="Ask-an-Expert"
              >
                Ask an Expert
              </text>
              <defs>
                <filter
                  id="filter0_i_370_10606"
                  x="0"
                  y="0"
                  width="285.797"
                  height="73.1476"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="13.6661" dy="12.1476" />
                  <feGaussianBlur stdDeviation="18.6011" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_370_10606"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_370_10606"
                  x1="0"
                  y1="0"
                  x2="265.5"
                  y2="61"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#01417E" />
                  <stop offset="1" stop-color="#072F56" />
                </linearGradient>
              </defs>
            </svg>
          </button>
          <button onClick={() => setModal(true)} style={{ background: "none" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="61"
              viewBox="0 0 273 61"
              fill="none"
            >
              <g filter="url(#filter0_i_370_10611)">
                <path
                  d="M273 49.6116C273 55.9012 267.901 61 261.612 61H26.1881C21.4013 61 17.1254 58.0066 15.4873 53.5087L1.56683 15.2855C-1.13843 7.85732 4.36218 0 12.2677 0H261.612C267.901 0 273 5.09876 273 11.3884V49.6116Z"
                  fill="url(#paint0_linear_370_10611)"
                />
              </g>
              <text
                x="21%"
                y="64%"
                font-family="Arial"
                className="Ask-an-Expert"
              >
                Proceed To Payment
              </text>
              <defs>
                <filter
                  id="filter0_i_370_10611"
                  x="0.869141"
                  y="0"
                  width="285.797"
                  height="73.1476"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="13.6661" dy="12.1476" />
                  <feGaussianBlur stdDeviation="18.6011" />
                  <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                  />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="shape"
                    result="effect1_innerShadow_370_10611"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_370_10611"
                  x1="273"
                  y1="61"
                  x2="7.5"
                  y2="-1.26219e-06"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#47BEB9" />
                  <stop offset="1" stop-color="#358F8B" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>
      </div>
      <CustomerIdModal
        show={modal}
        setModal={setModal}
        onHide={() => setModal(false)}
        AskAnExpertForm={AskAnExpertForm}
      />
      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/bingcampaign/bing-add-1/targeting"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Back
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};
// const ToggleBtn = ({ toggle, setToggle, display }) => {
//   return (
//     <div className=" google-funding-toggle-btn">
//       <div
//         className={`google-funding-toggle-btn-box ${toggle === "eur" &&
//           "active"}`}
//         onClick={() => setToggle("eur")}
//       >
//         <p
//           className={`google-funding-toggle-btn-text ${toggle === "eur" &&
//             "active"}`}
//         >
//           EUR
//         </p>
//       </div>

//       <div
//         className={`google-funding-toggle-btn-box ${toggle === "usd" &&
//           "active"}`}
//         onClick={() => setToggle("usd")}
//       >
//         <p
//           className={`google-funding-toggle-btn-text ${toggle === "usd" &&
//             "active"}`}
//         >
//           USD
//         </p>
//       </div>

//       <div
//         className={`google-funding-toggle-btn-box ${toggle === "gdp" &&
//           "active"}`}
//         onClick={() => setToggle("gdp")}
//         style={{ display: display }}
//       >
//         <p
//           className={`google-funding-toggle-btn-text ${toggle === "gdp" &&
//             "active"}`}
//         >
//           GDP
//         </p>
//       </div>
//     </div>
//   );
// };

const ForecastBox = ({ views, title, icon }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "11px" }}>
      {icon}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <span className="google-funding-div3-impression-no ">{views}</span>
        <p className="google-funding-div3-impression ">{title}</p>
      </div>
    </div>
  );
};
export const NoForecast = ({ logo, title, body, color }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        gap: "8px",
        alignSelf: "stretch",
      }}
    >
      <span>{logo}</span>
      <div>
        <p className="no-forecast-title">{title}</p>
        <span className="no-forecast-para" style={{ color: color }}>
          {body}
        </span>
      </div>
    </div>
  );
};

const LaunchItems = ({ item }) => {
  return (
    <div className="google-funding-div4-main">
      <div className="google-funding-div4-sub-main">
        {Tick}
        <span className="google-funding-div4-sub-main-text">{item}</span>
        {Excliamation}
      </div>
    </div>
  );
};

const Select = ({ selectHeading, options, handleChange }) => {
  const dropdownRef = useRef();

  // const handleDropdownClick = () => {
  //   dropdownRef.current.classList.add("clicked");
  // };
  return (
    <select
      name="noOfDays"
      className="hone-campaign-dropdown"
      ref={dropdownRef}
      onClick={(e) => handleChange(e)}
    >
      {/* <option
        value=""
        className="placeholder"
        style={"clicked" ? { display: "none" } : {}}
      >
        {selectHeading}
      </option> */}
      {options.map((item, i) => (
        <>
          <option value={item}>{`${item} days`}</option>
        </>
      ))}
    </select>
  );
};

// MODAL FOR CUSTOMER_ID SELECTION
const CustomerIdModal = (props) => {
  const { AskAnExpertForm, onHide } = props;
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    // Regular expression to allow only digits, spaces, parentheses, dashes, and dots
    const regex = /^[0-9\b\s().-]+$/;

    if (value === "" || regex.test(value)) {
      setPhoneNumber(value);
    }
  };

  const { register, handleSubmit, reset } = useForm();

  const Submit = async (data) => {
    data = { ...data, phoneNo: phoneNumber };
    let res = await AskAnExpertForm(data);
    if (res?.data?.status) {
      reset();
      setPhoneNumber("");
      onHide();
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Row className="header-modal">
        <Row className="header-modal-heading">Ask an Expert</Row>

        <Row>
          <form
            onSubmit={handleSubmit(Submit)}
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="ask-an-expert-text-field">
              <h6>Name*</h6>
              <input
                type="text"
                required
                {...register("name")}
                placeholder="Enter Name"
              />
            </div>

            <div className="ask-an-expert-text-field">
              <h6>Email*</h6>
              <input
                type="email"
                required
                {...register("email")}
                placeholder="Enter Email"
              />
            </div>
            <div className="ask-an-expert-text-field">
              <h6>Phone No</h6>
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <p>+91</p>
                <input
                  style={{ width: "100%" }}
                  type="tel"
                  placeholder="Enter phone number"
                  value={phoneNumber}
                  onChange={handleChange}
                  pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                  title="Please enter a valid phone number"
                  maxLength={10}
                  minLength={10}
                />
              </div>
            </div>
            <div className="ask-an-expert-text-field">
              <h6>Remark</h6>
              <textarea
                type="text"
                {...register("remark")}
                placeholder="Enter Remark"
              />
            </div>
            <button type="submit" style={{ background: "none" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="140"
                height="61"
                viewBox="0 0 273 61"
                fill="none"
              >
                <g filter="url(#filter0_i_370_10611)">
                  <path
                    d="M273 49.6116C273 55.9012 267.901 61 261.612 61H26.1881C21.4013 61 17.1254 58.0066 15.4873 53.5087L1.56683 15.2855C-1.13843 7.85732 4.36218 0 12.2677 0H261.612C267.901 0 273 5.09876 273 11.3884V49.6116Z"
                    fill="url(#paint0_linear_370_10611)"
                  />
                </g>
                <text
                  x="35%"
                  y="68%"
                  font-family="Arial"
                  className="Ask-an-expert-modal-btn"
                >
                  Submit
                </text>
                <defs>
                  <filter
                    id="filter0_i_370_10611"
                    x="0.869141"
                    y="0"
                    width="285.797"
                    height="73.1476"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="13.6661" dy="12.1476" />
                    <feGaussianBlur stdDeviation="18.6011" />
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_370_10611"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_370_10611"
                    x1="273"
                    y1="61"
                    x2="7.5"
                    y2="-1.26219e-06"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#358F8B" />
                  </linearGradient>
                </defs>
              </svg>
            </button>
          </form>
        </Row>
      </Row>
    </Modal>
  );
};
export default GoogleFunding;
