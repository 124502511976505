import React from 'react'
import "./generateBanner.css";
import RightSideImg from "../../../../../../assets/images/brand-set-up-img.png"
import StepperRight from '../../../../../icons/stepperRight';
import ThirdStepperRight from '../../../../../icons/stepperRight/thirdStepper';

export default function GenerateBanner({ generationView, setGenerationView }) {
    return (
        <div className="brand-setup-main-section">

            <div className="genrate-brand-setup-banner-section">
                <div className="brand-left-side">
                    <h1>Project Setup</h1>
                    <p> To generate ad creatives, you must have a brand, so let's setup it up.</p>
                </div>
                <div className="brand-right-side">
                    <div className="brand-step-alignment">
                        <div className="step-details-alignment" onClick={() => setGenerationView("A")}>
                            Step 1
                            <div className="step-box-alignment active-steper">
                                <div className="sterp-center-box">

                                </div>
                            </div>
                        </div>
                        <div className="step-details-alignment" onClick={() => setGenerationView("B")}>
                            Step 2
                            <div className={generationView !== "A"  ? "step-box-alignment active-steper" : "step-box-alignment "}>
                                <div className="sterp-center-box">

                                </div>
                            </div>
                        </div>
                        <div className="step-details-alignment " onClick={() => setGenerationView("C")}                    >
                            Step 3
                            <div className="step-box-alignment complte-steper">
                                <div className={generationView === "C" ? "step-box-alignment " : " step-box-alignment "}>
                                 {generationView==="C" ?  < ThirdStepperRight />:<StepperRight />}   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
