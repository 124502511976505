import React from 'react'

export default function RoundedIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_247_169)">
                <path d="M7.999 14.6665C11.6809 14.6665 14.6656 11.6817 14.6656 7.99986C14.6656 4.31799 11.6809 1.33325 7.999 1.33325C4.31714 1.33325 1.3324 4.31799 1.3324 7.99986C1.3324 11.6817 4.31714 14.6665 7.999 14.6665Z" stroke="#358F8B" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.99915 12C10.2083 12 11.9991 10.2091 11.9991 8C11.9991 5.79086 10.2083 4 7.99915 4C5.79001 4 3.99915 5.79086 3.99915 8C3.99915 10.2091 5.79001 12 7.99915 12Z" stroke="#358F8B" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.99914 9.33348C8.7356 9.33348 9.33262 8.73646 9.33262 7.99999C9.33262 7.26353 8.7356 6.6665 7.99914 6.6665C7.26267 6.6665 6.66565 7.26353 6.66565 7.99999C6.66565 8.73646 7.26267 9.33348 7.99914 9.33348Z" stroke="#358F8B" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_247_169">
                    <rect width="16" height="16" fill="white" transform="translate(-0.000854492)" />
                </clipPath>
            </defs>
        </svg>

    )
}
