// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-b-primary {
    width: 120px;
    height: 40px;
    justify-content: center;
    background: #B37FCA;
    border-radius: 12px;
}

.custom-input::placeholder {
    color: #B37FCA;
}

.custom-input {
    border: 1px solid #A8ACAF;
    border-radius: 20px;
    height: 60px;
}

.custom-input2{
    border: none;
}
.custom-input2:visited, .custom-input2:focus{
   outline: none;
}
.custom-input2::placeholder{
    color: #B37FCA;
}
.btn1{
    background-color: #B37FCA;

}
.btn1:hover{
    background-color: #B37FCA;
}
.btn2{
    background: #F5A4A1;
}
.btn2:hover{
    background: #F5A4A1;
}
.card-header{
    border :unset
}`, "",{"version":3,"sources":["webpack://./src/component/panel/user/pages/TextScreen.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;AACA;GACG,aAAa;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;;AAE7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI;AACJ","sourcesContent":[".btn-b-primary {\r\n    width: 120px;\r\n    height: 40px;\r\n    justify-content: center;\r\n    background: #B37FCA;\r\n    border-radius: 12px;\r\n}\r\n\r\n.custom-input::placeholder {\r\n    color: #B37FCA;\r\n}\r\n\r\n.custom-input {\r\n    border: 1px solid #A8ACAF;\r\n    border-radius: 20px;\r\n    height: 60px;\r\n}\r\n\r\n.custom-input2{\r\n    border: none;\r\n}\r\n.custom-input2:visited, .custom-input2:focus{\r\n   outline: none;\r\n}\r\n.custom-input2::placeholder{\r\n    color: #B37FCA;\r\n}\r\n.btn1{\r\n    background-color: #B37FCA;\r\n\r\n}\r\n.btn1:hover{\r\n    background-color: #B37FCA;\r\n}\r\n.btn2{\r\n    background: #F5A4A1;\r\n}\r\n.btn2:hover{\r\n    background: #F5A4A1;\r\n}\r\n.card-header{\r\n    border :unset\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
