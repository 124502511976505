import { createSlice } from "@reduxjs/toolkit";
const initialState = { websiteUrl: "", brandName: "", brandDescription: "" };
const newCampaignSlice = createSlice({
  name: "newCampaign",
  initialState,
  reducers: {
    addwebsiteUrl: (state, action) => {
      state.websiteUrl = action.payload;
    },
    addbrandDetails: (state, action) => {
      state.brandName = action.payload.brandname;
      state.brandDescription = action.payload.brandDescription;
    },
  },
});

export const { addwebsiteUrl, addbrandDetails } = newCampaignSlice.actions;
export default newCampaignSlice.reducer;
