// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerbar {
  border-radius: 15px;
  background: #ffffff;
}

.headerbar {
  position: sticky;
  top: 0; /* Adjust this value based on your requirements */
  z-index: 1;
}
.navbar-brand {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #b37fca;
}

.btn-prime {
  background: linear-gradient(180deg, #b37fca 0%, #f5a4a1 40.63%, #f1a2a9 100%);
  width: 150px;
  height: 50px;
  border: 0;
  background: linear-gradient(180deg, #b37fca 0%, #f5a4a1 70.83%, #f1a2a9 100%);
  border-radius: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #ffffff;
}

.custom-input-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  outline: none;
  /* Remove the focus outline */
  border: none;
}

.custom-input:focus,
.custom-input:visited {
  outline: none;
  /* Remove the focus outline and visited style */
}
`, "",{"version":3,"sources":["webpack://./src/component/panel/user/header/header.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,MAAM,EAAE,iDAAiD;EACzD,UAAU;AACZ;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,6EAA6E;EAC7E,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,6EAA6E;EAC7E,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,4BAA4B;;EAE5B,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,6BAA6B;EAC7B,YAAY;AACd;;AAEA;;EAEE,aAAa;EACb,+CAA+C;AACjD","sourcesContent":[".headerbar {\r\n  border-radius: 15px;\r\n  background: #ffffff;\r\n}\r\n\r\n.headerbar {\r\n  position: sticky;\r\n  top: 0; /* Adjust this value based on your requirements */\r\n  z-index: 1;\r\n}\r\n.navbar-brand {\r\n  font-family: \"Poppins\";\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 26px;\r\n  line-height: 39px;\r\n  color: #b37fca;\r\n}\r\n\r\n.btn-prime {\r\n  background: linear-gradient(180deg, #b37fca 0%, #f5a4a1 40.63%, #f1a2a9 100%);\r\n  width: 150px;\r\n  height: 50px;\r\n  border: 0;\r\n  background: linear-gradient(180deg, #b37fca 0%, #f5a4a1 70.83%, #f1a2a9 100%);\r\n  border-radius: 12px;\r\n  font-family: \"Poppins\";\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 16px;\r\n  line-height: 24px;\r\n  /* identical to box height */\r\n\r\n  color: #ffffff;\r\n}\r\n\r\n.custom-input-header {\r\n  font-family: \"Poppins\";\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-size: 12px;\r\n  line-height: 18px;\r\n  outline: none;\r\n  /* Remove the focus outline */\r\n  border: none;\r\n}\r\n\r\n.custom-input:focus,\r\n.custom-input:visited {\r\n  outline: none;\r\n  /* Remove the focus outline and visited style */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
