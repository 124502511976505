import axios from "../../../component/axios/auth";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";
import Header from "../Header/Header";
import MainContainer from "../../MainUI/Main";

const PrivacyPolicy = () => {
  const [content, setContent] = useState();
  useEffect(() => {
    getPrivacyPolicy();
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getPrivacyPolicy = async () => {
    try {
      const res = await axios.get("/get_privacy_policy");
      setContent(res?.data?.data[0]?.privacy);
    } catch (error) {
    }
  };
  return (
    <Container fluid style={{ padding: "0px" }}>

      <Header/>
      <Row style={{ color: "#000", padding: "64px 70px" }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Row>
      <MainContainer/>
    </Container>
  );
};

export default PrivacyPolicy;
