// import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "./SignUp.css"
import google from "../assets/images/loginWith/google.png";
import fb from "../assets/images/loginWith/fb.png";

import { Link } from "react-router-dom";
function Login() {
    return (
<>
        <div className="container-fluid backgroundImg">
            <div className="row pt-5 align-items-center">
                <div className="col-sm-12 text-center">
                    <div className=" ">
                        <h6 className="logoTitle">EXOAD</h6>
                    </div>

                    <div>
                        <h6 className="getStarted title mt-2">Let's get started</h6>
                    </div>

                    <div className="d-flex justify-content-center my-3">
                        <div className="login-box d-flex align-items-center">
                            <img src={google}></img>
                            <span className="title ms-2">Sign up with Google</span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center my-3">
                        <div className="login-box d-flex align-items-center">
                            <img src={fb}></img>
                            <span className=" title ms-2">Sign up with Facebook</span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                        <div className="term-service d-flex align-items-center">
                       <div className="horizontal-line"></div>
                       <div  >Or log in with email</div>
                       <div className="horizontal-line"></div>
                       </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className="  d-flex align-items-center">
                            <div className="term-service">
                                <label className="float-start">  Email</label>
                            <input type="email" className="form-control" placeholder="enter your email"/>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                        <div className=" term-service d-flex align-items-center">
                            <div className="term-service">
                                <label className="float-start"> Password</label>
                            <input type="email" className="form-control" placeholder="enter your password"/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <div className=" term-service d-flex align-items-center">
                            <div className="term-service mt-2">
                                <button className="text-center theme-bg-submit text-white btn"> Login</button>
                    
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3 mb-0">
                        <div className=" term-service d-flex align-items-center">
                            <div>
                         <h6 className="fw-bold">Forget Password</h6>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className=" d-flex align-items-center mt-0">
                            <div>
                            <h6 className=" no-wrap paragraph mt-0">Need an account?    <span className="fw-bold h6">
                               <Link to="/user/signup"> Sign up </Link></span>
                            </h6>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>



</>
    )
}
export default Login      