import React from 'react'

export default function TwitterIcon() {
    return (
        <svg width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.0686 0H30.9664L20.2686 12.3089L32.8997 29H22.9753L15.242 18.8822L6.34862 29H1.45084L12.922 15.8533L0.806396 0H10.9886L18.0131 9.28L26.0686 0ZM24.3286 26.0356H27.0353L9.5064 2.77111H6.54195L24.3286 26.0356Z" fill="#47BEB9" />
        </svg>

    )
}
