import React from 'react'

export default function SaveIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_12_2605)">
                <path d="M13.3352 13.3338L10.0012 9.99976L6.66724 13.3338" stroke="#03BB63" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.0009 9.99976V17.5003" stroke="#03BB63" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.9933 15.3255C17.8061 14.8824 18.4482 14.1812 18.8183 13.3325C19.1884 12.4839 19.2653 11.5362 19.0369 10.639C18.8086 9.74183 18.2879 8.94623 17.5572 8.37779C16.8265 7.80936 15.9272 7.50046 15.0014 7.49985H13.9513C13.6991 6.52413 13.2289 5.6183 12.5762 4.85045C11.9234 4.0826 11.1051 3.47271 10.1828 3.06664C9.26039 2.66057 8.25797 2.46888 7.25086 2.50599C6.24374 2.54309 5.25815 2.80803 4.36816 3.28087C3.47818 3.75372 2.70696 4.42217 2.11251 5.23597C1.51805 6.04977 1.11581 6.98775 0.936037 7.97938C0.756263 8.97102 0.80363 9.9905 1.07458 10.9612C1.34552 11.9319 1.833 12.8285 2.50035 13.5837" stroke="#03BB63" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.3352 13.3338L10.0012 9.99976L6.66724 13.3338" stroke="#03BB63" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_12_2605">
                    <rect width="20.0017" height="20.0017" fill="white" transform="translate(0 -0.000854492)" />
                </clipPath>
            </defs>
        </svg>

    )
}
