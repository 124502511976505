import "./Login.css";
// import LoginImg from "./Images/Login.png";
import Exoad from "./Images/EXOAD.png";
// import vector from "./Images/Vector 3.png";
// import google from "./Images/google.png";
// import faceBook from "./Images/Facebook.png";
// import apple from "./Images/apple.png";
import passwordiMG from "./Images/password.png";
import eyeOpen from "./Images/openEye.png";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { 
  // useContext,
   useState } from "react";
import { 
  // Link,
   useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "../../axios/auth";
import { 
  // ToastContainer,
   toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { MailContext } from "./mailContext";
const ConfirmPassword = () => {
  // Check both the passwords before calling API.
  const [modal, setModal] = useState(false);
  const [toggleEye, setToggleEye] = useState(false);
  const [toggleEye2, setToggleEye2] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch("password", ""); // Watch the "password" field
  const confirmPassword = watch("confirmPassword", ""); // Watch the "confirmPassword" field
  const navigate = useNavigate();
  // const { mail } = useContext(MailContext);
  // const userEmail = sessionStorage.getItem("email")
  // const email = {
  //   email: userEmail
  // };

  const mail = sessionStorage.getItem("email");
  const onSubmit = async (data) => {
    try {
      
      if (password === confirmPassword) {
         await sendOpt(data);
      }
    } catch (error) {
      // Display an error toast
      
      toast.error("Miss match password", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  };

  const sendOpt = async (password) => {
    let para = { email: mail, password: password.password };

    try {
      const res = await axios.post("/changeforgetpassword", para);

if(res?.data?.status ===true){

      toast.success("Password changed successfully!", {
        className: 'custom-toast-success',
        hideProgressBar: true
      });

      setTimeout(() => {
        setModal(true);
      }, 2000);
}
else{
  toast.error(res?.data?.message, {
    className: 'custom-toast-error',
    hideProgressBar: true
  });
}
    } catch (error) {
      toast.error("Miss match password", {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      throw error; // Re-throw the error to handle it in the onSubmit catch block
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    navigate("/login");
  };

  return (
    <Container fluid className="layout">
      <Row className="align-items-center">
        <Col
          className=" liginImg   d-flex justify-content-end align-items-center "
          lg={6}
          md={12}
          sm={12}
        >
          
        </Col>
        <Col style={{ textAlign: "center" }} lg={6} md={12} sm={12}>
          <div
            className="right d-flex justify-content-center align-items-center "
            style={{
              padding: "3rem 2rem 1rem 2rem",
              height: "70vh",
              gap: "20px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className=" ">
                <img src={Exoad} alt="Exoad" />

                <div style={{ width: "100%" }}>
                  <h1 className="welcome">Forgot Password</h1>
                  <p className="p" style={{ width: "100%" }}>
                    We have send a code to your email:&nbsp;
                    <strong>{sessionStorage.getItem("email")}</strong>
                  </p>
                </div>

                {/* <SignIn toggleEye={toggleEye} setToggleEye={setToggleEye} /> */}

                <div style={{ margin: "20px 0px" }}>
                 

                  <div className="input-div">
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Password
                    </label>

                    <div className="password-container">
                      <div className="password-sub-container">
                        <input
                          className="password-container password-input"
                          type={!toggleEye ? "password" : "text"}
                          placeholder="********"
                          maxLength={50}
                          {...register("password", {
                            required: "Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password must be at least 8 characters long",
                            },
                          })}
                        />

                        {!toggleEye ? (
                          <img
                            className="password-icon"
                            src={passwordiMG}
                            alt="password"
                            onClick={() => setToggleEye(!toggleEye)}
                          />
                        ) : (
                          <img
                            className="password-icon"
                            src={eyeOpen}
                            alt="password"
                            onClick={() => setToggleEye(!toggleEye)}
                          />
                        )}
                      </div>

                    </div>
                    {errors.password && (
                    <span className="text-danger fw-bold"  style={{
                      fontSize: "12px"}} >
                      Password must be at least 8 characters
                    </span>
                  )}
                  </div>
                

                  <div className="input-div">
                    <label style={{ fontSize: 13 }} className="signup-label">
                      Confirm Password
                    </label>

                    <div className="password-container">
                      <div className="password-sub-container">
                        <input
                          className="password-container password-input"
                          maxLength={50}
                          type={!toggleEye2 ? "password" : "text"}
                          placeholder="********"
                          {...register(
                            "confirmPassword",
                            { required: true },
                            { min: 8, max: 10 }
                          )}
                        />
                        {!toggleEye2 ? (
                          <img
                            className="password-icon"
                            src={passwordiMG}
                            alt="password"
                            onClick={() => setToggleEye2(!toggleEye2)}
                          />
                        ) : (
                          <img
                            className="password-icon"
                            src={eyeOpen}
                            alt="password"
                            onClick={() => setToggleEye2(!toggleEye2)}
                          />
                        )}
                      </div>
                    </div>
                    {confirmPassword && password !== confirmPassword && (
                    <span
                      className="text-danger fw-bold"
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      Passwords did not match
                    </span>
                  )}
                  </div>
                </div>

                <button
                  type="submit"
                  className=" login-button button-container"
                >
                  {"Confrim"}
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
      <CustomModal
        show={modal}
        buttonTitle={"Login"}
        handleCloseModal={handleCloseModal}
      />
      {/* <ToastContainer /> */}
    </Container>
  );
};

const CustomModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="confirmpass-body-modal">
        <h5 className="confirmpass-label-modal ">
          Password Reset Successfully
        </h5>

        <Button
          className="confirmpass-btn-modal "
          onClick={props.handleCloseModal}
        >
          {props.buttonTitle}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmPassword;
