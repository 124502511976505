import Header from "../header/Header"
import AdminSidebar from "../sidebar/AdminSidebar"
import StylesContaent from "./AdminContent.module.css"
import styles from '../sidebar/AdminSidebar.module.css'
import { Card } from "react-bootstrap"
import {  FaUser, FaUserTimes } from "react-icons/fa";
import { FaUserAltSlash } from "react-icons/fa";
import Cookies from "js-cookie";
import axios from "../../../axios/auth";
import { useEffect, useState } from "react"

function Dashboard() {
const [count,setCount] = useState([])

    const token = Cookies.get("token");
    async function fetchData() {
        try {
          const res = await axios.get("/count-all", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setCount(res?.data);
        } catch (error) {
          // console.error("Error fetching customer:", error);
        }
      }
      useEffect(() => {
        fetchData();
      }, [token]);
    

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-2 p-0 ">
                        <div className={styles.mobileSidebar}> 
                            <AdminSidebar className={styles.mobileSidebar} />
                        </div>
                    </div>
                    <div className="col-sm-12  col-md-10 bg-white">
                        <div className="container">
                            <Header title="Add Creative" btnname="Create Campaigns" />
                        </div>
                        <div className="container" style={{padding:"50px"}}>
                            <div className="container-fliud " >
                                <div className="row" >
                            <Card className="col-md-3" style={{margin:10}}>
      <Card.Body className="d-flex">
       
        <FaUser  style={{width:50,height:50,marginRight:20}} />
      <div style={{width:"100%"}} className="text-center">  
        <h5>Total User</h5>
        <h3 className="text-center">{count?.countAll}+</h3>
      </div>
      </Card.Body>
    </Card>                       
    <Card className="col-md-3" style={{margin:10}}>
      <Card.Body className="d-flex">
       
        <FaUser style={{width:50,height:50,marginRight:20}} />
      <div style={{width:"100%"}} className="text-center">  
        <h5>Total Active User</h5>
        <h3 >{count?.countActive}+</h3>
      </div>
      </Card.Body>
    </Card>                       
    <Card className="col-md-3" style={{margin:10}} >
      <Card.Body className="d-flex" >
      <FaUserAltSlash  style={{width:50,height:50,marginRight:20}}  />
      <div style={{width:"100%"}} className="text-center">  
        <h5>Total Inactive User</h5>
        <h3 className="text-center">{count?.counInactive}+</h3>
      </div>
      </Card.Body>
    </Card>                         
    </div>

    <div className="row" >
                            <Card className="col-md-3" style={{margin:10}}>
      <Card.Body className="d-flex">
       
        <FaUserTimes  style={{width:50,height:50,marginRight:20}} />
      <div style={{width:"100%"}} className="text-center">  
        <h5>Total Blocked Users</h5>
        <h3 className="text-center">{count?.countDel}+</h3>
      </div>
      </Card.Body>
    </Card>                       
    {/* <Card className="col-md-3" style={{margin:10}}>
      <Card.Body className="d-flex">
       
        <FaRegUser style={{width:50,height:50,marginRight:20}} />
      <div style={{width:"100%"}} className="text-center">  
        <h5>Total Active User</h5>
        <h3 >300+</h3>
      </div>
      </Card.Body>
    </Card>                       
    <Card className="col-md-3" style={{margin:10}} >
      <Card.Body className="d-flex" >
      <FaUserAltSlash  style={{width:50,height:50,marginRight:20}}  />
      <div style={{width:"100%"}} className="text-center">  
        <h5>Total Inactive User</h5>
        <h3 className="text-center">300+</h3>
      </div>
      </Card.Body>
    </Card>                          */}
    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard