import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  size:"",
  bgImage:"",
  convertAll:"",
  editOneCreative:"",
 
  brandData:{}
 
  // selectedLogo:""
};
const brandSetupData = createSlice({
  name: "addCreate",
  initialState,
  reducers: {
    
    addScreenSize: (state, action) => {
      state.size = action.payload;
    },
    addBgImage:(state,action) =>{
      state.bgImage=(action.payload)
    },
    addConvertAll:(state,action) =>{
      state.convertAll=(action.payload)
    },
    addEditOneCreative:(state,action) =>{
      state.editOneCreative=(action.payload)
    },
   addBrandData:(state,action)=>{
    state.brandData={...state.brandData,...action.payload}
   },
   removeBrandData:(state,action)=>{
    state.brandData=(action.payload)
   },
   replaceAllBrandData:(state,action)=>{
    state.brandData=(action.payload)
   }

  },
});

export const {
  
  addScreenSize,
  addBgImage,
  addConvertAll,
  addEditOneCreative,
  addBrandData,
  removeBrandData,
  replaceAllBrandData
} = brandSetupData.actions;
export default brandSetupData.reducer;
