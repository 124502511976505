import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import axios from "../../../axios/auth";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import StylesContaent from "./AdminContent.module.css"
import styles from '../sidebar/AdminSidebar.module.css'
import loader from "../images/ZZ5H.gif" 
import 'bootstrap/dist/css/bootstrap.min.css';


// import  './ManagePlane.css'
function PlanManagement() {
  const token = Cookies.get("token");
  const [userData, setUserData] = useState({planName: "",
    description: "",
    price1:"",
    price2:"",
    price3:"",
    credits1:"",
    credits2:"",
    credits3:"",
    brands1:"",
    brands2:"",
    brands3:"",
    subscriptionType: "",
    adspendlimitperday:"",
    exoadAccount:"",
    unlimitedPhotoByIStock:"",
    unlimitedAICreativesGeneration:true?true:false,
    unlimitedAIcreativesforallsizes:true?true:false,
    downloadYourAIcreatives:true?true:false,
    uploadyourowncreativestoAds:true?true:false,
    unlimitedAutomateddsgenerator:true?true:false,
    setyoudailyspentandduration:true?true:false,
    unifiedAdAnalyticsForAds:true?true:false,
    trackYourAdswithPowerfulFilters:true?true:false,
    connecttoAllYourAdPlatforms:true?true:false,
    aITextgenerators:true?true:false,
    dedicatedSupport:true?true:false})


  const [plan, setPlan] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading,setIsLoading] = useState(false)

  const [formData, setFormData] = useState({
    planName: "",
    description: "",
    price1:"",
    price2:"",
    price3:"",
    credits1:"",
    credits2:"",
    credits3:"",
    brands1:"",
    brands2:"",
    brands3:"",
    subscriptionType: "",
    adspendlimitperday:"",
    exoadAccount:"",
    unlimitedPhotoByIStock:"",
    unlimitedAICreativesGeneration:true?true:false,
    unlimitedAIcreativesforallsizes:true?true:false,
    downloadYourAIcreatives:true?true:false,
    uploadyourowncreativestoAds:true?true:false,
    unlimitedAutomateddsgenerator:true?true:false,
    setyoudailyspentandduration:true?true:false,
    unifiedAdAnalyticsForAds:true?true:false,
    trackYourAdswithPowerfulFilters:true?true:false,
    connecttoAllYourAdPlatforms:true?true:false,
    aITextgenerators:true?true:false,
    dedicatedSupport:true?true:false

  });
  const [] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updatePlanData, setUpdatePlanData] = useState(null);
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };


  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  // const openModal = () => {
  //   setShowModal(true);
  // };
  // const openModal = () => {
  //   setFormData({
  //     planName: "",
  //     description: "",
  //     price1: "",
  //     price2: "",
  //     price3: "",
  //     credits1: "",
  //     credits2: "",
  //     credits3: "",
  //     brands1: "",
  //     brands2: "",
  //     brands3: "",
  //     subscriptionType: "",
  //     adspendlimitperday: "",
  //     exoadAccount: "",
  //     unlimitedPhotoByIStock: "",
  //     unlimitedAICreativesGeneration: false,
  //     unlimitedAIcreativesforallsizes: false,
  //     downloadYourAIcreatives: false,
  //     uploadyourowncreativestoAds: false,
  //     unlimitedAutomateddsgenerator: false,
  //     setyoudailyspentandduration: false,
  //     unifiedAdAnalyticsForAds: false,
  //     trackYourAdswithPowerfulFilters: false,
  //     connecttoAllYourAdPlatforms: false,
  //     aITextgenerators: false,
  //     dedicatedSupport: false
  //   });
  //   setShowModal(true);
  // };


  const closeModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleInputUpdateChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };

const handleAiGenaertionCahnge = (e) =>{
  const { name, checked } = e.target;
  
  setFormData({
    ...formData,
    [name]: checked
  });

}


const handleAiGenaertionUpdateCahnge = (e) =>{
  const { name, checked } = e.target;
  
  setUserData({
    ...userData,
    [name]: checked
  });

}

  // submit newData for Update plan

  async function handleUpdate(e) {
e.preventDefault()
    const updatedFormData = {
      ...userData,
      planBenefits: [
        { amount: userData.price1||userData.planBenefits[0].amount, credits: userData.credits1||userData.planBenefits[0].credits, brands: userData.brands1||userData.planBenefits[0].brands },
        { amount: userData.price2||userData.planBenefits[1].amount, credits: userData.credits2||userData.planBenefits[1].credits, brands: userData.brands2||userData.planBenefits[1].brands },
        { amount: userData.price3||userData.planBenefits[2].amount, credits: userData.credits3||userData.planBenefits[2].credits, brands: userData.brands3||userData.planBenefits[2].brands}
      ]
    };
    // Update the plan data
    try {
       await axios.put(`/update-plan/${userData._id}`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // toast.success("Plan Updated Successfully");
      toast.success("Plan Updated Successfully", {
        className: 'custom-toast-success',
        hideProgressBar: true
      });
      getPlan();
      closeUpdateModal();
    } catch (error) {
      // console.error("Error updating plan:", error);
      // toast.error("Failed to update plan");
      toast.error("Failed to update plan", {
        className: 'custom-toast-error',
         hideProgressBar: true
       }); 
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");
    const updatedFormData = {
      ...formData,
      planBenefits: [
        { amount: formData.price1, credits: formData.credits1, brands: formData.brands1 },
        { amount: formData.price2, credits: formData.credits2, brands: formData.brands2 },
        { amount: formData.price3, credits: formData.credits3, brands: formData.brands3 }
      ]
    };

    // // Create a new plan
    try {
       await axios.post("/addplans", updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // toast.success("Plan Created Successfully");
      toast.success("Plan Created Successfully", {
        className: 'custom-toast-success',
        hideProgressBar: true
      });
      getPlan();
      closeModal();
    } catch (error) {
      // console.error("Error creating plan:", error);
      // toast.error("Failed to create plan");
      toast.error("Failed to update plan", {
        className: 'custom-toast-error',
         hideProgressBar: true
       });

    }
    }
  
  

  const getPlan = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get("/planfetch", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPlan(res?.data?.data);
      setFormData(...res.data?.data)

      setCurrentPage(1);
      setIsLoading(false)
    } catch (error) {
      // console.error("Error fetching plans:", error);
    }
  };

  useEffect(() => {
    getPlan();
  }, [token]);

  const filteredPlans = plan.filter((data) =>
    data?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );




  const totalPages = Math.ceil(filteredPlans.length / itemsPerPage);

  const pageRange = 10;
  const maxPageButtons = Math.min(pageRange, totalPages);

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const pageNumbers = Array.from(
    { length: maxPageButtons },
    (_, index) => startPage + index
  );

  const indexOfLastPlan = currentPage * itemsPerPage;
  const indexOfFirstPlan = indexOfLastPlan - itemsPerPage;
  const currentPlans = filteredPlans.slice(
    indexOfFirstPlan,
    indexOfLastPlan
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const openUpdateModal = (planData) => {
    setUserData(planData)
    setShowUpdateModal(true)
    
  };


  const closeUpdateModal = () => {
    setUpdatePlanData(null);
    setShowUpdateModal(false);
  };


  const openDeleteModal = (customerId) => {
    setDeleteCustomerId(customerId);
    setShowDeleteModal(true);
  };


  // Function to close the delete confirmation modal
  const closeDeleteModal = () => {
    setDeleteCustomerId(null);
    setShowDeleteModal(false);
  };



  // Function to handle delete customer action
  const handleDeleteCustomer = async () => {
    const token = Cookies.get("token");

    await axios.post(`/delete-plan/${deleteCustomerId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    toast.info('Plan deleted succefully')
    getPlan()
    closeDeleteModal();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-2 p-0">

          <div className={styles.mobileSidebar}>
              <AdminSidebar className={styles.mobileSidebar} />
            </div>

          </div>
          <div className="col-sm-10 bg-white">
            <div className="container">
              <Header title="Add Creative" btnname="Create Campaigns" />
            </div>
            <div className="container background-maininput-div-input">
              <div className="container">
                <div className="row">
                  <h4 className="my-4 ps-0">
                    <b>Manage Plan</b>
                  </h4>
                  <hr />

                  {isLoading?<div style={{width:"100%",height:"50vh", position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <img src={loader} style={{width:"100px",height:"100px"}} alt="" /></div>: 

                  <div className="mt-2 p-0">
                    <div className="container background-second-box-sub-div">
                      <div className="row">
                        <div className="col-md-6">
                          <div className=" mb-3">
                            <input
                              type="text"
                              className="form-control background-second-box-sub-div "
                              placeholder="Search by Plan Name"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className="d-flex">
                            <button
                              className="btn btn-outline-secondary m-2"
                              type="button"
                              onClick={() => setSearchQuery("")}
                            >
                              Clear
                            </button>
                            {/* <Button variant="primary" className="common-btn m-2" onClick={openModal}>
                              Add Plan
                            </Button> */}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className={StylesContaent.tableHeading}>
                      <table className="table table-striped table-border text-center">
                        <thead>
                          <tr>
                            <th className="border">Plan Name</th>
                            <th className="border">Plan Description</th>
                            <th className="border">Plan Benefits</th>
                            <th className="border">Plan Type</th>
                            <th className="border">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPlans.map((data) => (
                            <tr key={data.id}>
                              <td className="border">{data?.name}</td>
                              <td className="border">{data?.description}</td>
                              <td className="border">
  <table className="inner-table">
    <thead>
      <tr className="border">
        <th className="border">Amount</th>
        <th className="border">Credits</th>
        <th className="border">Brands</th>
      </tr>
    </thead>
    <tbody>
      {data?.planBenefits.map((benefit, index) => (
        <tr key={index} className="border">
          <td className="border">{benefit.amount}</td>
          <td className="border">{benefit.credits}</td>
          <td className="border">{benefit.brands}</td>
        </tr>
      ))}
    </tbody>
  </table>
</td>
                              <td className="border">{data.subscriptionType}</td>



                              {/* <td className="border">
                                <div className="dropdown">
                                  <button
                                    className="btn  common-btn  dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                  >
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => openUpdateModal(data)}
                                      >
                                        Update
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => openDeleteModal(data._id)}
                                      >
                                        Delete
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td> */}

{/* <td className="border">
      <div className="dropdown">
        <button
          className="btn common-btn dropdown-toggle"
          type="button"
          onClick={toggleDropdown}
          aria-expanded={isDropdownOpen}
        >
          Action
        </button>
        <ul
          className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}
          aria-labelledby="dropdownMenuButton1"
          style={{ display: isDropdownOpen ? 'block' : 'none' }}
        >
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => openUpdateModal(data)}
            >
              Update
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => openDeleteModal(data._id)}
            >
              Delete
            </a>
          </li>
        </ul>
      </div>
    </td> */}

<td className="border">
              <div className="dropdown">
                <button
                  className="btn common-btn dropdown-toggle"
                  type="button"
                  onClick={() => toggleDropdown(data._id)}
                  aria-expanded={openDropdown === data._id}
                >
                  Action
                </button>
                <ul
                  className={`dropdown-menu ${openDropdown === data._id ? 'show' : ''}`}
                  aria-labelledby="dropdownMenuButton1"
                  style={{ display: openDropdown === data._id ? 'block' : 'none' }}
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => openUpdateModal(data)}
                    >
                      Update
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => openDeleteModal(data._id)}
                    >
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </td>


                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-center my-3"
          style={{ right: 0 }}
        >
          <ul className="pagination">
            {currentPage > pageRange && (
              <li className="page-item">
                <button className="page-link" onClick={() => paginate(1)}>
                  1
                </button>
              </li>
            )}
            {currentPage > pageRange && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
            {pageNumbers.map((pageNumber) => (
                 <li
                 key={pageNumber}
                 className={`${currentPage === pageNumber}` ? `${StylesContaent.activePage}` : ""}
               >
                 <button 
                   className= {`${StylesContaent.activePage} page-link` } 
                   onClick={() => paginate(pageNumber)}
                 >
                   {pageNumber}
                 </button>
               </li>
            ))}
            {currentPage + Math.floor(pageRange / 2) < totalPages && (
              <li className="page-item disabled">
                <span className="page-link">...</span>
              </li>
            )}
            {currentPage + Math.floor(pageRange / 2) < totalPages && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => paginate(totalPages)}
                >
                  {totalPages}
                </button>
              </li>
            )}
          </ul>

          <Modal show={showModal} onHide={closeModal}>
  <Modal.Header closeButton className="modal-input-header">
    <Modal.Title>Add New Plan</Modal.Title>
  </Modal.Header>
  <form onSubmit={handleSubmit}>
    <Modal.Body className="container modal-input modal-input-modal-body">
      <div className="col">
          <label style={{fontWeight: 600}}>Plan Name</label>
          <input type="text"
            name="planName"
            value={formData.planName}
            required
            onChange={handleInputChange}
            placeholder="Enter Plan Name"
            className="form-control" />
        </div>
        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Plan Description</label>
          <input type="text"
            name="description"
            required
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Enter Plan Description"
            className="form-control" />
        </div>

        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Daily Spend Limit</label>
          <input type="number"
          min={0}
            name="adspendlimitperday"
            required
            value={formData.adspendlimitperday}
            onChange={handleInputChange}
            placeholder="Enter daily spend limit"
            className="form-control" />
        </div>

        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Add Running Account</label>
          <input type="text"
            name="exoadAccount"
            required
            value={formData.exoadAccount}
            onChange={handleInputChange}
            placeholder="Ad running account"
            className="form-control" />
        </div>

        
        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Unlimited Photo By IStock</label>
          <input type="text"
             required
            name="unlimitedPhotoByIStock"
            value={formData.unlimitedPhotoByIStock}
            onChange={handleInputChange}
            placeholder="Image platform"
            className="form-control" />
        </div>
      <div className="row mt-2">
      <label style={{fontWeight: 600}}>Plan Benefits</label>
        <div className="col-md-4">
          <label >Price-1</label>
          <input type="number"
            name="price1"
            min={0}
            required
            value={formData.price1}
            onChange={handleInputChange}
            placeholder="Enter Plan Price"
            className="form-control" />
        </div>
        <div className="col-md-4">
          <label>Credits-1</label>
          <input type="number"
           min={0}
            name="credits1"
            required
            value={formData.credits1}
            onChange={handleInputChange}
            placeholder="Enter Plan Credits"
            className="form-control" />
        </div>
        <div className="col-md-4">
          <label >Brands-1</label>
          <input type="number"
           min={0}
            name="brands1"
            required
            value={formData.brands1}
            onChange={handleInputChange}
            placeholder="Enter brands"
            className="form-control" />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-4">
          <label >Price-2</label>
          <input type="number"
             required
           min={0}
            name="price2"
            value={formData.price2}
            onChange={handleInputChange}
            placeholder="Enter Plan Price"
            className="form-control" />
        </div>
        <div className="col-md-4">
          <label>Credits-2</label>
          <input type="number"
             required
            name="credits2"
            min={0}
            value={formData.credits2}
            onChange={handleInputChange}
            placeholder="Enter Plan Credits"
            className="form-control" />
        </div>
        <div className="col-md-4">
          <label >Brands-2</label>
          <input type="number"
            name="brands2"
            required
            min={0}
            value={formData.brands2}
            onChange={handleInputChange}
            placeholder="Enter brands"
            className="form-control" />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-4">
          <label >Price-3</label>
          <input type="number"
            name="price3"
            min={0}
            required
            value={formData.price3}
            onChange={handleInputChange}
            placeholder="Enter Plan Price"
            className="form-control" />
        </div>
        <div className="col-md-4">
          <label>Credits-3</label>
          <input type="number"
            name="credits3"
            min={0}
            required
            value={formData.credits3}
            onChange={handleInputChange}
            placeholder="Enter Plan Credits"
            className="form-control" />
        </div>
        <div className="col-md-4">
          <label >Brands-3</label>
          <input type="number"
            name="brands3"
            required
            min={0}
            value={formData.brands3}
            onChange={handleInputChange}
            placeholder="Enter brands"
            className="form-control" />
        </div>
      </div>
      

        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Subscription Type</label>
          <select name="subscriptionType"
            value={formData.subscriptionType}
            onChange={handleInputChange}
            required
            className="form-control">
            <option value="">Select</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>

        <div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Unlimited AI Creatives Generation</label>
  
  <input 
    type="checkbox"
    name="unlimitedAICreativesGeneration"
    checked={formData.unlimitedAICreativesGeneration}
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>


<div className="row mt-2">
      <div className="col d-flex align-items-center justify-content-between">
        <label style={{ fontWeight: 600 }}>Unlimited AI Creatives Generation For All</label>
        <input 
          type="checkbox"
          name="unlimitedAIcreativesforallsizes"
          checked={formData.unlimitedAIcreativesforallsizes}
          onChange={handleAiGenaertionCahnge}
          className="mr-2" 
        />
      </div>
    </div>



<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Download Your AI creatives</label>
  
  <input 
    type="checkbox"
    name="downloadYourAIcreatives"
    checked={formData.downloadYourAIcreatives}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>


<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Upload Your Own Creatives To Ads</label>
  
  <input 
    type="checkbox"
    name="uploadyourowncreativestoAds"
    checked={formData.uploadyourowncreativestoAds}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>


<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Unlimited Automated Ads Generator</label>
  
  <input 
    type="checkbox"
    name="unlimitedAutomateddsgenerator"
    checked={formData.unlimitedAutomateddsgenerator}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Set Your Daily Spent And Duration</label>
  
  <input 
    type="checkbox"
    name="setyoudailyspentandduration"
    checked={formData.setyoudailyspentandduration}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Unified Analytics For Ads</label>
  
  <input 
    type="checkbox"
    name="unifiedAdAnalyticsForAds"
    checked={formData.unifiedAdAnalyticsForAds}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Ttrack Your Ads With Powerful Filters</label>
  
  <input 
    type="checkbox"
    name="trackYourAdswithPowerfulFilters"
    checked={formData.trackYourAdswithPowerfulFilters}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Connect To All Your Ad Platforms</label>
  
  <input 
    type="checkbox"
    name="connecttoAllYourAdPlatforms"
    checked={formData.connecttoAllYourAdPlatforms}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> AI Text Generators</label>
  
  <input 
    type="checkbox"
    name="aITextgenerators"
    checked={formData.aITextgenerators}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Dedicated Support</label>
  
  <input 
    type="checkbox"
    name="dedicatedSupport"
    checked={formData.dedicatedSupport}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionCahnge}
    className="mr-2" 
  />
  </div>
  
</div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" className="common-btn" type="submit">
        Submit
      </Button>
      <Button variant="secondary" onClick={closeModal}>
        Close
      </Button>
    </Modal.Footer>
  </form>
</Modal>



          {/* 
//update Plan */}

<Modal show={showUpdateModal} onHide={closeUpdateModal}>
  <Modal.Header closeButton>
    <Modal.Title>Update Plan</Modal.Title>
  </Modal.Header>
  <form onSubmit={handleUpdate}>
  <Modal.Body className="container modal-input modal-input-modal-body">
      <div className="col">
          <label style={{fontWeight: 600}}>Plan Name</label>
          <input type="text"
            name="name"
            defaultValue={ userData?.name}
            onChange={handleInputUpdateChange}
            placeholder="Enter Plan Name"
            className="form-control" />
        </div>
        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Plan Description</label>
          <input type="text"
            name="description"
            defaultValue={userData?.description}
            onChange={handleInputUpdateChange}
            placeholder="Enter Plan Description"
            className="form-control" />
        </div>

        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Daily Spend Limit</label>
          <input type="number"
          min={0}
            name="adspendlimitperday"
            value={userData?.adspendlimitperday}
            onChange={handleInputUpdateChange}
            placeholder="Enter daily spend limit"
            className="form-control" />
        </div>

        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Add Running Account</label>
          <input type="text"
            name="exoadAccount"
            value={userData?.exoadAccount}
            onChange={handleInputUpdateChange}
            placeholder="Ad running account"
            className="form-control" />
        </div>

        
        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Unlimited Photo By IStock</label>
          <input type="text"
            name="unlimitedPhotoByIStock"
            value={userData?.unlimitedPhotoByIStock}
            onChange={handleInputUpdateChange}
            placeholder="Image platform"
            className="form-control" />
        </div>
        {userData&&userData?.planBenefits?
        <>
          <div className="row mt-2">
          <label style={{fontWeight: 600}}>Plan Benefits</label>
            <div className="col-md-4">
              <label >Price-1</label>
              <input type="number"
                name="price1"
                min={0}
                defaultValue={userData?.planBenefits[0]?.amount}
                onChange={handleInputUpdateChange}
                placeholder="Enter Plan Price"
                className="form-control" />
            </div>
            <div className="col-md-4">
              <label>Credits-1</label>
              <input type="number"
               min={0}
                name="credits1"
                defaultValue={userData?.planBenefits[0]?.credits}
    
                onChange={handleInputUpdateChange}
                placeholder="Enter Plan Credits"
                className="form-control" />
            </div>
            <div className="col-md-4">
              <label >Brands-1</label>
              <input type="number"
               min={0}
                name="brands1"
                defaultValue={userData?.planBenefits[0]?.brands}
    
                onChange={handleInputUpdateChange}
                placeholder="Enter brands"
                className="form-control" />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <label >Price-2</label>
              <input type="number"
               min={0}
                name="price2"
                defaultValue={userData?.planBenefits[1]?.amount}
    
                onChange={handleInputUpdateChange}
                placeholder="Enter Plan Price"
                className="form-control" />
            </div>
            <div className="col-md-4">
              <label>Credits-2</label>
              <input type="number"
                name="credits2"
                min={0}
                defaultValue={userData?.planBenefits[1]?.credits}
    
                onChange={handleInputUpdateChange}
                placeholder="Enter Plan Credits"
                className="form-control" />
            </div>
            <div className="col-md-4">
              <label >Brands-2</label>
              <input type="number"
                name="brands2"
                min={0}
                defaultValue={userData?.planBenefits[1]?.brands}
    
                onChange={handleInputUpdateChange}
                placeholder="Enter brands"
                className="form-control" />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <label >Price-3</label>
              <input type="number"
                name="price3"
                min={0}
                defaultValue={userData?.planBenefits[2]?.amount}
    
                onChange={handleInputUpdateChange}
                placeholder="Enter Plan Price"
                className="form-control" />
            </div>
            <div className="col-md-4">
              <label>Credits-3</label>
              <input type="number"
                name="credits3"
                min={0}
                defaultValue={userData?.planBenefits[2]?.credits}
    
                onChange={handleInputUpdateChange}
                placeholder="Enter Plan Credits"
                className="form-control" />
            </div>
            <div className="col-md-4">
              <label >Brands-3</label>
              <input type="number"
                name="brands3"
                min={0}
                defaultValue={userData?.planBenefits[2]?.brands}
                onChange={handleInputUpdateChange}
                placeholder="Enter brands"
                className="form-control" />
            </div>
          </div>
          </>:""
        }
    
      

        <div className="col mt-2">
          <label style={{fontWeight: 600}}>Subscription Type</label>
          <select name="subscriptionType"
                     defaultValue={userData?.subscriptionType}

            onChange={handleInputUpdateChange}
            className="form-control">
            <option value="">Select</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>

        <div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Unlimited AI Creatives Generation</label>
  
  <input 
    type="checkbox"
    name="unlimitedAICreativesGeneration"
    checked={userData?.unlimitedAICreativesGeneration}
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>


<div className="row mt-2">
      <div className="col d-flex align-items-center justify-content-between">
        <label style={{ fontWeight: 600 }}>Unlimited AI Creatives Generation For All</label>
        <input 
          type="checkbox"
          name="unlimitedAIcreativesforallsizes"
          checked={userData?.unlimitedAIcreativesforallsizes}
          onChange={handleAiGenaertionUpdateCahnge}
          className="mr-2" 
        />
      </div>
    </div>



<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Download Your AI creatives</label>
  
  <input 
    type="checkbox"
    name="downloadYourAIcreatives"
    checked={userData?.downloadYourAIcreatives}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>


<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Upload Your Own Creatives To Ads</label>
  
  <input 
    type="checkbox"
    name="uploadyourowncreativestoAds"
    checked={userData?.uploadyourowncreativestoAds}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>


<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Unlimited Automated Ads Generator</label>
  
  <input 
    type="checkbox"
    name="unlimitedAutomateddsgenerator"
    checked={userData?.unlimitedAutomateddsgenerator}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Set Your Daily Spent And Duration</label>
  
  <input 
    type="checkbox"
    name="setyoudailyspentandduration"
    checked={userData?.setyoudailyspentandduration}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}>Unified Analytics For Ads</label>
  
  <input 
    type="checkbox"
    name="unifiedAdAnalyticsForAds"
    checked={userData?.unifiedAdAnalyticsForAds}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Ttrack Your Ads With Powerful Filters</label>
  
  <input 
    type="checkbox"
    name="trackYourAdswithPowerfulFilters"
    checked={userData?.trackYourAdswithPowerfulFilters}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Connect To All Your Ad Platforms</label>
  
  <input 
    type="checkbox"
    name="connecttoAllYourAdPlatforms"
    checked={userData?.connecttoAllYourAdPlatforms}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> AI Text Generators</label>
  
  <input 
    type="checkbox"
    name="aITextgenerators"
    checked={userData?.aITextgenerators}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>

<div className="row mt-2">
        <div className=" col d-flex align-items-center justify-content-between">
       
  <label style={{ fontWeight: 600 }}> Dedicated Support</label>
  
  <input 
    type="checkbox"
    name="dedicatedSupport"
    checked={userData?.dedicatedSupport}  // Use checked instead of value for checkboxes
    onChange={handleAiGenaertionUpdateCahnge}
    className="mr-2" 
  />
  </div>
  
</div>
    </Modal.Body>
    <Modal.Footer>
      <Button className="common-btn" type="submit">
        Update
      </Button>
      <Button variant="secondary" onClick={closeUpdateModal}>
        Close
      </Button>
    </Modal.Footer>
  </form>
</Modal>




          {/* Delete Confirmation Modal */}
          <Modal show={showDeleteModal} onHide={closeDeleteModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this customer?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDeleteCustomer}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

        </div>
        {/* <ToastContainer /> */}
      </div>
    </>

  );
}

export default PlanManagement;
