import React from 'react'

export default function InboxIcon() {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10_1667)">
                <path d="M0 29.3333L13.3333 8.25061H50.6667L64 29.3333V43.9999H0V29.3333Z" fill="#7A3202" />
                <path d="M13.3327 29.3335C14.7472 29.3335 16.1037 29.8954 17.1039 30.8956C18.1041 31.8958 18.666 33.2523 18.666 34.6668C18.666 36.0813 19.2279 37.4379 20.2281 38.4381C21.2283 39.4383 22.5849 40.0002 23.9993 40.0002H39.9993C41.4138 40.0002 42.7704 39.4383 43.7706 38.4381C44.7708 37.4379 45.3327 36.0813 45.3327 34.6668C45.3327 33.2523 45.8946 31.8958 46.8948 30.8956C47.895 29.8954 49.2515 29.3335 50.666 29.3335H53.3327L49.3327 21.3335H14.666L10.666 29.3335H13.3327Z" fill="url(#paint0_linear_10_1667)" />
                <path opacity="0.15" d="M13.3333 8.25061L0 29.3333H10.6667L14.6667 21.3333L13.3333 8.25061Z" fill="black" />
                <path opacity="0.1" d="M0.68 29.3333H0L13.3333 8.25061L13.4213 9.12528L0.68 29.3333Z" fill="black" />
                <path d="M50.5793 9.12515L50.6673 8.25049H13.334L13.422 9.12515H50.5793Z" fill="url(#paint1_linear_10_1667)" />
                <path opacity="0.1" d="M50.6673 8.25049L64.0007 29.3332H53.334L49.334 21.3332L50.6673 8.25049Z" fill="white" />
                <path opacity="0.2" d="M63.9995 29.3332L50.6661 8.25049L50.5781 9.12515L63.3195 29.3332" fill="white" />
                <path d="M58.6673 42.6667H5.33398V13.3333C5.33398 11.9188 5.89589 10.5623 6.89608 9.5621C7.89628 8.5619 9.25283 8 10.6673 8H53.334C54.7485 8 56.105 8.5619 57.1052 9.5621C58.1054 10.5623 58.6673 11.9188 58.6673 13.3333V42.6667Z" fill="#F2F2F2" />
                <path opacity="0.2" d="M53.334 8H10.6673C9.25283 8 7.89628 8.5619 6.89608 9.5621C5.89589 10.5623 5.33398 11.9188 5.33398 13.3333V14C5.33398 12.5855 5.89589 11.229 6.89608 10.2288C7.89628 9.22857 9.25283 8.66667 10.6673 8.66667H53.334C54.7485 8.66667 56.105 9.22857 57.1052 10.2288C58.1054 11.229 58.6673 12.5855 58.6673 14V13.3333C58.6673 11.9188 58.1054 10.5623 57.1052 9.5621C56.105 8.5619 54.7485 8 53.334 8Z" fill="white" />
                <path d="M40.0007 18.6665L62.6673 41.3332H17.334L40.0007 18.6665Z" fill="#69B342" />
                <path opacity="0.1" d="M40.0007 18.6665L62.6673 41.3332H17.334L40.0007 18.6665Z" fill="black" />
                <path d="M15.9993 28L29.3327 41.3333H2.66602L15.9993 28Z" fill="#69B342" />
                <path d="M5.33398 24H58.6673V41.3333H5.33398V24Z" fill="url(#paint2_linear_10_1667)" />
                <path d="M18.6673 24.0002C21.6128 24.0002 24.0007 21.6123 24.0007 18.6668C24.0007 15.7213 21.6128 13.3335 18.6673 13.3335C15.7218 13.3335 13.334 15.7213 13.334 18.6668C13.334 21.6123 15.7218 24.0002 18.6673 24.0002Z" fill="#FFCE51" />
                <path d="M45.3333 34.6667C45.3333 36.0812 44.7714 37.4377 43.7712 38.4379C42.771 39.4381 41.4145 40 40 40H24C22.5855 40 21.229 39.4381 20.2288 38.4379C19.2286 37.4377 18.6667 36.0812 18.6667 34.6667C18.6667 33.2522 18.1048 31.8957 17.1046 30.8955C16.1044 29.8953 14.7478 29.3334 13.3333 29.3334H0V50.6667C0 52.0812 0.561903 53.4377 1.5621 54.4379C2.56229 55.4381 3.91885 56 5.33333 56H58.6667C60.0812 56 61.4377 55.4381 62.4379 54.4379C63.4381 53.4377 64 52.0812 64 50.6667V29.3334H50.6667C49.2522 29.3334 47.8956 29.8953 46.8954 30.8955C45.8952 31.8957 45.3333 33.2522 45.3333 34.6667Z" fill="#D58347" />
                <path opacity="0.1" d="M58.6667 55.3333H5.33333C3.91885 55.3333 2.56229 54.7714 1.5621 53.7712C0.561903 52.771 0 51.4145 0 50V50.6667C0 52.0812 0.561903 53.4377 1.5621 54.4379C2.56229 55.4381 3.91885 56 5.33333 56H58.6667C60.0812 56 61.4377 55.4381 62.4379 54.4379C63.4381 53.4377 64 52.0812 64 50.6667V50C64 51.4145 63.4381 52.771 62.4379 53.7712C61.4377 54.7714 60.0812 55.3333 58.6667 55.3333Z" fill="black" />
                <path opacity="0.2" d="M63.32 29.3334H50.6667C49.2522 29.3334 47.8956 29.8953 46.8954 30.8955C45.8952 31.8957 45.3333 33.2522 45.3333 34.6667C45.3333 36.0812 44.7714 37.4377 43.7712 38.4379C42.771 39.4381 41.4145 40 40 40H24C22.5855 40 21.229 39.4381 20.2288 38.4379C19.2286 37.4377 18.6667 36.0812 18.6667 34.6667C18.6667 33.2522 18.1048 31.8957 17.1046 30.8955C16.1044 29.8953 14.7478 29.3334 13.3333 29.3334H0V30H13.3333C14.7478 30 16.1044 30.5619 17.1046 31.5621C18.1048 32.5623 18.6667 33.9189 18.6667 35.3334C18.6667 36.7479 19.2286 38.1044 20.2288 39.1046C21.229 40.1048 22.5855 40.6667 24 40.6667H40C41.4145 40.6667 42.771 40.1048 43.7712 39.1046C44.7714 38.1044 45.3333 36.7479 45.3333 35.3334C45.3333 33.9189 45.8952 32.5623 46.8954 31.5621C47.8956 30.5619 49.2522 30 50.6667 30H64V29.3334" fill="white" />
                <path d="M58.6667 20.9013V13.3333C58.6667 11.9188 58.1048 10.5623 57.1046 9.5621C56.1044 8.5619 54.7478 8 53.3333 8H10.6667C9.25218 8 7.89562 8.5619 6.89543 9.5621C5.89524 10.5623 5.33333 11.9188 5.33333 13.3333V20.9013L0 29.3333V50.6667C0 52.0812 0.561903 53.4377 1.5621 54.4379C2.56229 55.4381 3.91885 56 5.33333 56H58.6667C60.0812 56 61.4377 55.4381 62.4379 54.4379C63.4381 53.4377 64 52.0812 64 50.6667V29.3333L58.6667 20.9013Z" fill="url(#paint3_linear_10_1667)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_10_1667" x1="20.3327" y1="15.6668" x2="43.666" y2="39.0002" gradientUnits="userSpaceOnUse">
                    <stop stop-opacity="0.1" />
                    <stop offset="1" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_10_1667" x1="16.5073" y1="1.44249" x2="47.438" y2="15.8638" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.2" />
                </linearGradient>
                <linearGradient id="paint2_linear_10_1667" x1="32.0007" y1="41.3333" x2="32.0007" y2="24" gradientUnits="userSpaceOnUse">
                    <stop stop-opacity="0.1" />
                    <stop offset="1" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_10_1667" x1="2.016" y1="19.2613" x2="65.4133" y2="48.824" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.2" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0_10_1667">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
