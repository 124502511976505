import React from 'react'

export default function LinkdinIcon() {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.1719 0C27.0286 0 27.8501 0.340301 28.4559 0.946041C29.0616 1.55178 29.4019 2.37334 29.4019 3.22999V25.8399C29.4019 26.6965 29.0616 27.5181 28.4559 28.1238C27.8501 28.7296 27.0286 29.0699 26.1719 29.0699H3.56202C2.70537 29.0699 1.88381 28.7296 1.27807 28.1238C0.672332 27.5181 0.332031 26.6965 0.332031 25.8399V3.22999C0.332031 2.37334 0.672332 1.55178 1.27807 0.946041C1.88381 0.340301 2.70537 0 3.56202 0H26.1719ZM25.3644 25.0324V16.4729C25.3644 15.0766 24.8097 13.7375 23.8224 12.7501C22.835 11.7627 21.4959 11.2081 20.0996 11.2081C18.7268 11.2081 17.128 12.0479 16.3528 13.3075V11.5149H11.8469V25.0324H16.3528V17.0705C16.3528 15.8269 17.3541 14.8095 18.5976 14.8095C19.1973 14.8095 19.7724 15.0477 20.1964 15.4717C20.6204 15.8957 20.8586 16.4708 20.8586 17.0705V25.0324H25.3644ZM6.59821 8.97937C7.31779 8.97937 8.0079 8.69351 8.51672 8.18469C9.02554 7.67587 9.3114 6.98576 9.3114 6.26618C9.3114 4.76423 8.10015 3.53684 6.59821 3.53684C5.87434 3.53684 5.18012 3.82439 4.66827 4.33624C4.15642 4.84809 3.86887 5.54231 3.86887 6.26618C3.86887 7.76812 5.09626 8.97937 6.59821 8.97937ZM8.84305 25.0324V11.5149H4.36952V25.0324H8.84305Z" fill="#47BEB9" />
        </svg>

    )
}
