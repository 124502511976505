import React, { useState } from "react";
import "./GoogleAdd-3.css";
import { Col, Container, Row } from "react-bootstrap";
import { EditIcon, GreenTick } from "./SVG";
import { NoForecast } from "../SideNavContainer/BingFunding";
import { Excliamation } from "../SideNavContainer/SVG";

const BingAdd3 = () => {
  const LocalwebsiteUrl = sessionStorage.getItem("websiteUrl");

  return (
    <Container>
      <Row style={{ gap: "10px" }}>
        <Col lg={5} md={12}>
          <LeftBox url={LocalwebsiteUrl} />
        </Col>
        <Col lg={6}>
          <div className="google-add3-right-div">
            <div className="google-add3-right-div-main ">
              <div className="google-add3-right-div-main-header">
                <p>Analytics</p>
                <button>
                  <span>Connect</span>
                </button>
              </div>
              <div>
                <NoForecast
                  logo={Excliamation}
                  title={`Not connected`}
                  body={`EXOAD is not receiving any events from this page yet. We recommend you connect your page to Balloonary by installing the snippet.`}
                  color="#B2BBC6"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const Header = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <p>Page</p>
      <span>{EditIcon}</span>
    </div>
  );
};

const LeftBox = ({ url }) => {
  const [toggle, setToggle] = useState(false);
  // const [url, setUrl] = useState();
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleCancel = () => {
    setToggle(false);
  };

  const handlesave = (e) => {
    setToggle(e.target.value);
  };
  return (
    <div className="google-add3-div1">
      <div className=" google-add3-div1-main">
        <Header
          handleToggle={handleToggle}
          toggle={toggle}
          handlesave={handlesave}
          handleCancel={handleCancel}
        />
      </div>
      {toggle ? (
        <div className="d-flex  flex-column gap-1 " style={{}}>
          <label>enter website url</label>
          <input
            className="p-1 rounded webiste-placeholder"
            type="text"
            placeholder="website-Url"
            style={{ background: "#edf9f8", outline: "none" }}
            // onChange={(e) => setUrl(e.target.value)}
          />
        </div>
      ) : (
        <>
          <div className="google-add3-div2">
            <span>{GreenTick}</span>
            <div className="google-add3-div2-main">
              <p>Page connected</p>
              <span>You have connected a valid URL.</span>
            </div>
          </div>

          <div style={{}}>
            {url ? (
              <embed
                type="text/html"
                src={url}
                width="100%"
                height="200"
                style={{ overflow: "hidden" }}
              />
            ) : (
              <div
                style={{
                  width: "450px",
                  height: "40vh",
                  background: "#edf9f8",
                }}
                className="rounded-1"
              ></div>
            )}
          </div>

          <div className="google-add3-div4">
            {url ? url : "https://your-website.com"}
          </div>
        </>
      )}
    </div>
  );
};

export default BingAdd3;
