import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Cities: [],
  startDate: "",
  EndDate: "",
  CountryCode: "",
  amount: "",
};
const GoogleDisplaySlice = createSlice({
  name: "GoogleDisplaySlice",
  initialState,
  reducers: {
    AddFundingDetails: (state, action) => {
      //   state.websiteUrl = action.payload;
    },
    AddTargetingDetailsDisplay: (state, action) => {
      state.CountryCode = action.payload[0]?.country;
      state.Cities = action.payload[1]?.cityList;
      state.keywords = action.payload[2]?.keywords;
      // state.Cities = action.payload[0].cityList;
    },
  },
});

export const {
  AddFundingDetails,
  AddTargetingDetailsDisplay,
} = GoogleDisplaySlice.actions;
export default GoogleDisplaySlice.reducer;
