// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__WMN0N {
    width: 200px;
    height: 100vh;
    background-color: #333;
    color: black;
    position: fixed;
    left: 0;
    top: 0;
    background-color: blueviolet;
    border: 4px solid yellow;
  }`, "",{"version":3,"sources":["webpack://./src/component/admin/css/Sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,OAAO;IACP,MAAM;IACN,4BAA4B;IAC5B,wBAAwB;EAC1B","sourcesContent":[".sidebar {\r\n    width: 200px;\r\n    height: 100vh;\r\n    background-color: #333;\r\n    color: black;\r\n    position: fixed;\r\n    left: 0;\r\n    top: 0;\r\n    background-color: blueviolet;\r\n    border: 4px solid yellow;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__WMN0N`
};
export default ___CSS_LOADER_EXPORT___;
