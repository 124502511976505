import { createSlice } from "@reduxjs/toolkit";
const initialState = { GoogleAnalytics: null, FacebookAnalytics: null,InstagramAnalytics: null, MetaAnalytics: "", BingAnalytics: "" ,SingleGoogleAdDetail:null};
const AdAnalyticsSlice = createSlice({
    name: "AdAnalytics",
    initialState,
    reducers: {
        addGoogleAnalytics: (state, action) => {
            state.GoogleAnalytics = action.payload;
        },
        addFacebookAnalytics: (state, action) => {
            state.FacebookAnalytics = action.payload;
        },
        addInstagramAnalytics: (state, action) => {
            state.InstagramAnalytics = action.payload;
        },
        addMetaAnalytics: (state, action) => {
            state.MetaAnalytics = action.payload;
        },
        addBingAnalytics: (state, action) => {
            state.BingAnalytics = action.payload;
        },
        addSingleGoogleAnalyticsDetail: (state, action) => {
            state.SingleGoogleAdDetail = action.payload;
        },
        addSingleFacebookAnalyticsDetail: (state, action) => {
            state.SingleGoogleAdDetail = action.payload;
        },
        addSingleInstagramAnalyticsDetail: (state, action) => {
            state.SingleGoogleAdDetail = action.payload;
        },
    },
});

export const { addBingAnalytics, addGoogleAnalytics,addFacebookAnalytics,addInstagramAnalytics, addMetaAnalytics,addSingleGoogleAnalyticsDetail, addSingleFacebookAnalyticsDetail,addSingleInstagramAnalyticsDetail} = AdAnalyticsSlice.actions;
export default AdAnalyticsSlice.reducer;
