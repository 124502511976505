import React, { useState } from 'react'
import "./NewSettingPage.css";
import SettingHeader from './settingHeader';
import SettingsMainBanner from './settingsMainBanner';
import SettingOtherDetails from './settingOtherDetails';
import DeleteModal from './deleteModal';
import CancleModal from './cancleModal';
export default function NewSettingPage() {
    const [settinOption, setSettinOption] = useState("account");
    const [deleteModal, setDeleteModal] = useState(false);
    const [cancleModal, setCancleModal] = useState(false);
    return (
        <div className='setting-page-section'>
            <SettingHeader setDeleteModal={setDeleteModal} setCancleModal={setCancleModal} />
            <SettingsMainBanner settinOption={settinOption} setSettinOption={setSettinOption} />
            <SettingOtherDetails settinOption={settinOption} setSettinOption={setSettinOption} />
            {deleteModal && (
                <DeleteModal setDeleteModal={setDeleteModal} />
            )}

            {cancleModal && (

                <CancleModal setCancleModal={setCancleModal} />
            )}

        </div>
    )
}
