import React, { useContext, useEffect, useState } from "react";
import "./GoogleContent.css";
// import Laptop from "./Images/Laptop.png";
// import Mobile from "./Images/Phone.png";
import { MobileSvg, desktopIcon, dotsIcon1 } from "../SVG";
// import { AiIcon, BlackIcon, BlueDot } from "./SVG";
// import { CommonBtn } from "../../../../../UI-Components/ReusableComponents";
import { ToggleContext } from "../ContextFile";
import {  Spinner } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
// import InputMain from "./AiGenerationModal/InputMain";
import Cookies from "js-cookie";
// import AiSuggestionMain from "./AiGenerationModal/AiSuggestionMain";
import axios from "../../../../../axios/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  addGoogleSuggestion,
  // addWebsiteDeatil,
  addWebsiteUrl,
  addWebsiteDetailForGoogle,
} from "../../../../../../Redux/AiSuggestion";
// import { wondSvg } from "../../FacebookCampaign/SideNavContainer/SVG";
import { addGoogleSearchDeatils } from "../../../../../../Redux/CampaignDetailSlice";
// import { text } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
// import { FullLoader, halfLoader } from "../../FacebookCampaign/SVG";

// const data = [
//   "1 Ipsum is simply dummy text of the printing and typesetting industry.",
//   "2 Ipsum is simply dummy text of the printing and typesetting industry.",
//   "3 Ipsum is simply dummy text of the printing and typesetting industry.",
//   "4 Ipsum is simply dummy text of the printing and typesetting industry.",
// ];

// const MessageData = [
//   "Create Your Own Website",
//   "Create Your Own Website",
//   "Create Your Own Website",
//   "Create Your Own Website",
// ];

const GoogleContent = () => {
  // Headline and description from AI APi will be store in below states.
  const [aiApiDescription, setAiApiDescription] = useState();
  // const [aiApiDescription, setAiApiDescription] = useState(aiApiDescription);
  const [aiApiHeading, setAiApiHeading] = useState();

  // Selected Headline from uxser will be store here

  // aiText === Description
  const [aiText, setAiText] = useState("");
  // description Second
  const [aiDescription2, setAiDescription2] = useState("");

  // aiText2 === Headline
  const [aiText2, setAiText2] = useState("");
  // Headline 2 state
  const [aiHeadline2, setAiHeadline2] = useState("");
  // Headline 3 state

  const [aiHeadline3, setAiHeadline3] = useState("");

  const { Google,  websiteDetailsForGoogle } = useSelector(
    (state) => state.AiSuggestion
  );

  const { GoogleSearchHeadline, GoogleSearchDescription } = useSelector(
    (state) => state.CampaignDetail
  );
  const { handleToggleVisibleBtn, HandleActiveStep } = useContext(
    ToggleContext
  );
  const token = Cookies.get("token");
  const LocalwebsiteDetail = sessionStorage.getItem("websiteDetail");
  const LocalwebsiteUrl = sessionStorage.getItem("websiteUrl");
  const LocalCompanyName = sessionStorage.getItem("websiteTitle");
  const pendingUrl = JSON.parse(sessionStorage.getItem("pendingAdData")) || {};
  const dispatch = useDispatch();



  useEffect(() => {
    HandleActiveStep(1);
    handleToggleVisibleBtn(true);
    AiSuggestionData();

    if (GoogleSearchDescription?.length > 0 || GoogleSearchHeadline?.length > 0) {
      setAiText2(GoogleSearchHeadline[0]);
      setAiHeadline2(GoogleSearchHeadline[1]);
      setAiHeadline3(GoogleSearchHeadline[2]);

      setAiText(GoogleSearchDescription[0]);
      setAiDescription2(GoogleSearchDescription[1]);
    } else if (sessionStorage.getItem("googleSearchAdsCompleteData")) {
      let data = JSON.parse(
        sessionStorage.getItem("googleSearchAdsCompleteData")
      );
      
      if (data) {
        setAiText2(data?.GoogleSearchHeadline[0]);
        setAiHeadline2(data?.GoogleSearchHeadline[1]);
        setAiHeadline3(data?.GoogleSearchHeadline[2]);

        setAiText(data?.GoogleSearchDescription[0]);
        setAiDescription2(data?.GoogleSearchDescription[1]);
      }
    }

    if (
      websiteDetailsForGoogle &&
      LocalwebsiteDetail &&
      websiteDetailsForGoogle === LocalwebsiteDetail
    ) {
      if (Google.descriptions) {
        setAiApiDescription(Google?.descriptions);
        setAiApiHeading(Google?.headlines);
      } else {
        if (LocalwebsiteDetail) {
          AiSuggestionData();
        }
      }
    } else if (
      websiteDetailsForGoogle &&
      LocalwebsiteDetail &&
      websiteDetailsForGoogle !== LocalwebsiteDetail
    ) {

      if (LocalwebsiteDetail) {
        AiSuggestionData();
      }
    } else if (LocalwebsiteDetail) {
      AiSuggestionData();
    }

    return () => {
      handleToggleVisibleBtn(false);
    };
  }, []);

  // for storing selected data in redux start
useEffect(()=>{
  if(sessionStorage.getItem("pendingAdData")){
  sessionStorage.setItem("websiteUrl",pendingUrl?.WebsiteUrl
  )
  sessionStorage.setItem("websiteTitle",pendingUrl?.websiteTitle
  )}
},[])

  useEffect(() => {
    if (
      aiText?.length > 0 ||
      aiText2?.length > 0 ||
      aiHeadline2?.length > 0 ||
      aiHeadline3?.length > 0 ||
      aiDescription2
    ) {
      let headlines = [aiText2, aiHeadline2, aiHeadline3];
      let description = [aiText, aiDescription2];
      dispatch(
        addGoogleSearchDeatils({
          headline: headlines,
          descriptions: description,
        })
      );
    }
  }, [aiText, aiText2, aiHeadline2, aiHeadline3, aiDescription2]);

  // // for storing selected data in redux End
  const AiSuggestionData = () => {
    // setAiText("");
    // setAiText2("");

    dispatch(
      addGoogleSearchDeatils({
        headline: "",
        descriptions: "",

      })
      
    
    );
    dispatch(addWebsiteDetailForGoogle(""))
    try {
      const url = "/fetch-ai/data";

      axios
        .post(
          url,
          { platform: "google", websiteDetail:pendingUrl?.WebsiteUrl?pendingUrl?.WebsiteUrl: LocalwebsiteDetail  || "defaultUrl" },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          
          if (response?.data?.Description && response?.data?.Headline) {
            dispatch(
              addGoogleSuggestion({
                descriptions: response?.data?.Description,
                headlines: response?.data?.Headline,
                keyword: response?.data?.keyword,
              })
            );
            setAiApiDescription(response?.data?.Description);
            setAiApiHeading(response?.data?.Headline);
            dispatch(addWebsiteDetailForGoogle(LocalwebsiteDetail));
            dispatch(addWebsiteUrl(LocalwebsiteUrl));
          } else {
            setAiApiDescription(["no data Found"]);
            setAiApiHeading(["no data Found"]);
          }
        });
    } catch (error) {
      // console.error(error);
    }
  };

  const { toggle } = useContext(ToggleContext);
  return (
    <div className="FacebookAiSuggestionScroller">
      {toggle ? (
        <MobileView
          aiApiDescription={aiApiDescription}
          aiApiHeading={aiApiHeading}
          aiText={aiText}
          aiText2={aiText2}
          setAiText={setAiText}
          setAiDescription2={setAiDescription2}
          aiDescription2={aiDescription2}
          setAiText2={setAiText2}
          setAiHeadline2={setAiHeadline2}
          setAiHeadline3={setAiHeadline3}
          aiHeadline2={aiHeadline2}
          aiHeadline3={aiHeadline3}
          LocalwebsiteUrl={LocalwebsiteUrl}
          LocalCompanyName={LocalCompanyName}
        />
      ) : (
        <LaptopView
          aiApiDescription={aiApiDescription}
          setAiApiDescription={setAiApiDescription}
          setAiApiHeading={setAiApiHeading}
          aiApiHeading={aiApiHeading}
          aiText={aiText}
          aiText2={aiText2}
          setAiDescription2={setAiDescription2}
          aiDescription2={aiDescription2}
          setAiText={setAiText}
          setAiText2={setAiText2}
          setAiHeadline2={setAiHeadline2}
          setAiHeadline3={setAiHeadline3}
          aiHeadline2={aiHeadline2}
          aiHeadline3={aiHeadline3}
          LocalwebsiteUrl={LocalwebsiteUrl}
          LocalCompanyName={LocalCompanyName}
        />
      )}

      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/platfromselection"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7895)">
              <path
                d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H154.693C159.278 1.51845 163.259 4.67535 164.304 9.13933L173.249 47.3625C174.698 53.555 169.998 59.4815 163.639 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                stroke="url(#paint0_linear_392_7895)"
                stroke-width="3.03691"
              />
              <text
                x="34%"
                y="64%"
                font-family="Arial"
                fill="white"
                className="Back-svg-btn"
              >
                Back
              </text>
            </g>

            <defs>
              <filter
                id="filter0_i_392_7895"
                x="0"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7895"
                />
              </filter>
              <linearGradient
                id="paint0_linear_392_7895"
                x1="0"
                y1="0"
                x2="172.838"
                y2="42.9259"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#47BEB9" />
                <stop offset="1" stop-color="#215856" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
        <Link to={"/main/campaign/googlecompaign/google-add-1/targeting"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Next
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

const LaptopView = ({
  aiApiDescription,
  setAiApiDescription, // New prop
  aiApiHeading,
  setAiApiHeading,
  aiText,
  aiText2,
  setAiText,
  setAiText2,
  LocalwebsiteUrl,
  LocalCompnayName,
  setAiHeadline2,
  setAiHeadline3,
  aiHeadline2,
  aiHeadline3,
  setAiDescription2,
  aiDescription2,
 
}) => {
  const [aimodalToggle, setAiModalToggle] = useState(false);
  const [aimodalToggle2, setAiModalToggle2] = useState(false);
  const [messageEdit, setMessageEdit] = useState(false);
  const [editDescription2, setEditDescription2] = useState(false);

  const [messageEdit2, setMessageEdit2] = useState(false);
  const [editHeadline2, setEditHeadline2] = useState(false);
  const [editHeadline3, setEditHeadline3] = useState(false);

  const [updateHeadline, setUpdateHeadline] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [pendingadData,setPendndingadData] = useState()
  const [previousSelectedItem, setPreviousSelectedItem] = useState(null);
  // const [previousHeadline, setPreviousHeadline] = useState(null);

  // const [aiText, setAiText] = useState(
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  // );
  // const [aiText2, setAiText2] = useState("Website Main Headline");


// done by hari bhiya

useEffect(()=>{

  setPendndingadData(JSON.parse(sessionStorage.getItem("pendingAdData")))
 setAiDescription2((JSON.parse(sessionStorage.getItem("pendingAdData"))?.GoogleSearchDescription[1])||"")
 setAiText((JSON.parse(sessionStorage.getItem("pendingAdData"))?.GoogleSearchDescription[0])||"")
},[])

// done by hari bhiya

  // const HandelAiItems = (item) => {
  //   if (updateDescription) {
  //     if (updateDescription == "description1") {
  //       setAiText(item);
  //       setMessageEdit(false);
  //     } else if (updateDescription == "description2") {
  //       setAiDescription2(item);
  //       setEditDescription2(false);
  //     }
  //   } else if (aiText == "") {
  //     setAiText(item);
  //   } else if (aiDescription2 == "") {
  //     setAiDescription2(item);
  //   }

  //   // setAiText(item);
  //   setAiModalToggle(!aimodalToggle);
  //   setUpdateDescription("");
  // };

  // const HandelAiItems = (item) => {
  //   // Add the previously selected item back to the list, if any
  //   let updatedAiApiDescription = [...aiApiDescription];
  //   if (previousSelectedItem) {
  //     updatedAiApiDescription.push(previousSelectedItem);
  //   }

  //   // Remove the currently selected item from the list
  //   updatedAiApiDescription = updatedAiApiDescription.filter((description) => description !== item);

  //   // Update the state with the new item and set the previous item to the current one
  //   setPreviousSelectedItem(item);

  //   if (updateDescription) {
  //     if (updateDescription === "description1") {
  //       setAiText(item);
  //       setMessageEdit(false);
  //     } else if (updateDescription === "description2") {
  //       setAiDescription2(item);
  //       setEditDescription2(false);
  //     }
  //   } else if (aiText === "") {
  //     setAiText(item);
  //   } else if (aiDescription2 === "") {
  //     setAiDescription2(item);
  //   }

  //   // Update the aiApiDescription state with the modified array
  //   setAiApiDescription(updatedAiApiDescription);

  //   setAiModalToggle(!aimodalToggle);
  //   setUpdateDescription("");
  // };


  const HandelAiItems = (item) => {
    let updatedAiApiDescription = [...aiApiDescription];

    // Add the previously selected item back to the list, if any
    if (previousSelectedItem) {
      updatedAiApiDescription.push(previousSelectedItem);
    }

    // Remove the currently selected item from the list
    updatedAiApiDescription = updatedAiApiDescription.filter((description) => description !== item);

    // Update the state with the new item and set the previous item to the current one
    setPreviousSelectedItem(item);

    if (updateDescription) {
      if (updateDescription === "description1") {
        setAiText(item);
        setMessageEdit(false);
      } else if (updateDescription === "description2") {
        setAiDescription2(item);
        setEditDescription2(false);
      }
    } else if (aiText === "") {
      setAiText(item);
    } else if (aiDescription2 === "") {
      setAiDescription2(item);
    }

    // Filter out current aiText and aiDescription2 from the updated list
    updatedAiApiDescription = updatedAiApiDescription.filter((description) => description !== aiText && description !== aiDescription2);

    // Update the aiApiDescription state with the modified array
    setAiApiDescription(updatedAiApiDescription);

    setAiModalToggle(!aimodalToggle);
    setUpdateDescription("");
  };

  const HandelAiItemsMessage = (item) => {
    // If the item is already one of the selected headlines, do nothing
    if (item === aiText2 || item === aiHeadline2 || item === aiHeadline3) {
      return;
    }
  
    if (updateHeadline) {
      // Update specific headline based on updateHeadline
      if (updateHeadline === "Headline1") {
        setAiText2(item);
        setMessageEdit2(false);
      } else if (updateHeadline === "Headline2") {
        setAiHeadline2(item);
        setEditHeadline2(false);
      } else if (updateHeadline === "Headline3") {
        setAiHeadline3(item);
        setEditHeadline3(false);
      }
    } else {
      // Initially update each headline if they are empty or have default values
      if (!aiText2 || aiText2 === "Website Main Headline 1") {
        setAiText2(item);
      } else if (!aiHeadline2 || aiHeadline2 === "Website Main Headline 2") {
        setAiHeadline2(item);
      } else if (!aiHeadline3 || aiHeadline3 === "Website Main Headline 3") {
        setAiHeadline3(item);
      }
    }
  
    setAiModalToggle2(!aimodalToggle2);
    setUpdateHeadline("");
  };
  

  // const HandelAiItemsMessage = (item) => {
  //   let updatedAiApiHeading = [...aiApiHeading];

  //   if (previousHeadline) {
  //     updatedAiApiHeading.push(previousHeadline);
  //   }

  //   updatedAiApiHeading = updatedAiApiHeading.filter((headline) => headline !== item);

  //   setPreviousHeadline(item);

  //   if (updateHeadline) {
  //     if (updateHeadline === "Headline1") {
  //       setAiText2(item);
  //       setMessageEdit2(false);
  //     } else if (updateHeadline === "Headline2") {
  //       setAiHeadline2(item);
  //       setEditHeadline2(false);
  //     } else if (updateHeadline === "Headline3") {
  //       setAiHeadline3(item);
  //       setEditHeadline3(false);
  //     }
  //   } else if (aiText2 === "") {
  //     setAiText2(item);
  //   } else if (aiHeadline2 === "Website Main Headline 2" || aiHeadline2 === "") {
  //     setAiHeadline2(item);
  //   } else if (aiHeadline3 === "Website Main Headline 3" || aiHeadline3 === "") {
  //     setAiHeadline3(item);
  //   }

  //   updatedAiApiHeading = updatedAiApiHeading.filter((headline) => headline !== aiText2 && headline !== aiHeadline2 && headline !== aiHeadline3);

  //   setAiApiHeading(updatedAiApiHeading);

  //   setAiModalToggle2(!aimodalToggle2);
  // };

  // const HandelAiItemsMessage = (item) => {
  //   if (updateHeadline) {
  //     if (updateHeadline == "Headline1") {
  //       setAiText2(item);
  //       setMessageEdit2(false);
  //     } else if (updateHeadline == "Headline2") {
  //       setAiHeadline2(item);
  //       setEditHeadline2(false);
  //     } else if (updateHeadline == "Headline3") {
  //       setAiHeadline3(item);
  //       setEditHeadline3(false);
  //     }
  //   } else if (aiText2 == "") {
  //     setAiText2(item);
  //   } else if (aiHeadline2 == "Website Main Headline 2" || aiHeadline2 == "") {
  //     setAiHeadline2(item);
  //   } else if (aiHeadline3 == "Website Main Headline 3" || aiHeadline3 == "") {
  //     setAiHeadline3(item);
  //   }
  //   setAiModalToggle2(!aimodalToggle2);
  // };



  const HandleGreenDot = (e) => {
    e.stopPropagation();
    setAiModalToggle(!aimodalToggle);
    setAiModalToggle2(false);
  };
  const HandleGreenDot2 = (e) => {
    e.stopPropagation();
    setAiModalToggle2(!aimodalToggle2);
    setAiModalToggle(false);
  };

  // functions for Editing headlines
  const HandleHeadline1 = (headlineName) => {
    setMessageEdit2(!messageEdit2);
    setEditHeadline2(false);
    setEditHeadline3(false);
    setUpdateHeadline(headlineName);
    setAiModalToggle2(true);
  };

  const HandleHeadline2 = (headlineName) => {
    setEditHeadline2(!editHeadline2);
    setMessageEdit2(false);
    setEditHeadline3(false);

    setUpdateHeadline(headlineName);
    setAiModalToggle2(true);
  };

  const HandleHeadline3 = (headlineName) => {
    setEditHeadline3(!editHeadline3);
    setMessageEdit2(false);
    setEditHeadline2(false);

    setUpdateHeadline(headlineName);
    setAiModalToggle2(true);
  };

  // functions for Editing descriptions
  const HandleDescription1 = (descriptionName) => {
    setMessageEdit(!messageEdit);
    setEditDescription2(false);
    setUpdateDescription(descriptionName);
    setAiModalToggle(true);
  };

  const HandleDescription2 = (descriptionName) => {
    setMessageEdit(false);
    setEditDescription2(!editDescription2);

    setUpdateDescription(descriptionName);
    setAiModalToggle(true);
  };

  return (
    <div
      className="google-content-main"
      style={{ maxWidth: "900px" }}
      onClick={() => {
        setAiModalToggle(false);
        setAiModalToggle2(false);
        setMessageEdit2(false);
        setEditHeadline2(false);
        setEditHeadline3(false);
        setMessageEdit(false);
        setEditDescription2(false);
      }}
    >
      <div className="google-laptopimg-main-box">
        <div className="google-content-mainbox" style={{ flexWrap: "wrap" }}>
          <div className="google-content-mainbox-header">
            <div className="google-content-mainbox-header-div">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Ad ·
                </p>
                <div>
                  {/* <h6>
                    {LocalCompanyName ? LocalCompanyName : "Your Company Name"}
                  </h6> */}
                 <p>
  {LocalwebsiteUrl 
    ? LocalwebsiteUrl 
    : JSON.parse(sessionStorage.getItem("pendingAdData"))?.WebsiteUrl || "www.yourwebsite.com"}
</p>

                </div>
              </div>
              {dotsIcon1}
            </div>
          </div>

          <div
            className="google-content-mainbox-div2"
            style={{
              justifyContent: "start",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }} className="content-width-alignment ">
              {!messageEdit2 ? (
                <div style={{ display: "flex", gap: "5px", minWidth: "220px" }}>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      HandleHeadline1("Headline1");
                    }}
                  >
                    {aiText2 ? aiText2 :pendingadData?.GoogleSearchHeadline[0]||"Website Main Headline 1"}
                  </p>
                </div>
              ) : (
                <input
                  style={{ outline: "none", fontSize: "16px" }}
                  value={aiText2}
                  maxLength={50}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiText2(e.target.value);
                  }}
                />
              )}

              {/* headline 2 and 3 start */}

              {!editHeadline2 ? (
                <div style={{ display: "flex", gap: "5px", minWidth: "220px" }}>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      HandleHeadline2("Headline2");
                    }}
                  >
                    {aiHeadline2 ? aiHeadline2: pendingadData?.GoogleSearchHeadline[1] || "Website Main Headline 2"}
                  </p>
                </div>
              ) : (
                <input
                  maxLength={50}
                  style={{ outline: "none", fontSize: "16px" }}
                  value={aiHeadline2}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiHeadline2(e.target.value);
                  }}
                />
              )}
              {/* headline 2 end */}

              {/* headline 3 start */}

              {!editHeadline3 ? (
                <div style={{ display: "flex", gap: "5px", minWidth: "220px" }}>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      HandleHeadline3("Headline3");
                    }}
                  >
                    {aiHeadline3 ? aiHeadline3 : pendingadData?.GoogleSearchHeadline[2] || "Website Main Headline 3"}
                  </p>
                </div>
              ) : (
                <input
                  maxLength={50}
                  style={{ width: "90%", outline: "none", fontSize: "16px" }}
                  value={aiHeadline3}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiHeadline3(e.target.value);
                  }}
                />
              )}
              {/* headline 2 and 3 end */}
            </div>

            <div
              className="google-content-greendot"
              style={{ cursor: "pointer" }}
              onClick={(e) => HandleGreenDot2(e)}
            >
              <div className="google-pick-headline-button-google">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="153"
                  height="90"
                  viewBox="0 0 203 90"
                  fill="none"
                  style={{marginTop:"-29px"}}
                >
                  <g filter="url(#filter0_d_392_5164)">
                    <path
                      d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                      fill="url(#paint0_linear_392_5164)"
                    />
                    <text x="24%" y="44%" className="pickHeadlineSvgText">
                      Pick Headline
                    </text>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_392_5164"
                      x="0"
                      y="0"
                      width="203"
                      height="90"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="11" dy="11" />
                      <feGaussianBlur stdDeviation="11.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_392_5164"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_392_5164"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_392_5164"
                      x1="98.868"
                      y1="12"
                      x2="98.868"
                      y2="70.5283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#47BEB9" />
                      <stop offset="1" stop-color="#215856" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>

          {/* description stars from here */}

          <div
            className="google-content-mainbox-div2"
            style={{
              justifyContent: "center",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                className="google-content-mainbox-div3 aiItemhoverCss"
                style={{ width: "90%" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // setMessageEdit(true);
                  HandleDescription1("description1");
                }}
              >
                {!messageEdit ? (
                  aiText ? (
                    aiText
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      {
                        <svg
                          width="80%"
                          height="11"
                          viewBox="0 0 612 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="612"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                      {
                        <svg
                          width="40%"
                          height="9"
                          viewBox="0 0 333 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="333"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                    </div>
                  )
                ) : (
                  <input
                    style={{ width: "100%", outline: "none" }}
                    value={aiText}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setAiText(e.target.value);
                    }}
                    maxLength={110}
                  />
                )}
              </div>

              {/* description 2 stars */}
              <div
                className="google-content-mainbox-div3 aiItemhoverCss"
                style={{ marginTop: "10px", width: "90%" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // setEditDescription2(true);
                  HandleDescription2("description2");
                }}
              >
                {!editDescription2 ? (
                  aiDescription2 ? (
                    aiDescription2
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      {
                        <svg
                          width="80%"
                          height="11"
                          viewBox="0 0 612 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="612"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                      {
                        <svg
                          width="40%"
                          height="9"
                          viewBox="0 0 333 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="333"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                    </div>
                  )
                ) : (
                  <input
                  maxLength={110}
                    style={{ width: "100%", outline: "none" }}
                    value={aiDescription2}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setAiDescription2(e.target.value);
                    }}
                  />
                )}
              </div>
              {/* description 2 end */}
            </div>

            {/* <div
              onClick={(e) => HandleGreenDot(e)}
              style={{
                cursor: "pointer",
                marginTop: "-10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="134"
                height="84"
                viewBox="0 0 159 84"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5169)">
                  <path
                    d="M9 51.9685C9 55.891 12.1798 59.0708 16.1023 59.0708H126.898C130.82 59.0708 134 55.891 134 51.9685V27.2705C134 23.8763 131.598 20.9565 128.268 20.3017L72.1758 9.27231C71.2607 9.09238 70.3192 9.09443 69.4049 9.27834L14.7017 20.2819C11.3856 20.9489 9 23.8621 9 27.2447V51.9685Z"
                    fill="url(#paint0_linear_392_5169)"
                  />
                  <text
                    x="12%"
                    y="50%"
                    className="pickHeadlineSvgText"
                    fontSize={11}
                  >
                    Pick Discription
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5169"
                    x="0.477274"
                    y="0.615946"
                    width="157.67"
                    height="82.6026"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="7.8125" dy="7.8125" />
                    <feGaussianBlur stdDeviation="8.16761" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5169"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5169"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5169"
                    x1="71.5"
                    y1="59.0708"
                    x2="71.5"
                    y2="8.99978"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div> */}

<div
              onClick={(e) => HandleGreenDot(e)}
              style={{
                cursor: "pointer",
                marginTop: "-10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="134"
                height="50"
                viewBox="0 0 159 60"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5169)">
                  <path
                    d="M9 51.9685C9 55.891 12.1798 59.0708 16.1023 59.0708H126.898C130.82 59.0708 134 55.891 134 51.9685V27.2705C134 23.8763 131.598 20.9565 128.268 20.3017L72.1758 9.27231C71.2607 9.09238 70.3192 9.09443 69.4049 9.27834L14.7017 20.2819C11.3856 20.9489 9 23.8621 9 27.2447V51.9685Z"
                    fill="url(#paint0_linear_392_5169)"
                  />
                  <text
                    x="12%"
                    y="70%"
                    className="pickHeadlineSvgText"
                    fontSize={11}
                  >
                    Pick Description
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5169"
                    x="0.477274"
                    y="0.615946"
                    width="157.67"
                    height="82.6026"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="7.8125" dy="7.8125" />
                    <feGaussianBlur stdDeviation="8.16761" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5169"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5169"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5169"
                    x1="71.5"
                    y1="59.0708"
                    x2="71.5"
                    y2="8.99978"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div>



          </div>
        </div>
        {aimodalToggle2 && (
          <div
            className="google-content-modal laptopModalMarginAiMessage "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Google Ads > Headlines`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>

            <div className="google-content-modal-div2">
              {aiApiHeading ? ( //aiApiDescription
                aiApiHeading?.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItemsMessage(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>
            {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
              >
                Generate More
              </CommonBtn>
            </div> */}
          </div>
        )}

        {aimodalToggle && (
          <div
            className="google-content-modal googlelaptopModalMarginAiHeading"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Google Ads > Descriptions`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>


            <div className="google-content-modal-div2">
              {aiApiDescription ? ( //aiApiDescription
                aiApiDescription.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItems(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>


            {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
              >
                Generate More
              </CommonBtn>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

const MobileView = ({
  aiApiDescription,
  aiApiHeading,
  aiText,
  aiText2,
  setAiText,
  setAiText2,
  LocalwebsiteUrl,
  LocalCompanyName,
  setAiHeadline2,
  setAiHeadline3,
  aiHeadline2,
  aiHeadline3,
  aiDescription2,
  setAiDescription2,
}) => {
  const [aimodalToggle, setAiModalToggle] = useState(false);
  const [aimodalToggle2, setAiModalToggle2] = useState(false);
  const [messageEdit, setMessageEdit] = useState(false);
  const [editDescription2, setEditDescription2] = useState(false);

  const [messageEdit2, setMessageEdit2] = useState(false);
  const [editHeadline2, setEditHeadline2] = useState(false);
  const [editHeadline3, setEditHeadline3] = useState(false);

  const [updateHeadline, setUpdateHeadline] = useState("");
  const [updateDescription, setUpdateDescription] = useState("");
  const [pendingadData,setPendndingadData] = useState()

  // const [lgShow, setLgShow] = useState(false);
  // const [aiText, setAiText] = useState(
  //   "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
  // );
  // const [aiText2, setAiText2] = useState("Website Main Headline");


// done by hari bhiya

useEffect(()=>{

  setPendndingadData(JSON.parse(sessionStorage.getItem("pendingAdData")))
 setAiDescription2((JSON.parse(sessionStorage.getItem("pendingAdData"))?.GoogleSearchDescription[1])||"")
 setAiText((JSON.parse(sessionStorage.getItem("pendingAdData"))?.GoogleSearchDescription[0])||"")
},[])

// done by hari bhiya

  const HandelAiItemsMessage = (item) => {
    if (updateHeadline) {
      if (updateHeadline === "Headline1") {
        setAiText2(item);
        setMessageEdit2(false);
      } else if (updateHeadline === "Headline2") {
        setAiHeadline2(item);
        setEditHeadline2(false);
      } else if (updateHeadline === "Headline3") {
        setAiHeadline3(item);
        setEditHeadline3(false);
      }
    } else if (aiText2 === "") {
      setAiText2(item);
    } else if (aiHeadline2 === "Website Main Headline 2" || aiHeadline2 === "") {
      setAiHeadline2(item);
    } else if (aiHeadline3 === "Website Main Headline 3" || aiHeadline3 === "") {
      setAiHeadline3(item);
    }
    setAiModalToggle2(!aimodalToggle2);
  };

  const HandelAiItems = (item) => {
    if (updateDescription) {
      if (updateDescription === "description1") {
        setAiText(item);
        setMessageEdit(false);
      } else if (updateDescription === "description2") {
        setAiDescription2(item);
        setEditDescription2(false);
      }
    } else if (aiText === "") {
      setAiText(item);
    } else if (aiDescription2 === "") {
      setAiDescription2(item);
    }

    // setAiText(item);
    setAiModalToggle(!aimodalToggle);
    setUpdateDescription("");
  };

  // const HandelAiGenerat = () => {
  //   setLgShow(true);
  //   setAiModalToggle(false);
  // };

  const HandleGreenDot = (e) => {
    e.stopPropagation();
    setAiModalToggle(!aimodalToggle);
    setAiModalToggle2(false);
  };
  const HandleGreenDot2 = (e) => {
    e.stopPropagation();
    setAiModalToggle2(!aimodalToggle2);
    setAiModalToggle(false);
  };

  // functions for Editing headlines
  const HandleHeadline1 = (headlineName) => {
    setMessageEdit2(!messageEdit2);
    setEditHeadline2(false);
    setEditHeadline3(false);
    setUpdateHeadline(headlineName);
    setAiModalToggle2(true);
  };

  const HandleHeadline2 = (headlineName) => {
    setEditHeadline2(!editHeadline2);
    setMessageEdit2(false);
    setEditHeadline3(false);

    setUpdateHeadline(headlineName);
    setAiModalToggle2(true);
  };

  const HandleHeadline3 = (headlineName) => {
    setEditHeadline3(!editHeadline3);
    setMessageEdit2(false);
    setEditHeadline2(false);

    setUpdateHeadline(headlineName);
    setAiModalToggle2(true);
  };

  // functions for Editing descriptions
  const HandleDescription1 = (descriptionName) => {
    setMessageEdit(!messageEdit);
    setEditDescription2(false);

    setUpdateDescription(descriptionName);
    setAiModalToggle(true);
  };

  const HandleDescription2 = (descriptionName) => {
    setMessageEdit(false);
    setEditDescription2(!editDescription2);

    setUpdateDescription(descriptionName);
    setAiModalToggle(true);
  };

  return (
    <div
      className="main-google-content-main  google-mobileview-box "
      style={{ maxWidth: "900px" }}
      onClick={() => {
        setAiModalToggle(false);
        setAiModalToggle2(false);
        setMessageEdit(false);
        setMessageEdit2(false);
        setEditHeadline2(false);
        setEditHeadline3(false);
        setEditDescription2(false);
      }}
    >
      <div className="facebook-mobileview-box-subdiv">
        <div
          className="google-content-mainbox"
          style={{
            flexWrap: "wrap",
            // height: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <div className="google-content-mainbox-header">
            <div className="google-content-mainbox-header-div">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Ad ·
                </p>
                <div>
                  {/* <h6>
                  
                    {LocalCompanyName ? LocalCompanyName : "Your Company Name"}
                  </h6> */}
                  <p>{`${
                    LocalwebsiteUrl ? LocalwebsiteUrl : "www.yourwebsite.com"
                  }`}</p>
                </div>
              </div>
              {dotsIcon1}
            </div>
          </div>

          <div
            className="google-content-mainbox-div2"
            style={{ alignItems: "start" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "93%",
              }}
            >
            <div>

              {!messageEdit2 ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      HandleHeadline1("Headline1");
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    {aiText2 ? aiText2 : "Website Main Headline 1"}
                  </p>
                </div>
              ) : (
                <input
                  maxLength={50}
                  style={{ width: "90%", outline: "none", fontSize: "16px" }}
                  value={aiText2}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiText2(e.target.value);
                  }}
                />
              )}

              {/* headline 2 and 3 start */}

              {!editHeadline2 ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      HandleHeadline2("Headline2");
                    }}
                  >
                    {aiHeadline2 ? aiHeadline2 : "Website Main Headline 2"}
                  </p>
                </div>
              ) : (
                <input
                  maxLength={50}
                  style={{ width: "90%", outline: "none", fontSize: "16px" }}
                  value={aiHeadline2}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiHeadline2(e.target.value);
                  }}
                />
              )}
              {/* headline 2 end */}

              {/* headline 3 start */}

              {!editHeadline3 ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  <p
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      HandleHeadline3("Headline3");
                    }}
                  >
                    {aiHeadline3 ? aiHeadline3 : "Website Main Headline 3"}
                  </p>
                </div>
              ) : (
                <input
                  maxLength={50}
                  style={{ width: "90%", outline: "none", fontSize: "16px" }}
                  value={aiHeadline3}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    setAiHeadline3(e.target.value);
                  }}
                />
              )}
            </div>
              
            </div>

            {/* headline 2 and 3 end */}
            <div
              // className="google-content-greendot"
              style={{ width: "12px", height: "12px" }}
              onClick={HandleGreenDot2}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="153"
                height="90"
                viewBox="0 0 203 90"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5164)">
                  <path
                    d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                    fill="url(#paint0_linear_392_5164)"
                  />
                  <text x="24%" y="44%" className="pickHeadlineSvgText">
                    Pick Headline
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5164"
                    x="0"
                    y="0"
                    width="203"
                    height="90"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="11" dy="11" />
                    <feGaussianBlur stdDeviation="11.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5164"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5164"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5164"
                    x1="98.868"
                    y1="12"
                    x2="98.868"
                    y2="70.5283"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div
            // className="google-content-mainbox-div2"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div  style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}>

              <div
                className="google-content-mainbox-div3 aiItemhoverCss"
                style={{ fontSize: "12px",width:"90%" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  HandleDescription1("description1");
                }}
              >
                {!messageEdit ? (
                  aiText ? (
                    aiText
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      {
                        <svg
                          width="80%"
                          height="11"
                          viewBox="0 0 612 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="612"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                      {
                        <svg
                          width="40%"
                          height="9"
                          viewBox="0 0 333 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="333"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                    </div>
                  )
                ) : (
                  <input
                  maxLength={110}
                    style={{ width: "100%", outline: "none" }}
                    value={aiText}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setAiText(e.target.value);
                    }}
                  />
                )}
              </div>
              {/* description 2 stars */}
              <div
                className="google-content-mainbox-div3 aiItemhoverCss"
                style={{ marginTop: "10px",width:"90%" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // setEditDescription2(true);
                  HandleDescription2("description2");
                }}
              >
                {!editDescription2 ? (
                  aiDescription2 ? (
                    aiDescription2
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      {
                        <svg
                          width="80%"
                          height="11"
                          viewBox="0 0 612 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="612"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                      {
                        <svg
                          width="40%"
                          height="9"
                          viewBox="0 0 333 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="333"
                            height="11"
                            rx="5.5"
                            fill="#249FE4"
                            fill-opacity="0.6"
                          />
                        </svg>
                      }
                    </div>
                  )
                ) : (
                  <input
                    maxLength={110}
                    style={{ width: "100%", outline: "none" }}
                    value={aiDescription2}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setAiDescription2(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            {/* description 2 end */}
            <div
              // className="google-content-greendot"
              style={{ width: "12px", height: "12px" }}
              onClick={HandleGreenDot}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="153"
                height="90"
                viewBox="0 0 203 90"
                fill="none"
              >
                <g filter="url(#filter0_d_392_5164)">
                  <path
                    d="M25.743 16.1571C27.6223 13.5469 30.6422 12 33.8585 12H159C164.523 12 169 16.4772 169 22V46C169 51.5228 164.523 56 159 56H96.8756H33.5185C30.4942 56 27.6324 54.6314 25.734 52.2772L12 35.2453L25.743 16.1571Z"
                    fill="url(#paint0_linear_392_5164)"
                  />
                  <text x="24%" y="44%" className="pickHeadlineSvgText">
                    Pick Description
                  </text>
                </g>
                <defs>
                  <filter
                    id="filter0_d_392_5164"
                    x="0"
                    y="0"
                    width="203"
                    height="90"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="11" dy="11" />
                    <feGaussianBlur stdDeviation="11.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.278431 0 0 0 0 0.745098 0 0 0 0 0.72549 0 0 0 0.31 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_392_5164"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_392_5164"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_392_5164"
                    x1="98.868"
                    y1="12"
                    x2="98.868"
                    y2="70.5283"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#47BEB9" />
                    <stop offset="1" stop-color="#215856" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

          </div>
        </div>

        {aimodalToggle2 && (
          <div
            style={{ top: "-25px" }}
            className="google-content-modal  mobile-message-suggestionbing mobileMessageWidthPropgoogle"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Google Ads > Headlines`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>
            <div className="google-content-modal-div2">
              {aiApiHeading ? ( //aiApiDescription
                aiApiHeading.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItemsMessage(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>
            {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
                handleSubmit={() => setLgShow(true)}
              >
                Generate More
              </CommonBtn>
              <AiGenerationModal
                lgShow={lgShow}
                hide={() => setLgShow(false)}
              />
            </div> */}
          </div>
        )}

        {aimodalToggle && (
          <div
            style={{ top: "-25px" }}
            className="google-content-modal  mobile-headline-suggestiongoogle mobileHeadlinesWidthPropgoogle"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="google-content-modal-div1">
              <h1>AI Suggestions</h1>
              <p>{`Google Ads > Descriptions`}</p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#B2BBC6 " }}
            ></div>
            <div className="google-content-modal-div2">
              {aiApiDescription ? ( //aiApiDescription
                aiApiDescription.map((item, i) => (
                  <ul>
                    <li key={i} onClick={() => HandelAiItems(item)}>
                      {item}
                    </li>
                  </ul>
                ))
              ) : (
                <Spinner style={{ alignSelf: "center" }} />
              )}
            </div>
            {/* <div>
              <CommonBtn
                padding={"5px 10px"}
                gap={"5px"}
                left={false}
                icon={AiIcon}
                handleSubmit={() => setLgShow(true)}
              >
                Generate More
              </CommonBtn>
              <AiGenerationModal
                lgShow={lgShow}
                hide={() => setLgShow(false)}
              />
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
export const ToggleBtn = ({ toggle, handleToggleBtn }) => {
  return (
    <div>
      <button
        className={`desktop-toggle-btn-main ${toggle && "active"}`}
        onClick={handleToggleBtn}
      >
        <p
          className="desktop-toggle-text"
          style={toggle ? { display: "none" } : {}}
        >
          Desktop
        </p>
        <div className="desktop-icon-main">
          {toggle ? MobileSvg : desktopIcon}
        </div>
        <p
          className="desktop-toggle-text"
          style={!toggle ? { display: "none" } : {}}
        >
          Mobile
        </p>
      </button>
    </div>
  );
};

// const AiGenerationModal = ({ lgShow, hide }) => {
//   return (
//     <Modal
//       size="lg"
//       centered
//       show={lgShow}
//       onHide={hide}
//       aria-labelledby="example-modal-sizes-title-lg"
//     >
//       <Container fluid>
//         <Row style={{ gap: "10px" }}>
//           <Col style={{ padding: "0px" }}>
//             <div className="generate-modal-main-col1-header ">
//               <span>Input</span>
//               <ul>
//                 <NavLink to={"input/brand"}>
//                   <li>Brand</li>
//                 </NavLink>
//                 <NavLink to={"input/campaign"}>
//                   <li>Campaign</li>
//                 </NavLink>
//                 <NavLink to={"input/googlead"}>
//                   <li>Google Ad</li>
//                 </NavLink>
//               </ul>
//             </div>
//           </Col>
//           <Col style={{ padding: "0px" }}>
//             <div className="generate-modal-main-col1-header">
//               <span>AI Suggestions</span>
//               <ul>
//                 <NavLink to={"aisuggestion/headline"}>
//                   <li>Headline</li>
//                 </NavLink>
//                 <NavLink to={"aisuggestion/description"}>
//                   <li>Descriptions</li>
//                 </NavLink>
//                 <NavLink to={"aisuggestion/keyword"}>
//                   <li>Keywords</li>
//                 </NavLink>
//               </ul>
//             </div>
//             <div></div>
//           </Col>
//         </Row>

//         <Row style={{ display: "flex ", justifyContent: "center" }}>
//           <Col
//             style={{
//               height: "380px",
//             }}
//             lg={8}
//           >
//             <InputMain />
//           </Col>
//         </Row>
//         <Row>
//           <div className="ai-generate-footer">
//             <button>Done</button>
//             <button>Generate</button>
//           </div>
//         </Row>
//       </Container>
//     </Modal>
//   );
// };

export default GoogleContent;
