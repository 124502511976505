import { createSlice } from "@reduxjs/toolkit";
const initialState = {
 onlyCreativeState:"",
 onlyCreativeSize:"",
  // selectedLogo:""
};
const creativeData = createSlice({
  name: "addCreate",
  initialState,
  reducers: {
    
    addOnlyCreativeState: (state, action) => {
      state.onlyCreativeState = action.payload;
    },
    addOnlyCreativeSize: (state, action) => {
        state.onlyCreativeSize = action.payload;
      },

   
  },
});

export const {
    addOnlyCreativeState,
    addOnlyCreativeSize,
} = creativeData.actions;
export default creativeData.reducer;
