import React from 'react'

export default function SocialIcon2() {
    return (
        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.753 28.6975L18.3577 1.06958C18.0786 0.353651 17.5553 0 16.8053 0H12.0437C11.2937 0 10.7704 0.353651 10.4738 1.06958L0.0872096 28.6975L0 29.077C0 29.3359 0.0872096 29.5515 0.261629 29.7154C0.436048 29.8879 0.645352 29.9741 0.906981 29.9741H4.90118C4.98839 29.9741 5.07561 29.9569 5.16281 29.9482C5.44188 29.9051 5.71223 29.7844 5.96514 29.6464L14.0931 25.0661C14.285 24.9626 14.5117 24.9626 14.7036 25.0661L22.8751 29.6636C23.1106 29.793 23.3547 29.9051 23.6251 29.9569C23.782 29.9827 23.9303 30 24.0786 30C24.1746 30 24.2618 30 24.3577 29.9827H27.942C28.1774 29.9827 28.378 29.8965 28.5698 29.724C28.753 29.5601 28.8489 29.3445 28.8489 29.0857L28.753 28.6975ZM14.1018 19.5457L8.54655 22.6854C8.40701 22.7631 8.25004 22.6251 8.30236 22.4871L14.2588 6.43474C14.3111 6.2881 14.5204 6.2881 14.5727 6.43474L20.5117 22.4871C20.5641 22.6337 20.407 22.7631 20.2675 22.6854L14.7036 19.5457C14.5204 19.4423 14.285 19.4423 14.1018 19.5457Z" fill="#47BEB9" />
        </svg>

    )
}
