import React, { useContext, useEffect, useState } from "react";
import "./GoogleTargeting.css";
import { plusIcon, SearchSvg, arr1, RemoveSVG,  arr3 } from "./SVG";
import { useSelector } from "react-redux";
import { minusIcon } from "../../GoogleCampaign/SideNavContainer/SVG";
import { ToggleContext } from "../ContextFile";
import { Link } from "react-router-dom";

const GoogleTargeting = () => {
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);

  const { keyword } = useSelector((state) => state.AiSuggestion.Bing);
  const { HandleActiveStep } = useContext(ToggleContext);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  // const [data4, setData4] = useState([]);

  useEffect(() => {
    HandleActiveStep(2);
  }, []);

  const addIntoArray1 = (item) => {
    if (!data1.includes(item)) {
      setData1([item]);
    }
    setActive1(false);
  };

  const addIntoArray2 = (item) => {
    if (!data2.includes(item)) {
      setData2([...data2, item]);
    }
  };
  const addIntoArray3 = (item) => {
    if (!data3.includes(item)) {
      setData3([item]);
    }
    setActive3(false);
  };

  const DeleteItemFromListArry1 = (array, i) => {
    const updatedData1 = [...data1]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData1(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry2 = (array, i) => {
    const updatedData1 = [...data2]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData2(updatedData1); // Update the state with the new array
  };

  const DeleteItemFromListArry3 = (array, i) => {
    const updatedData1 = [...data3]; // Create a copy of the array
    updatedData1.splice(i, 1); // Remove the item at index i
    setData3(updatedData1); // Update the state with the new array
  };

  const handleAccordian1 = (active) => {
    setActive1(active);
    setActive2(false);
    setActive3(false);
    setActive4(false);
  };

  const handleAccordian2 = (active) => {
    setActive1(false);
    setActive2(active);
    setActive3(false);
    setActive4(false);
  };

  const handleAccordian3 = (active) => {
    setActive1(false);
    setActive2(false);
    setActive3(active);
    setActive4(false);
  };

  // const handleAccordian4 = (active) => {
  //   setActive1(false);
  //   setActive2(false);
  //   setActive3(false);
  //   setActive4(active);
  // };

  return (
    <div className="targerting-main-box Fb-funding-scroller" style={{}}>
      <div style={{ alignSelf: "center", maxWidth: "700px", width: "100%" }}>
        <Accordion
          title={"Select Location"}
          placeholder={"The Entire World"}
          active={active1}
          setActive={handleAccordian1}
          dataArr={arr1}
          addIntoArray={addIntoArray1}
          DeleteItemFromList={DeleteItemFromListArry1}
          data={data1}
        />
        <Accordion
          title={"Select Keyword"}
          dataArr={keyword ? keyword : ["Please select headline first"]}
          placeholder={"Keyword"}
          active={active2}
          setActive={handleAccordian2}
          addIntoArray={addIntoArray2}
          DeleteItemFromList={DeleteItemFromListArry2}
          data={data2}
          scrollHeight={120}
        />
        <Accordion
          title={"Language"}
          placeholder={"All Language"}
          active={active3}
          setActive={handleAccordian3}
          dataArr={arr3}
          addIntoArray={addIntoArray3}
          DeleteItemFromList={DeleteItemFromListArry3}
          data={data3}
          scrollHeight={400}
        />
      </div>
      <div className="campaign-nav-btn">
        <Link to={"/main/campaign/bingcampaign/bing-add-1/content"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7895)">
              <path
                d="M1.51845 11.3884C1.51845 5.93738 5.93738 1.51845 11.3884 1.51845H154.693C159.278 1.51845 163.259 4.67535 164.304 9.13933L173.249 47.3625C174.698 53.555 169.998 59.4815 163.639 59.4815H11.3884C5.93737 59.4815 1.51845 55.0626 1.51845 49.6116V11.3884Z"
                stroke="url(#paint0_linear_392_7895)"
                stroke-width="3.03691"
              />
              <text
                x="34%"
                y="64%"
                font-family="Arial"
                fill="white"
                className="Back-svg-btn"
              >
                Back
              </text>
            </g>

            <defs>
              <filter
                id="filter0_i_392_7895"
                x="0"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7895"
                />
              </filter>
              <linearGradient
                id="paint0_linear_392_7895"
                x1="0"
                y1="0"
                x2="172.838"
                y2="42.9259"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#47BEB9" />
                <stop offset="1" stop-color="#215856" />
              </linearGradient>
            </defs>
          </svg>
        </Link>
        <Link to={"/main/campaign/bingcampaign/bing-add-1/funding"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="106"
            height="61"
            viewBox="0 0 176 61"
            fill="none"
          >
            <g filter="url(#filter0_i_392_7897)">
              <path
                d="M176 49.6116C176 55.9012 170.901 61 164.612 61H21.3066C16.0167 61 11.4232 57.3574 10.2178 52.2067L1.27252 13.9835C-0.399628 6.83836 5.02313 0 12.3613 0H164.612C170.901 0 176 5.09876 176 11.3884V49.6116Z"
                fill="#5BBCBB"
              />
              <text
                x="36%"
                y="64%"
                font-family="Arial"
                // fill="#000"
                className="Ask-an-Expert"
              >
                Next
              </text>
            </g>
            <defs>
              <filter
                id="filter0_i_392_7897"
                x="0.96875"
                y="0"
                width="188.697"
                height="73.1476"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="13.6661" dy="12.1476" />
                <feGaussianBlur stdDeviation="18.6011" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.142792 0 0 0 0 0.622572 0 0 0 0 0.892448 0 0 0 0.6 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_392_7897"
                />
              </filter>
            </defs>
          </svg>
        </Link>
      </div>
    </div>
  );
};

const Accordion = ({
  active,
  setActive,
  dataArr,
  addIntoArray,
  title,
  placeholder,
  data,
  DeleteItemFromList,
  scrollHeight,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const HandleScroll = () => {
    const mainBox = document.querySelector(".targerting-main-box");
    setTimeout(() => {
      mainBox.scrollTo({ top: scrollHeight, behavior: "smooth" });
    }, 100);
  };

  return (
    <div>
      <button
        className="targerting-box"
        style={{}}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setActive(!active);
          HandleScroll();
        }}
      >
        <p className="targerting-text">{title}</p>
        <div className="tagert-accordion-main">
          <div className="tagert-accordion">
            <p className="targer-accordion-text">
              {data.length > 0
                ? data.map((item, i) => (
                    <div className="item-in-data" key={i}>
                      <p className="item-in-data-text">{item}</p>
                      <span
                        style={{}}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          DeleteItemFromList(data, i);
                        }}
                      >
                        {RemoveSVG}
                      </span>
                    </div>
                  ))
                : placeholder}
            </p>
            <span style={{ width: "20px" }}>
              {active ? minusIcon : plusIcon}
            </span>
          </div>
        </div>
      </button>

      {active && (
        <div className="search-body" style={{}}>
          <div className="search-main">
            <div>
              {SearchSvg}
              <input
                type="text"
                style={{
                  border: "transparent",
                  background: "inherit",
                  outline: "none",
                }}
                placeholder="Search Keyword"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              maxHeight: "200px",
              overflow: "hidden",
              width: "100%",
              overflowY: "scroll",
            }}
          >
            {dataArr.length > 0
              ? dataArr
                  ?.filter((item) =>
                    item.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  .map((item, i) => (
                    <div
                      className="item-main"
                      onClick={() => addIntoArray(item)}
                    >
                      <p className="item-text">{item}</p>
                      <span>{plusIcon}</span>
                    </div>
                  ))
              : "No data"}
          </div>
        </div>
      )}
    </div>
  );
};

export default GoogleTargeting;
