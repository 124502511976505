import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import StylesContaent from "./AdminContent.module.css";
import styles from "../sidebar/AdminSidebar.module.css";
import { Button, Modal, ToastContainer } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios, { ImageUrl } from "../../../axios/auth";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import loader from "../images/ZZ5H.gif"
import 'bootstrap/dist/css/bootstrap.min.css'; 

function ManageDiscount() {
  const token = Cookies.get("token");
  const [showModal, setShowModal] = useState(false);
  const [imgPreview, setImagePreview] = useState("");
  // const { register, handleSubmit, errors } = useForm();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [listData, setListData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [personView,setPersonView] = useState('')
  const [updateDisplayImg,setUpdateDisplayImg] = useState('')
  const [updatePersonImg,setUpdatePersonImg] = useState('')
  const [updateOldDisplayImg,setUpdateOldDisplayImg] = useState('')
  const [updateOldPersonImg,setUpdateOldPersonImg] = useState('')
const [showDeleteModal,setShowDeleteModal] = useState(false)
const [isLoading,setIsLoading] = useState(false)


  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const [formData,setFormData] = useState({
    displayImage:"",
    tag:"",
    heading:"",
    description:"",
    personImage:"",
    personName:"",
    personDesignation:""
  })
  const modalShow = () => {
    setShowModal(true);
  };

  const closeModal = () => {
      reset(); // Reset form fields
      setImagePreview(''); // Clear image preview
      setPersonView(''); // Clear person image preview
      // Any other state reset if needed
    setShowModal(false);
    setUpdateModal(false);
    setShowDeleteModal(false)
  };

  const onSubmit = async (data) => {
    let bodyData = {
      displayImage: data?.displayImage,
      tag: data?.tag,
      heading: data?.heading,
      description: data?.description,
      personImage: data?.personImage,
      personName: data?.personName,
      personDesignation: data?.personDesignation,
    };

    try {
      const res = await axios.post("/create-blog", bodyData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
        },
      });
      if (res?.data?.status == true) {
        // toast.success(res?.data?.message);
        toast.success(res?.data?.message, {
          className: 'custom-toast-success',
          hideProgressBar: true
        });

        fetchData();
        closeModal();
      }
    } catch (error) {
      // toast.error(error.message);
      toast.error(error?.message, {
        className: 'custom-toast-error',
        hideProgressBar: true
      });
      // console.error("Error fetching customer:", error);
    }
  };

  // get data
  async function fetchData() {
    setIsLoading(true)
    try {
      const res = await axios.get("/get-blog");
      setListData(res?.data?.data);
      setIsLoading(false)
    } catch (error) {
      // console.error("Error fetching customer:", error);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const openUpdateModal = (data) => {
    reset();
    setUpdateModal(true);
    setSelectedId(data?._id);
    setSelectedData(data);
    setUpdateDisplayImg(data?.personImage)
    setUpdatePersonImg(data?.personImage)
    setUpdateOldDisplayImg(null)
    setUpdateOldPersonImg(null)
  };

  const onUpdateSubmit = async (e) => {
    e.preventDefault();
    let data ={
        id:selectedId,
        displayImage:formData.displayImage||selectedData?.displayImage,
        tag:formData.tag||selectedData?.tag,
        heading:formData.heading||selectedData?.heading,
        description:formData.description||selectedData?.description,
        personImage:formData.personImage||selectedData?.personImage,
        personName:formData.personName||selectedData?.personName,
        personDesignation:formData.personDesignation||selectedData?.personDesignation
    }
    try {
        const res = await axios.post("/update-blog", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
          },
        });
        if (res?.data?.status == true) {
          // toast.success(res?.data?.message);
          toast.success(res?.data?.message, {
            className: 'custom-toast-success',
            hideProgressBar: true
          });
          fetchData();
          closeModal();
          formData.displayImage="";
          formData.personImage='';
        }
      } catch (error) {
        // toast.error(error.message);
        toast.error(error?.message, {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
        // console.error("Error fetching customer:", error);
      }
  };

const setImagView = (e) =>{
    let img = URL.createObjectURL(e.target.files[0])
    setImagePreview(img)
}

const setPersonImagView = (e) =>{
    let img = URL.createObjectURL(e.target.files[0])
    setPersonView(img)
}

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
}


const setImagePreviewForUpdate=(e)=>{
    let img = URL.createObjectURL(e.target.files[0])
    formData.displayImage=e.target.files[0]
    setUpdateOldDisplayImg(img)
    setUpdateDisplayImg(null)
}

const setImagePreviewForPersonUpdate=(e)=>{
    let img = URL.createObjectURL(e.target.files[0])
    formData.personImage=e.target.files[0]
    setUpdateOldPersonImg(img)
    setUpdatePersonImg(null)
}


const openDeleteModal = (data) =>{
setShowDeleteModal(true)
setSelectedId(data?._id)
setSelectedData(data)
}

const handleDeleteBlog = async() =>{
  try {
    const res = await axios.post("/delete-blog", selectedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res?.data?.status == true) {
      // toast.success(res?.data?.message);
      toast.success(res?.data?.message, {
        className: 'custom-toast-success',
        hideProgressBar: true
      });
      fetchData();
      closeModal();
    }
  } catch (error) {
    // toast.error(error.message);
    toast.error(error?.message, {
      className: 'custom-toast-error',
      hideProgressBar: true
    });
    // console.error("Error fetching customer:", error);
  }
}

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <div className={styles.mobileSidebar}>
              <AdminSidebar className={styles.mobileSidebar} />
            </div>
          </div>
          <div className="col-sm-10 bg-white">
            <div className="container">
              <Header title="Add Creative" btnname="Create Campaigns" />
            </div>
            <div className="container">
              <div className="container">
                <div className="row">
                  <h4 className=" my-4 ps-0">
                    <b> Manage Blogs </b>
                  </h4>
                  <div>
                    <Button
                      variant="primary"
                      className="common-btn m-2"
                      onClick={() => {
                        modalShow();
                      }}
                    >
                      Add Blogs
                    </Button>
                  </div>

                  <hr />
                  {isLoading?<div style={{width:"100%",height:"50vh", position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <img src={loader} style={{width:"100px",height:"100px"}} alt="" /></div>: 
                  <div className={StylesContaent.tableHeading}>
                    <table className="table table-striped table-border text-center">
                      <tr>
                        <th className="border">Main Image </th>
                        <th className="border">Tag</th>
                        <th className="border"> Heading</th>
                        <th className="border"> Descrption</th>
                        <th className="border"> Creator Image </th>
                        <th className="border"> Creator Name </th>
                        <th className="border">Creator Designation</th>
                        <th className="border">Action</th>
                      </tr>
                      {listData.map((data, index) => (
                        <tr key={index}>
                          <td className="border">
                            <img
                              src={`${ImageUrl}/${data?.displayImage}`}
                              alt="Display"
                              width={80}
                              height={80}
                            />
                          </td>
                          <td className="border">{data?.tag}</td>
                          <td className="border">{data?.heading ? data.heading.slice(0, 50) + (data.heading.length > 50 ? '...' : '') : ''}</td>

                          <td className="border">{data?.description ? (data.description.length > 50 ? data.description.slice(0, 50) + '...' : data.description) : ''}</td>

                          <td className="border">
                            {" "}
                            <img
                              src={`${ImageUrl}/${data?.personImage}`}
                              alt="Display"
                              width={80}
                              height={80}
                            />
                          </td>
                          <td className="border">{data?.personName}</td>
                          <td className="border">{data?.personDesignation}</td>

                          {/* <td className="border">
                            <div className="dropdown">
                              <button
                                className="btn common-btn dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButton${index}`} // Unique ID for each dropdown
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Action
                              </button>

                              <ul
                                className="dropdown-menu"
                                aria-labelledby={`dropdownMenuButton${index}`} // Use the same unique ID here
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => openUpdateModal(data)}
                                  >
                                    Update
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" onClick={()=>openDeleteModal(data)}>
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td> */}


                          <td className="border">
              <div className="dropdown">
                <button
                  className="btn common-btn dropdown-toggle"
                  type="button"
                  id={`dropdownMenuButton${index}`}
                  aria-expanded={openDropdownIndex === index}
                  onClick={() => toggleDropdown(index)}
                >
                  Action
                </button>
                <ul
                  className={`dropdown-menu ${openDropdownIndex === index ? 'show' : ''}`}
                  aria-labelledby={`dropdownMenuButton${index}`}
                  style={{ display: openDropdownIndex === index ? 'block' : 'none' }}
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => openUpdateModal(data)}
                    >
                      Update
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => openDeleteModal(data)}
                    >
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </td>



                        </tr>
                      ))}
                    </table>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton className="modal-input-header">
          <Modal.Title>Add New Blog</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body className="container modal-input">
            <div className="col">
              <div className="row">
                <label style={{ fontWeight: 600 }}>Dispaly Image</label>
                <input
                  type="file"
                  name="displayImage"
                  className="form-control"
                  {...register("displayImage", { required: true })}
                  onChange={(e)=>setImagView(e)}
                />
              </div>
              {imgPreview&&
              <img src={imgPreview} alt="display" width={80} height={80}/>
              }

              {/* <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Tag</label>
                <input
                  type="text"
                  name="tag"
                  className="form-control"
                  placeholder="Enter Tag"
                  {...register("tag", { required: true })}
                />
              </div> */}
                <div className="row mt-2">
        <label style={{ fontWeight: 600 }}>Tag</label>
        <select
          name="tag"
          className="form-control"
          {...register("tag", { required: true })}
        >
          <option value="" >Select Tag</option>
          <option value="Creative">Creative</option>
          <option value="Personal">Personal</option>
          <option value="Digital Marketing">Digital Marketing</option>
          <option value="Brands">Brands</option>
          <option value="Technology">Technology</option>
        </select>
        {errors?.tag && <p style={{ color: 'red' }}>This field is required</p>}
      </div>


              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Heading</label>
                <textarea
                  name="heading"
                  className="form-control"
                  placeholder="Enter Heading"
                  {...register("heading", { required: true })}
                />
              </div>

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="Enter Description"
                  {...register("description", { required: true })}
                />
              </div>

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Creator Image</label>
                <input
                  type="file"
                  name="personImage"
                  className="form-control"
                  {...register("personImage", { required: true })}
                  onChange={(e)=>setPersonImagView(e)}

                />
              </div>
              {personView&&
              <img src={personView} alt="display" width={80} height={80}/>
              }

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Creator Name</label>
                <input
                  type="text"
                  name="personName"
                  className="form-control"
                  placeholder="Enter Person Name"
                  {...register("personName", { required: true })}
                />
              </div>

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Creator Designation</label>
                <input
                  type="text"
                  name="personDesignation"
                  className="form-control"
                  placeholder="Enter  personDesignation"
                  {...register("personDesignation", { required: true })}
                />
              </div>

              {/* <div>
                <img src={imgPreview} width={30} height={30}/>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="common-btn">
              {" "}
              {/* Added type="submit" to the Add Button */}
              Add
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

  
      <Modal show={updateModal} onHide={closeModal}>
        <Modal.Header closeButton className="modal-input-header">
          <Modal.Title>Update Blog</Modal.Title>
        </Modal.Header>
        <form onSubmit={onUpdateSubmit}>
          <Modal.Body className="container modal-input">
            <div className="col">
              <div className="row">
                <label style={{ fontWeight: 600 }}>Dispaly Image</label>
                <input
                  type="file"
                  name="displayImage"
                  className="form-control"
                  onChange={(e) => {
                    setImagePreviewForUpdate(e);
                }}
                />
              </div>
              
              {updateDisplayImg&&
              <img
              src={`${ImageUrl}/${selectedData?.displayImage}`}
              alt="Display"
              width={80}
              height={80}
            />
              }

              {
                updateOldDisplayImg&& <img src={updateOldDisplayImg} alt="display" width={80} height={80}/>
              }


              {/* <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Tag</label>
                <input
                  type="text"
                  name="tag"
                  className="form-control"
                  placeholder="Enter Tag"
                  defaultValue={selectedData?.tag}
                  onChange={(e)=>{ handleChange(e)}}
                />
              </div> */}
              <div className="row mt-2">
            <label style={{ fontWeight: 600 }}>Tag</label>
            <select
              name="tag"
              className="form-control"
              {...register("tag", { required: true })}
              defaultValue={selectedData?.tag || ''} // Set default selected value
            >
              <option value="">Select Tag</option>
              <option value="Creative">Creative</option>
              <option value="Personal">Personal</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Brands">Brands</option>
              <option value="Technology">Technology</option>
            </select>
            {errors?.tag && <p style={{ color: 'red' }}>This field is required</p>}
          </div>




              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Heading</label>
                <textarea
                  name="heading"
                  className="form-control"
                  placeholder="Enter Heading"
                  defaultValue={selectedData?.heading}
                  onChange={(e)=>{ handleChange(e)}}
                />
              </div>

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="Enter Description"
                  defaultValue={selectedData?.description}
                  onChange={(e)=>{ handleChange(e);
                  
                  }}
                />
              </div>

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Creator Image</label>
                <input
                  type="file"
                  name="personImage"
                  className="form-control"
           
                  onChange={(e)=>{setImagePreviewForPersonUpdate(e)
                  }}

                />
              </div>
              {updatePersonImg&&
               <img
               src={`${ImageUrl}/${selectedData?.personImage}`}
               alt="Display"
               width={80}
               height={80}
             />
              }
                  {
                updateOldPersonImg&& <img src={updateOldPersonImg} alt="display" width={80} height={80}/>
              }

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Creator Name</label>
                <input
                  type="text"
                  name="personName"
                  className="form-control"
                  placeholder="Enter Person Name"
                  defaultValue={selectedData?.personName}
                  onChange={(e)=>{ handleChange(e)}}

                />
              </div>

              <div className="row mt-2">
                <label style={{ fontWeight: 600 }}>Creator Designation</label>
                <input
                  type="text"
                  name="personDesignation"
                  className="form-control"
                  placeholder="Enter  personDesignation"
                  defaultValue={selectedData?.personDesignation}
                  onChange={(e)=>{ handleChange(e)}}

                />
              </div>

         
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="common-btn">
              {" "}
              {/* Added type="submit" to the Add Button */}
              Update
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>



       {/* Delete Confirmation Modal */}
       <Modal show={showDeleteModal} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this customer?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={handleDeleteBlog}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
    </>
  );
}

export default ManageDiscount;
