import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import axios from "../../../axios/auth";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import StylesContaent from "./AdminContent.module.css"
import styles from '../sidebar/AdminSidebar.module.css'
import loader from "../images/ZZ5H.gif"

// import  './ManagePlane.css'
function CreditManagment() {
    const token = Cookies.get("token");
    const [userData, setUserData] = useState({
        scanWebsite:"",
        adFlow:"",
        publishAd:"",
        createBrand:"",
        projectCreation:"",
        templateGeneration:"",
        creativeDownload:""
    })


    const [plan, setPlan] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage] = useState(5);
    // const [searchQuery, setSearchQuery] = useState("");
    // const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    // const [formData, setFormData] = useState({
       

    // });

    const [] = useState(null);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updatePlanData, setUpdatePlanData] = useState(null);
    const [deleteCustomerId, setDeleteCustomerId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const openModal = () => {
        setShowUpdateModal(true);
    };


    // const closeModal = () => {
    //     setShowModal(false);
    // };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value
    //     });
    // };

    const handleInputUpdateChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    // const handleAiGenaertionCahnge = (e) => {
    //     const { name, checked } = e.target;

    //     setFormData({
    //         ...formData,
    //         [name]: checked
    //     });

    // }


    // const handleAiGenaertionUpdateCahnge = (e) => {
    //     const { name, checked } = e.target;

    //     setUserData({
    //         ...userData,
    //         [name]: checked
    //     });

    // }

    // submit newData for Update plan

    async function handleUpdate(e) {
        e.preventDefault()
        // Update the plan data
        try {
             await axios.post(`/create-credits`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // toast.success("Plan Updated Successfully");
            toast.success("Plan Updated Successfully", {
                className: 'custom-toast-success',
                hideProgressBar: true
              });
            getPlan();
            closeUpdateModal();
        } catch (error) {
            // console.error("Error updating plan:", error);
            // toast.error("Failed to update plan");
            toast.error("Failed to update plan", {
                className: 'custom-toast-error',
                 hideProgressBar: true
               });
        }

    }




    const getPlan = async () => {
        setIsLoading(true)
        try {
            const res = await axios.get("/get-credits", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPlan(res?.data?.data[0]);
            setUserData(...res?.data?.data)

            setIsLoading(false)
        } catch (error) {
            // console.error("Error fetching plans:", error);
        }
    };

    useEffect(() => {
        getPlan();
    }, [token]);





    // const openUpdateModal = (planData) => {
    //     setUserData(planData)
    //     setShowUpdateModal(true)

    // };


    const closeUpdateModal = () => {
        setUpdatePlanData(null);
        setShowUpdateModal(false);
    };


    // const openDeleteModal = (customerId) => {
    //     setDeleteCustomerId(customerId);
    //     setShowDeleteModal(true);
    // };


    // Function to close the delete confirmation modal
    const closeDeleteModal = () => {
        setDeleteCustomerId(null);
        setShowDeleteModal(false);
    };



    // Function to handle delete customer action
    const handleDeleteCustomer = async () => {
        const token = Cookies.get("token");

         await axios.post(`/delete-plan/${deleteCustomerId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        toast.info('Plan deleted succefully')
        getPlan()
        closeDeleteModal();
    };

    for (const key in plan) {
        if (plan.hasOwnProperty(key)) {
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-sm-2 p-0">

                        <div className={styles.mobileSidebar}>
                            <AdminSidebar className={styles.mobileSidebar} />
                        </div>

                    </div>
                    <div className="col-sm-10 bg-white">
                        <div className="container">
                            <Header title="Add Creative" btnname="Create Campaigns" />
                        </div>
                        <div className="container background-maininput-div-input">
                            <div className="container">
                                <div className="row">
                                    <h4 className="my-4 ps-0">
                                        <b>Manage Credits</b>
                                    </h4>
                                    <hr />

                                    {isLoading ? <div style={{ width: "100%", height: "50vh", position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img src={loader} style={{ width: "100px", height: "100px" }} alt="" /></div> :

                                        <div className="mt-2 p-0">
                                            <div className="container background-second-box-sub-div">
                                            <Button variant="primary" className="common-btn m-2" onClick={openModal}>
                                                                    Update Creits
                                                                </Button>
                                            </div>
                                            <div className={StylesContaent.tableHeading}>
                                                <table class="table table-striped table-bordered text-center">
                                                    <thead>
                                                        <tr>
                                                            <th class="border">Action</th>
                                                            <th class="border">Credits Deduction</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {Object.keys(plan).slice(1, -1).map(key => (
    <tr key={key}>
      <td class="border">{key}</td>
      <td class="border">{plan[key]}</td>
    </tr>
  ))}
                                                    </tbody>
                                             
                                                </table>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="d-flex justify-content-center my-3"
                    style={{ right: 0 }}
                >


              



                    {/* 
//update Plan */}

                    <Modal show={showUpdateModal} onHide={closeUpdateModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Credits</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleUpdate}>
                            <Modal.Body className="container modal-input modal-input-modal-body">
                                <div className="col">
                                    <label style={{ fontWeight: 600 }} className="mb-2 mt-2">Scan Website</label>
                                    <input type="number"
                                        name="scanWebsite"
                                        defaultValue={plan?.scanWebsite}
                                        onChange={handleInputUpdateChange}
                                        placeholder="Enter Credits"
                                        className="form-control" />
                                </div>
                                <div className="col">
                                    <label style={{ fontWeight: 600 }} className="mb-2 mt-2">Ad Flow</label>
                                    <input type="number"
                                        name="adFlow"
                                        defaultValue={plan?.adFlow}
                                        onChange={handleInputUpdateChange}
                                        placeholder="Enter Credits"
                                        className="form-control" />
                                </div>
                                <div className="col">
                                    <label style={{ fontWeight: 600 }} className="mb-2 mt-2">Publish Ad</label>
                                    <input type="number"
                                        name="publishAd"
                                        defaultValue={plan?.publishAd}
                                        onChange={handleInputUpdateChange}
                                        placeholder="Enter Credits"
                                        className="form-control" />
                                </div>
                                <div className="col">
                                    <label style={{ fontWeight: 600 }} className="mb-2 mt-2">Create Brand</label>
                                    <input type="number"
                                        name="createBrand"
                                        defaultValue={plan?.createBrand}
                                        onChange={handleInputUpdateChange}
                                        placeholder="Enter Credits"
                                        className="form-control" />
                                </div>
                                <div className="col">
                                    <label style={{ fontWeight: 600 }} className="mb-2 mt-2">Project Creation</label>
                                    <input type="number"
                                        name="projectCreation"
                                        defaultValue={plan?.projectCreation}
                                        onChange={handleInputUpdateChange}
                                        placeholder="Enter Credits"
                                        className="form-control" />
                                </div>
                                <div className="col">
                                    <label style={{ fontWeight: 600 }} className="mb-2 mt-2">Template Generation</label>
                                    <input type="number"
                                        name="templateGeneration"
                                        defaultValue={plan?.templateGeneration}
                                        onChange={handleInputUpdateChange}
                                        placeholder="Enter Credits"
                                        className="form-control" />
                                </div>
                                <div className="col">
                                    <label style={{ fontWeight: 600 }} className="mb-2 mt-2">Creative Download</label>
                                    <input type="number"
                                        name="creativeDownload"
                                        defaultValue={plan?.creativeDownload}
                                        onChange={handleInputUpdateChange}
                                        placeholder="Enter Credits"
                                        className="form-control" />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="common-btn" type="submit">
                                    Update
                                </Button>
                                <Button variant="secondary" onClick={closeUpdateModal}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>




                    {/* Delete Confirmation Modal */}
                    <Modal show={showDeleteModal} onHide={closeDeleteModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete this customer?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeDeleteModal}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleDeleteCustomer}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
                {/* <ToastContainer /> */}
            </div>
        </>

    );
}

export default CreditManagment;
