import React from 'react'

export default function RightArrowGreen() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.33324 7.99988H12.6666" stroke="#47BEB9" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.99976 3.33325L12.6664 7.99992L7.99976 12.6666" stroke="#47BEB9" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
