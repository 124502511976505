import React, { useEffect } from "react";
import "./generateDetails.css";

import Img1 from "../../../../../../assets/icons/sqpost.svg";
import DefImg1 from "../../../../../../assets/icons/sqpost.png";
import DefImg2 from "../../../../../../assets/icons/landscape.svg";
import Img2 from "../../../../../../assets/icons/hover-img2.svg";
import DefImg3 from "../../../../../../assets/icons/story-img.svg";
import Img3 from "../../../../../../assets/icons/hover-img-3.svg";
import DeLaadImg from "../../../../../../assets/icons/deflt-lead.svg";
import LaadImg from "../../../../../../assets/icons/lead.svg";
import DeLgLaadImg from "../../../../../../assets/icons/Lglead.svg";
import LgLaadImg from "../../../../../../assets/icons/hover-lg-lead.svg";
import DeMDImg from "../../../../../../assets/icons/MD.svg";
import MDImg from "../../../../../../assets/icons/hover-MD.svg";
import DeHPImg from "../../../../../../assets/icons/halfpage.svg";
import HPImg from "../../../../../../assets/icons/hover-hp.svg";
import DeSkyScnnerImg from "../../../../../../assets/icons/hover-skyscannerImg.svg";
import SkyScnnerImg from "../../../../../../assets/icons/skyscannerImg.svg";
import IntegrationIcon from "../../../../../icons/instragramIcon";
import TwitterIcon from "../../../../../icons/twitterIcon";
import LinkdinIcon from "../../../../../icons/linkdinIcon";
import SocialIcon from "../../../../../icons/socialIcon";
import FacebookIcon from "../../../../../icons/facebookIcon";
import SocilaIcon3 from "../../../../../icons/socilaIcon3";
import SocialIcon2 from "../../../../../icons/socialIcon2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addBrandData } from "../../../../../../Redux/brandSetupData";
export default function GenerateDetails({ setGenerationView }) {
  const { editAll } = useSelector((state) => state.addcreative);
  const { convertAll, editOneCreative,brandData } = useSelector(
    (state) => state.brandSetupData
  );
  const { onlyCreativeState, onlyCreativeSize } = useSelector(
    (state) => state.creativeData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (editAll || editOneCreative) {
  //     setGenerationView("B");
  //   }
  //   else if (onlyCreativeSize){
  //       setGenerationView("B");
  //   }

  // }, [editAll, editOneCreative]);

  const handleSelctionSize = (size) => {  
    dispatch(addBrandData({size:size}))
     if (brandData?.convertAll) {
        navigate("/main/generated-selection");
       }
       else{
        setGenerationView("B");

       }

    // dispatch(addBrandData({size:size}))
    // if (onlyCreativeState) {

    //   if (onlyCreativeSize) {
    //     setGenerationView("B");
    //   }

    //   else if(brandData?.convertAll){
    //     navigate("/main/generated-selection");
    //   }
      
    //   else {
    //     navigate("/main/brand-setup");
    //   }
    // } else if (brandData?.convertAll) {
    //   navigate("/main/generated-selection");
    // } else {
    //   setGenerationView("B");
    // }
  };
useEffect(()=>{
if(brandData?.singleEdit?.length>0){
  setGenerationView("B");
}
},[])
  return (
    <div className="generate-details-section">
      <div className="generate-details-alignment">
        <div className="generate-details-grid">
          <div className="generate-left-side">
            <h2>Social Media Size Create Post </h2>
            <p>Most Common sizes for social media advertising</p>
            <div className="social-icon-alignment">
              <IntegrationIcon />
              <TwitterIcon />
              <LinkdinIcon />
              <SocialIcon />
              <FacebookIcon />
            </div>
          </div>

          <div className="generate-right-side">
            <div className="generate-child-grid">
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("1080x1080")}
              >
                <div className="number-alignment">
                  <span>1</span>
                </div>

                <div className="number-img-alignment">
                  <img src={DefImg1} alt="DefImg1" />
                </div>

                <div className="hover-img-alignment">
                  <img src={Img1} alt="Img1" />
                </div>

                <div className="name-name-alignment">
                  <h4>Post Size</h4>
                  <p>1080x1080</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("1200x628")}
              >
                <div className="number-alignment">
                  <span>2</span>
                </div>

                <div className="number-img-alignment">
                  <img src={DefImg2} alt="DefImg2" />
                </div>

                <div className="hover-img-alignment">
                  <img src={Img2} alt="Img2" />
                </div>

                <div className="name-name-alignment">
                  <h4>Landscape Size</h4>
                  <p>1200x628</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("1080x1920")}
              >
                <div className="number-alignment">
                  <span>3</span>
                </div>
                <div className="number-img-alignment">
                  <img src={DefImg3} alt="DefImg3" />
                </div>

                <div className="hover-img-alignment">
                  <img src={Img3} alt="Img3" />
                </div>
                <div className="name-name-alignment">
                  <h4>Story Size</h4>
                  <p>1080x1920</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("1080x1350")}
              >
                <div className="number-alignment">
                  <span>4</span>
                </div>
                <div className="number-img-alignment">
                  <img src={DefImg3} alt="DefImg3" />
                </div>

                <div className="hover-img-alignment">
                  <img src={Img3} alt="Img3" />
                </div>
                <div className="name-name-alignment">
                  <h4>Vertical Size</h4>
                  <p>1080x1350</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("1000x1500")}
              >
                <div className="number-alignment">
                  <span>5</span>
                </div>

                <div className="number-img-alignment">
                  <img src={DefImg3} alt="DefImg3" />
                </div>

                <div className="hover-img-alignment">
                  <img src={Img3} alt="Img3" />
                </div>
                <div className="name-name-alignment">
                  <h4>Pin Size</h4>
                  <p>1000x1500</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="generate-details-grid">
          <div className="generate-left-side">
            <h2>Display Sizes</h2>
            <p>Most Common sizes for display advertising</p>
            <div className="social-icon-alignment">
              <SocialIcon2 />
              <SocilaIcon3 />
              <SocialIcon />
            </div>
          </div>

          <div className="generate-right-side">
            <div className="generate-child-grid">
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("728x90")}
              >
                <div className="number-alignment">
                  <span>1</span>
                </div>
                <div className="number-img-alignment">
                  <img src={DeLaadImg} alt="DeLaadImg" />
                </div>

                <div className="hover-img-alignment">
                  <img src={LaadImg} alt="LaadImg" />
                </div>

                <div className="name-name-alignment">
                  <h4>Leaderboard</h4>
                  <p>728x90</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("970x90")}
              >
                <div className="number-alignment">
                  <span>2</span>
                </div>
                <div className="number-img-alignment">
                  <img src={DeLgLaadImg} alt="LgLaadImg" />
                </div>
                <div className="hover-img-alignment">
                  <img src={LgLaadImg} alt="DeLgLaadImg" />
                </div>

                <div className="name-name-alignment">
                  <h4>Large Leaderboard</h4>
                  <p>970x90</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("300x250")}
              >
                <div className="number-alignment">
                  <span>3</span>
                </div>
                <div className="number-img-alignment">
                  <img src={DeMDImg} alt="DeMDImg" />
                </div>
                <div className="hover-img-alignment">
                  <img src={MDImg} alt="MDImg" />
                </div>

                <div className="name-name-alignment meduim-banner ">
                  <h4>Medium Banner</h4>
                  <p>300x250</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("300x600")}
              >
                <div className="number-alignment">
                  <span>4</span>
                </div>
                <div className="number-img-alignment">
                  <img src={DeHPImg} alt="DeHPImg" />
                </div>

                <div className="hover-img-alignment">
                  <img src={HPImg} alt="HPImg" />
                </div>

                <div className="name-name-alignment">
                  <h4>Half page</h4>
                  <p>300x600</p>
                </div>
              </div>
              <div
                className="generate-box-alignment"
                onClick={() => handleSelctionSize("160x600")}
              >
                <div className="number-alignment">
                  <span>5</span>
                </div>
                <div className="number-img-alignment">
                  <img src={SkyScnnerImg} alt="SkyScnnerImg" />
                </div>
                <div className="hover-img-alignment">
                  <img src={DeSkyScnnerImg} alt="DeSkyScnnerImg" />
                </div>

                <div className="name-name-alignment skyscroper">
                  <h4>wide skyscraper</h4>
                  <p>160x600</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
