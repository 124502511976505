import { useEffect } from "react";
import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import Cookies from "js-cookie";
import axios from "../../../axios/auth";
import StylesContaent from "./AdminContent.module.css";
import styles from "../sidebar/AdminSidebar.module.css";
function ManageCampaign() {
  const userToken = Cookies.get("token");
  const getCustomer = async () => {
    if (userToken) {
      // Now you have the 'token2' value in the 'token' variable.
    } else {
    }

    try {
      const res = await axios.get(
        "/fetch-customer",
        
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0">
            <div className={styles.mobileSidebar}>
              <AdminSidebar className={styles.mobileSidebar} />
            </div>
          </div>
          <div className="col-sm-10 bg-white">
            <div className="container">
              <Header title="Add Creative" btnname="Create Campaigns" />
            </div>
            <div className="container">
              <div className="container">
                <div className="row">
                  <h4 className=" my-4 ps-0">
                    <b> Manage Campaign </b>
                  </h4>
                  <hr />

                  <div className={StylesContaent.tableHeading}>
                    <table className="table table-striped table-border text-center">
                      <tr>
                        <th className="border"> Customer Name </th>
                        <th className="border">Campaign ID</th>
                        <th className="border"> Campaign Name</th>

                        <th className="border">Action</th>
                      </tr>
                      <tr>
                        <td className="border"> Customer Name </td>
                        <td className="border">Campaign ID</td>
                        <td className="border"> Campaign Name</td>

                        <td className="border">
                          <div className="dropdown">
                            <button
                              className="btn common-btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Action
                            </button>

                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#viewCampaign"
                                >
                                  View
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal Start */}
        <div
          className="modal fade"
          id="viewCampaign"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  <h4 className=" my-4 ps-0">
                    <b> Manage Plan </b>
                  </h4>
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                {" "}
                <div className="container-fluid">
                  <div className="row">
                    <div className="mt-2 p-0">
                      <table className="table table-striped table-border text-center">
                        <tr>
                          <th className="border">Ad No</th>
                          <th className="border">Ad Name</th>
                          <th className="border">Ad ID </th>
                          <th className="border"> Ad Platform</th>
                          <th className="border"> Ad Budget</th>
                          <th className="border"> Ad Start Date</th>
                          <th className="border">Ad End Dates</th>
                          <th className="border"> DemoGraphics Selected</th>
                          <th className="border"> Language Selected </th>
                          <th className="border"> Headline-1</th>
                          <th className="border"> Headline-2 </th>
                          <th className="border"> Description-1 </th>
                          <th className="border"> Description-2 </th>
                          <th className="border"> Impression </th>
                          <th className="border"> Visits </th>
                          <th className="border"> Action </th>
                        </tr>
                        <tr>
                          <td className="border">01</td>
                          <td className="border">Ad Name</td>
                          <td className="border">Ad ID </td>
                          <td className="border"> Ad Platform</td>
                          <td className="border"> Ad Budget</td>
                          <td className="border"> Ad Start Date</td>
                          <td className="border">Ad End Dates</td>
                          <td className="border"> DemoGraphics Selected</td>
                          <td className="border"> Language Selected </td>
                          <td className="border"> Headline-1</td>
                          <td className="border"> Headline-2 </td>
                          <td className="border"> Description-1 </td>
                          <td className="border"> Description-2 </td>
                          <td className="border"> Impression </td>
                          <td className="border"> Visits </td>
                          <td className="border">
                            <div className="dropdown">
                              <button
                                className="btn common-btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Action
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Add
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Update
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn common-btn">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ManageCampaign;
