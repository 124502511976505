import React from 'react'
import "./colorSelectionPopup.css";
import AngleImg from "../../../../../../assets/images/angle-img.svg";
import { data } from './dataColor';
// import { useDispatch } from 'react-redux';
// import { logoColorPikedFun } from '../../../../../../Redux/addCreateSlice';
export default function ColorSelectionPopup({ setSelectColorModal
,color1,color2,color3,
setSelectedColor1,setSelectedColor2,setSelectedColor3,ctaColor
}) {
// const [selectColor,setSelectColor]=useState()
// const dispatch=useDispatch()
const handleSelectColor=(colors)=>{
setSelectedColor1(colors[0])
setSelectedColor2(colors[1])  
setSelectedColor3(colors[2])
    // dispatch(logoColorPikedFun(colors))
}

const mainBgColor =(e)=>{
setSelectedColor1(e)
}
const headingColor =(e)=>{
    setSelectedColor2(e)
    }
    const descriptionColor =(e)=>{
        setSelectedColor3(e)
        }
// useEffect(()=>{  

// },[selectColor])
    return (
        <div className="color-selection-popup-section">
            <div className="color-selection-popup-box">
                <div className="color-selection-heading-alignment">
                    <h2>Recommend & Preview</h2>
                    <p>Experiment with the color palette suggested by A.I.</p>
                </div>


                <div className="color-section-card-details">

                    <div className="color-section-card-box">
                        <div className="color-selection-child-box-details">
                            <div className="color-selection-child-box">
                                <img src={AngleImg} alt="AngleImg" />
                            </div>
                        </div>
                        <div className="color-section-all-details-alignment">
                            <div className="text-alignment">
                                <h6 style={{color:color2}}>Your main headline here!</h6>
                                <p style={{color:color3}}>Your main punchline is here!</p>
                            </div>
                            <div className='call-action-button' style={{backgroundColor:"unset"}}>
                                <button style={{color:ctaColor}}>Call to action</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="color-section-body-alignment">
                    <div className="color-section-body-grid">
                        <div className="left-box-alignment">
                            <p>
                                Your Color
                            </p>
                        </div>
                        <div className="color-box-alignment">
                            <div className="color-box-grid " >

                                <div className="color-selected-box" style={{backgroundColor:color1}}>
                                   {/* <p>{color1}</p>*/}
                                   <input type='color' onChange={(e)=>mainBgColor(e.target.value)}  value={color1} style={{width:"100%",height:"100%",borderRadius:"10px"}} />
                                </div>
                                <div className="color-selected-box" style={{backgroundColor:color2}}>
                                  {/*  <p>{color2}</p> */}
                                  <input type='color' onChange={(e)=>headingColor(e.target.value)}  value={color2} style={{width:"100%",height:"100%",borderRadius:"10px"}} />
                                </div>
                                <div className="color-selected-box" style={{backgroundColor:color3}}>
    {/*  <p>{color3}</p>*/}  <input type='color' onChange={(e)=>descriptionColor(e.target.value)}  value={color3} style={{width:"100%",height:"100%",borderRadius:"10px"}} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="color-section-body-grid">
                        <div className="left-box-alignment recommedition">
                            <p>
                                Recommendations
                            </p>
                        </div>
                        <div className="multi-color-picker-alignment">
                            <div className="multi-color-grid">
                                {data.map((palette,index) => {
                                    return (

                                        <div className="multi-color-box" onClick={()=>handleSelectColor(palette)}>
                                            <div className="child-box-alignment" style={{backgroundColor:palette[0]}}>

                                            </div>
                                            <div className="child-box-alignment" style={{backgroundColor:palette[1]}}>

                                            </div>
                                            <div className="child-box-alignment" style={{backgroundColor:palette[2]}}>

                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="color-section-bottom-alignment" onClick={() => setSelectColorModal(false)}>
                    <button>Save</button>
                </div>
            </div>
        </div>
    )
}
