import React from 'react'

export default function EditIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_247_478)">
                <path d="M14.161 2.49949C14.3799 2.2806 14.6397 2.10697 14.9257 1.98851C15.2117 1.87005 15.5182 1.80908 15.8278 1.80908C16.1373 1.80908 16.4439 1.87005 16.7298 1.98851C17.0158 2.10697 17.2757 2.2806 17.4946 2.49949C17.7135 2.71838 17.8871 2.97823 18.0056 3.26422C18.124 3.55021 18.185 3.85673 18.185 4.16628C18.185 4.47584 18.124 4.78236 18.0056 5.06835C17.8871 5.35434 17.7135 5.61419 17.4946 5.83308L6.24372 17.0839L1.66003 18.334L2.91013 13.7503L14.161 2.49949Z" stroke="white" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_247_478">
                    <rect width="20.0017" height="20.0017" fill="white" transform="translate(-0.00683594 -0.000976562)" />
                </clipPath>
            </defs>
        </svg>

    )
}
