import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Col } from "react-bootstrap";
import { addBrandData } from "../../../../../Redux/brandSetupData";
import { useDispatch, useSelector } from "react-redux";

const PixabayImageSearch = ({ setSearchImgesVIew, setGenerationView }) => {
  const { brandData } = useSelector((state) => state?.brandSetupData);
  const [currentPage, setCurrentPage] = useState(1);
  const [images, setImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState(brandData?.searchQuery ?? "Marketing");
  const [loader, setLoader] = useState(false);
  const apiKey = "41195581-a6a30be41ff9bef3f4ca1e8a0";
  const apiUrl = "https://pixabay.com/api/";
  const imageType = "photo";
  const dispatch = useDispatch();
  let typingTimer;

  // Function to upload the selected Pixabay image
  const uploadPixabaseImage = async (picUrl) => {
    setLoader(true);
    dispatch(addBrandData({ mainImage: picUrl }));
    setLoader(false);
    setSearchImgesVIew(false);
    setGenerationView("C");
  };

  // Fetch data from Pixabay API
  const fetchData = useCallback(
    (page = 1) => {
      setLoader(true);
      axios
        .get(apiUrl, {
          params: {
            key: apiKey,
            q: searchQuery,
            image_type: imageType,
            page: page,
          },
        })
        .then((response) => {
          const hits = response.data.hits;
          if (hits.length > 0) {
            setImages((prevImages) => {
              return page === 1 ? [...hits] : [...prevImages, ...hits];
            });
          } else {
            setImages([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching data from Pixabay API:", error);
        })
        .finally(() => {
          setLoader(false);
        });
    },
    [searchQuery]
  );

  useEffect(() => {
    fetchData();
    return () => {
      setImages([]);
    };
  }, []);

  const LoadMoreData = (page) => {
    setLoader(true);
    axios
      .get(apiUrl, {
        params: {
          key: apiKey,
          q: searchQuery,
          image_type: imageType,
          page: page,
        },
      })
      .then((response) => {
        if (response.data.hits.length > 0) {
          setImages((prevImages) => [...prevImages, ...response.data.hits]);
          setCurrentPage(page);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Pixabay API:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleSearchQuery = (e) => {
    setSearchQuery(e.target.value);
    dispatch(addBrandData({ searchQuery: e.target.value }));


  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    LoadMoreData(nextPage);
  };

  const spinnerStyle = {
    border: "8px solid #f3f3f3", /* Light grey */
    borderTop: "8px solid #3498db", /* Blue */
    borderRadius: "50%",
    width: "60px", /* Adjust size as needed */
    height: "60px", /* Adjust size as needed */
    animation: "spin 1s linear infinite",
    margin: "20px auto", /* Center the spinner */
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div className="container">
      <style>{keyframesStyle}</style>
      <div className="stockImageSearchMain">
        <h1
          className=""
          style={{
            fontSize: "24px",
            fontFamily: "Montserrat Alternates",
            color: "#546881",
          }}
        >
          Image Search
        </h1>

        <div className="form-group">
          <div className="stockImageSearchSub">
            <div>
              <input
                type="text"
                id="searchQuery"
                className="form-control"
                placeholder="Search Image"
                value={searchQuery}
                onChange={handleSearchQuery}
              />
            </div>
            <button
              onClick={() => fetchData(1)}
              className="btn btn-primary"
              style={{
                background: "#358f8b",
                border: "#358f8b",
                fontSize: "12px",
              }}
            >
              Search Images
            </button>
          </div>
        </div>
        <div className="">
          {images.length > 0 && (
            <button
              onClick={loadMore}
              id="loadMoreBtn"
              className="btn btn-secondary"
              style={{
                background: "#358f8b",
                border: "#358f8b",
                fontSize: "12px",
              }}
            >
              Load More
            </button>
          )}
        </div>
      </div>

      <Col style={{ padding: "0px" }}>
        <div className="background-images-carosole">
        {loader && <div style={spinnerStyle}></div>} 
          {!loader &&
            images.map((pic, i) => (
              <div className="background-img-div" key={i}>
                <img
                  className="background-Img"
                  src={pic.previewURL} // Use a lower-resolution image for initial load
                  alt=""
                  loading="lazy"
                  onClick={() => uploadPixabaseImage(pic.webformatURL)}
                />
              </div>
            ))}
        </div>
      </Col>
    </div>
  );
};

export default PixabayImageSearch;
