import React from 'react'

export default function UploadFolder() {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M36.2334 16.2334H3.33337V11.2C3.33337 7.13337 6.63337 3.83337 10.7 3.83337H14.5834C17.3 3.83337 18.15 4.71671 19.2334 6.16671L21.5667 9.26671C22.0834 9.95004 22.15 10.05 23.1167 10.05H27.7667C31.7167 10.0334 35.0834 12.6334 36.2334 16.2334Z" fill="white" />
            <path d="M36.65 18.5666C36.6167 17.7499 36.4834 16.9833 36.2334 16.2333H3.33337V28.2499C3.33337 33.1666 7.33337 37.1666 12.25 37.1666H27.75C32.6667 37.1666 36.6667 33.1666 36.6667 28.2499V18.9499C36.6667 18.8333 36.6667 18.6833 36.65 18.5666ZM24.2334 27.1166L20.65 30.2499C20.4667 30.4166 20.2334 30.4999 20 30.4999C19.7667 30.4999 19.5334 30.4166 19.35 30.2499L15.7667 27.1166C14.6334 26.1166 14.4834 24.4166 15.4167 23.2333C16.3834 22.0333 18.0834 21.8166 19.3167 22.7333L19.9834 23.2333L20.65 22.7333C21.8834 21.8166 23.5834 22.0333 24.55 23.2333C25.5167 24.4166 25.3667 26.1166 24.2334 27.1166Z" fill="white" />
        </svg>

    )
}
