import React from "react";
import BrandWatch from "../../Images/BrandWatch.png";
import BrandWatchFriday from "../../Images/BrandWatchFriday.png";

import AiStyle from "./AiCreative.module.css";

const Freelancer = () => {
  const ImageData = [BrandWatchFriday, BrandWatch];
  return (
    <div
      style={{ color: "#000" }}
      className={AiStyle.AicreativeGraphicDesigner}
    >
      {ImageData.map((pic) => (
        <img style={{ width: "500px" }} src={pic} alt="BrandWatch" />
      ))}
    </div>
  );
};

export default Freelancer;
