import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSearch, faSignOut, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import "./header.css"
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import axios from "../../../axios/auth"
import AdminSidebar from '../sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react';
// import { Axios } from 'axios';
import logo from "../images/Logo.png"

function Header({ title, btnname }) {
    const token = Cookies.get("token");
    const [open,setOpen] = useState(false)
    const [notification,setNotification] = useState([])
    const navigate = useNavigate();

    const logout = async () => {
        try {
             await axios.get("/logout",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            Cookies.remove('token');
            navigate("/login")
        } catch (error) { }
    }

    const openBox = () => {
        setOpen(!open);
        fetchData()
    }
    
    async function fetchData() {
        try {
          const res = await axios.get("/getNotification", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setNotification(res?.data?.data);
        } catch (error) {
          console.error("Error fetching customer:", error);
        }
      }
      useEffect(() => {
        fetchData();
      }, [token]);


    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-white mt-2 ">
                <div className="container-fluid bg-white py-2 px-3 headerbar shadow">
                   <img src={logo} className='navbar-brand' alt='' style={{width:100,height:50}}/>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                               
                            </li>
                        </ul>

                        <div className='ms-3'>
                            <button className='btn btn-primary' onClick={openBox} style={{ backgroundColor: "#47beb9", border: "none" }}><FontAwesomeIcon icon={faBell} /> </button>
                        </div>
                        {/* <div className='ms-3'>
                            <button className='btn btn-primary' style={{ backgroundColor: "#47beb9", border: "none" }}><FontAwesomeIcon icon={faSignOutAlt} /> </button>
                        </div> */}
                        <div className='ms-3'>
                            <button className='btn btn-primary' style={{ backgroundColor: "#47beb9", border: "none" }} onClick={() => { logout() }}>  Logout </button>
                        </div>

                        <div className='d-sm-none'>
                            <AdminSidebar className="mobileSidebarShow" />
                        </div>
                    </div>
                </div>
            </nav>

          {open&&  <div className="notification-div" style={{height:"60vh",overflow:"scroll"}}>
          {notification?.slice(0, 20).map((a, index) => {
    return (
        <React.Fragment key={index}>
            <p><FontAwesomeIcon icon={faBell} /> &nbsp; {a?.notificationDescription}</p>
            <hr/>
        </React.Fragment>
    );
})}
               
                {/* Add your notification content here */}
            </div>}
        </>
    )
}

export default Header;
