import React, { useEffect } from 'react'
import "./NewBrand.css";
import BrandList from './brandlist';
import BrandBottom from './brandbottom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export default function NewBrand() {
const {brand}=useSelector((state)=>state.brandList)
// const {brandData} =useSelector((state)=>state?.brandSetupData)

const navigate = useNavigate()  
  useEffect(()=>{
if(!brand?.length>0){
navigate("/main/brand")
}
  },[brand])


  return (
    <div className="brands-section">
      <div className="brand-heading">
        <h1>Brands List</h1>
        <p>See your projects and create new ones under the selected brand.</p>
      </div>
      <BrandList  />
    <BrandBottom /> 
      
    </div>
  )
}
