import { createSlice } from "@reduxjs/toolkit";
const initialState = { GoogleAnalytics: null, MetaAnalytics: "", BingAnalytics: "" ,SingleGoogleAdDetail:null};
const AdAnalyticsSlice = createSlice({
    name: "AdAnalytics",
    initialState,
    reducers: {
        addGoogleAnalytics: (state, action) => {
            state.GoogleAnalytics = action.payload;
        },
        addMetaAnalytics: (state, action) => {
            state.MetaAnalytics = action.payload;
        },
        addBingAnalytics: (state, action) => {
            state.BingAnalytics = action.payload;
        },
        addSingleGoogleAnalyticsDetail: (state, action) => {
            state.SingleGoogleAdDetail = action.payload;
        },
    },
});

export const { addBingAnalytics, addGoogleAnalytics, addMetaAnalytics,addSingleGoogleAnalyticsDetail } = AdAnalyticsSlice.actions;
export default AdAnalyticsSlice.reducer;
