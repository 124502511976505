import React, { createContext, useState } from 'react';

// Create a new context
const DataContext = createContext();

// Create a provider for the context
const DataProvider = ({ children }) => {
  // The data to be passed to the child components
  const [data, setData] = useState(' Welcome');

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}    
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
