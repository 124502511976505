import React from "react";
import robo from "../../Images/robot.png";
const WhyExoad = () => {
  return (
    <div className="landingpage-whyExoad">
      <div className="landingpage-whyExoad-text">
        <h5>Who is EXOAD ?</h5>
        <h3>
          Your Friendly Guide to Advertising Awesomeness
          <br />
          <span>Elevate Ads, Instant Results.</span>
        </h3>
        <p>
          Tired of ads that blend into the background? Exo Ad Whiz is here to
          shake things up! Our AI assistant is your friendly guide to creating
          ads that capture attention, spark conversations, and drive results.
        </p>
      </div>
      <img className="landingpage-whyExoad-img " src={robo} alt="robot" />
      <div style={{ width: "100%" }}></div>
    </div>
  );
};

export default WhyExoad;
