import axios from "../../../component/axios/auth";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";

const TermsAndCondition = () => {
  const [content, setContent] = useState();
  useEffect(() => {
    getPrivacyPolicy();
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getPrivacyPolicy = async () => {
    try {
      const res = await axios.get("/get_term_and_condtion");
      setContent(res?.data?.data[0]?.termsAndCondition);
    } catch (error) {
    }
  };

  return (
    <Container fluid style={{ padding: "0px" }}>
      <Row style={{ color: "#000", padding: "75px 70px" }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Row>
    </Container>
  );
};

export default TermsAndCondition;
