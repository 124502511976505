import React from 'react'
import "./searchImges.css";
import ImageIcon from '../../../../../icons/imageIcon';
import LivePreview from '../../../../../icons/livePreview';
import SelectImg from "../../../../../../assets/images/select-img.png"
import SaveIcon from '../../../../../icons/saveIcon';
import PixabayImageSearch from '../../StockImages/StockImages';
export default function SearchImges({setGenerationView,setSearchImgesVIew}) {


    return (
        <div className=
            "search-imges-section">
            <div className="search-imges-heading">
                <div className="search-img-box" onClick={ ()=>{setSearchImgesVIew(false);setGenerationView("C")}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19 11.9993H5" stroke="#FFFFFF" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M12 18.9995L5 11.9995L12 4.99951" stroke="#FFFFFF" stroke-width="1.66678" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              
                </div>
                <h2 >Background Image </h2>
            </div>

            <div className="search-images-main-details-alignment">
                <h3>Select image for your
                    Creative</h3>
            </div>

            <div className="stock-main-images-alignment">
                <div className="child-heading-alignment">
                    <LivePreview />
                    <h4>Stock Images</h4>
                </div>


                <PixabayImageSearch setGenerationView={ setGenerationView} setSearchImgesVIew={setSearchImgesVIew} />
               {/* <div className="all-images-alignment">
                    {[0, 1, 2].map(() => {
                        return (

                            <div className="imagesBoxALignment">
                                <img src={SelectImg} alt="SelectImg" />
                            </div>
                        )
                    })}
                </div>  */} 
            </div>

            {/* <div className="save-just-alignment">
                <SaveIcon />
                <p>Saved Just Now</p>
            </div> */}
        </div>
    )
}
