import React, { useState } from 'react'
import "./deleteModal.css";
import { toast } from 'react-toastify';
import axios from 'axios';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { nodeUrl } from '../../../../../axios/auth';
import CloseIcon from "../../../../../../assets/icons/close.png"

export default function DeleteModal({ setDeleteModal }) {

    const [inputText, setinputtext] = useState("")
    const navigate = useNavigate()
    const onHandleChange = (e) => {
        setinputtext(e.target.value)

        if (e.target.value !== "Delete") {


            if (e.target.value === "") {
                let ptag = document.getElementById('paraTag');
                ptag.style.visibility = "hidden"
            }
            else {
                let ptag = document.getElementById('paraTag');
                ptag.style.visibility = "visible"
            }
        }



        else {

            let ptag = document.getElementById('paraTag');
            ptag.style.visibility = "hidden"
        }
    }

    const handlSubmit = async () => {


        if (inputText === "Delete") {
            let ptag = document.getElementById('paraTag');
            ptag.style.visibility = "hidden"

            const token = Cookies.get("token");
            try {
                const res = await axios.get(
                    `${nodeUrl}/delete-user`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );


                if (res?.data?.status === true) {
                    navigate("/login")
                    Cookies.remove("token")
                    setDeleteModal(false)
                    // toast.success("Account Delete Successfully");
                    toast.success("Account Delete Successfully", {
                        className: 'custom-toast-success',
                        hideProgressBar: true
                      });
                }
                else {
                    // toast.error("some thing went wrong");
                      toast.error("some thing went wrong", {
                        className: 'custom-toast-error',
                         hideProgressBar: true
                       }); 
                }

            } catch (error) { }




        } else {
            let ptag = document.getElementById('paraTag');
            ptag.style.visibility = "visible"
        }
    }



    return (
        <div className="delete-modal-section">
            <div className="delete-modal-box">
                <div className='delete-modal-heaading'>
                    <h4>Delete Account?</h4>
                    <p>You are going to delete your account permanently. If you proceed further the following will happen:</p>
              
                 
                </div>
                <div className="close-icon-dele" 
                onClick={() => setDeleteModal(false)}
                >
                        <img src={CloseIcon} alt="CloseIcon" />
                    </div>
               
                <div className="delete-modal-body-alignment">

                    <div className="delete-modal-list-alignment">
                        <ul>
                            <li>All your data will be deleted permanently.</li>
                            <li>All your ads will stop</li>
                            <li>Your plan subscription will be canceled</li>
                            <li>Remaining credits will be refunded</li>
                        </ul>
                    </div>

                    <div className="confirmation-details-alignment">
                        <label>Confirmation</label>
                        <input type="text"
                            onChange={(e) => onHandleChange(e)}
                            placeholder='Type Delete to confirm' />
                        <p id='paraTag' style={{ visibility: "hidden", color: "red", marginTop: "2px" }}>Please enter correct details.</p>
                    
                    </div>
                </div>

                <div className='delete-modal-bottom-alignment'>
                <button onClick={() => setDeleteModal(false)}>Don’t Delete</button>

<button onClick={handlSubmit}>Delete Account</button>
                </div>
            </div>
        </div>
    )
}
