export const EditIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.9999 4H3.99998C3.46955 4 2.96085 4.21071 2.58578 4.58578C2.21071 4.96085 2 5.46955 2 5.99998V19.9999C2 20.5303 2.21071 21.039 2.58578 21.4141C2.96085 21.7891 3.46955 21.9998 3.99998 21.9998H17.9999C18.5303 21.9998 19.039 21.7891 19.4141 21.4141C19.7891 21.039 19.9998 20.5303 19.9998 19.9999V12.9999"
      stroke="#546881"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.4998 2.49949C18.8976 2.10167 19.4371 1.87817 19.9998 1.87817C20.5624 1.87817 21.1019 2.10167 21.4998 2.49949C21.8976 2.89732 22.1211 3.43689 22.1211 3.99949C22.1211 4.5621 21.8976 5.10167 21.4998 5.49949L11.9998 14.9995L7.99976 15.9995L8.99976 11.9995L18.4998 2.49949Z"
      stroke="#546881"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const GreenTick = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    // className="activeSvg"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M15.9998 2.66663C8.65317 2.66663 2.6665 8.65329 2.6665 16C2.6665 23.3466 8.65317 29.3333 15.9998 29.3333C23.3465 29.3333 29.3332 23.3466 29.3332 16C29.3332 8.65329 23.3465 2.66663 15.9998 2.66663ZM22.3732 12.9333L14.8132 20.4933C14.6257 20.6806 14.3715 20.7857 14.1065 20.7857C13.8415 20.7857 13.5873 20.6806 13.3998 20.4933L9.6265 16.72C9.44053 16.5318 9.33623 16.2779 9.33623 16.0133C9.33623 15.7487 9.44053 15.4948 9.6265 15.3066C10.0132 14.92 10.6532 14.92 11.0398 15.3066L14.1065 18.3733L20.9598 11.52C21.3465 11.1333 21.9865 11.1333 22.3732 11.52C22.7598 11.9066 22.7598 12.5333 22.3732 12.9333Z"
      fill="#03BB63"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#03BB63" />
  </svg>
);
