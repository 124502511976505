import React, { useEffect, useState } from "react";
import AdminSidebar from "../sidebar/AdminSidebar";
import Header from "../header/Header";
import styles from "../sidebar/AdminSidebar.module.css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import Cookies from "js-cookie";
import axios from "../../../axios/auth";
import {  toast } from "react-toastify";
import loader from "../images/ZZ5H.gif" 

const Privacy = () => {
    const [content, setContent] = useState('');
    const [isLoading,setIsLoading] = useState(false)

    const handleChange = (value) => {
        setContent(value);
      };

      const token = Cookies.get("token");
    
  
      async function fetchData() {
        setIsLoading(true)
        try {
          const res = await axios.get("/get_privacy_policy", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setContent(res?.data?.data[0]?.privacy);
          setIsLoading(false)
        } catch (error) {
          // console.error("Error fetching customer:", error);
        }
      }
      useEffect(() => {
        fetchData();
      }, [token]);
    
  
    const handleSave = async () =>{
      try {
         const res = await axios.post('/create-privacy_policy',{privacy:content},
         {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
         )
         if(res.status===200){
          // toast.success("Content Updated succesfully")
          toast.success("Content Updated succesfully", {
            className: 'custom-toast-success',
            hideProgressBar: true
          });
         }
      } catch (error) {
        // toast.error("Something went wrong")
        toast.error("Something went wrong", {
          className: 'custom-toast-error',
          hideProgressBar: true
        });
        
      }
    }
    const modules = {
      toolbar: [
        [{ 'font': [] }, { 'size': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'super' }, { 'script': 'sub' }],
        [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }, { 'align': [] }],
        ['link', 'image', 'video'],
        ['clean'] // Remove formatting button
      ]
    };
  
    return (
        <>
        <div className="container-fluid">
        <div className="row ">
          <div className="col-sm-2 p-0">

          <div className={styles.mobileSidebar}>
              <AdminSidebar className={styles.mobileSidebar} />
            </div>

          </div>
          <div className="col-sm-10 bg-white">
            <div className="container">
              <Header title="Add Creative" btnname="Create Campaigns" />
            </div>
            <div className="container background-maininput-div-input">
              <div className="container">
                <div className="row">
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                  <h4 className="my-4 ps-0">
                    <b>Manage Privacy Policies</b>
                  </h4>
                  <button style={{height:"50px",background:"rgb(71, 190, 185)",color:"white"}} className="btn " onClick={handleSave}>Save</button>
                  </div>
                  <hr />
                  {isLoading?<div style={{width:"100%",height:"50vh", position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <img src={loader} style={{width:"100px",height:"100px"}} alt="" /></div>: 
            <div className="container background-maininput-div-input">
            <ReactQuill 
      style={{ width: '100%' }} 
      theme="snow" 
      value={content} 
      onChange={handleChange} 
      modules={modules} 
    />
    </div>
}
    </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-center my-3"
          style={{ right: 0 }}
        >
      



   </div>
   </div>
   </div>
        </>
    );
};

export default Privacy;
