import React from 'react'

export default function ColorPicker() {
    return (
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8594 12.1688L7.16719 7.47656C4.64062 10.0031 3.09375 13.5094 3.09375 17.3766H9.69375C9.69375 15.3141 10.5188 13.5094 11.8594 12.1688Z" fill="#00B76C" />
            <path d="M8.35352 17.3766H9.69414C9.69414 15.3656 10.5191 13.5094 11.8598 12.2203L11.241 11.6016C9.48789 12.8906 8.35352 15.0047 8.35352 17.3766Z" fill="#00A05B" />
            <path d="M9.69375 17.3766H3.09375C3.09375 21.2438 4.64062 24.6985 7.16719 27.2251L11.8594 22.5329C10.5188 21.1923 9.69375 19.3876 9.69375 17.3766Z" fill="#546F7A" />
            <path d="M8.35352 17.3765C8.35352 19.7483 9.48789 21.8108 11.241 23.1515L11.8598 22.5327C10.5191 21.1921 9.69414 19.3874 9.69414 17.3765H8.35352Z" fill="#475D63" />
            <path d="M11.8602 22.5327L7.16797 27.2249C9.69453 29.7515 13.1492 31.2983 17.0164 31.2983V24.6468C15.0055 24.6468 13.1492 23.8218 11.8602 22.5327Z" fill="#3D4FF9" />
            <path d="M15.623 24.5952H15.984C14.3855 24.389 12.9418 23.6155 11.859 22.5327L11.2402 23.1515C12.4777 24.028 13.973 24.5952 15.623 24.5952Z" fill="#3046C1" />
            <path d="M17.0156 24.647V31.247C20.8828 31.247 24.3375 29.7001 26.8641 27.1735L22.1719 22.5329C20.8828 23.822 19.0266 24.647 17.0156 24.647Z" fill="#39A4FA" />
            <path d="M17.0156 31.1952V31.2468C20.8828 31.2468 24.3375 29.6999 26.8641 27.1734L26.1422 26.4515C23.8734 29.0812 20.625 30.8343 17.0156 31.1952Z" fill="#0091E2" />
            <path d="M24.3375 17.5312C24.3375 19.5422 23.5125 21.3984 22.1719 22.6875L26.8641 27.3797C29.3906 24.8531 30.9375 21.3469 30.9375 17.5312H24.3375Z" fill="#8B41F7" />
            <path d="M29.5457 17.3765C29.5457 20.8827 28.2566 24.028 26.1426 26.503L26.8645 27.2249C29.391 24.6983 30.9379 21.1921 30.9379 17.3765H29.5457Z" fill="#7539D3" />
            <path d="M24.3375 17.3766H30.9375C30.9375 13.5094 29.3906 10.0548 26.8641 7.5282L22.1719 12.1688C23.5125 13.5094 24.3375 15.3141 24.3375 17.3766Z" fill="#FF434D" />
            <path d="M29.5457 17.3768H30.9379C30.9379 13.5096 29.391 10.0549 26.8645 7.52832L26.1426 8.2502C28.2566 10.6736 29.5457 13.8705 29.5457 17.3768Z" fill="#DD3646" />
            <path d="M22.1719 12.1688L26.8641 7.47665C24.3375 5.00165 20.8828 3.45477 17.0156 3.45477V10.0548C19.0266 10.0548 20.8828 10.8798 22.1719 12.1688Z" fill="#FF6137" />
            <path d="M26.1422 8.25009L26.8641 7.52821C24.3375 5.00165 20.8828 3.45477 17.0156 3.45477V3.50634C20.625 3.86727 23.8734 5.6204 26.1422 8.25009Z" fill="#E04F32" />
            <path d="M17.0164 10.0546V3.45459C13.1492 3.45459 9.69453 5.00147 7.16797 7.52803L11.8602 12.2202C13.1492 10.8796 15.0055 10.0546 17.0164 10.0546Z" fill="#FFD039" />
            <path d="M15.623 10.1064C13.973 10.1064 12.4777 10.6736 11.2402 11.5502L11.859 12.1689C12.9418 11.0861 14.3855 10.3127 15.984 10.1064H15.623Z" fill="#FFAE47" />
        </svg>

    )
}
