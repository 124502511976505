import React from "react";
import "./Testing.css";
import LoadingTextAnimation, {
  AILoaderSvg,
  loadertext,
} from "./LoadingAnimation";
import AiLoadingBot from "../component/account/Main Pages/Images/AiLoadingBot.png";
import { useEffect } from "react";

const TestingPage = () => {
  useEffect(() => {
    loadertext();
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        position: "relative", // Added position property
        backdropFilter: "blur(1px)", // Adjusted blur value
        // background: "transparent",
        zIndex: "100",
        background: "rgba(0, 0, 0, 0.3)",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "250px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#edf9f8",
          borderStartStartRadius: "50%",
          borderStartEndRadius: "50%",
        }}
      >
        <div class="text-container" style={{ position: "relative" }}>
          <img
           className="botLoader"
            src={AiLoadingBot}
            alt="AiBot"
          />
          <LoadingTextAnimation />
          <div className="AI-Loader-Text ">
            {AILoaderSvg}
            <p>Follow the magic wand to find your suggestions...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingPage;
