export const InfoSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M10.0005 18.3338C14.6032 18.3338 18.3344 14.6026 18.3344 9.99986C18.3344 5.39714 14.6032 1.66589 10.0005 1.66589C5.39775 1.66589 1.6665 5.39714 1.6665 9.99986C1.6665 14.6026 5.39775 18.3338 10.0005 18.3338Z"
      stroke="#546881"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0005 13.3335H10.0105"
      stroke="#546881"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0005 6.66626V10.0003"
      stroke="#546881"
      stroke-width="1.66678"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
