export const data = [
  ["#F7CAC9", "#A6A5A1", "#5A5858"],
  ["#8A7A59", "#B6985A", "#D9BF77"],
  ["#006994", "#008C9E", "#00AAB5"],
  ["#FFD1DC", "#FFABAB", "#FFC3A0"],
  ["#E63946", "#F1FAEE", "#A8DADC"],
  ["#D9BF77", "#D08159", "#6A0572"],
  ["#1B4332", "#2D6A4F", "#66CC99"],
  ["#FFD700", "#FFA500", "#FF4500"],
  ["#FF0000", "#FF7F00", "#FFFF00"],
  ["#2E282A", "#635DFF", "#9C92AC"],
  ["#F4A261", "#E76F51", "#2A9D8F"],
  ["#D8A47F", "#8E8D8A", "#9D9B97"],
  ["#FFFFFF", "#000000", "#C2F7F7"],
  ["#96E1D4", "#F4A261", "#E76F51"],
  ["#FF6B6B", "#FFE66D", "#98C1D9"],
  ["#F76D57", "#FFD166", "#4A4E4D"],
  ["#FF6B6B", "#F76D57", "#EA9999"],
  ["#C2DFFF", "#8DB0CC", "#3D509A"],
  ["#FFDAC1", "#B7D1E5", "#A2A8D3"],
  ["#1B4332", "#2D6A4F", "#66CC99"],
  ["#F7CAC9", "#A6A5A1", "#5A5858"],
  ["#8A7A59", "#B6985A", "#D9BF77"],
  ["#006994", "#008C9E", "#00AAB5"],
  ["#FFD1DC", "#FFABAB", "#FFC3A0"],
  ["#E63946", "#F1FAEE", "#A8DADC"],
];

export const DetailsSvg = (
  <svg
    fill="#358f8b"
    width="46px"
    height="46px"
    viewBox="0 0 64 64"
    id="Layer_1_1_"
    version="1.1"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <path d="M36,21c0-2.206-1.794-4-4-4s-4,1.794-4,4s1.794,4,4,4S36,23.206,36,21z M30,21c0-1.103,0.897-2,2-2s2,0.897,2,2 s-0.897,2-2,2S30,22.103,30,21z" />{" "}
        <path d="M27,41v6h10v-6h-2V27h-8v6h2v8H27z M29,31v-2h4v14h2v2h-6v-2h2V31H29z" />{" "}
        <path d="M32,1C14.907,1,1,14.907,1,32s13.907,31,31,31s31-13.907,31-31S49.093,1,32,1z M32,61C16.009,61,3,47.991,3,32 S16.009,3,32,3s29,13.009,29,29S47.991,61,32,61z" />{" "}
        <path d="M32,7c-5.236,0-10.254,1.607-14.512,4.649l1.162,1.628C22.567,10.479,27.184,9,32,9c12.682,0,23,10.318,23,23 c0,4.816-1.479,9.433-4.277,13.35l1.628,1.162C55.393,42.254,57,37.236,57,32C57,18.215,45.785,7,32,7z" />{" "}
        <path d="M32,55C19.318,55,9,44.682,9,32c0-4.817,1.479-9.433,4.277-13.35l-1.627-1.162C8.608,21.746,7,26.764,7,32 c0,13.785,11.215,25,25,25c5.236,0,10.254-1.607,14.512-4.649l-1.162-1.628C41.433,53.521,36.816,55,32,55z" />{" "}
      </g>{" "}
    </g>
  </svg>
);

export const ExtraColorSvg = (
  <svg
    height="40px"
    width="40px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 512 512"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        style={{ fill: "#D8D8DA" }}
        d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,336.842c-44.648,0-80.842-36.194-80.842-80.842s36.194-80.842,80.842-80.842s80.842,36.194,80.842,80.842 S300.648,336.842,256,336.842z"
      />{" "}
      <path
        style={{ fill: "#a728f0" }}
        d="M282.947,188.632h220.076C485.09,122.726,441.507,67.394,383.64,34.044L229.053,188.632H282.947z"
      />{" "}
      <path
        style={{ fill: "#EBAFD1" }}
        d="M229.053,188.632L383.639,34.044C346.068,12.39,302.482,0,256,0c-23.319,0-45.899,3.135-67.368,8.978 v220.075L229.053,188.632z"
      />{" "}
      <path
        style={{ fill: "#e01a42" }}
        d="M188.632,229.053V8.978C122.726,26.91,67.394,70.493,34.045,128.36l154.586,154.588V229.053z"
      />{" "}
      <g>
        {" "}
        <polygon
          style={{ fill: "#D8D8DA" }}
          points="188.632,229.053 229.053,188.633 282.947,188.633 282.947,188.632 229.053,188.632 "
        />{" "}
        <polygon
          style={{ fill: "#D8D8DA" }}
          points="229.053,323.367 188.632,282.947 229.053,323.368 282.947,323.368 323.368,282.947 282.947,323.367 "
        />{" "}
      </g>{" "}
      <path
        style={{ fill: "#B4D8F1" }}
        d="M503.024,188.632H282.947v0.001h0.958l39.463,40.42L477.955,383.64 C499.611,346.068,512,302.482,512,256C512,232.681,508.865,210.099,503.024,188.632z"
      />{" "}
      <path
        style={{ fill: "#2aead0" }}
        d="M323.368,282.947v220.075c65.905-17.932,121.238-61.517,154.586-119.382L323.368,229.053V282.947z"
      />{" "}
      <path
        style={{ fill: "#95D5A7" }}
        d="M282.947,323.368L128.361,477.956C165.932,499.61,209.518,512,256,512 c23.319,0,45.899-3.135,67.368-8.977V282.947L282.947,323.368z"
      />{" "}
      <path
        style={{ fill: "#fbd932" }}
        d="M229.053,323.368H8.976C26.91,389.274,70.493,444.606,128.36,477.956l154.588-154.588H229.053z"
      />{" "}
      <path
        style={{ fill: "#EFC27B" }}
        d="M188.632,282.947L34.045,128.36C12.389,165.932,0,209.518,0,256c0,23.319,3.135,45.901,8.976,67.368 h220.076L188.632,282.947z"
      />{" "}
      <polygon
        style={{ fill: "#D8D8DA" }}
        points="283.905,188.633 282.947,188.633 323.368,229.053 "
      />{" "}
      <path
        style={{ fill: "#9726d9" }}
        d="M503.024,188.632C485.09,122.726,441.507,67.394,383.64,34.044L256,161.684v26.947h26.947H503.024z"
      />{" "}
      <path
        style={{ fill: "#E592BF" }}
        d="M383.639,34.044C346.068,12.39,302.482,0,256,0v161.684L383.639,34.044z"
      />{" "}
      <path
        style={{ fill: "#59cf76" }}
        d="M256,350.316V512c23.319,0,45.899-3.135,67.368-8.977V282.947l-40.421,40.421L256,350.316z"
      />{" "}
      <polygon
        style={{ fill: "#F6E27D" }}
        points="282.947,323.368 256,323.368 256,350.316 "
      />{" "}
    </g>
  </svg>
);
