import React from 'react'

export default function BillingIcon() {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 0C19.7956 0 20.5587 0.316071 21.1213 0.87868C21.6839 1.44129 22 2.20435 22 3V5H20V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.31607 18.5587 0 17.7956 0 17V15H16V17C16 17.2449 16.09 17.4813 16.2527 17.6644C16.4155 17.8474 16.6397 17.9643 16.883 17.993L17 18C17.2449 18 17.4813 17.91 17.6644 17.7473C17.8474 17.5845 17.9643 17.3603 17.993 17.117L18 17V13H2V3C2 2.20435 2.31607 1.44129 2.87868 0.87868C3.44129 0.316071 4.20435 0 5 0H19Z" fill="white" />
        </svg>

    )
}
